<script lang="ts" setup>
import type { RouteLocationRaw } from 'vue-router';

const { footerMenu } = useContextStore();

const runtimeConfig = useRuntimeConfig();

function shouldOpenNewTab(url: RouteLocationRaw): boolean {
    if (typeof url !== 'string') {
        return false;
    }

    return (
        url.startsWith('http') && !url.startsWith(runtimeConfig.public.baseUrl)
    );
}
</script>

<template>
    <div class="flex flex-col gap-y-5">
        <TextBody bold>О морском институте</TextBody>

        <nav
            class="grid grid-cols-2 gap-x-1 xl:gap-x-16 gap-y-1 xl:gap-y-2 items-start"
        >
            <LinkText
                v-for="(item, idx) in footerMenu"
                :key="idx"
                :to="item.url"
                :target="shouldOpenNewTab(item.url) ? '_blank' : undefined"
                size="sm"
            >
                {{ item.text }}
            </LinkText>
        </nav>
    </div>
</template>

<style scoped></style>
