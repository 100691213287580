import validate from "/home/runner/work/flagman/flagman/apps/frontstore/node_modules/nuxt/dist/pages/runtime/validate.js";
import call_45tracking_45global from "/home/runner/work/flagman/flagman/apps/frontstore/middleware/callTracking.global.ts";
import manifest_45route_45rule from "/home/runner/work/flagman/flagman/apps/frontstore/node_modules/nuxt/dist/app/middleware/manifest-route-rule.js";
export const globalMiddleware = [
  validate,
  call_45tracking_45global,
  manifest_45route_45rule
]
export const namedMiddleware = {
  "sanctum:auth": () => import("/home/runner/work/flagman/flagman/apps/frontstore/node_modules/nuxt-auth-sanctum/dist/runtime/middleware/sanctum.auth.js"),
  "sanctum:guest": () => import("/home/runner/work/flagman/flagman/apps/frontstore/node_modules/nuxt-auth-sanctum/dist/runtime/middleware/sanctum.guest.js")
}