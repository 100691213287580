<script lang="ts" setup>
const { remoteConfig } = useConfigStore();
</script>

<template>
    <div class="flex gap-2">
        <NuxtLink
            external
            :to="remoteConfig.SocialRutube"
            title="RuTube"
            class="bg-white rounded-full p-1.5 xl:p-2 text-uk-orange"
            target="_blank"
        >
            <IconSocialRutube />
        </NuxtLink>

        <NuxtLink
            external
            :to="remoteConfig.SocialTelegram"
            title="Telegram"
            class="bg-white rounded-full p-1.5 xl:p-2 text-uk-orange"
            target="_blank"
        >
            <IconSocialTelegram />
        </NuxtLink>

        <NuxtLink
            external
            :to="remoteConfig.SocialOk"
            title="Одноклассники"
            class="bg-white rounded-full p-1.5 xl:p-2 text-uk-orange"
            target="_blank"
        >
            <IconSocialOk />
        </NuxtLink>

        <NuxtLink
            external
            :to="remoteConfig.SocialVk"
            title="Вконтакте"
            class="bg-white rounded-full p-1.5 xl:p-2 text-uk-orange"
            target="_blank"
        >
            <IconSocialVk />
        </NuxtLink>
    </div>
</template>

<style scoped></style>
