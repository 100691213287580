import { default as _91id_93nVpXxPXDNbMeta } from "/home/runner/work/flagman/flagman/apps/frontstore/pages/about/careers/[id].vue?macro=true";
import { default as indexczdHtGGnDOMeta } from "/home/runner/work/flagman/flagman/apps/frontstore/pages/about/careers/index.vue?macro=true";
import { default as _91slug_93LJhFq8GY93Meta } from "/home/runner/work/flagman/flagman/apps/frontstore/pages/about/gallery/[slug].vue?macro=true";
import { default as indexGUtDJ18gtiMeta } from "/home/runner/work/flagman/flagman/apps/frontstore/pages/about/gallery/index.vue?macro=true";
import { default as indexm3pdmU7mO3Meta } from "/home/runner/work/flagman/flagman/apps/frontstore/pages/about/index.vue?macro=true";
import { default as teamJUEueRWbjaMeta } from "/home/runner/work/flagman/flagman/apps/frontstore/pages/about/team.vue?macro=true";
import { default as _91slug_933sJRZVA1KNMeta } from "/home/runner/work/flagman/flagman/apps/frontstore/pages/articles/[slug].vue?macro=true";
import { default as indexIXLFxwaqBAMeta } from "/home/runner/work/flagman/flagman/apps/frontstore/pages/articles/index.vue?macro=true";
import { default as failurerDoiZ4jE4XMeta } from "/home/runner/work/flagman/flagman/apps/frontstore/pages/cart/failure.vue?macro=true";
import { default as indexDAiSHT8gP7Meta } from "/home/runner/work/flagman/flagman/apps/frontstore/pages/cart/index.vue?macro=true";
import { default as successrWpEVObAyLMeta } from "/home/runner/work/flagman/flagman/apps/frontstore/pages/cart/success.vue?macro=true";
import { default as certificates6Vz8ubqzW9Meta } from "/home/runner/work/flagman/flagman/apps/frontstore/pages/certificates.vue?macro=true";
import { default as contactsvfleAaOUu6Meta } from "/home/runner/work/flagman/flagman/apps/frontstore/pages/contacts.vue?macro=true";
import { default as _91slug_93dp372dPtIDMeta } from "/home/runner/work/flagman/flagman/apps/frontstore/pages/courses/[slug].vue?macro=true";
import { default as indexpCjLiQqyyjMeta } from "/home/runner/work/flagman/flagman/apps/frontstore/pages/courses/index.vue?macro=true";
import { default as test0vCG7q3gOxMeta } from "/home/runner/work/flagman/flagman/apps/frontstore/pages/courses/test.vue?macro=true";
import { default as documentsDTYSjaOdJcMeta } from "/home/runner/work/flagman/flagman/apps/frontstore/pages/documents.vue?macro=true";
import { default as indexhkg0rt4uqaMeta } from "/home/runner/work/flagman/flagman/apps/frontstore/pages/index.vue?macro=true";
import { default as _91slug_93DA2vqy0tf6Meta } from "/home/runner/work/flagman/flagman/apps/frontstore/pages/news/[slug].vue?macro=true";
import { default as indexbV264kfQwJMeta } from "/home/runner/work/flagman/flagman/apps/frontstore/pages/news/index.vue?macro=true";
import { default as policyuSLlXBcO5BMeta } from "/home/runner/work/flagman/flagman/apps/frontstore/pages/policy.vue?macro=true";
import { default as _91slug_934shkDyLQ13Meta } from "/home/runner/work/flagman/flagman/apps/frontstore/pages/promotions/[slug].vue?macro=true";
import { default as indexMB8qXhFQR6Meta } from "/home/runner/work/flagman/flagman/apps/frontstore/pages/promotions/index.vue?macro=true";
import { default as reviewsNtR5gkf3P7Meta } from "/home/runner/work/flagman/flagman/apps/frontstore/pages/reviews.vue?macro=true";
import { default as searchuWR54qGfVdMeta } from "/home/runner/work/flagman/flagman/apps/frontstore/pages/search.vue?macro=true";
import { default as _91slug_93masS76YKedMeta } from "/home/runner/work/flagman/flagman/apps/frontstore/pages/shop/[slug].vue?macro=true";
import { default as indexM289ivDXM9Meta } from "/home/runner/work/flagman/flagman/apps/frontstore/pages/shop/index.vue?macro=true";
import { default as _91slug_93qeZxUdQbbPMeta } from "/home/runner/work/flagman/flagman/apps/frontstore/pages/shop/product/[slug].vue?macro=true";
import { default as component_45stubYjueJ23IJZMeta } from "/home/runner/work/flagman/flagman/apps/frontstore/node_modules/nuxt/dist/pages/runtime/component-stub?macro=true";
import { default as component_45stubYjueJ23IJZ } from "/home/runner/work/flagman/flagman/apps/frontstore/node_modules/nuxt/dist/pages/runtime/component-stub";
export default [
  {
    name: _91id_93nVpXxPXDNbMeta?.name ?? "about-careers-id",
    path: _91id_93nVpXxPXDNbMeta?.path ?? "/about/careers/:id()",
    meta: _91id_93nVpXxPXDNbMeta || {},
    alias: _91id_93nVpXxPXDNbMeta?.alias || [],
    redirect: _91id_93nVpXxPXDNbMeta?.redirect,
    component: () => import("/home/runner/work/flagman/flagman/apps/frontstore/pages/about/careers/[id].vue").then(m => m.default || m)
  },
  {
    name: indexczdHtGGnDOMeta?.name ?? "about-careers",
    path: indexczdHtGGnDOMeta?.path ?? "/about/careers",
    meta: indexczdHtGGnDOMeta || {},
    alias: indexczdHtGGnDOMeta?.alias || [],
    redirect: indexczdHtGGnDOMeta?.redirect,
    component: () => import("/home/runner/work/flagman/flagman/apps/frontstore/pages/about/careers/index.vue").then(m => m.default || m)
  },
  {
    name: _91slug_93LJhFq8GY93Meta?.name ?? "about-gallery-slug",
    path: _91slug_93LJhFq8GY93Meta?.path ?? "/about/gallery/:slug()",
    meta: _91slug_93LJhFq8GY93Meta || {},
    alias: _91slug_93LJhFq8GY93Meta?.alias || [],
    redirect: _91slug_93LJhFq8GY93Meta?.redirect,
    component: () => import("/home/runner/work/flagman/flagman/apps/frontstore/pages/about/gallery/[slug].vue").then(m => m.default || m)
  },
  {
    name: indexGUtDJ18gtiMeta?.name ?? "about-gallery",
    path: indexGUtDJ18gtiMeta?.path ?? "/about/gallery",
    meta: indexGUtDJ18gtiMeta || {},
    alias: indexGUtDJ18gtiMeta?.alias || [],
    redirect: indexGUtDJ18gtiMeta?.redirect,
    component: () => import("/home/runner/work/flagman/flagman/apps/frontstore/pages/about/gallery/index.vue").then(m => m.default || m)
  },
  {
    name: indexm3pdmU7mO3Meta?.name ?? "about",
    path: indexm3pdmU7mO3Meta?.path ?? "/about",
    meta: indexm3pdmU7mO3Meta || {},
    alias: indexm3pdmU7mO3Meta?.alias || [],
    redirect: indexm3pdmU7mO3Meta?.redirect,
    component: () => import("/home/runner/work/flagman/flagman/apps/frontstore/pages/about/index.vue").then(m => m.default || m)
  },
  {
    name: teamJUEueRWbjaMeta?.name ?? "about-team",
    path: teamJUEueRWbjaMeta?.path ?? "/about/team",
    meta: teamJUEueRWbjaMeta || {},
    alias: teamJUEueRWbjaMeta?.alias || [],
    redirect: teamJUEueRWbjaMeta?.redirect,
    component: () => import("/home/runner/work/flagman/flagman/apps/frontstore/pages/about/team.vue").then(m => m.default || m)
  },
  {
    name: _91slug_933sJRZVA1KNMeta?.name ?? "articles-slug",
    path: _91slug_933sJRZVA1KNMeta?.path ?? "/articles/:slug()",
    meta: _91slug_933sJRZVA1KNMeta || {},
    alias: _91slug_933sJRZVA1KNMeta?.alias || [],
    redirect: _91slug_933sJRZVA1KNMeta?.redirect,
    component: () => import("/home/runner/work/flagman/flagman/apps/frontstore/pages/articles/[slug].vue").then(m => m.default || m)
  },
  {
    name: indexIXLFxwaqBAMeta?.name ?? "articles",
    path: indexIXLFxwaqBAMeta?.path ?? "/articles",
    meta: indexIXLFxwaqBAMeta || {},
    alias: indexIXLFxwaqBAMeta?.alias || [],
    redirect: indexIXLFxwaqBAMeta?.redirect,
    component: () => import("/home/runner/work/flagman/flagman/apps/frontstore/pages/articles/index.vue").then(m => m.default || m)
  },
  {
    name: failurerDoiZ4jE4XMeta?.name ?? "cart-failure",
    path: failurerDoiZ4jE4XMeta?.path ?? "/cart/failure",
    meta: failurerDoiZ4jE4XMeta || {},
    alias: failurerDoiZ4jE4XMeta?.alias || [],
    redirect: failurerDoiZ4jE4XMeta?.redirect,
    component: () => import("/home/runner/work/flagman/flagman/apps/frontstore/pages/cart/failure.vue").then(m => m.default || m)
  },
  {
    name: indexDAiSHT8gP7Meta?.name ?? "cart",
    path: indexDAiSHT8gP7Meta?.path ?? "/cart",
    meta: indexDAiSHT8gP7Meta || {},
    alias: indexDAiSHT8gP7Meta?.alias || [],
    redirect: indexDAiSHT8gP7Meta?.redirect,
    component: () => import("/home/runner/work/flagman/flagman/apps/frontstore/pages/cart/index.vue").then(m => m.default || m)
  },
  {
    name: successrWpEVObAyLMeta?.name ?? "cart-success",
    path: successrWpEVObAyLMeta?.path ?? "/cart/success",
    meta: successrWpEVObAyLMeta || {},
    alias: successrWpEVObAyLMeta?.alias || [],
    redirect: successrWpEVObAyLMeta?.redirect,
    component: () => import("/home/runner/work/flagman/flagman/apps/frontstore/pages/cart/success.vue").then(m => m.default || m)
  },
  {
    name: certificates6Vz8ubqzW9Meta?.name ?? "certificates",
    path: certificates6Vz8ubqzW9Meta?.path ?? "/certificates",
    meta: certificates6Vz8ubqzW9Meta || {},
    alias: certificates6Vz8ubqzW9Meta?.alias || [],
    redirect: certificates6Vz8ubqzW9Meta?.redirect,
    component: () => import("/home/runner/work/flagman/flagman/apps/frontstore/pages/certificates.vue").then(m => m.default || m)
  },
  {
    name: contactsvfleAaOUu6Meta?.name ?? "contacts",
    path: contactsvfleAaOUu6Meta?.path ?? "/contacts",
    meta: contactsvfleAaOUu6Meta || {},
    alias: contactsvfleAaOUu6Meta?.alias || [],
    redirect: contactsvfleAaOUu6Meta?.redirect,
    component: () => import("/home/runner/work/flagman/flagman/apps/frontstore/pages/contacts.vue").then(m => m.default || m)
  },
  {
    name: _91slug_93dp372dPtIDMeta?.name ?? "courses-slug",
    path: _91slug_93dp372dPtIDMeta?.path ?? "/courses/:slug()",
    meta: _91slug_93dp372dPtIDMeta || {},
    alias: _91slug_93dp372dPtIDMeta?.alias || [],
    redirect: _91slug_93dp372dPtIDMeta?.redirect,
    component: () => import("/home/runner/work/flagman/flagman/apps/frontstore/pages/courses/[slug].vue").then(m => m.default || m)
  },
  {
    name: indexpCjLiQqyyjMeta?.name ?? "courses",
    path: indexpCjLiQqyyjMeta?.path ?? "/courses",
    meta: indexpCjLiQqyyjMeta || {},
    alias: indexpCjLiQqyyjMeta?.alias || [],
    redirect: indexpCjLiQqyyjMeta?.redirect,
    component: () => import("/home/runner/work/flagman/flagman/apps/frontstore/pages/courses/index.vue").then(m => m.default || m)
  },
  {
    name: test0vCG7q3gOxMeta?.name ?? "courses-test",
    path: test0vCG7q3gOxMeta?.path ?? "/courses/test",
    meta: test0vCG7q3gOxMeta || {},
    alias: test0vCG7q3gOxMeta?.alias || [],
    redirect: test0vCG7q3gOxMeta?.redirect,
    component: () => import("/home/runner/work/flagman/flagman/apps/frontstore/pages/courses/test.vue").then(m => m.default || m)
  },
  {
    name: documentsDTYSjaOdJcMeta?.name ?? "documents",
    path: documentsDTYSjaOdJcMeta?.path ?? "/documents",
    meta: documentsDTYSjaOdJcMeta || {},
    alias: documentsDTYSjaOdJcMeta?.alias || [],
    redirect: documentsDTYSjaOdJcMeta?.redirect,
    component: () => import("/home/runner/work/flagman/flagman/apps/frontstore/pages/documents.vue").then(m => m.default || m)
  },
  {
    name: indexhkg0rt4uqaMeta?.name ?? "index",
    path: indexhkg0rt4uqaMeta?.path ?? "/",
    meta: indexhkg0rt4uqaMeta || {},
    alias: indexhkg0rt4uqaMeta?.alias || [],
    redirect: indexhkg0rt4uqaMeta?.redirect,
    component: () => import("/home/runner/work/flagman/flagman/apps/frontstore/pages/index.vue").then(m => m.default || m)
  },
  {
    name: _91slug_93DA2vqy0tf6Meta?.name ?? "news-slug",
    path: _91slug_93DA2vqy0tf6Meta?.path ?? "/news/:slug()",
    meta: _91slug_93DA2vqy0tf6Meta || {},
    alias: _91slug_93DA2vqy0tf6Meta?.alias || [],
    redirect: _91slug_93DA2vqy0tf6Meta?.redirect,
    component: () => import("/home/runner/work/flagman/flagman/apps/frontstore/pages/news/[slug].vue").then(m => m.default || m)
  },
  {
    name: indexbV264kfQwJMeta?.name ?? "news",
    path: indexbV264kfQwJMeta?.path ?? "/news",
    meta: indexbV264kfQwJMeta || {},
    alias: indexbV264kfQwJMeta?.alias || [],
    redirect: indexbV264kfQwJMeta?.redirect,
    component: () => import("/home/runner/work/flagman/flagman/apps/frontstore/pages/news/index.vue").then(m => m.default || m)
  },
  {
    name: policyuSLlXBcO5BMeta?.name ?? "policy",
    path: policyuSLlXBcO5BMeta?.path ?? "/policy",
    meta: policyuSLlXBcO5BMeta || {},
    alias: policyuSLlXBcO5BMeta?.alias || [],
    redirect: policyuSLlXBcO5BMeta?.redirect,
    component: () => import("/home/runner/work/flagman/flagman/apps/frontstore/pages/policy.vue").then(m => m.default || m)
  },
  {
    name: _91slug_934shkDyLQ13Meta?.name ?? "promotions-slug",
    path: _91slug_934shkDyLQ13Meta?.path ?? "/promotions/:slug()",
    meta: _91slug_934shkDyLQ13Meta || {},
    alias: _91slug_934shkDyLQ13Meta?.alias || [],
    redirect: _91slug_934shkDyLQ13Meta?.redirect,
    component: () => import("/home/runner/work/flagman/flagman/apps/frontstore/pages/promotions/[slug].vue").then(m => m.default || m)
  },
  {
    name: indexMB8qXhFQR6Meta?.name ?? "promotions",
    path: indexMB8qXhFQR6Meta?.path ?? "/promotions",
    meta: indexMB8qXhFQR6Meta || {},
    alias: indexMB8qXhFQR6Meta?.alias || [],
    redirect: indexMB8qXhFQR6Meta?.redirect,
    component: () => import("/home/runner/work/flagman/flagman/apps/frontstore/pages/promotions/index.vue").then(m => m.default || m)
  },
  {
    name: reviewsNtR5gkf3P7Meta?.name ?? "reviews",
    path: reviewsNtR5gkf3P7Meta?.path ?? "/reviews",
    meta: reviewsNtR5gkf3P7Meta || {},
    alias: reviewsNtR5gkf3P7Meta?.alias || [],
    redirect: reviewsNtR5gkf3P7Meta?.redirect,
    component: () => import("/home/runner/work/flagman/flagman/apps/frontstore/pages/reviews.vue").then(m => m.default || m)
  },
  {
    name: searchuWR54qGfVdMeta?.name ?? "search",
    path: searchuWR54qGfVdMeta?.path ?? "/search",
    meta: searchuWR54qGfVdMeta || {},
    alias: searchuWR54qGfVdMeta?.alias || [],
    redirect: searchuWR54qGfVdMeta?.redirect,
    component: () => import("/home/runner/work/flagman/flagman/apps/frontstore/pages/search.vue").then(m => m.default || m)
  },
  {
    name: _91slug_93masS76YKedMeta?.name ?? "shop-slug",
    path: _91slug_93masS76YKedMeta?.path ?? "/shop/:slug()",
    meta: _91slug_93masS76YKedMeta || {},
    alias: _91slug_93masS76YKedMeta?.alias || [],
    redirect: _91slug_93masS76YKedMeta?.redirect,
    component: () => import("/home/runner/work/flagman/flagman/apps/frontstore/pages/shop/[slug].vue").then(m => m.default || m)
  },
  {
    name: indexM289ivDXM9Meta?.name ?? "shop",
    path: indexM289ivDXM9Meta?.path ?? "/shop",
    meta: indexM289ivDXM9Meta || {},
    alias: indexM289ivDXM9Meta?.alias || [],
    redirect: indexM289ivDXM9Meta?.redirect,
    component: () => import("/home/runner/work/flagman/flagman/apps/frontstore/pages/shop/index.vue").then(m => m.default || m)
  },
  {
    name: _91slug_93qeZxUdQbbPMeta?.name ?? "shop-product-slug",
    path: _91slug_93qeZxUdQbbPMeta?.path ?? "/shop/product/:slug()",
    meta: _91slug_93qeZxUdQbbPMeta || {},
    alias: _91slug_93qeZxUdQbbPMeta?.alias || [],
    redirect: _91slug_93qeZxUdQbbPMeta?.redirect,
    component: () => import("/home/runner/work/flagman/flagman/apps/frontstore/pages/shop/product/[slug].vue").then(m => m.default || m)
  },
  {
    name: component_45stubYjueJ23IJZMeta?.name ?? undefined,
    path: component_45stubYjueJ23IJZMeta?.path ?? "/2-mate-at-aframax/:pathMatch(.*)",
    meta: component_45stubYjueJ23IJZMeta || {},
    alias: component_45stubYjueJ23IJZMeta?.alias || [],
    redirect: component_45stubYjueJ23IJZMeta?.redirect,
    component: component_45stubYjueJ23IJZ
  },
  {
    name: component_45stubYjueJ23IJZMeta?.name ?? undefined,
    path: component_45stubYjueJ23IJZMeta?.path ?? "/2-mate-on-oil-bunker-vessel/:pathMatch(.*)",
    meta: component_45stubYjueJ23IJZMeta || {},
    alias: component_45stubYjueJ23IJZMeta?.alias || [],
    redirect: component_45stubYjueJ23IJZMeta?.redirect,
    component: component_45stubYjueJ23IJZ
  },
  {
    name: component_45stubYjueJ23IJZMeta?.name ?? undefined,
    path: component_45stubYjueJ23IJZMeta?.path ?? "/2-pomoshhnik-kapitana-na-aframax/:pathMatch(.*)",
    meta: component_45stubYjueJ23IJZMeta || {},
    alias: component_45stubYjueJ23IJZMeta?.alias || [],
    redirect: component_45stubYjueJ23IJZMeta?.redirect,
    component: component_45stubYjueJ23IJZ
  },
  {
    name: component_45stubYjueJ23IJZMeta?.name ?? undefined,
    path: component_45stubYjueJ23IJZMeta?.path ?? "/25iyunya-den-moryaka/:pathMatch(.*)",
    meta: component_45stubYjueJ23IJZMeta || {},
    alias: component_45stubYjueJ23IJZMeta?.alias || [],
    redirect: component_45stubYjueJ23IJZMeta?.redirect,
    component: component_45stubYjueJ23IJZ
  },
  {
    name: component_45stubYjueJ23IJZMeta?.name ?? undefined,
    path: component_45stubYjueJ23IJZMeta?.path ?? "/3-mate-at-aframax/:pathMatch(.*)",
    meta: component_45stubYjueJ23IJZMeta || {},
    alias: component_45stubYjueJ23IJZMeta?.alias || [],
    redirect: component_45stubYjueJ23IJZMeta?.redirect,
    component: component_45stubYjueJ23IJZ
  },
  {
    name: component_45stubYjueJ23IJZMeta?.name ?? undefined,
    path: component_45stubYjueJ23IJZMeta?.path ?? "/3-pomoshhnik-kapitana-na-aframax-ijul-2021/:pathMatch(.*)",
    meta: component_45stubYjueJ23IJZMeta || {},
    alias: component_45stubYjueJ23IJZMeta?.alias || [],
    redirect: component_45stubYjueJ23IJZMeta?.redirect,
    component: component_45stubYjueJ23IJZ
  },
  {
    name: component_45stubYjueJ23IJZMeta?.name ?? undefined,
    path: component_45stubYjueJ23IJZMeta?.path ?? "/3-pomoshhnik-kapitana-na-oil-bunker-vessel/:pathMatch(.*)",
    meta: component_45stubYjueJ23IJZMeta || {},
    alias: component_45stubYjueJ23IJZMeta?.alias || [],
    redirect: component_45stubYjueJ23IJZMeta?.redirect,
    component: component_45stubYjueJ23IJZ
  },
  {
    name: component_45stubYjueJ23IJZMeta?.name ?? undefined,
    path: component_45stubYjueJ23IJZMeta?.path ?? "/6115-2/:pathMatch(.*)",
    meta: component_45stubYjueJ23IJZMeta || {},
    alias: component_45stubYjueJ23IJZMeta?.alias || [],
    redirect: component_45stubYjueJ23IJZMeta?.redirect,
    component: component_45stubYjueJ23IJZ
  },
  {
    name: component_45stubYjueJ23IJZMeta?.name ?? undefined,
    path: component_45stubYjueJ23IJZMeta?.path ?? "/9670-2/:pathMatch(.*)",
    meta: component_45stubYjueJ23IJZMeta || {},
    alias: component_45stubYjueJ23IJZMeta?.alias || [],
    redirect: component_45stubYjueJ23IJZMeta?.redirect,
    component: component_45stubYjueJ23IJZ
  },
  {
    name: component_45stubYjueJ23IJZMeta?.name ?? undefined,
    path: component_45stubYjueJ23IJZMeta?.path ?? "/about-us/:pathMatch(.*)",
    meta: component_45stubYjueJ23IJZMeta || {},
    alias: component_45stubYjueJ23IJZMeta?.alias || [],
    redirect: component_45stubYjueJ23IJZMeta?.redirect,
    component: component_45stubYjueJ23IJZ
  },
  {
    name: component_45stubYjueJ23IJZMeta?.name ?? undefined,
    path: component_45stubYjueJ23IJZMeta?.path ?? "/about-us-in-the-media-evening-rostov-37-dated-02-march-2020/:pathMatch(.*)",
    meta: component_45stubYjueJ23IJZMeta || {},
    alias: component_45stubYjueJ23IJZMeta?.alias || [],
    redirect: component_45stubYjueJ23IJZMeta?.redirect,
    component: component_45stubYjueJ23IJZ
  },
  {
    name: component_45stubYjueJ23IJZMeta?.name ?? undefined,
    path: component_45stubYjueJ23IJZMeta?.path ?? "/about-us-in-the-media-water-transport-of-the-don-no-3-march-2020/:pathMatch(.*)",
    meta: component_45stubYjueJ23IJZMeta || {},
    alias: component_45stubYjueJ23IJZMeta?.alias || [],
    redirect: component_45stubYjueJ23IJZMeta?.redirect,
    component: component_45stubYjueJ23IJZ
  },
  {
    name: component_45stubYjueJ23IJZMeta?.name ?? undefined,
    path: component_45stubYjueJ23IJZMeta?.path ?? "/about-us/accessible-environment/:pathMatch(.*)",
    meta: component_45stubYjueJ23IJZMeta || {},
    alias: component_45stubYjueJ23IJZMeta?.alias || [],
    redirect: component_45stubYjueJ23IJZMeta?.redirect,
    component: component_45stubYjueJ23IJZ
  },
  {
    name: component_45stubYjueJ23IJZMeta?.name ?? undefined,
    path: component_45stubYjueJ23IJZMeta?.path ?? "/about-us/communities/:pathMatch(.*)",
    meta: component_45stubYjueJ23IJZMeta || {},
    alias: component_45stubYjueJ23IJZMeta?.alias || [],
    redirect: component_45stubYjueJ23IJZMeta?.redirect,
    component: component_45stubYjueJ23IJZ
  },
  {
    name: component_45stubYjueJ23IJZMeta?.name ?? undefined,
    path: component_45stubYjueJ23IJZMeta?.path ?? "/about-us/paid-services/:pathMatch(.*)",
    meta: component_45stubYjueJ23IJZMeta || {},
    alias: component_45stubYjueJ23IJZMeta?.alias || [],
    redirect: component_45stubYjueJ23IJZMeta?.redirect,
    component: component_45stubYjueJ23IJZ
  },
  {
    name: component_45stubYjueJ23IJZMeta?.name ?? undefined,
    path: component_45stubYjueJ23IJZMeta?.path ?? "/about-us/staff/:pathMatch(.*)",
    meta: component_45stubYjueJ23IJZMeta || {},
    alias: component_45stubYjueJ23IJZMeta?.alias || [],
    redirect: component_45stubYjueJ23IJZMeta?.redirect,
    component: component_45stubYjueJ23IJZ
  },
  {
    name: component_45stubYjueJ23IJZMeta?.name ?? undefined,
    path: component_45stubYjueJ23IJZMeta?.path ?? "/about-us/staff-2/:pathMatch(.*)",
    meta: component_45stubYjueJ23IJZMeta || {},
    alias: component_45stubYjueJ23IJZMeta?.alias || [],
    redirect: component_45stubYjueJ23IJZMeta?.redirect,
    component: component_45stubYjueJ23IJZ
  },
  {
    name: component_45stubYjueJ23IJZMeta?.name ?? undefined,
    path: component_45stubYjueJ23IJZMeta?.path ?? "/about-us/the-documents/:pathMatch(.*)",
    meta: component_45stubYjueJ23IJZMeta || {},
    alias: component_45stubYjueJ23IJZMeta?.alias || [],
    redirect: component_45stubYjueJ23IJZMeta?.redirect,
    component: component_45stubYjueJ23IJZ
  },
  {
    name: component_45stubYjueJ23IJZMeta?.name ?? undefined,
    path: component_45stubYjueJ23IJZMeta?.path ?? "/about-us/the-documents/fitness-center-certificate-of-conformity/:pathMatch(.*)",
    meta: component_45stubYjueJ23IJZMeta || {},
    alias: component_45stubYjueJ23IJZMeta?.alias || [],
    redirect: component_45stubYjueJ23IJZMeta?.redirect,
    component: component_45stubYjueJ23IJZ
  },
  {
    name: component_45stubYjueJ23IJZMeta?.name ?? undefined,
    path: component_45stubYjueJ23IJZMeta?.path ?? "/about-us/the-documents/license-to-carry-out-educational-activities/:pathMatch(.*)",
    meta: component_45stubYjueJ23IJZMeta || {},
    alias: component_45stubYjueJ23IJZMeta?.alias || [],
    redirect: component_45stubYjueJ23IJZMeta?.redirect,
    component: component_45stubYjueJ23IJZ
  },
  {
    name: component_45stubYjueJ23IJZMeta?.name ?? undefined,
    path: component_45stubYjueJ23IJZMeta?.path ?? "/about-us/the-documents/local-regulations/:pathMatch(.*)",
    meta: component_45stubYjueJ23IJZMeta || {},
    alias: component_45stubYjueJ23IJZMeta?.alias || [],
    redirect: component_45stubYjueJ23IJZMeta?.redirect,
    component: component_45stubYjueJ23IJZ
  },
  {
    name: component_45stubYjueJ23IJZMeta?.name ?? undefined,
    path: component_45stubYjueJ23IJZMeta?.path ?? "/about-us/the-documents/regulations-on-paid-services/:pathMatch(.*)",
    meta: component_45stubYjueJ23IJZMeta || {},
    alias: component_45stubYjueJ23IJZMeta?.alias || [],
    redirect: component_45stubYjueJ23IJZMeta?.redirect,
    component: component_45stubYjueJ23IJZ
  },
  {
    name: component_45stubYjueJ23IJZMeta?.name ?? undefined,
    path: component_45stubYjueJ23IJZMeta?.path ?? "/about-us/the-documents/utc-flagman-charter/:pathMatch(.*)",
    meta: component_45stubYjueJ23IJZMeta || {},
    alias: component_45stubYjueJ23IJZMeta?.alias || [],
    redirect: component_45stubYjueJ23IJZMeta?.redirect,
    component: component_45stubYjueJ23IJZ
  },
  {
    name: component_45stubYjueJ23IJZMeta?.name ?? undefined,
    path: component_45stubYjueJ23IJZMeta?.path ?? "/about-us/the-documents/utc-flagman-charter-2/:pathMatch(.*)",
    meta: component_45stubYjueJ23IJZMeta || {},
    alias: component_45stubYjueJ23IJZMeta?.alias || [],
    redirect: component_45stubYjueJ23IJZMeta?.redirect,
    component: component_45stubYjueJ23IJZ
  },
  {
    name: component_45stubYjueJ23IJZMeta?.name ?? undefined,
    path: component_45stubYjueJ23IJZMeta?.path ?? "/additional-training-for-seafarers/:pathMatch(.*)",
    meta: component_45stubYjueJ23IJZMeta || {},
    alias: component_45stubYjueJ23IJZMeta?.alias || [],
    redirect: component_45stubYjueJ23IJZMeta?.redirect,
    component: component_45stubYjueJ23IJZ
  },
  {
    name: component_45stubYjueJ23IJZMeta?.name ?? undefined,
    path: component_45stubYjueJ23IJZMeta?.path ?? "/affiliate-login/:pathMatch(.*)",
    meta: component_45stubYjueJ23IJZMeta || {},
    alias: component_45stubYjueJ23IJZMeta?.alias || [],
    redirect: component_45stubYjueJ23IJZMeta?.redirect,
    component: component_45stubYjueJ23IJZ
  },
  {
    name: component_45stubYjueJ23IJZMeta?.name ?? undefined,
    path: component_45stubYjueJ23IJZMeta?.path ?? "/affiliate-tos-page/:pathMatch(.*)",
    meta: component_45stubYjueJ23IJZMeta || {},
    alias: component_45stubYjueJ23IJZMeta?.alias || [],
    redirect: component_45stubYjueJ23IJZMeta?.redirect,
    component: component_45stubYjueJ23IJZ
  },
  {
    name: component_45stubYjueJ23IJZMeta?.name ?? undefined,
    path: component_45stubYjueJ23IJZMeta?.path ?? "/akcii/:pathMatch(.*)",
    meta: component_45stubYjueJ23IJZMeta || {},
    alias: component_45stubYjueJ23IJZMeta?.alias || [],
    redirect: component_45stubYjueJ23IJZMeta?.redirect,
    component: component_45stubYjueJ23IJZ
  },
  {
    name: component_45stubYjueJ23IJZMeta?.name ?? undefined,
    path: component_45stubYjueJ23IJZMeta?.path ?? "/akcija-privedi-druga/:pathMatch(.*)",
    meta: component_45stubYjueJ23IJZMeta || {},
    alias: component_45stubYjueJ23IJZMeta?.alias || [],
    redirect: component_45stubYjueJ23IJZMeta?.redirect,
    component: component_45stubYjueJ23IJZ
  },
  {
    name: component_45stubYjueJ23IJZMeta?.name ?? undefined,
    path: component_45stubYjueJ23IJZMeta?.path ?? "/anglijskij-jazyk-dlja-morjakov/:pathMatch(.*)",
    meta: component_45stubYjueJ23IJZMeta || {},
    alias: component_45stubYjueJ23IJZMeta?.alias || [],
    redirect: component_45stubYjueJ23IJZMeta?.redirect,
    component: component_45stubYjueJ23IJZ
  },
  {
    name: component_45stubYjueJ23IJZMeta?.name ?? undefined,
    path: component_45stubYjueJ23IJZMeta?.path ?? "/anglijskij-yazyk-dlya-moryakov/:pathMatch(.*)",
    meta: component_45stubYjueJ23IJZMeta || {},
    alias: component_45stubYjueJ23IJZMeta?.alias || [],
    redirect: component_45stubYjueJ23IJZMeta?.redirect,
    component: component_45stubYjueJ23IJZ
  },
  {
    name: component_45stubYjueJ23IJZMeta?.name ?? undefined,
    path: component_45stubYjueJ23IJZMeta?.path ?? "/become-an-affiliate/:pathMatch(.*)",
    meta: component_45stubYjueJ23IJZMeta || {},
    alias: component_45stubYjueJ23IJZMeta?.alias || [],
    redirect: component_45stubYjueJ23IJZMeta?.redirect,
    component: component_45stubYjueJ23IJZ
  },
  {
    name: component_45stubYjueJ23IJZMeta?.name ?? undefined,
    path: component_45stubYjueJ23IJZMeta?.path ?? "/best-employee-q3-2021/:pathMatch(.*)",
    meta: component_45stubYjueJ23IJZMeta || {},
    alias: component_45stubYjueJ23IJZMeta?.alias || [],
    redirect: component_45stubYjueJ23IJZMeta?.redirect,
    component: component_45stubYjueJ23IJZ
  },
  {
    name: component_45stubYjueJ23IJZMeta?.name ?? undefined,
    path: component_45stubYjueJ23IJZMeta?.path ?? "/boatswain/:pathMatch(.*)",
    meta: component_45stubYjueJ23IJZMeta || {},
    alias: component_45stubYjueJ23IJZMeta?.alias || [],
    redirect: component_45stubYjueJ23IJZMeta?.redirect,
    component: component_45stubYjueJ23IJZ
  },
  {
    name: component_45stubYjueJ23IJZMeta?.name ?? undefined,
    path: component_45stubYjueJ23IJZMeta?.path ?? "/boatswain-on-oil-bunker-vessel/:pathMatch(.*)",
    meta: component_45stubYjueJ23IJZMeta || {},
    alias: component_45stubYjueJ23IJZMeta?.alias || [],
    redirect: component_45stubYjueJ23IJZMeta?.redirect,
    component: component_45stubYjueJ23IJZ
  },
  {
    name: component_45stubYjueJ23IJZMeta?.name ?? undefined,
    path: component_45stubYjueJ23IJZMeta?.path ?? "/bwg_album/fotogalereja/:pathMatch(.*)",
    meta: component_45stubYjueJ23IJZMeta || {},
    alias: component_45stubYjueJ23IJZMeta?.alias || [],
    redirect: component_45stubYjueJ23IJZMeta?.redirect,
    component: component_45stubYjueJ23IJZ
  },
  {
    name: component_45stubYjueJ23IJZMeta?.name ?? undefined,
    path: component_45stubYjueJ23IJZMeta?.path ?? "/bwg_album/navigacionnyj-klass/:pathMatch(.*)",
    meta: component_45stubYjueJ23IJZMeta || {},
    alias: component_45stubYjueJ23IJZMeta?.alias || [],
    redirect: component_45stubYjueJ23IJZMeta?.redirect,
    component: component_45stubYjueJ23IJZ
  },
  {
    name: component_45stubYjueJ23IJZMeta?.name ?? undefined,
    path: component_45stubYjueJ23IJZMeta?.path ?? "/bwg_gallery/albom-flagman-team/:pathMatch(.*)",
    meta: component_45stubYjueJ23IJZMeta || {},
    alias: component_45stubYjueJ23IJZMeta?.alias || [],
    redirect: component_45stubYjueJ23IJZMeta?.redirect,
    component: component_45stubYjueJ23IJZ
  },
  {
    name: component_45stubYjueJ23IJZMeta?.name ?? undefined,
    path: component_45stubYjueJ23IJZMeta?.path ?? "/bwg_gallery/klass-medicinskoj-podgotovki/:pathMatch(.*)",
    meta: component_45stubYjueJ23IJZMeta || {},
    alias: component_45stubYjueJ23IJZMeta?.alias || [],
    redirect: component_45stubYjueJ23IJZMeta?.redirect,
    component: component_45stubYjueJ23IJZ
  },
  {
    name: component_45stubYjueJ23IJZMeta?.name ?? undefined,
    path: component_45stubYjueJ23IJZMeta?.path ?? "/bwg_gallery/navigacionnyj-klass/:pathMatch(.*)",
    meta: component_45stubYjueJ23IJZMeta || {},
    alias: component_45stubYjueJ23IJZMeta?.alias || [],
    redirect: component_45stubYjueJ23IJZMeta?.redirect,
    component: component_45stubYjueJ23IJZ
  },
  {
    name: component_45stubYjueJ23IJZMeta?.name ?? undefined,
    path: component_45stubYjueJ23IJZMeta?.path ?? "/bwg_gallery/trenazher-gmssb/:pathMatch(.*)",
    meta: component_45stubYjueJ23IJZMeta || {},
    alias: component_45stubYjueJ23IJZMeta?.alias || [],
    redirect: component_45stubYjueJ23IJZMeta?.redirect,
    component: component_45stubYjueJ23IJZ
  },
  {
    name: component_45stubYjueJ23IJZMeta?.name ?? undefined,
    path: component_45stubYjueJ23IJZMeta?.path ?? "/bwg_gallery/trenazher-vysokogo-naprjazhenija/:pathMatch(.*)",
    meta: component_45stubYjueJ23IJZMeta || {},
    alias: component_45stubYjueJ23IJZMeta?.alias || [],
    redirect: component_45stubYjueJ23IJZMeta?.redirect,
    component: component_45stubYjueJ23IJZ
  },
  {
    name: component_45stubYjueJ23IJZMeta?.name ?? undefined,
    path: component_45stubYjueJ23IJZMeta?.path ?? "/bwg_gallery/vyzhivanie-na-more/:pathMatch(.*)",
    meta: component_45stubYjueJ23IJZMeta || {},
    alias: component_45stubYjueJ23IJZMeta?.alias || [],
    redirect: component_45stubYjueJ23IJZMeta?.redirect,
    component: component_45stubYjueJ23IJZ
  },
  {
    name: component_45stubYjueJ23IJZMeta?.name ?? undefined,
    path: component_45stubYjueJ23IJZMeta?.path ?? "/calculate-services/:pathMatch(.*)",
    meta: component_45stubYjueJ23IJZMeta || {},
    alias: component_45stubYjueJ23IJZMeta?.alias || [],
    redirect: component_45stubYjueJ23IJZMeta?.redirect,
    component: component_45stubYjueJ23IJZ
  },
  {
    name: component_45stubYjueJ23IJZMeta?.name ?? undefined,
    path: component_45stubYjueJ23IJZMeta?.path ?? "/calendar-2/:pathMatch(.*)",
    meta: component_45stubYjueJ23IJZMeta || {},
    alias: component_45stubYjueJ23IJZMeta?.alias || [],
    redirect: component_45stubYjueJ23IJZMeta?.redirect,
    component: component_45stubYjueJ23IJZ
  },
  {
    name: component_45stubYjueJ23IJZMeta?.name ?? undefined,
    path: component_45stubYjueJ23IJZMeta?.path ?? "/calendar/:pathMatch(.*)",
    meta: component_45stubYjueJ23IJZMeta || {},
    alias: component_45stubYjueJ23IJZMeta?.alias || [],
    redirect: component_45stubYjueJ23IJZMeta?.redirect,
    component: component_45stubYjueJ23IJZ
  },
  {
    name: component_45stubYjueJ23IJZMeta?.name ?? undefined,
    path: component_45stubYjueJ23IJZMeta?.path ?? "/captain-on-the-tanker-aframax/:pathMatch(.*)",
    meta: component_45stubYjueJ23IJZMeta || {},
    alias: component_45stubYjueJ23IJZMeta?.alias || [],
    redirect: component_45stubYjueJ23IJZMeta?.redirect,
    component: component_45stubYjueJ23IJZ
  },
  {
    name: component_45stubYjueJ23IJZMeta?.name ?? undefined,
    path: component_45stubYjueJ23IJZMeta?.path ?? "/category/articles/:pathMatch(.*)",
    meta: component_45stubYjueJ23IJZMeta || {},
    alias: component_45stubYjueJ23IJZMeta?.alias || [],
    redirect: component_45stubYjueJ23IJZMeta?.redirect,
    component: component_45stubYjueJ23IJZ
  },
  {
    name: component_45stubYjueJ23IJZMeta?.name ?? undefined,
    path: component_45stubYjueJ23IJZMeta?.path ?? "/category/novosti/:pathMatch(.*)",
    meta: component_45stubYjueJ23IJZMeta || {},
    alias: component_45stubYjueJ23IJZMeta?.alias || [],
    redirect: component_45stubYjueJ23IJZMeta?.redirect,
    component: component_45stubYjueJ23IJZ
  },
  {
    name: component_45stubYjueJ23IJZMeta?.name ?? undefined,
    path: component_45stubYjueJ23IJZMeta?.path ?? "/chief-mate-at-aframax/:pathMatch(.*)",
    meta: component_45stubYjueJ23IJZMeta || {},
    alias: component_45stubYjueJ23IJZMeta?.alias || [],
    redirect: component_45stubYjueJ23IJZMeta?.redirect,
    component: component_45stubYjueJ23IJZ
  },
  {
    name: component_45stubYjueJ23IJZMeta?.name ?? undefined,
    path: component_45stubYjueJ23IJZMeta?.path ?? "/chief-mate-on-an-oil-bunker-vessel/:pathMatch(.*)",
    meta: component_45stubYjueJ23IJZMeta || {},
    alias: component_45stubYjueJ23IJZMeta?.alias || [],
    redirect: component_45stubYjueJ23IJZMeta?.redirect,
    component: component_45stubYjueJ23IJZ
  },
  {
    name: component_45stubYjueJ23IJZMeta?.name ?? undefined,
    path: component_45stubYjueJ23IJZMeta?.path ?? "/cost-of-education/:pathMatch(.*)",
    meta: component_45stubYjueJ23IJZMeta || {},
    alias: component_45stubYjueJ23IJZMeta?.alias || [],
    redirect: component_45stubYjueJ23IJZMeta?.redirect,
    component: component_45stubYjueJ23IJZ
  },
  {
    name: component_45stubYjueJ23IJZMeta?.name ?? undefined,
    path: component_45stubYjueJ23IJZMeta?.path ?? "/course-selection/:pathMatch(.*)",
    meta: component_45stubYjueJ23IJZMeta || {},
    alias: component_45stubYjueJ23IJZMeta?.alias || [],
    redirect: component_45stubYjueJ23IJZMeta?.redirect,
    component: component_45stubYjueJ23IJZ
  },
  {
    name: component_45stubYjueJ23IJZMeta?.name ?? undefined,
    path: component_45stubYjueJ23IJZMeta?.path ?? "/cybersecurity-on-ships-and-cybersecurity-in-the-maritime-industry/:pathMatch(.*)",
    meta: component_45stubYjueJ23IJZMeta || {},
    alias: component_45stubYjueJ23IJZMeta?.alias || [],
    redirect: component_45stubYjueJ23IJZMeta?.redirect,
    component: component_45stubYjueJ23IJZ
  },
  {
    name: component_45stubYjueJ23IJZMeta?.name ?? undefined,
    path: component_45stubYjueJ23IJZMeta?.path ?? "/delta-testing/:pathMatch(.*)",
    meta: component_45stubYjueJ23IJZMeta || {},
    alias: component_45stubYjueJ23IJZMeta?.alias || [],
    redirect: component_45stubYjueJ23IJZMeta?.redirect,
    component: component_45stubYjueJ23IJZ
  },
  {
    name: component_45stubYjueJ23IJZMeta?.name ?? undefined,
    path: component_45stubYjueJ23IJZMeta?.path ?? "/delta-testirovanie/:pathMatch(.*)",
    meta: component_45stubYjueJ23IJZMeta || {},
    alias: component_45stubYjueJ23IJZMeta?.alias || [],
    redirect: component_45stubYjueJ23IJZMeta?.redirect,
    component: component_45stubYjueJ23IJZ
  },
  {
    name: component_45stubYjueJ23IJZMeta?.name ?? undefined,
    path: component_45stubYjueJ23IJZMeta?.path ?? "/diplomvvp/:pathMatch(.*)",
    meta: component_45stubYjueJ23IJZMeta || {},
    alias: component_45stubYjueJ23IJZMeta?.alias || [],
    redirect: component_45stubYjueJ23IJZMeta?.redirect,
    component: component_45stubYjueJ23IJZ
  },
  {
    name: component_45stubYjueJ23IJZMeta?.name ?? undefined,
    path: component_45stubYjueJ23IJZMeta?.path ?? "/dokumenty/:pathMatch(.*)",
    meta: component_45stubYjueJ23IJZMeta || {},
    alias: component_45stubYjueJ23IJZMeta?.alias || [],
    redirect: component_45stubYjueJ23IJZMeta?.redirect,
    component: component_45stubYjueJ23IJZ
  },
  {
    name: component_45stubYjueJ23IJZMeta?.name ?? undefined,
    path: component_45stubYjueJ23IJZMeta?.path ?? "/dopolnitelnaja-podgotovka-morjakov/:pathMatch(.*)",
    meta: component_45stubYjueJ23IJZMeta || {},
    alias: component_45stubYjueJ23IJZMeta?.alias || [],
    redirect: component_45stubYjueJ23IJZMeta?.redirect,
    component: component_45stubYjueJ23IJZ
  },
  {
    name: component_45stubYjueJ23IJZMeta?.name ?? undefined,
    path: component_45stubYjueJ23IJZMeta?.path ?? "/drugie-napravlenija/:pathMatch(.*)",
    meta: component_45stubYjueJ23IJZMeta || {},
    alias: component_45stubYjueJ23IJZMeta?.alias || [],
    redirect: component_45stubYjueJ23IJZMeta?.redirect,
    component: component_45stubYjueJ23IJZ
  },
  {
    name: component_45stubYjueJ23IJZMeta?.name ?? undefined,
    path: component_45stubYjueJ23IJZMeta?.path ?? "/e-learning-at-the-flagship-training-and-training-center/:pathMatch(.*)",
    meta: component_45stubYjueJ23IJZMeta || {},
    alias: component_45stubYjueJ23IJZMeta?.alias || [],
    redirect: component_45stubYjueJ23IJZMeta?.redirect,
    component: component_45stubYjueJ23IJZ
  },
  {
    name: component_45stubYjueJ23IJZMeta?.name ?? undefined,
    path: component_45stubYjueJ23IJZMeta?.path ?? "/education/:pathMatch(.*)",
    meta: component_45stubYjueJ23IJZMeta || {},
    alias: component_45stubYjueJ23IJZMeta?.alias || [],
    redirect: component_45stubYjueJ23IJZMeta?.redirect,
    component: component_45stubYjueJ23IJZ
  },
  {
    name: component_45stubYjueJ23IJZMeta?.name ?? undefined,
    path: component_45stubYjueJ23IJZMeta?.path ?? "/education/additional-training-for-seafarers/:pathMatch(.*)",
    meta: component_45stubYjueJ23IJZMeta || {},
    alias: component_45stubYjueJ23IJZMeta?.alias || [],
    redirect: component_45stubYjueJ23IJZMeta?.redirect,
    component: component_45stubYjueJ23IJZ
  },
  {
    name: component_45stubYjueJ23IJZMeta?.name ?? undefined,
    path: component_45stubYjueJ23IJZMeta?.path ?? "/education/bzhs/:pathMatch(.*)",
    meta: component_45stubYjueJ23IJZMeta || {},
    alias: component_45stubYjueJ23IJZMeta?.alias || [],
    redirect: component_45stubYjueJ23IJZMeta?.redirect,
    component: component_45stubYjueJ23IJZ
  },
  {
    name: component_45stubYjueJ23IJZMeta?.name ?? undefined,
    path: component_45stubYjueJ23IJZMeta?.path ?? "/education/dangerous-goods-and-the-environment/:pathMatch(.*)",
    meta: component_45stubYjueJ23IJZMeta || {},
    alias: component_45stubYjueJ23IJZMeta?.alias || [],
    redirect: component_45stubYjueJ23IJZMeta?.redirect,
    component: component_45stubYjueJ23IJZ
  },
  {
    name: component_45stubYjueJ23IJZMeta?.name ?? undefined,
    path: component_45stubYjueJ23IJZMeta?.path ?? "/education/engine-room-simulators/:pathMatch(.*)",
    meta: component_45stubYjueJ23IJZMeta || {},
    alias: component_45stubYjueJ23IJZMeta?.alias || [],
    redirect: component_45stubYjueJ23IJZMeta?.redirect,
    component: component_45stubYjueJ23IJZ
  },
  {
    name: component_45stubYjueJ23IJZMeta?.name ?? undefined,
    path: component_45stubYjueJ23IJZMeta?.path ?? "/education/foreign-language/:pathMatch(.*)",
    meta: component_45stubYjueJ23IJZMeta || {},
    alias: component_45stubYjueJ23IJZMeta?.alias || [],
    redirect: component_45stubYjueJ23IJZMeta?.redirect,
    component: component_45stubYjueJ23IJZ
  },
  {
    name: component_45stubYjueJ23IJZMeta?.name ?? undefined,
    path: component_45stubYjueJ23IJZMeta?.path ?? "/education/gmdss/:pathMatch(.*)",
    meta: component_45stubYjueJ23IJZMeta || {},
    alias: component_45stubYjueJ23IJZMeta?.alias || [],
    redirect: component_45stubYjueJ23IJZMeta?.redirect,
    component: component_45stubYjueJ23IJZ
  },
  {
    name: component_45stubYjueJ23IJZMeta?.name ?? undefined,
    path: component_45stubYjueJ23IJZMeta?.path ?? "/education/medicine-and-hygiene/:pathMatch(.*)",
    meta: component_45stubYjueJ23IJZMeta || {},
    alias: component_45stubYjueJ23IJZMeta?.alias || [],
    redirect: component_45stubYjueJ23IJZMeta?.redirect,
    component: component_45stubYjueJ23IJZ
  },
  {
    name: component_45stubYjueJ23IJZMeta?.name ?? undefined,
    path: component_45stubYjueJ23IJZMeta?.path ?? "/education/navigation-simulators/:pathMatch(.*)",
    meta: component_45stubYjueJ23IJZMeta || {},
    alias: component_45stubYjueJ23IJZMeta?.alias || [],
    redirect: component_45stubYjueJ23IJZMeta?.redirect,
    component: component_45stubYjueJ23IJZ
  },
  {
    name: component_45stubYjueJ23IJZMeta?.name ?? undefined,
    path: component_45stubYjueJ23IJZMeta?.path ?? "/education/occupational-safety-on-ships/:pathMatch(.*)",
    meta: component_45stubYjueJ23IJZMeta || {},
    alias: component_45stubYjueJ23IJZMeta?.alias || [],
    redirect: component_45stubYjueJ23IJZMeta?.redirect,
    component: component_45stubYjueJ23IJZ
  },
  {
    name: component_45stubYjueJ23IJZMeta?.name ?? undefined,
    path: component_45stubYjueJ23IJZMeta?.path ?? "/education/onshore-specialist-training/:pathMatch(.*)",
    meta: component_45stubYjueJ23IJZMeta || {},
    alias: component_45stubYjueJ23IJZMeta?.alias || [],
    redirect: component_45stubYjueJ23IJZMeta?.redirect,
    component: component_45stubYjueJ23IJZ
  },
  {
    name: component_45stubYjueJ23IJZMeta?.name ?? undefined,
    path: component_45stubYjueJ23IJZMeta?.path ?? "/education/passenger-ships/:pathMatch(.*)",
    meta: component_45stubYjueJ23IJZMeta || {},
    alias: component_45stubYjueJ23IJZMeta?.alias || [],
    redirect: component_45stubYjueJ23IJZMeta?.redirect,
    component: component_45stubYjueJ23IJZ
  },
  {
    name: component_45stubYjueJ23IJZMeta?.name ?? undefined,
    path: component_45stubYjueJ23IJZMeta?.path ?? "/education/security/:pathMatch(.*)",
    meta: component_45stubYjueJ23IJZMeta || {},
    alias: component_45stubYjueJ23IJZMeta?.alias || [],
    redirect: component_45stubYjueJ23IJZMeta?.redirect,
    component: component_45stubYjueJ23IJZ
  },
  {
    name: component_45stubYjueJ23IJZMeta?.name ?? undefined,
    path: component_45stubYjueJ23IJZMeta?.path ?? "/education/security-and-security-of-ships/:pathMatch(.*)",
    meta: component_45stubYjueJ23IJZMeta || {},
    alias: component_45stubYjueJ23IJZMeta?.alias || [],
    redirect: component_45stubYjueJ23IJZMeta?.redirect,
    component: component_45stubYjueJ23IJZ
  },
  {
    name: component_45stubYjueJ23IJZMeta?.name ?? undefined,
    path: component_45stubYjueJ23IJZMeta?.path ?? "/education/small-boats/:pathMatch(.*)",
    meta: component_45stubYjueJ23IJZMeta || {},
    alias: component_45stubYjueJ23IJZMeta?.alias || [],
    redirect: component_45stubYjueJ23IJZMeta?.redirect,
    component: component_45stubYjueJ23IJZ
  },
  {
    name: component_45stubYjueJ23IJZMeta?.name ?? undefined,
    path: component_45stubYjueJ23IJZMeta?.path ?? "/education/tanker-handling-and-dangerous-goods/:pathMatch(.*)",
    meta: component_45stubYjueJ23IJZMeta || {},
    alias: component_45stubYjueJ23IJZMeta?.alias || [],
    redirect: component_45stubYjueJ23IJZMeta?.redirect,
    component: component_45stubYjueJ23IJZ
  },
  {
    name: component_45stubYjueJ23IJZMeta?.name ?? undefined,
    path: component_45stubYjueJ23IJZMeta?.path ?? "/education/testing-and-additional-services/:pathMatch(.*)",
    meta: component_45stubYjueJ23IJZMeta || {},
    alias: component_45stubYjueJ23IJZMeta?.alias || [],
    redirect: component_45stubYjueJ23IJZMeta?.redirect,
    component: component_45stubYjueJ23IJZ
  },
  {
    name: component_45stubYjueJ23IJZMeta?.name ?? undefined,
    path: component_45stubYjueJ23IJZMeta?.path ?? "/education/transport-safety/:pathMatch(.*)",
    meta: component_45stubYjueJ23IJZMeta || {},
    alias: component_45stubYjueJ23IJZMeta?.alias || [],
    redirect: component_45stubYjueJ23IJZMeta?.redirect,
    component: component_45stubYjueJ23IJZ
  },
  {
    name: component_45stubYjueJ23IJZMeta?.name ?? undefined,
    path: component_45stubYjueJ23IJZMeta?.path ?? "/education/unorganized-masses/:pathMatch(.*)",
    meta: component_45stubYjueJ23IJZMeta || {},
    alias: component_45stubYjueJ23IJZMeta?.alias || [],
    redirect: component_45stubYjueJ23IJZMeta?.redirect,
    component: component_45stubYjueJ23IJZ
  },
  {
    name: component_45stubYjueJ23IJZMeta?.name ?? undefined,
    path: component_45stubYjueJ23IJZMeta?.path ?? "/english-for-seafarers/:pathMatch(.*)",
    meta: component_45stubYjueJ23IJZMeta || {},
    alias: component_45stubYjueJ23IJZMeta?.alias || [],
    redirect: component_45stubYjueJ23IJZMeta?.redirect,
    component: component_45stubYjueJ23IJZ
  },
  {
    name: component_45stubYjueJ23IJZMeta?.name ?? undefined,
    path: component_45stubYjueJ23IJZMeta?.path ?? "/english-for-seafarers-2/:pathMatch(.*)",
    meta: component_45stubYjueJ23IJZMeta || {},
    alias: component_45stubYjueJ23IJZMeta?.alias || [],
    redirect: component_45stubYjueJ23IJZMeta?.redirect,
    component: component_45stubYjueJ23IJZ
  },
  {
    name: component_45stubYjueJ23IJZMeta?.name ?? undefined,
    path: component_45stubYjueJ23IJZMeta?.path ?? "/enrollment-in-a-group-training-for-the-right-to-operate-small-boats-and-jet-skis",
    meta: component_45stubYjueJ23IJZMeta || {},
    alias: component_45stubYjueJ23IJZMeta?.alias || [],
    redirect: component_45stubYjueJ23IJZMeta?.redirect,
    component: component_45stubYjueJ23IJZ
  },
  {
    name: component_45stubYjueJ23IJZMeta?.name ?? undefined,
    path: component_45stubYjueJ23IJZMeta?.path ?? "/enrollment-is-open-for-refresher-courses-when-extending-a-diploma/:pathMatch(.*)",
    meta: component_45stubYjueJ23IJZMeta || {},
    alias: component_45stubYjueJ23IJZMeta?.alias || [],
    redirect: component_45stubYjueJ23IJZMeta?.redirect,
    component: component_45stubYjueJ23IJZ
  },
  {
    name: component_45stubYjueJ23IJZMeta?.name ?? undefined,
    path: component_45stubYjueJ23IJZMeta?.path ?? "/events_categories/navigacionnye-trenazhyory/:pathMatch(.*)",
    meta: component_45stubYjueJ23IJZMeta || {},
    alias: component_45stubYjueJ23IJZMeta?.alias || [],
    redirect: component_45stubYjueJ23IJZMeta?.redirect,
    component: component_45stubYjueJ23IJZ
  },
  {
    name: component_45stubYjueJ23IJZMeta?.name ?? undefined,
    path: component_45stubYjueJ23IJZMeta?.path ?? "/events_categories/nbzhs/:pathMatch(.*)",
    meta: component_45stubYjueJ23IJZMeta || {},
    alias: component_45stubYjueJ23IJZMeta?.alias || [],
    redirect: component_45stubYjueJ23IJZMeta?.redirect,
    component: component_45stubYjueJ23IJZ
  },
  {
    name: component_45stubYjueJ23IJZMeta?.name ?? undefined,
    path: component_45stubYjueJ23IJZMeta?.path ?? "/events_categories/podgotovka-po-oxrane-morskoj-okruzhayushhej-sredy/",
    meta: component_45stubYjueJ23IJZMeta || {},
    alias: component_45stubYjueJ23IJZMeta?.alias || [],
    redirect: component_45stubYjueJ23IJZMeta?.redirect,
    component: component_45stubYjueJ23IJZ
  },
  {
    name: component_45stubYjueJ23IJZMeta?.name ?? undefined,
    path: component_45stubYjueJ23IJZMeta?.path ?? "/events_categories/tankernaya-podgotovka/:pathMatch(.*)",
    meta: component_45stubYjueJ23IJZMeta || {},
    alias: component_45stubYjueJ23IJZMeta?.alias || [],
    redirect: component_45stubYjueJ23IJZMeta?.redirect,
    component: component_45stubYjueJ23IJZ
  },
  {
    name: component_45stubYjueJ23IJZMeta?.name ?? undefined,
    path: component_45stubYjueJ23IJZMeta?.path ?? "/events_categories/trenazhyory-mashinnogo-otdeleniya/:pathMatch(.*)",
    meta: component_45stubYjueJ23IJZMeta || {},
    alias: component_45stubYjueJ23IJZMeta?.alias || [],
    redirect: component_45stubYjueJ23IJZMeta?.redirect,
    component: component_45stubYjueJ23IJZ
  },
  {
    name: component_45stubYjueJ23IJZMeta?.name ?? undefined,
    path: component_45stubYjueJ23IJZMeta?.path ?? "/finansovo-hozjajstvennaja-dejatelnost/:pathMatch(.*)",
    meta: component_45stubYjueJ23IJZMeta || {},
    alias: component_45stubYjueJ23IJZMeta?.alias || [],
    redirect: component_45stubYjueJ23IJZMeta?.redirect,
    component: component_45stubYjueJ23IJZ
  },
  {
    name: component_45stubYjueJ23IJZMeta?.name ?? undefined,
    path: component_45stubYjueJ23IJZMeta?.path ?? "/first-aid-courses/:pathMatch(.*)",
    meta: component_45stubYjueJ23IJZMeta || {},
    alias: component_45stubYjueJ23IJZMeta?.alias || [],
    redirect: component_45stubYjueJ23IJZMeta?.redirect,
    component: component_45stubYjueJ23IJZ
  },
  {
    name: component_45stubYjueJ23IJZMeta?.name ?? undefined,
    path: component_45stubYjueJ23IJZMeta?.path ?? "/fitter/:pathMatch(.*)",
    meta: component_45stubYjueJ23IJZMeta || {},
    alias: component_45stubYjueJ23IJZMeta?.alias || [],
    redirect: component_45stubYjueJ23IJZMeta?.redirect,
    component: component_45stubYjueJ23IJZ
  },
  {
    name: component_45stubYjueJ23IJZMeta?.name ?? undefined,
    path: component_45stubYjueJ23IJZMeta?.path ?? "/flagman-podpisal-soglashenie-s-ministerstvom-transporta-rossijskoj-federacii",
    meta: component_45stubYjueJ23IJZMeta || {},
    alias: component_45stubYjueJ23IJZMeta?.alias || [],
    redirect: component_45stubYjueJ23IJZMeta?.redirect,
    component: component_45stubYjueJ23IJZ
  },
  {
    name: component_45stubYjueJ23IJZMeta?.name ?? undefined,
    path: component_45stubYjueJ23IJZMeta?.path ?? "/flagman-prinjal-uchastie-v-konferencii-podgotovka-i-diplomirovanie-morjakov",
    meta: component_45stubYjueJ23IJZMeta || {},
    alias: component_45stubYjueJ23IJZMeta?.alias || [],
    redirect: component_45stubYjueJ23IJZMeta?.redirect,
    component: component_45stubYjueJ23IJZ
  },
  {
    name: component_45stubYjueJ23IJZMeta?.name ?? undefined,
    path: component_45stubYjueJ23IJZMeta?.path ?? "/flagman-proshel-ezhegodnyj-vneshnij-audit-sistemy-menedzhmenta-kachestva",
    meta: component_45stubYjueJ23IJZMeta || {},
    alias: component_45stubYjueJ23IJZMeta?.alias || [],
    redirect: component_45stubYjueJ23IJZMeta?.redirect,
    component: component_45stubYjueJ23IJZ
  },
  {
    name: component_45stubYjueJ23IJZMeta?.name ?? undefined,
    path: component_45stubYjueJ23IJZMeta?.path ?? "/flagman-proshel-osvidetelstvovanie-rossijskim-morskim-registrom-sudohodstva",
    meta: component_45stubYjueJ23IJZMeta || {},
    alias: component_45stubYjueJ23IJZMeta?.alias || [],
    redirect: component_45stubYjueJ23IJZMeta?.redirect,
    component: component_45stubYjueJ23IJZ
  },
  {
    name: component_45stubYjueJ23IJZMeta?.name ?? undefined,
    path: component_45stubYjueJ23IJZMeta?.path ?? "/flagman-proshel-vneshnij-audit-sistemy-menedzhmenta-kachestva/:pathMatch(.*)",
    meta: component_45stubYjueJ23IJZMeta || {},
    alias: component_45stubYjueJ23IJZMeta?.alias || [],
    redirect: component_45stubYjueJ23IJZMeta?.redirect,
    component: component_45stubYjueJ23IJZ
  },
  {
    name: component_45stubYjueJ23IJZMeta?.name ?? undefined,
    path: component_45stubYjueJ23IJZMeta?.path ?? "/flagman-sootvetstvuet-trebovaniyam-standartov-iso-90012015/:pathMatch(.*)",
    meta: component_45stubYjueJ23IJZMeta || {},
    alias: component_45stubYjueJ23IJZMeta?.alias || [],
    redirect: component_45stubYjueJ23IJZMeta?.redirect,
    component: component_45stubYjueJ23IJZ
  },
  {
    name: component_45stubYjueJ23IJZMeta?.name ?? undefined,
    path: component_45stubYjueJ23IJZMeta?.path ?? "/flagman-sootvetstvuet-trebovaniyam-standartov-iso-90012015-2/:pathMatch(.*)",
    meta: component_45stubYjueJ23IJZMeta || {},
    alias: component_45stubYjueJ23IJZMeta?.alias || [],
    redirect: component_45stubYjueJ23IJZMeta?.redirect,
    component: component_45stubYjueJ23IJZ
  },
  {
    name: component_45stubYjueJ23IJZMeta?.name ?? undefined,
    path: component_45stubYjueJ23IJZMeta?.path ?? "/flagman-ustanovil-novoe-oborudovanie/:pathMatch(.*)",
    meta: component_45stubYjueJ23IJZMeta || {},
    alias: component_45stubYjueJ23IJZMeta?.alias || [],
    redirect: component_45stubYjueJ23IJZMeta?.redirect,
    component: component_45stubYjueJ23IJZ
  },
  {
    name: component_45stubYjueJ23IJZMeta?.name ?? undefined,
    path: component_45stubYjueJ23IJZMeta?.path ?? "/foto/:pathMatch(.*)",
    meta: component_45stubYjueJ23IJZMeta || {},
    alias: component_45stubYjueJ23IJZMeta?.alias || [],
    redirect: component_45stubYjueJ23IJZMeta?.redirect,
    component: component_45stubYjueJ23IJZ
  },
  {
    name: component_45stubYjueJ23IJZMeta?.name ?? undefined,
    path: component_45stubYjueJ23IJZMeta?.path ?? "/fotogallereya/:pathMatch(.*)",
    meta: component_45stubYjueJ23IJZMeta || {},
    alias: component_45stubYjueJ23IJZMeta?.alias || [],
    redirect: component_45stubYjueJ23IJZMeta?.redirect,
    component: component_45stubYjueJ23IJZ
  },
  {
    name: component_45stubYjueJ23IJZMeta?.name ?? undefined,
    path: component_45stubYjueJ23IJZMeta?.path ?? "/fotogallereya/?type_0=gallery&album_gallery_id_0=1",
    meta: component_45stubYjueJ23IJZMeta || {},
    alias: component_45stubYjueJ23IJZMeta?.alias || [],
    redirect: component_45stubYjueJ23IJZMeta?.redirect,
    component: component_45stubYjueJ23IJZ
  },
  {
    name: component_45stubYjueJ23IJZMeta?.name ?? undefined,
    path: component_45stubYjueJ23IJZMeta?.path ?? "/fotogallereya/?type_0=gallery&album_gallery_id_0=3",
    meta: component_45stubYjueJ23IJZMeta || {},
    alias: component_45stubYjueJ23IJZMeta?.alias || [],
    redirect: component_45stubYjueJ23IJZMeta?.redirect,
    component: component_45stubYjueJ23IJZ
  },
  {
    name: component_45stubYjueJ23IJZMeta?.name ?? undefined,
    path: component_45stubYjueJ23IJZMeta?.path ?? "/fotogallereya/?type_0=gallery&album_gallery_id_0=4",
    meta: component_45stubYjueJ23IJZMeta || {},
    alias: component_45stubYjueJ23IJZMeta?.alias || [],
    redirect: component_45stubYjueJ23IJZMeta?.redirect,
    component: component_45stubYjueJ23IJZ
  },
  {
    name: component_45stubYjueJ23IJZMeta?.name ?? undefined,
    path: component_45stubYjueJ23IJZMeta?.path ?? "/fotogallereya/?type_0=gallery&album_gallery_id_0=5",
    meta: component_45stubYjueJ23IJZMeta || {},
    alias: component_45stubYjueJ23IJZMeta?.alias || [],
    redirect: component_45stubYjueJ23IJZMeta?.redirect,
    component: component_45stubYjueJ23IJZ
  },
  {
    name: component_45stubYjueJ23IJZMeta?.name ?? undefined,
    path: component_45stubYjueJ23IJZMeta?.path ?? "/fotogallereya/?type_0=gallery&album_gallery_id_0=6",
    meta: component_45stubYjueJ23IJZMeta || {},
    alias: component_45stubYjueJ23IJZMeta?.alias || [],
    redirect: component_45stubYjueJ23IJZMeta?.redirect,
    component: component_45stubYjueJ23IJZ
  },
  {
    name: component_45stubYjueJ23IJZMeta?.name ?? undefined,
    path: component_45stubYjueJ23IJZMeta?.path ?? "/fotogallereya/?type_0=gallery&album_gallery_id_0=7",
    meta: component_45stubYjueJ23IJZMeta || {},
    alias: component_45stubYjueJ23IJZMeta?.alias || [],
    redirect: component_45stubYjueJ23IJZMeta?.redirect,
    component: component_45stubYjueJ23IJZ
  },
  {
    name: component_45stubYjueJ23IJZMeta?.name ?? undefined,
    path: component_45stubYjueJ23IJZMeta?.path ?? "/gallery/:pathMatch(.*)",
    meta: component_45stubYjueJ23IJZMeta || {},
    alias: component_45stubYjueJ23IJZMeta?.alias || [],
    redirect: component_45stubYjueJ23IJZMeta?.redirect,
    component: component_45stubYjueJ23IJZ
  },
  {
    name: component_45stubYjueJ23IJZMeta?.name ?? undefined,
    path: component_45stubYjueJ23IJZMeta?.path ?? "/goskomissiya-prinyala-spasatelnyj-buksir-sb-121/:pathMatch(.*)",
    meta: component_45stubYjueJ23IJZMeta || {},
    alias: component_45stubYjueJ23IJZMeta?.alias || [],
    redirect: component_45stubYjueJ23IJZMeta?.redirect,
    component: component_45stubYjueJ23IJZ
  },
  {
    name: component_45stubYjueJ23IJZMeta?.name ?? undefined,
    path: component_45stubYjueJ23IJZMeta?.path ?? "/happy-2021/:pathMatch(.*)",
    meta: component_45stubYjueJ23IJZMeta || {},
    alias: component_45stubYjueJ23IJZMeta?.alias || [],
    redirect: component_45stubYjueJ23IJZMeta?.redirect,
    component: component_45stubYjueJ23IJZ
  },
  {
    name: component_45stubYjueJ23IJZMeta?.name ?? undefined,
    path: component_45stubYjueJ23IJZMeta?.path ?? "/happy-new-year/:pathMatch(.*)",
    meta: component_45stubYjueJ23IJZMeta || {},
    alias: component_45stubYjueJ23IJZMeta?.alias || [],
    redirect: component_45stubYjueJ23IJZMeta?.redirect,
    component: component_45stubYjueJ23IJZ
  },
  {
    name: component_45stubYjueJ23IJZMeta?.name ?? undefined,
    path: component_45stubYjueJ23IJZMeta?.path ?? "/happy-new-year-2019/:pathMatch(.*)",
    meta: component_45stubYjueJ23IJZMeta || {},
    alias: component_45stubYjueJ23IJZMeta?.alias || [],
    redirect: component_45stubYjueJ23IJZMeta?.redirect,
    component: component_45stubYjueJ23IJZ
  },
  {
    name: component_45stubYjueJ23IJZMeta?.name ?? undefined,
    path: component_45stubYjueJ23IJZMeta?.path ?? "/job/instruktor/:pathMatch(.*)",
    meta: component_45stubYjueJ23IJZMeta || {},
    alias: component_45stubYjueJ23IJZMeta?.alias || [],
    redirect: component_45stubYjueJ23IJZMeta?.redirect,
    component: component_45stubYjueJ23IJZ
  },
  {
    name: component_45stubYjueJ23IJZMeta?.name ?? undefined,
    path: component_45stubYjueJ23IJZMeta?.path ?? "/june-25-day-of-the-sailor/:pathMatch(.*)",
    meta: component_45stubYjueJ23IJZMeta || {},
    alias: component_45stubYjueJ23IJZMeta?.alias || [],
    redirect: component_45stubYjueJ23IJZMeta?.redirect,
    component: component_45stubYjueJ23IJZ
  },
  {
    name: component_45stubYjueJ23IJZMeta?.name ?? undefined,
    path: component_45stubYjueJ23IJZMeta?.path ?? "/kak-stat-morjakom/:pathMatch(.*)",
    meta: component_45stubYjueJ23IJZMeta || {},
    alias: component_45stubYjueJ23IJZMeta?.alias || [],
    redirect: component_45stubYjueJ23IJZMeta?.redirect,
    component: component_45stubYjueJ23IJZ
  },
  {
    name: component_45stubYjueJ23IJZMeta?.name ?? undefined,
    path: component_45stubYjueJ23IJZMeta?.path ?? "/kiberbezopasnost-na-sudah-i-kiberbezopasnost-v-morskoj-otrasli/:pathMatch(.*)",
    meta: component_45stubYjueJ23IJZMeta || {},
    alias: component_45stubYjueJ23IJZMeta?.alias || [],
    redirect: component_45stubYjueJ23IJZMeta?.redirect,
    component: component_45stubYjueJ23IJZ
  },
  {
    name: component_45stubYjueJ23IJZMeta?.name ?? undefined,
    path: component_45stubYjueJ23IJZMeta?.path ?? "/kompleks-ocenki-znanij-morjakov/:pathMatch(.*)",
    meta: component_45stubYjueJ23IJZMeta || {},
    alias: component_45stubYjueJ23IJZMeta?.alias || [],
    redirect: component_45stubYjueJ23IJZMeta?.redirect,
    component: component_45stubYjueJ23IJZ
  },
  {
    name: component_45stubYjueJ23IJZMeta?.name ?? undefined,
    path: component_45stubYjueJ23IJZMeta?.path ?? "/konsol/:pathMatch(.*)",
    meta: component_45stubYjueJ23IJZMeta || {},
    alias: component_45stubYjueJ23IJZMeta?.alias || [],
    redirect: component_45stubYjueJ23IJZMeta?.redirect,
    component: component_45stubYjueJ23IJZ
  },
  {
    name: component_45stubYjueJ23IJZMeta?.name ?? undefined,
    path: component_45stubYjueJ23IJZMeta?.path ?? "/kontakty/:pathMatch(.*)",
    meta: component_45stubYjueJ23IJZMeta || {},
    alias: component_45stubYjueJ23IJZMeta?.alias || [],
    redirect: component_45stubYjueJ23IJZMeta?.redirect,
    component: component_45stubYjueJ23IJZ
  },
  {
    name: component_45stubYjueJ23IJZMeta?.name ?? undefined,
    path: component_45stubYjueJ23IJZMeta?.path ?? "/kontakty-2/:pathMatch(.*)",
    meta: component_45stubYjueJ23IJZMeta || {},
    alias: component_45stubYjueJ23IJZMeta?.alias || [],
    redirect: component_45stubYjueJ23IJZMeta?.redirect,
    component: component_45stubYjueJ23IJZ
  },
  {
    name: component_45stubYjueJ23IJZMeta?.name ?? undefined,
    path: component_45stubYjueJ23IJZMeta?.path ?? "/krjuing-menedzher-dannye-o-professii/:pathMatch(.*)",
    meta: component_45stubYjueJ23IJZMeta || {},
    alias: component_45stubYjueJ23IJZMeta?.alias || [],
    redirect: component_45stubYjueJ23IJZMeta?.redirect,
    component: component_45stubYjueJ23IJZ
  },
  {
    name: component_45stubYjueJ23IJZMeta?.name ?? undefined,
    path: component_45stubYjueJ23IJZMeta?.path ?? "/kurs-podgotovka-po-osobennostjam-raboty-sudovym-povarom-punkt-3-pravila-3-2-ktms",
    meta: component_45stubYjueJ23IJZMeta || {},
    alias: component_45stubYjueJ23IJZMeta?.alias || [],
    redirect: component_45stubYjueJ23IJZMeta?.redirect,
    component: component_45stubYjueJ23IJZ
  },
  {
    name: component_45stubYjueJ23IJZMeta?.name ?? undefined,
    path: component_45stubYjueJ23IJZMeta?.path ?? "/kursy-moryakov/:pathMatch(.*)",
    meta: component_45stubYjueJ23IJZMeta || {},
    alias: component_45stubYjueJ23IJZMeta?.alias || [],
    redirect: component_45stubYjueJ23IJZMeta?.redirect,
    component: component_45stubYjueJ23IJZ
  },
  {
    name: component_45stubYjueJ23IJZMeta?.name ?? undefined,
    path: component_45stubYjueJ23IJZMeta?.path ?? "/kursy-okazaniya-pervoj-medicinskoj-pomoshhi/:pathMatch(.*)",
    meta: component_45stubYjueJ23IJZMeta || {},
    alias: component_45stubYjueJ23IJZMeta?.alias || [],
    redirect: component_45stubYjueJ23IJZMeta?.redirect,
    component: component_45stubYjueJ23IJZ
  },
  {
    name: component_45stubYjueJ23IJZMeta?.name ?? undefined,
    path: component_45stubYjueJ23IJZMeta?.path ?? "/kursy-povyshenija-kvalifikacii-dlja-prodlenija-diploma/:pathMatch(.*)",
    meta: component_45stubYjueJ23IJZMeta || {},
    alias: component_45stubYjueJ23IJZMeta?.alias || [],
    redirect: component_45stubYjueJ23IJZMeta?.redirect,
    component: component_45stubYjueJ23IJZ
  },
  {
    name: component_45stubYjueJ23IJZMeta?.name ?? undefined,
    path: component_45stubYjueJ23IJZMeta?.path ?? "/kvalificirovannyj-matros-na-tanker/:pathMatch(.*)",
    meta: component_45stubYjueJ23IJZMeta || {},
    alias: component_45stubYjueJ23IJZMeta?.alias || [],
    redirect: component_45stubYjueJ23IJZMeta?.redirect,
    component: component_45stubYjueJ23IJZ
  },
  {
    name: component_45stubYjueJ23IJZMeta?.name ?? undefined,
    path: component_45stubYjueJ23IJZMeta?.path ?? "/list-of-destinations/:pathMatch(.*)",
    meta: component_45stubYjueJ23IJZMeta || {},
    alias: component_45stubYjueJ23IJZMeta?.alias || [],
    redirect: component_45stubYjueJ23IJZMeta?.redirect,
    component: component_45stubYjueJ23IJZ
  },
  {
    name: component_45stubYjueJ23IJZMeta?.name ?? undefined,
    path: component_45stubYjueJ23IJZMeta?.path ?? "/lost-password/:pathMatch(.*)",
    meta: component_45stubYjueJ23IJZMeta || {},
    alias: component_45stubYjueJ23IJZMeta?.alias || [],
    redirect: component_45stubYjueJ23IJZMeta?.redirect,
    component: component_45stubYjueJ23IJZ
  },
  {
    name: component_45stubYjueJ23IJZMeta?.name ?? undefined,
    path: component_45stubYjueJ23IJZMeta?.path ?? "/luchshij-sotrudnik-3-kvartala-2021-goda-2/:pathMatch(.*)",
    meta: component_45stubYjueJ23IJZMeta || {},
    alias: component_45stubYjueJ23IJZMeta?.alias || [],
    redirect: component_45stubYjueJ23IJZMeta?.redirect,
    component: component_45stubYjueJ23IJZ
  },
  {
    name: component_45stubYjueJ23IJZMeta?.name ?? undefined,
    path: component_45stubYjueJ23IJZMeta?.path ?? "/lukoil-commissions-ice-class-vessels-in-the-caspian-sea/:pathMatch(.*)",
    meta: component_45stubYjueJ23IJZMeta || {},
    alias: component_45stubYjueJ23IJZMeta?.alias || [],
    redirect: component_45stubYjueJ23IJZMeta?.redirect,
    component: component_45stubYjueJ23IJZ
  },
  {
    name: component_45stubYjueJ23IJZMeta?.name ?? undefined,
    path: component_45stubYjueJ23IJZMeta?.path ?? "/lukoil-has-put-into-operation-three-vessels-for-work-in-the-caspian-sea",
    meta: component_45stubYjueJ23IJZMeta || {},
    alias: component_45stubYjueJ23IJZMeta?.alias || [],
    redirect: component_45stubYjueJ23IJZMeta?.redirect,
    component: component_45stubYjueJ23IJZ
  },
  {
    name: component_45stubYjueJ23IJZMeta?.name ?? undefined,
    path: component_45stubYjueJ23IJZMeta?.path ?? "/lukojl-na-kaspii-vvel-v-ekspluataciyu-suda-ledovogo-klassa/:pathMatch(.*)",
    meta: component_45stubYjueJ23IJZMeta || {},
    alias: component_45stubYjueJ23IJZMeta?.alias || [],
    redirect: component_45stubYjueJ23IJZMeta?.redirect,
    component: component_45stubYjueJ23IJZ
  },
  {
    name: component_45stubYjueJ23IJZMeta?.name ?? undefined,
    path: component_45stubYjueJ23IJZMeta?.path ?? "/lukojl-vvel-v-ekspluataciyu-tri-sudna-dlya-raboty-na-kaspii/:pathMatch(.*)",
    meta: component_45stubYjueJ23IJZMeta || {},
    alias: component_45stubYjueJ23IJZMeta?.alias || [],
    redirect: component_45stubYjueJ23IJZMeta?.redirect,
    component: component_45stubYjueJ23IJZ
  },
  {
    name: component_45stubYjueJ23IJZMeta?.name ?? undefined,
    path: component_45stubYjueJ23IJZMeta?.path ?? "/malomernye-suda/:pathMatch(.*)",
    meta: component_45stubYjueJ23IJZMeta || {},
    alias: component_45stubYjueJ23IJZMeta?.alias || [],
    redirect: component_45stubYjueJ23IJZMeta?.redirect,
    component: component_45stubYjueJ23IJZ
  },
  {
    name: component_45stubYjueJ23IJZMeta?.name ?? undefined,
    path: component_45stubYjueJ23IJZMeta?.path ?? "/manual-on-damage-control-of-ships/:pathMatch(.*)",
    meta: component_45stubYjueJ23IJZMeta || {},
    alias: component_45stubYjueJ23IJZMeta?.alias || [],
    redirect: component_45stubYjueJ23IJZMeta?.redirect,
    component: component_45stubYjueJ23IJZ
  },
  {
    name: component_45stubYjueJ23IJZMeta?.name ?? undefined,
    path: component_45stubYjueJ23IJZMeta?.path ?? "/marine-mechanics-and-electromechanics/:pathMatch(.*)",
    meta: component_45stubYjueJ23IJZMeta || {},
    alias: component_45stubYjueJ23IJZMeta?.alias || [],
    redirect: component_45stubYjueJ23IJZMeta?.redirect,
    component: component_45stubYjueJ23IJZ
  },
  {
    name: component_45stubYjueJ23IJZMeta?.name ?? undefined,
    path: component_45stubYjueJ23IJZMeta?.path ?? "/materialno-tehnicheskoe-obespechenie-i-osnashhennost-obrazovatelnogo-processa/",
    meta: component_45stubYjueJ23IJZMeta || {},
    alias: component_45stubYjueJ23IJZMeta?.alias || [],
    redirect: component_45stubYjueJ23IJZMeta?.redirect,
    component: component_45stubYjueJ23IJZ
  },
  {
    name: component_45stubYjueJ23IJZMeta?.name ?? undefined,
    path: component_45stubYjueJ23IJZMeta?.path ?? "/mdtf-results-page/:pathMatch(.*)",
    meta: component_45stubYjueJ23IJZMeta || {},
    alias: component_45stubYjueJ23IJZMeta?.alias || [],
    redirect: component_45stubYjueJ23IJZMeta?.redirect,
    component: component_45stubYjueJ23IJZ
  },
  {
    name: component_45stubYjueJ23IJZMeta?.name ?? undefined,
    path: component_45stubYjueJ23IJZMeta?.path ?? "/medical-examination-and-training-anything-is-possible/:pathMatch(.*)",
    meta: component_45stubYjueJ23IJZMeta || {},
    alias: component_45stubYjueJ23IJZMeta?.alias || [],
    redirect: component_45stubYjueJ23IJZMeta?.redirect,
    component: component_45stubYjueJ23IJZ
  },
  {
    name: component_45stubYjueJ23IJZMeta?.name ?? undefined,
    path: component_45stubYjueJ23IJZMeta?.path ?? "/medkomissija-i-obuchenie-vse-vozmozhno/:pathMatch(.*)",
    meta: component_45stubYjueJ23IJZMeta || {},
    alias: component_45stubYjueJ23IJZMeta?.alias || [],
    redirect: component_45stubYjueJ23IJZMeta?.redirect,
    component: component_45stubYjueJ23IJZ
  },
  {
    name: component_45stubYjueJ23IJZMeta?.name ?? undefined,
    path: component_45stubYjueJ23IJZMeta?.path ?? "/mezhdunarodnoe-sotrudnichestvo/:pathMatch(.*)",
    meta: component_45stubYjueJ23IJZMeta || {},
    alias: component_45stubYjueJ23IJZMeta?.alias || [],
    redirect: component_45stubYjueJ23IJZMeta?.redirect,
    component: component_45stubYjueJ23IJZ
  },
  {
    name: component_45stubYjueJ23IJZMeta?.name ?? undefined,
    path: component_45stubYjueJ23IJZMeta?.path ?? "/missija/:pathMatch(.*)",
    meta: component_45stubYjueJ23IJZMeta || {},
    alias: component_45stubYjueJ23IJZMeta?.alias || [],
    redirect: component_45stubYjueJ23IJZMeta?.redirect,
    component: component_45stubYjueJ23IJZ
  },
  {
    name: component_45stubYjueJ23IJZMeta?.name ?? undefined,
    path: component_45stubYjueJ23IJZMeta?.path ?? "/mission/:pathMatch(.*)",
    meta: component_45stubYjueJ23IJZMeta || {},
    alias: component_45stubYjueJ23IJZMeta?.alias || [],
    redirect: component_45stubYjueJ23IJZMeta?.redirect,
    component: component_45stubYjueJ23IJZ
  },
  {
    name: component_45stubYjueJ23IJZMeta?.name ?? undefined,
    path: component_45stubYjueJ23IJZMeta?.path ?? "/morskomu-uchebnomu-centru-flagman-7-let/:pathMatch(.*)",
    meta: component_45stubYjueJ23IJZMeta || {},
    alias: component_45stubYjueJ23IJZMeta?.alias || [],
    redirect: component_45stubYjueJ23IJZMeta?.redirect,
    component: component_45stubYjueJ23IJZ
  },
  {
    name: component_45stubYjueJ23IJZMeta?.name ?? undefined,
    path: component_45stubYjueJ23IJZMeta?.path ?? "/morskomu-uchebnomu-centru-flagman-7-let-2/:pathMatch(.*)",
    meta: component_45stubYjueJ23IJZMeta || {},
    alias: component_45stubYjueJ23IJZMeta?.alias || [],
    redirect: component_45stubYjueJ23IJZMeta?.redirect,
    component: component_45stubYjueJ23IJZ
  },
  {
    name: component_45stubYjueJ23IJZMeta?.name ?? undefined,
    path: component_45stubYjueJ23IJZMeta?.path ?? "/my-account-2/:pathMatch(.*)",
    meta: component_45stubYjueJ23IJZMeta || {},
    alias: component_45stubYjueJ23IJZMeta?.alias || [],
    redirect: component_45stubYjueJ23IJZMeta?.redirect,
    component: component_45stubYjueJ23IJZ
  },
  {
    name: component_45stubYjueJ23IJZMeta?.name ?? undefined,
    path: component_45stubYjueJ23IJZMeta?.path ?? "/nachalnaya-podgotovka-po-bezopasnosti/:pathMatch(.*)",
    meta: component_45stubYjueJ23IJZMeta || {},
    alias: component_45stubYjueJ23IJZMeta?.alias || [],
    redirect: component_45stubYjueJ23IJZMeta?.redirect,
    component: component_45stubYjueJ23IJZ
  },
  {
    name: component_45stubYjueJ23IJZMeta?.name ?? undefined,
    path: component_45stubYjueJ23IJZMeta?.path ?? "/nastavlenie-po-borbe-za-zhivuchest-sudov/:pathMatch(.*)",
    meta: component_45stubYjueJ23IJZMeta || {},
    alias: component_45stubYjueJ23IJZMeta?.alias || [],
    redirect: component_45stubYjueJ23IJZMeta?.redirect,
    component: component_45stubYjueJ23IJZ
  },
  {
    name: component_45stubYjueJ23IJZMeta?.name ?? undefined,
    path: component_45stubYjueJ23IJZMeta?.path ?? "/navigators/:pathMatch(.*)",
    meta: component_45stubYjueJ23IJZMeta || {},
    alias: component_45stubYjueJ23IJZMeta?.alias || [],
    redirect: component_45stubYjueJ23IJZMeta?.redirect,
    component: component_45stubYjueJ23IJZ
  },
  {
    name: component_45stubYjueJ23IJZMeta?.name ?? undefined,
    path: component_45stubYjueJ23IJZMeta?.path ?? "/nbzs-pozary-boats/:pathMatch(.*)",
    meta: component_45stubYjueJ23IJZMeta || {},
    alias: component_45stubYjueJ23IJZMeta?.alias || [],
    redirect: component_45stubYjueJ23IJZMeta?.redirect,
    component: component_45stubYjueJ23IJZ
  },
  {
    name: component_45stubYjueJ23IJZMeta?.name ?? undefined,
    path: component_45stubYjueJ23IJZMeta?.path ?? "/nbzs-pozary-boats-2/:pathMatch(.*)",
    meta: component_45stubYjueJ23IJZMeta || {},
    alias: component_45stubYjueJ23IJZMeta?.alias || [],
    redirect: component_45stubYjueJ23IJZMeta?.redirect,
    component: component_45stubYjueJ23IJZ
  },
  {
    name: component_45stubYjueJ23IJZMeta?.name ?? undefined,
    path: component_45stubYjueJ23IJZMeta?.path ?? "/ndfl-dlya-moryakov/:pathMatch(.*)",
    meta: component_45stubYjueJ23IJZMeta || {},
    alias: component_45stubYjueJ23IJZMeta?.alias || [],
    redirect: component_45stubYjueJ23IJZMeta?.redirect,
    component: component_45stubYjueJ23IJZ
  },
  {
    name: component_45stubYjueJ23IJZMeta?.name ?? undefined,
    path: component_45stubYjueJ23IJZMeta?.path ?? "/need-effective-technical-management/:pathMatch(.*)",
    meta: component_45stubYjueJ23IJZMeta || {},
    alias: component_45stubYjueJ23IJZMeta?.alias || [],
    redirect: component_45stubYjueJ23IJZMeta?.redirect,
    component: component_45stubYjueJ23IJZ
  },
  {
    name: component_45stubYjueJ23IJZMeta?.name ?? undefined,
    path: component_45stubYjueJ23IJZMeta?.path ?? "/new-course-of-study/:pathMatch(.*)",
    meta: component_45stubYjueJ23IJZMeta || {},
    alias: component_45stubYjueJ23IJZMeta?.alias || [],
    redirect: component_45stubYjueJ23IJZMeta?.redirect,
    component: component_45stubYjueJ23IJZ
  },
  {
    name: component_45stubYjueJ23IJZMeta?.name ?? undefined,
    path: component_45stubYjueJ23IJZMeta?.path ?? "/northern-shipping-company-performs-its-first-voyage-within-the-framework-of-a-new-partnership",
    meta: component_45stubYjueJ23IJZMeta || {},
    alias: component_45stubYjueJ23IJZMeta?.alias || [],
    redirect: component_45stubYjueJ23IJZMeta?.redirect,
    component: component_45stubYjueJ23IJZ
  },
  {
    name: component_45stubYjueJ23IJZMeta?.name ?? undefined,
    path: component_45stubYjueJ23IJZMeta?.path ?? "/norvezhcy-postroyat-avtonomnoe-gruzovoe-sudno/:pathMatch(.*)",
    meta: component_45stubYjueJ23IJZMeta || {},
    alias: component_45stubYjueJ23IJZMeta?.alias || [],
    redirect: component_45stubYjueJ23IJZMeta?.redirect,
    component: component_45stubYjueJ23IJZ
  },
  {
    name: component_45stubYjueJ23IJZMeta?.name ?? undefined,
    path: component_45stubYjueJ23IJZMeta?.path ?? "/novoe-oborudovanie-v-sostave-flagmana/:pathMatch(.*)",
    meta: component_45stubYjueJ23IJZMeta || {},
    alias: component_45stubYjueJ23IJZMeta?.alias || [],
    redirect: component_45stubYjueJ23IJZMeta?.redirect,
    component: component_45stubYjueJ23IJZ
  },
  {
    name: component_45stubYjueJ23IJZMeta?.name ?? undefined,
    path: component_45stubYjueJ23IJZMeta?.path ?? "/novyj-kurs-obuchenija/:pathMatch(.*)",
    meta: component_45stubYjueJ23IJZMeta || {},
    alias: component_45stubYjueJ23IJZMeta?.alias || [],
    redirect: component_45stubYjueJ23IJZMeta?.redirect,
    component: component_45stubYjueJ23IJZ
  },
  {
    name: component_45stubYjueJ23IJZMeta?.name ?? undefined,
    path: component_45stubYjueJ23IJZMeta?.path ?? "/nuzhen-effektivnyj-texnicheskij-menedzhment/:pathMatch(.*)",
    meta: component_45stubYjueJ23IJZMeta || {},
    alias: component_45stubYjueJ23IJZMeta?.alias || [],
    redirect: component_45stubYjueJ23IJZMeta?.redirect,
    component: component_45stubYjueJ23IJZ
  },
  {
    name: component_45stubYjueJ23IJZMeta?.name ?? undefined,
    path: component_45stubYjueJ23IJZMeta?.path ?? "/o-nas/:pathMatch(.*)",
    meta: component_45stubYjueJ23IJZMeta || {},
    alias: component_45stubYjueJ23IJZMeta?.alias || [],
    redirect: component_45stubYjueJ23IJZMeta?.redirect,
    component: component_45stubYjueJ23IJZ
  },
  {
    name: component_45stubYjueJ23IJZMeta?.name ?? undefined,
    path: component_45stubYjueJ23IJZMeta?.path ?? "/o-nas/dokumenty/:pathMatch(.*)",
    meta: component_45stubYjueJ23IJZMeta || {},
    alias: component_45stubYjueJ23IJZMeta?.alias || [],
    redirect: component_45stubYjueJ23IJZMeta?.redirect,
    component: component_45stubYjueJ23IJZ
  },
  {
    name: component_45stubYjueJ23IJZMeta?.name ?? undefined,
    path: component_45stubYjueJ23IJZMeta?.path ?? "/o-nas/dokumenty/licenziya/:pathMatch(.*)",
    meta: component_45stubYjueJ23IJZMeta || {},
    alias: component_45stubYjueJ23IJZMeta?.alias || [],
    redirect: component_45stubYjueJ23IJZMeta?.redirect,
    component: component_45stubYjueJ23IJZ
  },
  {
    name: component_45stubYjueJ23IJZMeta?.name ?? undefined,
    path: component_45stubYjueJ23IJZMeta?.path ?? "/o-nas/dokumenty/licenziya-na-osushhestvlenie-obrazovatelnoj-deyatelnosti",
    meta: component_45stubYjueJ23IJZMeta || {},
    alias: component_45stubYjueJ23IJZMeta?.alias || [],
    redirect: component_45stubYjueJ23IJZMeta?.redirect,
    component: component_45stubYjueJ23IJZ
  },
  {
    name: component_45stubYjueJ23IJZMeta?.name ?? undefined,
    path: component_45stubYjueJ23IJZMeta?.path ?? "/o-nas/dokumenty/lokalnye-normativnye-akty/:pathMatch(.*)",
    meta: component_45stubYjueJ23IJZMeta || {},
    alias: component_45stubYjueJ23IJZMeta?.alias || [],
    redirect: component_45stubYjueJ23IJZMeta?.redirect,
    component: component_45stubYjueJ23IJZ
  },
  {
    name: component_45stubYjueJ23IJZMeta?.name ?? undefined,
    path: component_45stubYjueJ23IJZMeta?.path ?? "/o-nas/dokumenty/polozhenie-o-platnyx-uslugax/:pathMatch(.*)",
    meta: component_45stubYjueJ23IJZMeta || {},
    alias: component_45stubYjueJ23IJZMeta?.alias || [],
    redirect: component_45stubYjueJ23IJZMeta?.redirect,
    component: component_45stubYjueJ23IJZ
  },
  {
    name: component_45stubYjueJ23IJZMeta?.name ?? undefined,
    path: component_45stubYjueJ23IJZMeta?.path ?? "/o-nas/dokumenty/ustav-utc-flagman/:pathMatch(.*)",
    meta: component_45stubYjueJ23IJZMeta || {},
    alias: component_45stubYjueJ23IJZMeta?.alias || [],
    redirect: component_45stubYjueJ23IJZMeta?.redirect,
    component: component_45stubYjueJ23IJZ
  },
  {
    name: component_45stubYjueJ23IJZMeta?.name ?? undefined,
    path: component_45stubYjueJ23IJZMeta?.path ?? "/o-nas/dokumenty/ustav-utc-flagman-2/:pathMatch(.*)",
    meta: component_45stubYjueJ23IJZMeta || {},
    alias: component_45stubYjueJ23IJZMeta?.alias || [],
    redirect: component_45stubYjueJ23IJZMeta?.redirect,
    component: component_45stubYjueJ23IJZ
  },
  {
    name: component_45stubYjueJ23IJZMeta?.name ?? undefined,
    path: component_45stubYjueJ23IJZMeta?.path ?? "/o-nas/dostupnaja-sreda/:pathMatch(.*)",
    meta: component_45stubYjueJ23IJZMeta || {},
    alias: component_45stubYjueJ23IJZMeta?.alias || [],
    redirect: component_45stubYjueJ23IJZMeta?.redirect,
    component: component_45stubYjueJ23IJZ
  },
  {
    name: component_45stubYjueJ23IJZMeta?.name ?? undefined,
    path: component_45stubYjueJ23IJZMeta?.path ?? "/o-nas/personal/:pathMatch(.*)",
    meta: component_45stubYjueJ23IJZMeta || {},
    alias: component_45stubYjueJ23IJZMeta?.alias || [],
    redirect: component_45stubYjueJ23IJZMeta?.redirect,
    component: component_45stubYjueJ23IJZ
  },
  {
    name: component_45stubYjueJ23IJZMeta?.name ?? undefined,
    path: component_45stubYjueJ23IJZMeta?.path ?? "/o-nas/platnye-uslugi/:pathMatch(.*)",
    meta: component_45stubYjueJ23IJZMeta || {},
    alias: component_45stubYjueJ23IJZMeta?.alias || [],
    redirect: component_45stubYjueJ23IJZMeta?.redirect,
    component: component_45stubYjueJ23IJZ
  },
  {
    name: component_45stubYjueJ23IJZMeta?.name ?? undefined,
    path: component_45stubYjueJ23IJZMeta?.path ?? "/o-nas/rukovodstvo/:pathMatch(.*)",
    meta: component_45stubYjueJ23IJZMeta || {},
    alias: component_45stubYjueJ23IJZMeta?.alias || [],
    redirect: component_45stubYjueJ23IJZMeta?.redirect,
    component: component_45stubYjueJ23IJZ
  },
  {
    name: component_45stubYjueJ23IJZMeta?.name ?? undefined,
    path: component_45stubYjueJ23IJZMeta?.path ?? "/o-nas/soobshhestva/:pathMatch(.*)",
    meta: component_45stubYjueJ23IJZMeta || {},
    alias: component_45stubYjueJ23IJZMeta?.alias || [],
    redirect: component_45stubYjueJ23IJZMeta?.redirect,
    component: component_45stubYjueJ23IJZ
  },
  {
    name: component_45stubYjueJ23IJZMeta?.name ?? undefined,
    path: component_45stubYjueJ23IJZMeta?.path ?? "/objavljaem-nabor-na-kursy-kvalificirovannyj-matros-i-vahtennyj-motorist",
    meta: component_45stubYjueJ23IJZMeta || {},
    alias: component_45stubYjueJ23IJZMeta?.alias || [],
    redirect: component_45stubYjueJ23IJZMeta?.redirect,
    component: component_45stubYjueJ23IJZ
  },
  {
    name: component_45stubYjueJ23IJZMeta?.name ?? undefined,
    path: component_45stubYjueJ23IJZMeta?.path ?? "/obrazovanie/:pathMatch(.*)",
    meta: component_45stubYjueJ23IJZMeta || {},
    alias: component_45stubYjueJ23IJZMeta?.alias || [],
    redirect: component_45stubYjueJ23IJZMeta?.redirect,
    component: component_45stubYjueJ23IJZ
  },
  {
    name: component_45stubYjueJ23IJZMeta?.name ?? undefined,
    path: component_45stubYjueJ23IJZMeta?.path ?? "/obuchajtes-u-nas-i-poluchajte-nalogovyj-vychet-za-obuchenie/:pathMatch(.*)",
    meta: component_45stubYjueJ23IJZMeta || {},
    alias: component_45stubYjueJ23IJZMeta?.alias || [],
    redirect: component_45stubYjueJ23IJZMeta?.redirect,
    component: component_45stubYjueJ23IJZ
  },
  {
    name: component_45stubYjueJ23IJZMeta?.name ?? undefined,
    path: component_45stubYjueJ23IJZMeta?.path ?? "/obuchenie/:pathMatch(.*)",
    meta: component_45stubYjueJ23IJZMeta || {},
    alias: component_45stubYjueJ23IJZMeta?.alias || [],
    redirect: component_45stubYjueJ23IJZMeta?.redirect,
    component: component_45stubYjueJ23IJZ
  },
  {
    name: component_45stubYjueJ23IJZMeta?.name ?? undefined,
    path: component_45stubYjueJ23IJZMeta?.path ?? "/obuchenie-na-upravlenie-malomernym-flotom/:pathMatch(.*)",
    meta: component_45stubYjueJ23IJZMeta || {},
    alias: component_45stubYjueJ23IJZMeta?.alias || [],
    redirect: component_45stubYjueJ23IJZMeta?.redirect,
    component: component_45stubYjueJ23IJZ
  },
  {
    name: component_45stubYjueJ23IJZMeta?.name ?? undefined,
    path: component_45stubYjueJ23IJZMeta?.path ?? "/obuchenie-na-upravlenie-malomernym-flotom-2/:pathMatch(.*)",
    meta: component_45stubYjueJ23IJZMeta || {},
    alias: component_45stubYjueJ23IJZMeta?.alias || [],
    redirect: component_45stubYjueJ23IJZMeta?.redirect,
    component: component_45stubYjueJ23IJZ
  },
  {
    name: component_45stubYjueJ23IJZMeta?.name ?? undefined,
    path: component_45stubYjueJ23IJZMeta?.path ?? "/obuchenie-na-upravlenie-malomernym-flotom-2-2/:pathMatch(.*)",
    meta: component_45stubYjueJ23IJZMeta || {},
    alias: component_45stubYjueJ23IJZMeta?.alias || [],
    redirect: component_45stubYjueJ23IJZMeta?.redirect,
    component: component_45stubYjueJ23IJZ
  },
  {
    name: component_45stubYjueJ23IJZMeta?.name ?? undefined,
    path: component_45stubYjueJ23IJZMeta?.path ?? "/obuchenie-tonkostjam-professii-sotrudnikov-sjurvejerskoj-kompanii/:pathMatch(.*)",
    meta: component_45stubYjueJ23IJZMeta || {},
    alias: component_45stubYjueJ23IJZMeta?.alias || [],
    redirect: component_45stubYjueJ23IJZMeta?.redirect,
    component: component_45stubYjueJ23IJZ
  },
  {
    name: component_45stubYjueJ23IJZMeta?.name ?? undefined,
    path: component_45stubYjueJ23IJZMeta?.path ?? "/obuchenie/dopolnitelnaya-podgotovka-moryakov/:pathMatch(.*)",
    meta: component_45stubYjueJ23IJZMeta || {},
    alias: component_45stubYjueJ23IJZMeta?.alias || [],
    redirect: component_45stubYjueJ23IJZMeta?.redirect,
    component: component_45stubYjueJ23IJZ
  },
  {
    name: component_45stubYjueJ23IJZMeta?.name ?? undefined,
    path: component_45stubYjueJ23IJZMeta?.path ?? "/obuchenie/gmssb/:pathMatch(.*)",
    meta: component_45stubYjueJ23IJZMeta || {},
    alias: component_45stubYjueJ23IJZMeta?.alias || [],
    redirect: component_45stubYjueJ23IJZMeta?.redirect,
    component: component_45stubYjueJ23IJZ
  },
  {
    name: component_45stubYjueJ23IJZMeta?.name ?? undefined,
    path: component_45stubYjueJ23IJZMeta?.path ?? "/obuchenie/inostrannyj-yazyk/:pathMatch(.*)",
    meta: component_45stubYjueJ23IJZMeta || {},
    alias: component_45stubYjueJ23IJZMeta?.alias || [],
    redirect: component_45stubYjueJ23IJZMeta?.redirect,
    component: component_45stubYjueJ23IJZ
  },
  {
    name: component_45stubYjueJ23IJZMeta?.name ?? undefined,
    path: component_45stubYjueJ23IJZMeta?.path ?? "/obuchenie/malomernye-suda/:pathMatch(.*)",
    meta: component_45stubYjueJ23IJZMeta || {},
    alias: component_45stubYjueJ23IJZMeta?.alias || [],
    redirect: component_45stubYjueJ23IJZMeta?.redirect,
    component: component_45stubYjueJ23IJZ
  },
  {
    name: component_45stubYjueJ23IJZMeta?.name ?? undefined,
    path: component_45stubYjueJ23IJZMeta?.path ?? "/obuchenie/medicina-i-gigiena/:pathMatch(.*)",
    meta: component_45stubYjueJ23IJZMeta || {},
    alias: component_45stubYjueJ23IJZMeta?.alias || [],
    redirect: component_45stubYjueJ23IJZMeta?.redirect,
    component: component_45stubYjueJ23IJZ
  },
  {
    name: component_45stubYjueJ23IJZMeta?.name ?? undefined,
    path: component_45stubYjueJ23IJZMeta?.path ?? "/obuchenie/navigacionnye-trenazhyory/:pathMatch(.*)",
    meta: component_45stubYjueJ23IJZMeta || {},
    alias: component_45stubYjueJ23IJZMeta?.alias || [],
    redirect: component_45stubYjueJ23IJZMeta?.redirect,
    component: component_45stubYjueJ23IJZ
  },
  {
    name: component_45stubYjueJ23IJZMeta?.name ?? undefined,
    path: component_45stubYjueJ23IJZMeta?.path ?? "/obuchenie/nbzhs/:pathMatch(.*)",
    meta: component_45stubYjueJ23IJZMeta || {},
    alias: component_45stubYjueJ23IJZMeta?.alias || [],
    redirect: component_45stubYjueJ23IJZMeta?.redirect,
    component: component_45stubYjueJ23IJZ
  },
  {
    name: component_45stubYjueJ23IJZMeta?.name ?? undefined,
    path: component_45stubYjueJ23IJZMeta?.path ?? "/obuchenie/neorganizovannye-massy/:pathMatch(.*)",
    meta: component_45stubYjueJ23IJZMeta || {},
    alias: component_45stubYjueJ23IJZMeta?.alias || [],
    redirect: component_45stubYjueJ23IJZMeta?.redirect,
    component: component_45stubYjueJ23IJZ
  },
  {
    name: component_45stubYjueJ23IJZMeta?.name ?? undefined,
    path: component_45stubYjueJ23IJZMeta?.path ?? "/obuchenie/opasnye-gruzy-i-okruzhayushhaya-sreda/:pathMatch(.*)",
    meta: component_45stubYjueJ23IJZMeta || {},
    alias: component_45stubYjueJ23IJZMeta?.alias || [],
    redirect: component_45stubYjueJ23IJZMeta?.redirect,
    component: component_45stubYjueJ23IJZ
  },
  {
    name: component_45stubYjueJ23IJZMeta?.name ?? undefined,
    path: component_45stubYjueJ23IJZMeta?.path ?? "/obuchenie/oxrana/:pathMatch(.*)",
    meta: component_45stubYjueJ23IJZMeta || {},
    alias: component_45stubYjueJ23IJZMeta?.alias || [],
    redirect: component_45stubYjueJ23IJZMeta?.redirect,
    component: component_45stubYjueJ23IJZ
  },
  {
    name: component_45stubYjueJ23IJZMeta?.name ?? undefined,
    path: component_45stubYjueJ23IJZMeta?.path ?? "/obuchenie/oxrana-sudov-portovyx-sooruzhenij-i-transportnaya-bezopasnost",
    meta: component_45stubYjueJ23IJZMeta || {},
    alias: component_45stubYjueJ23IJZMeta?.alias || [],
    redirect: component_45stubYjueJ23IJZMeta?.redirect,
    component: component_45stubYjueJ23IJZ
  },
  {
    name: component_45stubYjueJ23IJZMeta?.name ?? undefined,
    path: component_45stubYjueJ23IJZMeta?.path ?? "/obuchenie/oxrana-truda/:pathMatch(.*)",
    meta: component_45stubYjueJ23IJZMeta || {},
    alias: component_45stubYjueJ23IJZMeta?.alias || [],
    redirect: component_45stubYjueJ23IJZMeta?.redirect,
    component: component_45stubYjueJ23IJZ
  },
  {
    name: component_45stubYjueJ23IJZMeta?.name ?? undefined,
    path: component_45stubYjueJ23IJZMeta?.path ?? "/obuchenie/passajirskie-suda/:pathMatch(.*)",
    meta: component_45stubYjueJ23IJZMeta || {},
    alias: component_45stubYjueJ23IJZMeta?.alias || [],
    redirect: component_45stubYjueJ23IJZMeta?.redirect,
    component: component_45stubYjueJ23IJZ
  },
  {
    name: component_45stubYjueJ23IJZMeta?.name ?? undefined,
    path: component_45stubYjueJ23IJZMeta?.path ?? "/obuchenie/podgotovka-beregovyx-specialistov/:pathMatch(.*)",
    meta: component_45stubYjueJ23IJZMeta || {},
    alias: component_45stubYjueJ23IJZMeta?.alias || [],
    redirect: component_45stubYjueJ23IJZMeta?.redirect,
    component: component_45stubYjueJ23IJZ
  },
  {
    name: component_45stubYjueJ23IJZMeta?.name ?? undefined,
    path: component_45stubYjueJ23IJZMeta?.path ?? "/obuchenie/tankernaya-podgotovka/:pathMatch(.*)",
    meta: component_45stubYjueJ23IJZMeta || {},
    alias: component_45stubYjueJ23IJZMeta?.alias || [],
    redirect: component_45stubYjueJ23IJZMeta?.redirect,
    component: component_45stubYjueJ23IJZ
  },
  {
    name: component_45stubYjueJ23IJZMeta?.name ?? undefined,
    path: component_45stubYjueJ23IJZMeta?.path ?? "/obuchenie/testirovanie-i-dopolnitelnye-uslugi/:pathMatch(.*)",
    meta: component_45stubYjueJ23IJZMeta || {},
    alias: component_45stubYjueJ23IJZMeta?.alias || [],
    redirect: component_45stubYjueJ23IJZMeta?.redirect,
    component: component_45stubYjueJ23IJZ
  },
  {
    name: component_45stubYjueJ23IJZMeta?.name ?? undefined,
    path: component_45stubYjueJ23IJZMeta?.path ?? "/obuchenie/transportnaya-bezopasnost/:pathMatch(.*)",
    meta: component_45stubYjueJ23IJZMeta || {},
    alias: component_45stubYjueJ23IJZMeta?.alias || [],
    redirect: component_45stubYjueJ23IJZMeta?.redirect,
    component: component_45stubYjueJ23IJZ
  },
  {
    name: component_45stubYjueJ23IJZMeta?.name ?? undefined,
    path: component_45stubYjueJ23IJZMeta?.path ?? "/obuchenie/trenazhyory-mashinnogo-otdeleniya/:pathMatch(.*)",
    meta: component_45stubYjueJ23IJZMeta || {},
    alias: component_45stubYjueJ23IJZMeta?.alias || [],
    redirect: component_45stubYjueJ23IJZMeta?.redirect,
    component: component_45stubYjueJ23IJZ
  },
  {
    name: component_45stubYjueJ23IJZMeta?.name ?? undefined,
    path: component_45stubYjueJ23IJZMeta?.path ?? "/occupational-safety-on-ships/:pathMatch(.*)",
    meta: component_45stubYjueJ23IJZMeta || {},
    alias: component_45stubYjueJ23IJZMeta?.alias || [],
    redirect: component_45stubYjueJ23IJZMeta?.redirect,
    component: component_45stubYjueJ23IJZ
  },
  {
    name: component_45stubYjueJ23IJZMeta?.name ?? undefined,
    path: component_45stubYjueJ23IJZMeta?.path ?? "/ochered-na-kerchenskoj-pereprave-v-chasy-pik-uvelichilas-do-900-mashin",
    meta: component_45stubYjueJ23IJZMeta || {},
    alias: component_45stubYjueJ23IJZMeta?.alias || [],
    redirect: component_45stubYjueJ23IJZMeta?.redirect,
    component: component_45stubYjueJ23IJZ
  },
  {
    name: component_45stubYjueJ23IJZMeta?.name ?? undefined,
    path: component_45stubYjueJ23IJZMeta?.path ?? "/osnovnye-svedenija/:pathMatch(.*)",
    meta: component_45stubYjueJ23IJZMeta || {},
    alias: component_45stubYjueJ23IJZMeta?.alias || [],
    redirect: component_45stubYjueJ23IJZMeta?.redirect,
    component: component_45stubYjueJ23IJZ
  },
  {
    name: component_45stubYjueJ23IJZMeta?.name ?? undefined,
    path: component_45stubYjueJ23IJZMeta?.path ?? "/other-directions/:pathMatch(.*)",
    meta: component_45stubYjueJ23IJZMeta || {},
    alias: component_45stubYjueJ23IJZMeta?.alias || [],
    redirect: component_45stubYjueJ23IJZMeta?.redirect,
    component: component_45stubYjueJ23IJZ
  },
  {
    name: component_45stubYjueJ23IJZMeta?.name ?? undefined,
    path: component_45stubYjueJ23IJZMeta?.path ?? "/otkryt-nabor-na-kursy-povyshenija-kvalifikacii-pri-prodlenii-diploma",
    meta: component_45stubYjueJ23IJZMeta || {},
    alias: component_45stubYjueJ23IJZMeta?.alias || [],
    redirect: component_45stubYjueJ23IJZMeta?.redirect,
    component: component_45stubYjueJ23IJZ
  },
  {
    name: component_45stubYjueJ23IJZMeta?.name ?? undefined,
    path: component_45stubYjueJ23IJZMeta?.path ?? "/otkryta-vakansija-instruktor/:pathMatch(.*)",
    meta: component_45stubYjueJ23IJZMeta || {},
    alias: component_45stubYjueJ23IJZMeta?.alias || [],
    redirect: component_45stubYjueJ23IJZMeta?.redirect,
    component: component_45stubYjueJ23IJZ
  },
  {
    name: component_45stubYjueJ23IJZMeta?.name ?? undefined,
    path: component_45stubYjueJ23IJZMeta?.path ?? "/otkryty-kursy-obuchenija-na-matrosa-i-motorista/:pathMatch(.*)",
    meta: component_45stubYjueJ23IJZMeta || {},
    alias: component_45stubYjueJ23IJZMeta?.alias || [],
    redirect: component_45stubYjueJ23IJZMeta?.redirect,
    component: component_45stubYjueJ23IJZ
  },
  {
    name: component_45stubYjueJ23IJZMeta?.name ?? undefined,
    path: component_45stubYjueJ23IJZMeta?.path ?? "/otkryty-novye-programmy-podgotovki/:pathMatch(.*)",
    meta: component_45stubYjueJ23IJZMeta || {},
    alias: component_45stubYjueJ23IJZMeta?.alias || [],
    redirect: component_45stubYjueJ23IJZMeta?.redirect,
    component: component_45stubYjueJ23IJZ
  },
  {
    name: component_45stubYjueJ23IJZMeta?.name ?? undefined,
    path: component_45stubYjueJ23IJZMeta?.path ?? "/otlichnaja-novost-flagman-uspeshno-proshel-resertifikaciju-sistemy-menedzhmenta-kachestva-i-poluchil-sertificiroval-kompleks-ocenki-znanij-e-smart/",
    meta: component_45stubYjueJ23IJZMeta || {},
    alias: component_45stubYjueJ23IJZMeta?.alias || [],
    redirect: component_45stubYjueJ23IJZMeta?.redirect,
    component: component_45stubYjueJ23IJZ
  },
  {
    name: component_45stubYjueJ23IJZMeta?.name ?? undefined,
    path: component_45stubYjueJ23IJZMeta?.path ?? "/oxrana-truda-na-sudax/:pathMatch(.*)",
    meta: component_45stubYjueJ23IJZMeta || {},
    alias: component_45stubYjueJ23IJZMeta?.alias || [],
    redirect: component_45stubYjueJ23IJZMeta?.redirect,
    component: component_45stubYjueJ23IJZ
  },
  {
    name: component_45stubYjueJ23IJZMeta?.name ?? undefined,
    path: component_45stubYjueJ23IJZMeta?.path ?? "/parusnik-pallada-vzyal-kurs-na-vladivostok/:pathMatch(.*)",
    meta: component_45stubYjueJ23IJZMeta || {},
    alias: component_45stubYjueJ23IJZMeta?.alias || [],
    redirect: component_45stubYjueJ23IJZMeta?.redirect,
    component: component_45stubYjueJ23IJZ
  },
  {
    name: component_45stubYjueJ23IJZMeta?.name ?? undefined,
    path: component_45stubYjueJ23IJZMeta?.path ?? "/payment-by-installments-for-tuition/:pathMatch(.*)",
    meta: component_45stubYjueJ23IJZMeta || {},
    alias: component_45stubYjueJ23IJZMeta?.alias || [],
    redirect: component_45stubYjueJ23IJZMeta?.redirect,
    component: component_45stubYjueJ23IJZ
  },
  {
    name: component_45stubYjueJ23IJZMeta?.name ?? undefined,
    path: component_45stubYjueJ23IJZMeta?.path ?? "/personal-income-tax-for-seafarers/:pathMatch(.*)",
    meta: component_45stubYjueJ23IJZMeta || {},
    alias: component_45stubYjueJ23IJZMeta?.alias || [],
    redirect: component_45stubYjueJ23IJZMeta?.redirect,
    component: component_45stubYjueJ23IJZ
  },
  {
    name: component_45stubYjueJ23IJZMeta?.name ?? undefined,
    path: component_45stubYjueJ23IJZMeta?.path ?? "/photo/:pathMatch(.*)",
    meta: component_45stubYjueJ23IJZMeta || {},
    alias: component_45stubYjueJ23IJZMeta?.alias || [],
    redirect: component_45stubYjueJ23IJZMeta?.redirect,
    component: component_45stubYjueJ23IJZ
  },
  {
    name: component_45stubYjueJ23IJZMeta?.name ?? undefined,
    path: component_45stubYjueJ23IJZMeta?.path ?? "/platnye-obrazovatelnye-uslugi/:pathMatch(.*)",
    meta: component_45stubYjueJ23IJZMeta || {},
    alias: component_45stubYjueJ23IJZMeta?.alias || [],
    redirect: component_45stubYjueJ23IJZMeta?.redirect,
    component: component_45stubYjueJ23IJZ
  },
  {
    name: component_45stubYjueJ23IJZMeta?.name ?? undefined,
    path: component_45stubYjueJ23IJZMeta?.path ?? "/podgotovka-chlenov-jekipazhej-opredelennyh-tipov-sudov/:pathMatch(.*)",
    meta: component_45stubYjueJ23IJZMeta || {},
    alias: component_45stubYjueJ23IJZMeta?.alias || [],
    redirect: component_45stubYjueJ23IJZMeta?.redirect,
    component: component_45stubYjueJ23IJZ
  },
  {
    name: component_45stubYjueJ23IJZMeta?.name ?? undefined,
    path: component_45stubYjueJ23IJZMeta?.path ?? "/podgotovka-po-okazaniyu-pervoj-medicinskoj-pomoshhi/:pathMatch(.*)",
    meta: component_45stubYjueJ23IJZMeta || {},
    alias: component_45stubYjueJ23IJZMeta?.alias || [],
    redirect: component_45stubYjueJ23IJZMeta?.redirect,
    component: component_45stubYjueJ23IJZ
  },
  {
    name: component_45stubYjueJ23IJZMeta?.name ?? undefined,
    path: component_45stubYjueJ23IJZMeta?.path ?? "/podgotovka-po-oxrane-morskoj-okruzhayushhej-sredy/:pathMatch(.*)",
    meta: component_45stubYjueJ23IJZMeta || {},
    alias: component_45stubYjueJ23IJZMeta?.alias || [],
    redirect: component_45stubYjueJ23IJZMeta?.redirect,
    component: component_45stubYjueJ23IJZ
  },
  {
    name: component_45stubYjueJ23IJZMeta?.name ?? undefined,
    path: component_45stubYjueJ23IJZMeta?.path ?? "/podgotovka-po-upravleniyu-neorganizovannoj-massoj-lyudej-podgotovka-po-bezopasnosti-dlya-personala/",
    meta: component_45stubYjueJ23IJZMeta || {},
    alias: component_45stubYjueJ23IJZMeta?.alias || [],
    redirect: component_45stubYjueJ23IJZMeta?.redirect,
    component: component_45stubYjueJ23IJZ
  },
  {
    name: component_45stubYjueJ23IJZMeta?.name ?? undefined,
    path: component_45stubYjueJ23IJZMeta?.path ?? "/podgotovka-po-voprosam-bezopasnosti-passazhirov-i-vodonepronicaemosti-korpusa/",
    meta: component_45stubYjueJ23IJZMeta || {},
    alias: component_45stubYjueJ23IJZMeta?.alias || [],
    redirect: component_45stubYjueJ23IJZMeta?.redirect,
    component: component_45stubYjueJ23IJZ
  },
  {
    name: component_45stubYjueJ23IJZMeta?.name ?? undefined,
    path: component_45stubYjueJ23IJZMeta?.path ?? "/podgotovka-specialista-po-spasatelnym-shlyupkam-i-plotam-i-dezhurnym-shlyupkam-ne-yavlyayushhimisya-skorostnymi-dezhurnymi-shlyupkami/",
    meta: component_45stubYjueJ23IJZMeta || {},
    alias: component_45stubYjueJ23IJZMeta?.alias || [],
    redirect: component_45stubYjueJ23IJZMeta?.redirect,
    component: component_45stubYjueJ23IJZ
  },
  {
    name: component_45stubYjueJ23IJZMeta?.name ?? undefined,
    path: component_45stubYjueJ23IJZMeta?.path ?? "/podgotovka-specialistov-vnutrennih-vodnyh-putej-i-beregovyh-specialistov/",
    meta: component_45stubYjueJ23IJZMeta || {},
    alias: component_45stubYjueJ23IJZMeta?.alias || [],
    redirect: component_45stubYjueJ23IJZMeta?.redirect,
    component: component_45stubYjueJ23IJZ
  },
  {
    name: component_45stubYjueJ23IJZMeta?.name ?? undefined,
    path: component_45stubYjueJ23IJZMeta?.path ?? "/podgotovki-dlja-off-shore-sektora/:pathMatch(.*)",
    meta: component_45stubYjueJ23IJZMeta || {},
    alias: component_45stubYjueJ23IJZMeta?.alias || [],
    redirect: component_45stubYjueJ23IJZMeta?.redirect,
    component: component_45stubYjueJ23IJZ
  },
  {
    name: component_45stubYjueJ23IJZMeta?.name ?? undefined,
    path: component_45stubYjueJ23IJZMeta?.path ?? "/podpisano-soglashenie-s-mintransom-po-programmam-podgotovki-sudovoditelej-mehanikov-jelektromehanikov",
    meta: component_45stubYjueJ23IJZMeta || {},
    alias: component_45stubYjueJ23IJZMeta?.alias || [],
    redirect: component_45stubYjueJ23IJZMeta?.redirect,
    component: component_45stubYjueJ23IJZ
  },
  {
    name: component_45stubYjueJ23IJZMeta?.name ?? undefined,
    path: component_45stubYjueJ23IJZMeta?.path ?? "/politika-konfidencialnosti/:pathMatch(.*)",
    meta: component_45stubYjueJ23IJZMeta || {},
    alias: component_45stubYjueJ23IJZMeta?.alias || [],
    redirect: component_45stubYjueJ23IJZMeta?.redirect,
    component: component_45stubYjueJ23IJZ
  },
  {
    name: component_45stubYjueJ23IJZMeta?.name ?? undefined,
    path: component_45stubYjueJ23IJZMeta?.path ?? "/povyshenie-kvalifikacii-specialistov-morskogo-sektora/:pathMatch(.*)",
    meta: component_45stubYjueJ23IJZMeta || {},
    alias: component_45stubYjueJ23IJZMeta?.alias || [],
    redirect: component_45stubYjueJ23IJZMeta?.redirect,
    component: component_45stubYjueJ23IJZ
  },
  {
    name: component_45stubYjueJ23IJZMeta?.name ?? undefined,
    path: component_45stubYjueJ23IJZMeta?.path ?? "/pozdravlyaem-s-novym-2019-godom/:pathMatch(.*)",
    meta: component_45stubYjueJ23IJZMeta || {},
    alias: component_45stubYjueJ23IJZMeta?.alias || [],
    redirect: component_45stubYjueJ23IJZMeta?.redirect,
    component: component_45stubYjueJ23IJZ
  },
  {
    name: component_45stubYjueJ23IJZMeta?.name ?? undefined,
    path: component_45stubYjueJ23IJZMeta?.path ?? "/prajs-2/:pathMatch(.*)",
    meta: component_45stubYjueJ23IJZMeta || {},
    alias: component_45stubYjueJ23IJZMeta?.alias || [],
    redirect: component_45stubYjueJ23IJZMeta?.redirect,
    component: component_45stubYjueJ23IJZ
  },
  {
    name: component_45stubYjueJ23IJZMeta?.name ?? undefined,
    path: component_45stubYjueJ23IJZMeta?.path ?? "/pravitelstvo-gotovo-pomenyat-zakonodatelstvo-radi-privatizacii-sovkomflota",
    meta: component_45stubYjueJ23IJZMeta || {},
    alias: component_45stubYjueJ23IJZMeta?.alias || [],
    redirect: component_45stubYjueJ23IJZMeta?.redirect,
    component: component_45stubYjueJ23IJZ
  },
  {
    name: component_45stubYjueJ23IJZMeta?.name ?? undefined,
    path: component_45stubYjueJ23IJZMeta?.path ?? "/preparations-for-the-off-shore-sector/:pathMatch(.*)",
    meta: component_45stubYjueJ23IJZMeta || {},
    alias: component_45stubYjueJ23IJZMeta?.alias || [],
    redirect: component_45stubYjueJ23IJZMeta?.redirect,
    component: component_45stubYjueJ23IJZ
  },
  {
    name: component_45stubYjueJ23IJZMeta?.name ?? undefined,
    path: component_45stubYjueJ23IJZMeta?.path ?? "/price/:pathMatch(.*)",
    meta: component_45stubYjueJ23IJZMeta || {},
    alias: component_45stubYjueJ23IJZMeta?.alias || [],
    redirect: component_45stubYjueJ23IJZMeta?.redirect,
    component: component_45stubYjueJ23IJZ
  },
  {
    name: component_45stubYjueJ23IJZMeta?.name ?? undefined,
    path: component_45stubYjueJ23IJZMeta?.path ?? "/price-2/:pathMatch(.*)",
    meta: component_45stubYjueJ23IJZMeta || {},
    alias: component_45stubYjueJ23IJZMeta?.alias || [],
    redirect: component_45stubYjueJ23IJZMeta?.redirect,
    component: component_45stubYjueJ23IJZ
  },
  {
    name: component_45stubYjueJ23IJZMeta?.name ?? undefined,
    path: component_45stubYjueJ23IJZMeta?.path ?? "/privacy-policy/:pathMatch(.*)",
    meta: component_45stubYjueJ23IJZMeta || {},
    alias: component_45stubYjueJ23IJZMeta?.alias || [],
    redirect: component_45stubYjueJ23IJZMeta?.redirect,
    component: component_45stubYjueJ23IJZ
  },
  {
    name: component_45stubYjueJ23IJZMeta?.name ?? undefined,
    path: component_45stubYjueJ23IJZMeta?.path ?? "/product-category/additional-training-for-seafarers/:pathMatch(.*)",
    meta: component_45stubYjueJ23IJZMeta || {},
    alias: component_45stubYjueJ23IJZMeta?.alias || [],
    redirect: component_45stubYjueJ23IJZMeta?.redirect,
    component: component_45stubYjueJ23IJZ
  },
  {
    name: component_45stubYjueJ23IJZMeta?.name ?? undefined,
    path: component_45stubYjueJ23IJZMeta?.path ?? "/product-category/additional-training-for-seafarers/additionally/:pathMatch(.*)",
    meta: component_45stubYjueJ23IJZMeta || {},
    alias: component_45stubYjueJ23IJZMeta?.alias || [],
    redirect: component_45stubYjueJ23IJZMeta?.redirect,
    component: component_45stubYjueJ23IJZ
  },
  {
    name: component_45stubYjueJ23IJZMeta?.name ?? undefined,
    path: component_45stubYjueJ23IJZMeta?.path ?? "/product-category/additional-training-for-seafarers/dangerous-goods-and-the-environment",
    meta: component_45stubYjueJ23IJZMeta || {},
    alias: component_45stubYjueJ23IJZMeta?.alias || [],
    redirect: component_45stubYjueJ23IJZMeta?.redirect,
    component: component_45stubYjueJ23IJZ
  },
  {
    name: component_45stubYjueJ23IJZMeta?.name ?? undefined,
    path: component_45stubYjueJ23IJZMeta?.path ?? "/product-category/additional-training-for-seafarers/engine-room-simulator",
    meta: component_45stubYjueJ23IJZMeta || {},
    alias: component_45stubYjueJ23IJZMeta?.alias || [],
    redirect: component_45stubYjueJ23IJZMeta?.redirect,
    component: component_45stubYjueJ23IJZ
  },
  {
    name: component_45stubYjueJ23IJZMeta?.name ?? undefined,
    path: component_45stubYjueJ23IJZMeta?.path ?? "/product-category/additional-training-for-seafarers/medicine-and-hygiene",
    meta: component_45stubYjueJ23IJZMeta || {},
    alias: component_45stubYjueJ23IJZMeta?.alias || [],
    redirect: component_45stubYjueJ23IJZMeta?.redirect,
    component: component_45stubYjueJ23IJZ
  },
  {
    name: component_45stubYjueJ23IJZMeta?.name ?? undefined,
    path: component_45stubYjueJ23IJZMeta?.path ?? "/product-category/additional-training-for-seafarers/navigation-simulator/?lang=en",
    meta: component_45stubYjueJ23IJZMeta || {},
    alias: component_45stubYjueJ23IJZMeta?.alias || [],
    redirect: component_45stubYjueJ23IJZMeta?.redirect,
    component: component_45stubYjueJ23IJZ
  },
  {
    name: component_45stubYjueJ23IJZMeta?.name ?? undefined,
    path: component_45stubYjueJ23IJZMeta?.path ?? "/product-category/additional-training-for-seafarers/passenger-ships/:pathMatch(.*)",
    meta: component_45stubYjueJ23IJZMeta || {},
    alias: component_45stubYjueJ23IJZMeta?.alias || [],
    redirect: component_45stubYjueJ23IJZMeta?.redirect,
    component: component_45stubYjueJ23IJZ
  },
  {
    name: component_45stubYjueJ23IJZMeta?.name ?? undefined,
    path: component_45stubYjueJ23IJZMeta?.path ?? "/product-category/additional-training-for-seafarers/ship-safety-and-security",
    meta: component_45stubYjueJ23IJZMeta || {},
    alias: component_45stubYjueJ23IJZMeta?.alias || [],
    redirect: component_45stubYjueJ23IJZMeta?.redirect,
    component: component_45stubYjueJ23IJZ
  },
  {
    name: component_45stubYjueJ23IJZMeta?.name ?? undefined,
    path: component_45stubYjueJ23IJZMeta?.path ?? "/product-category/additional-training-for-seafarers/svs/:pathMatch(.*)",
    meta: component_45stubYjueJ23IJZMeta || {},
    alias: component_45stubYjueJ23IJZMeta?.alias || [],
    redirect: component_45stubYjueJ23IJZMeta?.redirect,
    component: component_45stubYjueJ23IJZ
  },
  {
    name: component_45stubYjueJ23IJZMeta?.name ?? undefined,
    path: component_45stubYjueJ23IJZMeta?.path ?? "/product-category/additional-training-for-seafarers/tanker-training/:pathMatch(.*)",
    meta: component_45stubYjueJ23IJZMeta || {},
    alias: component_45stubYjueJ23IJZMeta?.alias || [],
    redirect: component_45stubYjueJ23IJZMeta?.redirect,
    component: component_45stubYjueJ23IJZ
  },
  {
    name: component_45stubYjueJ23IJZMeta?.name ?? undefined,
    path: component_45stubYjueJ23IJZMeta?.path ?? "/product-category/beregovoe-napravlenie/:pathMatch(.*)",
    meta: component_45stubYjueJ23IJZMeta || {},
    alias: component_45stubYjueJ23IJZMeta?.alias || [],
    redirect: component_45stubYjueJ23IJZMeta?.redirect,
    component: component_45stubYjueJ23IJZ
  },
  {
    name: component_45stubYjueJ23IJZMeta?.name ?? undefined,
    path: component_45stubYjueJ23IJZMeta?.path ?? "/product-category/coastal-direction/:pathMatch(.*)",
    meta: component_45stubYjueJ23IJZMeta || {},
    alias: component_45stubYjueJ23IJZMeta?.alias || [],
    redirect: component_45stubYjueJ23IJZMeta?.redirect,
    component: component_45stubYjueJ23IJZ
  },
  {
    name: component_45stubYjueJ23IJZMeta?.name ?? undefined,
    path: component_45stubYjueJ23IJZMeta?.path ?? "/product-category/dopolnitelnaja-podgotovka-morjakov/:pathMatch(.*)",
    meta: component_45stubYjueJ23IJZMeta || {},
    alias: component_45stubYjueJ23IJZMeta?.alias || [],
    redirect: component_45stubYjueJ23IJZMeta?.redirect,
    component: component_45stubYjueJ23IJZ
  },
  {
    name: component_45stubYjueJ23IJZMeta?.name ?? undefined,
    path: component_45stubYjueJ23IJZMeta?.path ?? "/product-category/dopolnitelnaja-podgotovka-morjakov/bezopasnost-i-ohrana-sudna/",
    meta: component_45stubYjueJ23IJZMeta || {},
    alias: component_45stubYjueJ23IJZMeta?.alias || [],
    redirect: component_45stubYjueJ23IJZMeta?.redirect,
    component: component_45stubYjueJ23IJZ
  },
  {
    name: component_45stubYjueJ23IJZMeta?.name ?? undefined,
    path: component_45stubYjueJ23IJZMeta?.path ?? "/product-category/dopolnitelnaja-podgotovka-morjakov/bzhs/:pathMatch(.*)",
    meta: component_45stubYjueJ23IJZMeta || {},
    alias: component_45stubYjueJ23IJZMeta?.alias || [],
    redirect: component_45stubYjueJ23IJZMeta?.redirect,
    component: component_45stubYjueJ23IJZ
  },
  {
    name: component_45stubYjueJ23IJZMeta?.name ?? undefined,
    path: component_45stubYjueJ23IJZMeta?.path ?? "/product-category/dopolnitelnaja-podgotovka-morjakov/dopolnitelno/:pathMatch(.*)",
    meta: component_45stubYjueJ23IJZMeta || {},
    alias: component_45stubYjueJ23IJZMeta?.alias || [],
    redirect: component_45stubYjueJ23IJZMeta?.redirect,
    component: component_45stubYjueJ23IJZ
  },
  {
    name: component_45stubYjueJ23IJZMeta?.name ?? undefined,
    path: component_45stubYjueJ23IJZMeta?.path ?? "/product-category/dopolnitelnaja-podgotovka-morjakov/medicina-i-gigiena/",
    meta: component_45stubYjueJ23IJZMeta || {},
    alias: component_45stubYjueJ23IJZMeta?.alias || [],
    redirect: component_45stubYjueJ23IJZMeta?.redirect,
    component: component_45stubYjueJ23IJZ
  },
  {
    name: component_45stubYjueJ23IJZMeta?.name ?? undefined,
    path: component_45stubYjueJ23IJZMeta?.path ?? "/product-category/dopolnitelnaja-podgotovka-morjakov/navigacionnyj-trenazher-dopolnitelnaja-podgotovka-morjakov/",
    meta: component_45stubYjueJ23IJZMeta || {},
    alias: component_45stubYjueJ23IJZMeta?.alias || [],
    redirect: component_45stubYjueJ23IJZMeta?.redirect,
    component: component_45stubYjueJ23IJZ
  },
  {
    name: component_45stubYjueJ23IJZMeta?.name ?? undefined,
    path: component_45stubYjueJ23IJZMeta?.path ?? "/product-category/dopolnitelnaja-podgotovka-morjakov/opasnye-gruzy-i-okruzhajushhaja-sreda/",
    meta: component_45stubYjueJ23IJZMeta || {},
    alias: component_45stubYjueJ23IJZMeta?.alias || [],
    redirect: component_45stubYjueJ23IJZMeta?.redirect,
    component: component_45stubYjueJ23IJZ
  },
  {
    name: component_45stubYjueJ23IJZMeta?.name ?? undefined,
    path: component_45stubYjueJ23IJZMeta?.path ?? "/product-category/dopolnitelnaja-podgotovka-morjakov/passazhirskie-suda/",
    meta: component_45stubYjueJ23IJZMeta || {},
    alias: component_45stubYjueJ23IJZMeta?.alias || [],
    redirect: component_45stubYjueJ23IJZMeta?.redirect,
    component: component_45stubYjueJ23IJZ
  },
  {
    name: component_45stubYjueJ23IJZMeta?.name ?? undefined,
    path: component_45stubYjueJ23IJZMeta?.path ?? "/product-category/dopolnitelnaja-podgotovka-morjakov/tankernaja-podgotovka/",
    meta: component_45stubYjueJ23IJZMeta || {},
    alias: component_45stubYjueJ23IJZMeta?.alias || [],
    redirect: component_45stubYjueJ23IJZMeta?.redirect,
    component: component_45stubYjueJ23IJZ
  },
  {
    name: component_45stubYjueJ23IJZMeta?.name ?? undefined,
    path: component_45stubYjueJ23IJZMeta?.path ?? "/product-category/dopolnitelnaja-podgotovka-morjakov/trenazher-mashinnogo-otdelenija-dopolnitelnaja-podgotovka-morjakov/",
    meta: component_45stubYjueJ23IJZMeta || {},
    alias: component_45stubYjueJ23IJZMeta?.alias || [],
    redirect: component_45stubYjueJ23IJZMeta?.redirect,
    component: component_45stubYjueJ23IJZ
  },
  {
    name: component_45stubYjueJ23IJZMeta?.name ?? undefined,
    path: component_45stubYjueJ23IJZMeta?.path ?? "/product-category/drugie-napravlenija/:pathMatch(.*)",
    meta: component_45stubYjueJ23IJZMeta || {},
    alias: component_45stubYjueJ23IJZMeta?.alias || [],
    redirect: component_45stubYjueJ23IJZMeta?.redirect,
    component: component_45stubYjueJ23IJZ
  },
  {
    name: component_45stubYjueJ23IJZMeta?.name ?? undefined,
    path: component_45stubYjueJ23IJZMeta?.path ?? "/product-category/drugie-napravlenija/pervaja-pomoshh/:pathMatch(.*)",
    meta: component_45stubYjueJ23IJZMeta || {},
    alias: component_45stubYjueJ23IJZMeta?.alias || [],
    redirect: component_45stubYjueJ23IJZMeta?.redirect,
    component: component_45stubYjueJ23IJZ
  },
  {
    name: component_45stubYjueJ23IJZMeta?.name ?? undefined,
    path: component_45stubYjueJ23IJZMeta?.path ?? "/product-category/drugie-napravlenija/professionalnoe-obuchenie/:pathMatch(.*)",
    meta: component_45stubYjueJ23IJZMeta || {},
    alias: component_45stubYjueJ23IJZMeta?.alias || [],
    redirect: component_45stubYjueJ23IJZMeta?.redirect,
    component: component_45stubYjueJ23IJZ
  },
  {
    name: component_45stubYjueJ23IJZMeta?.name ?? undefined,
    path: component_45stubYjueJ23IJZMeta?.path ?? "/product-category/gmssb/:pathMatch(.*)",
    meta: component_45stubYjueJ23IJZMeta || {},
    alias: component_45stubYjueJ23IJZMeta?.alias || [],
    redirect: component_45stubYjueJ23IJZMeta?.redirect,
    component: component_45stubYjueJ23IJZ
  },
  {
    name: component_45stubYjueJ23IJZMeta?.name ?? undefined,
    path: component_45stubYjueJ23IJZMeta?.path ?? "/product-category/marine-direction/:pathMatch(.*)",
    meta: component_45stubYjueJ23IJZMeta || {},
    alias: component_45stubYjueJ23IJZMeta?.alias || [],
    redirect: component_45stubYjueJ23IJZMeta?.redirect,
    component: component_45stubYjueJ23IJZ
  },
  {
    name: component_45stubYjueJ23IJZMeta?.name ?? undefined,
    path: component_45stubYjueJ23IJZMeta?.path ?? "/product-category/marine-mechanics-and-electromechanics/:pathMatch(.*)",
    meta: component_45stubYjueJ23IJZMeta || {},
    alias: component_45stubYjueJ23IJZMeta?.alias || [],
    redirect: component_45stubYjueJ23IJZMeta?.redirect,
    component: component_45stubYjueJ23IJZ
  },
  {
    name: component_45stubYjueJ23IJZMeta?.name ?? undefined,
    path: component_45stubYjueJ23IJZMeta?.path ?? "/product-category/marine-mechanics-and-electromechanics/2-mechanic/:pathMatch(.*)",
    meta: component_45stubYjueJ23IJZMeta || {},
    alias: component_45stubYjueJ23IJZMeta?.alias || [],
    redirect: component_45stubYjueJ23IJZMeta?.redirect,
    component: component_45stubYjueJ23IJZ
  },
  {
    name: component_45stubYjueJ23IJZMeta?.name ?? undefined,
    path: component_45stubYjueJ23IJZMeta?.path ?? "/product-category/marine-mechanics-and-electromechanics/chief-engineer",
    meta: component_45stubYjueJ23IJZMeta || {},
    alias: component_45stubYjueJ23IJZMeta?.alias || [],
    redirect: component_45stubYjueJ23IJZMeta?.redirect,
    component: component_45stubYjueJ23IJZ
  },
  {
    name: component_45stubYjueJ23IJZMeta?.name ?? undefined,
    path: component_45stubYjueJ23IJZMeta?.path ?? "/product-category/marine-mechanics-and-electromechanics/electromechanic",
    meta: component_45stubYjueJ23IJZMeta || {},
    alias: component_45stubYjueJ23IJZMeta?.alias || [],
    redirect: component_45stubYjueJ23IJZMeta?.redirect,
    component: component_45stubYjueJ23IJZ
  },
  {
    name: component_45stubYjueJ23IJZMeta?.name ?? undefined,
    path: component_45stubYjueJ23IJZMeta?.path ?? "/product-category/marine-mechanics-and-electromechanics/on-demand-training-programs-for-shipping-companies-en",
    meta: component_45stubYjueJ23IJZMeta || {},
    alias: component_45stubYjueJ23IJZMeta?.alias || [],
    redirect: component_45stubYjueJ23IJZMeta?.redirect,
    component: component_45stubYjueJ23IJZ
  },
  {
    name: component_45stubYjueJ23IJZMeta?.name ?? undefined,
    path: component_45stubYjueJ23IJZMeta?.path ?? "/product-category/marine-mechanics-and-electromechanics/senior-electrician",
    meta: component_45stubYjueJ23IJZMeta || {},
    alias: component_45stubYjueJ23IJZMeta?.alias || [],
    redirect: component_45stubYjueJ23IJZMeta?.redirect,
    component: component_45stubYjueJ23IJZ
  },
  {
    name: component_45stubYjueJ23IJZMeta?.name ?? undefined,
    path: component_45stubYjueJ23IJZMeta?.path ?? "/product-category/marine-mechanics-and-electromechanics/watch-mechanic",
    meta: component_45stubYjueJ23IJZMeta || {},
    alias: component_45stubYjueJ23IJZMeta?.alias || [],
    redirect: component_45stubYjueJ23IJZMeta?.redirect,
    component: component_45stubYjueJ23IJZ
  },
  {
    name: component_45stubYjueJ23IJZMeta?.name ?? undefined,
    path: component_45stubYjueJ23IJZMeta?.path ?? "/product-category/morskoe-napravlenie/:pathMatch(.*)",
    meta: component_45stubYjueJ23IJZMeta || {},
    alias: component_45stubYjueJ23IJZMeta?.alias || [],
    redirect: component_45stubYjueJ23IJZMeta?.redirect,
    component: component_45stubYjueJ23IJZ
  },
  {
    name: component_45stubYjueJ23IJZMeta?.name ?? undefined,
    path: component_45stubYjueJ23IJZMeta?.path ?? "/product-category/napravlenija/:pathMatch(.*)",
    meta: component_45stubYjueJ23IJZMeta || {},
    alias: component_45stubYjueJ23IJZMeta?.alias || [],
    redirect: component_45stubYjueJ23IJZMeta?.redirect,
    component: component_45stubYjueJ23IJZ
  },
  {
    name: component_45stubYjueJ23IJZMeta?.name ?? undefined,
    path: component_45stubYjueJ23IJZMeta?.path ?? "/product-category/navigators/:pathMatch(.*)",
    meta: component_45stubYjueJ23IJZMeta || {},
    alias: component_45stubYjueJ23IJZMeta?.alias || [],
    redirect: component_45stubYjueJ23IJZMeta?.redirect,
    component: component_45stubYjueJ23IJZ
  },
  {
    name: component_45stubYjueJ23IJZMeta?.name ?? undefined,
    path: component_45stubYjueJ23IJZMeta?.path ?? "/product-category/navigators/captain/:pathMatch(.*)",
    meta: component_45stubYjueJ23IJZMeta || {},
    alias: component_45stubYjueJ23IJZMeta?.alias || [],
    redirect: component_45stubYjueJ23IJZMeta?.redirect,
    component: component_45stubYjueJ23IJZ
  },
  {
    name: component_45stubYjueJ23IJZMeta?.name ?? undefined,
    path: component_45stubYjueJ23IJZMeta?.path ?? "/product-category/navigators/officer-of-the-watch/:pathMatch(.*)",
    meta: component_45stubYjueJ23IJZMeta || {},
    alias: component_45stubYjueJ23IJZMeta?.alias || [],
    redirect: component_45stubYjueJ23IJZMeta?.redirect,
    component: component_45stubYjueJ23IJZ
  },
  {
    name: component_45stubYjueJ23IJZMeta?.name ?? undefined,
    path: component_45stubYjueJ23IJZMeta?.path ?? "/product-category/navigators/senior-mate/:pathMatch(.*)",
    meta: component_45stubYjueJ23IJZMeta || {},
    alias: component_45stubYjueJ23IJZMeta?.alias || [],
    redirect: component_45stubYjueJ23IJZMeta?.redirect,
    component: component_45stubYjueJ23IJZ
  },
  {
    name: component_45stubYjueJ23IJZMeta?.name ?? undefined,
    path: component_45stubYjueJ23IJZMeta?.path ?? "/product-category/off-shore/:pathMatch(.*)",
    meta: component_45stubYjueJ23IJZMeta || {},
    alias: component_45stubYjueJ23IJZMeta?.alias || [],
    redirect: component_45stubYjueJ23IJZMeta?.redirect,
    component: component_45stubYjueJ23IJZ
  },
  {
    name: component_45stubYjueJ23IJZMeta?.name ?? undefined,
    path: component_45stubYjueJ23IJZMeta?.path ?? "/product-category/other-directions/:pathMatch(.*)",
    meta: component_45stubYjueJ23IJZMeta || {},
    alias: component_45stubYjueJ23IJZMeta?.alias || [],
    redirect: component_45stubYjueJ23IJZMeta?.redirect,
    component: component_45stubYjueJ23IJZ
  },
  {
    name: component_45stubYjueJ23IJZMeta?.name ?? undefined,
    path: component_45stubYjueJ23IJZMeta?.path ?? "/product-category/other-directions/first-aid/:pathMatch(.*)",
    meta: component_45stubYjueJ23IJZMeta || {},
    alias: component_45stubYjueJ23IJZMeta?.alias || [],
    redirect: component_45stubYjueJ23IJZMeta?.redirect,
    component: component_45stubYjueJ23IJZ
  },
  {
    name: component_45stubYjueJ23IJZMeta?.name ?? undefined,
    path: component_45stubYjueJ23IJZMeta?.path ?? "/product-category/other-directions/professional-education/:pathMatch(.*)",
    meta: component_45stubYjueJ23IJZMeta || {},
    alias: component_45stubYjueJ23IJZMeta?.alias || [],
    redirect: component_45stubYjueJ23IJZMeta?.redirect,
    component: component_45stubYjueJ23IJZ
  },
  {
    name: component_45stubYjueJ23IJZMeta?.name ?? undefined,
    path: component_45stubYjueJ23IJZMeta?.path ?? "/product-category/podgotovka-chlenov-jekipazhej-opredelennyh-tipov-sudov/",
    meta: component_45stubYjueJ23IJZMeta || {},
    alias: component_45stubYjueJ23IJZMeta?.alias || [],
    redirect: component_45stubYjueJ23IJZMeta?.redirect,
    component: component_45stubYjueJ23IJZ
  },
  {
    name: component_45stubYjueJ23IJZMeta?.name ?? undefined,
    path: component_45stubYjueJ23IJZMeta?.path ?? "/product-category/podgotovka-chlenov-jekipazhej-opredelennyh-tipov-sudov/passazhirskie-suda-podgotovka-chlenov-jekipazhej-opredelennyh-tipov-sudov/",
    meta: component_45stubYjueJ23IJZMeta || {},
    alias: component_45stubYjueJ23IJZMeta?.alias || [],
    redirect: component_45stubYjueJ23IJZMeta?.redirect,
    component: component_45stubYjueJ23IJZ
  },
  {
    name: component_45stubYjueJ23IJZMeta?.name ?? undefined,
    path: component_45stubYjueJ23IJZMeta?.path ?? "/product-category/podgotovka-chlenov-jekipazhej-opredelennyh-tipov-sudov/tankera/",
    meta: component_45stubYjueJ23IJZMeta || {},
    alias: component_45stubYjueJ23IJZMeta?.alias || [],
    redirect: component_45stubYjueJ23IJZMeta?.redirect,
    component: component_45stubYjueJ23IJZ
  },
  {
    name: component_45stubYjueJ23IJZMeta?.name ?? undefined,
    path: component_45stubYjueJ23IJZMeta?.path ?? "/product-category/podgotovka-specialistov-vnutrennih-vodnyh-putej-i-beregovyh-specialistov/",
    meta: component_45stubYjueJ23IJZMeta || {},
    alias: component_45stubYjueJ23IJZMeta?.alias || [],
    redirect: component_45stubYjueJ23IJZMeta?.redirect,
    component: component_45stubYjueJ23IJZ
  },
  {
    name: component_45stubYjueJ23IJZMeta?.name ?? undefined,
    path: component_45stubYjueJ23IJZMeta?.path ?? "/product-category/podgotovka-specialistov-vnutrennih-vodnyh-putej-i-beregovyh-specialistov/beregovye-specialisty/",
    meta: component_45stubYjueJ23IJZMeta || {},
    alias: component_45stubYjueJ23IJZMeta?.alias || [],
    redirect: component_45stubYjueJ23IJZMeta?.redirect,
    component: component_45stubYjueJ23IJZ
  },
  {
    name: component_45stubYjueJ23IJZMeta?.name ?? undefined,
    path: component_45stubYjueJ23IJZMeta?.path ?? "/product-category/podgotovka-specialistov-vnutrennih-vodnyh-putej-i-beregovyh-specialistov/specialisty-vnutrennih-vodnyh-putej/",
    meta: component_45stubYjueJ23IJZMeta || {},
    alias: component_45stubYjueJ23IJZMeta?.alias || [],
    redirect: component_45stubYjueJ23IJZMeta?.redirect,
    component: component_45stubYjueJ23IJZ
  },
  {
    name: component_45stubYjueJ23IJZMeta?.name ?? undefined,
    path: component_45stubYjueJ23IJZMeta?.path ?? "/product-category/povyshenie-kvalifikacii-specialistov-morskogo-sektora/",
    meta: component_45stubYjueJ23IJZMeta || {},
    alias: component_45stubYjueJ23IJZMeta?.alias || [],
    redirect: component_45stubYjueJ23IJZMeta?.redirect,
    component: component_45stubYjueJ23IJZ
  },
  {
    name: component_45stubYjueJ23IJZMeta?.name ?? undefined,
    path: component_45stubYjueJ23IJZMeta?.path ?? "/product-category/preparations-for-the-off-shore-sector/:pathMatch(.*)",
    meta: component_45stubYjueJ23IJZMeta || {},
    alias: component_45stubYjueJ23IJZMeta?.alias || [],
    redirect: component_45stubYjueJ23IJZMeta?.redirect,
    component: component_45stubYjueJ23IJZ
  },
  {
    name: component_45stubYjueJ23IJZMeta?.name ?? undefined,
    path: component_45stubYjueJ23IJZMeta?.path ?? "/product-category/professional-development-of-specialists-in-the-maritime-sector",
    meta: component_45stubYjueJ23IJZMeta || {},
    alias: component_45stubYjueJ23IJZMeta?.alias || [],
    redirect: component_45stubYjueJ23IJZMeta?.redirect,
    component: component_45stubYjueJ23IJZ
  },
  {
    name: component_45stubYjueJ23IJZMeta?.name ?? undefined,
    path: component_45stubYjueJ23IJZMeta?.path ?? "/product-category/programms-povyshenija-dlja-prodlenija-diploma/:pathMatch(.*)",
    meta: component_45stubYjueJ23IJZMeta || {},
    alias: component_45stubYjueJ23IJZMeta?.alias || [],
    redirect: component_45stubYjueJ23IJZMeta?.redirect,
    component: component_45stubYjueJ23IJZ
  },
  {
    name: component_45stubYjueJ23IJZMeta?.name ?? undefined,
    path: component_45stubYjueJ23IJZMeta?.path ?? "/product-category/rank-and-file/:pathMatch(.*)",
    meta: component_45stubYjueJ23IJZMeta || {},
    alias: component_45stubYjueJ23IJZMeta?.alias || [],
    redirect: component_45stubYjueJ23IJZMeta?.redirect,
    component: component_45stubYjueJ23IJZ
  },
  {
    name: component_45stubYjueJ23IJZMeta?.name ?? undefined,
    path: component_45stubYjueJ23IJZMeta?.path ?? "/product-category/rank-and-file/preparation-for-work-on-passenger-ships-and-tankers",
    meta: component_45stubYjueJ23IJZMeta || {},
    alias: component_45stubYjueJ23IJZMeta?.alias || [],
    redirect: component_45stubYjueJ23IJZMeta?.redirect,
    component: component_45stubYjueJ23IJZ
  },
  {
    name: component_45stubYjueJ23IJZMeta?.name ?? undefined,
    path: component_45stubYjueJ23IJZMeta?.path ?? "/product-category/rank-and-file/qualified-minder/:pathMatch(.*)",
    meta: component_45stubYjueJ23IJZMeta || {},
    alias: component_45stubYjueJ23IJZMeta?.alias || [],
    redirect: component_45stubYjueJ23IJZMeta?.redirect,
    component: component_45stubYjueJ23IJZ
  },
  {
    name: component_45stubYjueJ23IJZMeta?.name ?? undefined,
    path: component_45stubYjueJ23IJZMeta?.path ?? "/product-category/rank-and-file/ship-cook/:pathMatch(.*)",
    meta: component_45stubYjueJ23IJZMeta || {},
    alias: component_45stubYjueJ23IJZMeta?.alias || [],
    redirect: component_45stubYjueJ23IJZMeta?.redirect,
    component: component_45stubYjueJ23IJZ
  },
  {
    name: component_45stubYjueJ23IJZMeta?.name ?? undefined,
    path: component_45stubYjueJ23IJZMeta?.path ?? "/product-category/rank-and-file/ship-electrician/:pathMatch(.*)",
    meta: component_45stubYjueJ23IJZMeta || {},
    alias: component_45stubYjueJ23IJZMeta?.alias || [],
    redirect: component_45stubYjueJ23IJZMeta?.redirect,
    component: component_45stubYjueJ23IJZ
  },
  {
    name: component_45stubYjueJ23IJZMeta?.name ?? undefined,
    path: component_45stubYjueJ23IJZMeta?.path ?? "/product-category/rank-and-file/skilled-sailor/:pathMatch(.*)",
    meta: component_45stubYjueJ23IJZMeta || {},
    alias: component_45stubYjueJ23IJZMeta?.alias || [],
    redirect: component_45stubYjueJ23IJZMeta?.redirect,
    component: component_45stubYjueJ23IJZ
  },
  {
    name: component_45stubYjueJ23IJZMeta?.name ?? undefined,
    path: component_45stubYjueJ23IJZMeta?.path ?? "/product-category/rank-and-file/watch-minder/:pathMatch(.*)",
    meta: component_45stubYjueJ23IJZMeta || {},
    alias: component_45stubYjueJ23IJZMeta?.alias || [],
    redirect: component_45stubYjueJ23IJZMeta?.redirect,
    component: component_45stubYjueJ23IJZ
  },
  {
    name: component_45stubYjueJ23IJZMeta?.name ?? undefined,
    path: component_45stubYjueJ23IJZMeta?.path ?? "/product-category/rank-and-file/watch-sailor/:pathMatch(.*)",
    meta: component_45stubYjueJ23IJZMeta || {},
    alias: component_45stubYjueJ23IJZMeta?.alias || [],
    redirect: component_45stubYjueJ23IJZMeta?.redirect,
    component: component_45stubYjueJ23IJZ
  },
  {
    name: component_45stubYjueJ23IJZMeta?.name ?? undefined,
    path: component_45stubYjueJ23IJZMeta?.path ?? "/product-category/rechnoe-napravlenie/malomernye-suda/:pathMatch(.*)",
    meta: component_45stubYjueJ23IJZMeta || {},
    alias: component_45stubYjueJ23IJZMeta?.alias || [],
    redirect: component_45stubYjueJ23IJZMeta?.redirect,
    component: component_45stubYjueJ23IJZ
  },
  {
    name: component_45stubYjueJ23IJZMeta?.name ?? undefined,
    path: component_45stubYjueJ23IJZMeta?.path ?? "/product-category/rjadovoj-sostav/:pathMatch(.*)",
    meta: component_45stubYjueJ23IJZMeta || {},
    alias: component_45stubYjueJ23IJZMeta?.alias || [],
    redirect: component_45stubYjueJ23IJZMeta?.redirect,
    component: component_45stubYjueJ23IJZ
  },
  {
    name: component_45stubYjueJ23IJZMeta?.name ?? undefined,
    path: component_45stubYjueJ23IJZMeta?.path ?? "/product-category/rjadovoj-sostav/kvalificirovannyj-matros/:pathMatch(.*)",
    meta: component_45stubYjueJ23IJZMeta || {},
    alias: component_45stubYjueJ23IJZMeta?.alias || [],
    redirect: component_45stubYjueJ23IJZMeta?.redirect,
    component: component_45stubYjueJ23IJZ
  },
  {
    name: component_45stubYjueJ23IJZMeta?.name ?? undefined,
    path: component_45stubYjueJ23IJZMeta?.path ?? "/product-category/rjadovoj-sostav/kvalificirovannyj-motorist/:pathMatch(.*)",
    meta: component_45stubYjueJ23IJZMeta || {},
    alias: component_45stubYjueJ23IJZMeta?.alias || [],
    redirect: component_45stubYjueJ23IJZMeta?.redirect,
    component: component_45stubYjueJ23IJZ
  },
  {
    name: component_45stubYjueJ23IJZMeta?.name ?? undefined,
    path: component_45stubYjueJ23IJZMeta?.path ?? "/product-category/rjadovoj-sostav/podgotovka-dlja-raboty-na-passazhirskih-sudah-i-tankerah/",
    meta: component_45stubYjueJ23IJZMeta || {},
    alias: component_45stubYjueJ23IJZMeta?.alias || [],
    redirect: component_45stubYjueJ23IJZMeta?.redirect,
    component: component_45stubYjueJ23IJZ
  },
  {
    name: component_45stubYjueJ23IJZMeta?.name ?? undefined,
    path: component_45stubYjueJ23IJZMeta?.path ?? "/product-category/rjadovoj-sostav/povar-sudovoj/:pathMatch(.*)",
    meta: component_45stubYjueJ23IJZMeta || {},
    alias: component_45stubYjueJ23IJZMeta?.alias || [],
    redirect: component_45stubYjueJ23IJZMeta?.redirect,
    component: component_45stubYjueJ23IJZ
  },
  {
    name: component_45stubYjueJ23IJZMeta?.name ?? undefined,
    path: component_45stubYjueJ23IJZMeta?.path ?? "/product-category/rjadovoj-sostav/sudovoj-jelektrik/:pathMatch(.*)",
    meta: component_45stubYjueJ23IJZMeta || {},
    alias: component_45stubYjueJ23IJZMeta?.alias || [],
    redirect: component_45stubYjueJ23IJZMeta?.redirect,
    component: component_45stubYjueJ23IJZ
  },
  {
    name: component_45stubYjueJ23IJZMeta?.name ?? undefined,
    path: component_45stubYjueJ23IJZMeta?.path ?? "/product-category/rjadovoj-sostav/vahtennyj-matros/:pathMatch(.*)",
    meta: component_45stubYjueJ23IJZMeta || {},
    alias: component_45stubYjueJ23IJZMeta?.alias || [],
    redirect: component_45stubYjueJ23IJZMeta?.redirect,
    component: component_45stubYjueJ23IJZ
  },
  {
    name: component_45stubYjueJ23IJZMeta?.name ?? undefined,
    path: component_45stubYjueJ23IJZMeta?.path ?? "/product-category/rjadovoj-sostav/vahtennyj-motorist/:pathMatch(.*)",
    meta: component_45stubYjueJ23IJZMeta || {},
    alias: component_45stubYjueJ23IJZMeta?.alias || [],
    redirect: component_45stubYjueJ23IJZMeta?.redirect,
    component: component_45stubYjueJ23IJZ
  },
  {
    name: component_45stubYjueJ23IJZMeta?.name ?? undefined,
    path: component_45stubYjueJ23IJZMeta?.path ?? "/product-category/short-term-diploma-renewal-courses/:pathMatch(.*)",
    meta: component_45stubYjueJ23IJZMeta || {},
    alias: component_45stubYjueJ23IJZMeta?.alias || [],
    redirect: component_45stubYjueJ23IJZMeta?.redirect,
    component: component_45stubYjueJ23IJZ
  },
  {
    name: component_45stubYjueJ23IJZMeta?.name ?? undefined,
    path: component_45stubYjueJ23IJZMeta?.path ?? "/product-category/sudovoditeli/:pathMatch(.*)",
    meta: component_45stubYjueJ23IJZMeta || {},
    alias: component_45stubYjueJ23IJZMeta?.alias || [],
    redirect: component_45stubYjueJ23IJZMeta?.redirect,
    component: component_45stubYjueJ23IJZ
  },
  {
    name: component_45stubYjueJ23IJZMeta?.name ?? undefined,
    path: component_45stubYjueJ23IJZMeta?.path ?? "/product-category/sudovoditeli/kapitan/:pathMatch(.*)",
    meta: component_45stubYjueJ23IJZMeta || {},
    alias: component_45stubYjueJ23IJZMeta?.alias || [],
    redirect: component_45stubYjueJ23IJZMeta?.redirect,
    component: component_45stubYjueJ23IJZ
  },
  {
    name: component_45stubYjueJ23IJZMeta?.name ?? undefined,
    path: component_45stubYjueJ23IJZMeta?.path ?? "/product-category/sudovoditeli/programmy-podgotovki-po-trebovaniju-sudohodnyh-kompanij/",
    meta: component_45stubYjueJ23IJZMeta || {},
    alias: component_45stubYjueJ23IJZMeta?.alias || [],
    redirect: component_45stubYjueJ23IJZMeta?.redirect,
    component: component_45stubYjueJ23IJZ
  },
  {
    name: component_45stubYjueJ23IJZMeta?.name ?? undefined,
    path: component_45stubYjueJ23IJZMeta?.path ?? "/product-category/sudovoditeli/starshij-pomoshhnik-kapitana/:pathMatch(.*)",
    meta: component_45stubYjueJ23IJZMeta || {},
    alias: component_45stubYjueJ23IJZMeta?.alias || [],
    redirect: component_45stubYjueJ23IJZMeta?.redirect,
    component: component_45stubYjueJ23IJZ
  },
  {
    name: component_45stubYjueJ23IJZMeta?.name ?? undefined,
    path: component_45stubYjueJ23IJZMeta?.path ?? "/product-category/sudovoditeli/vahtennyj-pomoshhnik-kapitana/:pathMatch(.*)",
    meta: component_45stubYjueJ23IJZMeta || {},
    alias: component_45stubYjueJ23IJZMeta?.alias || [],
    redirect: component_45stubYjueJ23IJZMeta?.redirect,
    component: component_45stubYjueJ23IJZ
  },
  {
    name: component_45stubYjueJ23IJZMeta?.name ?? undefined,
    path: component_45stubYjueJ23IJZMeta?.path ?? "/product-category/sudovye-mehaniki-i-jelektromehaniki/:pathMatch(.*)",
    meta: component_45stubYjueJ23IJZMeta || {},
    alias: component_45stubYjueJ23IJZMeta?.alias || [],
    redirect: component_45stubYjueJ23IJZMeta?.redirect,
    component: component_45stubYjueJ23IJZ
  },
  {
    name: component_45stubYjueJ23IJZMeta?.name ?? undefined,
    path: component_45stubYjueJ23IJZMeta?.path ?? "/product-category/sudovye-mehaniki-i-jelektromehaniki/2-mehanik/:pathMatch(.*)",
    meta: component_45stubYjueJ23IJZMeta || {},
    alias: component_45stubYjueJ23IJZMeta?.alias || [],
    redirect: component_45stubYjueJ23IJZMeta?.redirect,
    component: component_45stubYjueJ23IJZ
  },
  {
    name: component_45stubYjueJ23IJZMeta?.name ?? undefined,
    path: component_45stubYjueJ23IJZMeta?.path ?? "/product-category/sudovye-mehaniki-i-jelektromehaniki/jelektromehanik/",
    meta: component_45stubYjueJ23IJZMeta || {},
    alias: component_45stubYjueJ23IJZMeta?.alias || [],
    redirect: component_45stubYjueJ23IJZMeta?.redirect,
    component: component_45stubYjueJ23IJZ
  },
  {
    name: component_45stubYjueJ23IJZMeta?.name ?? undefined,
    path: component_45stubYjueJ23IJZMeta?.path ?? "/product-category/sudovye-mehaniki-i-jelektromehaniki/programmy-podgotovki-po-trebovaniju-sudohodnyh-kompanij-sudovye-mehaniki-i-jelektromehaniki/",
    meta: component_45stubYjueJ23IJZMeta || {},
    alias: component_45stubYjueJ23IJZMeta?.alias || [],
    redirect: component_45stubYjueJ23IJZMeta?.redirect,
    component: component_45stubYjueJ23IJZ
  },
  {
    name: component_45stubYjueJ23IJZMeta?.name ?? undefined,
    path: component_45stubYjueJ23IJZMeta?.path ?? "/product-category/sudovye-mehaniki-i-jelektromehaniki/starshij-jelektromehanik/",
    meta: component_45stubYjueJ23IJZMeta || {},
    alias: component_45stubYjueJ23IJZMeta?.alias || [],
    redirect: component_45stubYjueJ23IJZMeta?.redirect,
    component: component_45stubYjueJ23IJZ
  },
  {
    name: component_45stubYjueJ23IJZMeta?.name ?? undefined,
    path: component_45stubYjueJ23IJZMeta?.path ?? "/product-category/sudovye-mehaniki-i-jelektromehaniki/starshij-mehanik/",
    meta: component_45stubYjueJ23IJZMeta || {},
    alias: component_45stubYjueJ23IJZMeta?.alias || [],
    redirect: component_45stubYjueJ23IJZMeta?.redirect,
    component: component_45stubYjueJ23IJZ
  },
  {
    name: component_45stubYjueJ23IJZMeta?.name ?? undefined,
    path: component_45stubYjueJ23IJZMeta?.path ?? "/product-category/sudovye-mehaniki-i-jelektromehaniki/vahtennyj-mehanik/",
    meta: component_45stubYjueJ23IJZMeta || {},
    alias: component_45stubYjueJ23IJZMeta?.alias || [],
    redirect: component_45stubYjueJ23IJZMeta?.redirect,
    component: component_45stubYjueJ23IJZ
  },
  {
    name: component_45stubYjueJ23IJZMeta?.name ?? undefined,
    path: component_45stubYjueJ23IJZMeta?.path ?? "/product-category/testing-and-additional-services/:pathMatch(.*)",
    meta: component_45stubYjueJ23IJZMeta || {},
    alias: component_45stubYjueJ23IJZMeta?.alias || [],
    redirect: component_45stubYjueJ23IJZMeta?.redirect,
    component: component_45stubYjueJ23IJZ
  },
  {
    name: component_45stubYjueJ23IJZMeta?.name ?? undefined,
    path: component_45stubYjueJ23IJZMeta?.path ?? "/product-category/testing-and-additional-services/additional-services",
    meta: component_45stubYjueJ23IJZMeta || {},
    alias: component_45stubYjueJ23IJZMeta?.alias || [],
    redirect: component_45stubYjueJ23IJZMeta?.redirect,
    component: component_45stubYjueJ23IJZ
  },
  {
    name: component_45stubYjueJ23IJZMeta?.name ?? undefined,
    path: component_45stubYjueJ23IJZMeta?.path ?? "/product-category/testing-and-additional-services/english-for-seafarers",
    meta: component_45stubYjueJ23IJZMeta || {},
    alias: component_45stubYjueJ23IJZMeta?.alias || [],
    redirect: component_45stubYjueJ23IJZMeta?.redirect,
    component: component_45stubYjueJ23IJZ
  },
  {
    name: component_45stubYjueJ23IJZMeta?.name ?? undefined,
    path: component_45stubYjueJ23IJZMeta?.path ?? "/product-category/testing-and-additional-services/testing/:pathMatch(.*)",
    meta: component_45stubYjueJ23IJZMeta || {},
    alias: component_45stubYjueJ23IJZMeta?.alias || [],
    redirect: component_45stubYjueJ23IJZMeta?.redirect,
    component: component_45stubYjueJ23IJZ
  },
  {
    name: component_45stubYjueJ23IJZMeta?.name ?? undefined,
    path: component_45stubYjueJ23IJZMeta?.path ?? "/product-category/testirovanie-i-dopolnitelnye-uslugi/:pathMatch(.*)",
    meta: component_45stubYjueJ23IJZMeta || {},
    alias: component_45stubYjueJ23IJZMeta?.alias || [],
    redirect: component_45stubYjueJ23IJZMeta?.redirect,
    component: component_45stubYjueJ23IJZ
  },
  {
    name: component_45stubYjueJ23IJZMeta?.name ?? undefined,
    path: component_45stubYjueJ23IJZMeta?.path ?? "/product-category/testirovanie-i-dopolnitelnye-uslugi/angliyskiy-yasyk-dlya-moryakov/",
    meta: component_45stubYjueJ23IJZMeta || {},
    alias: component_45stubYjueJ23IJZMeta?.alias || [],
    redirect: component_45stubYjueJ23IJZMeta?.redirect,
    component: component_45stubYjueJ23IJZ
  },
  {
    name: component_45stubYjueJ23IJZMeta?.name ?? undefined,
    path: component_45stubYjueJ23IJZMeta?.path ?? "/product-category/testirovanie-i-dopolnitelnye-uslugi/dopolnitelnye-uslugi/",
    meta: component_45stubYjueJ23IJZMeta || {},
    alias: component_45stubYjueJ23IJZMeta?.alias || [],
    redirect: component_45stubYjueJ23IJZMeta?.redirect,
    component: component_45stubYjueJ23IJZ
  },
  {
    name: component_45stubYjueJ23IJZMeta?.name ?? undefined,
    path: component_45stubYjueJ23IJZMeta?.path ?? "/product-category/testirovanie-i-dopolnitelnye-uslugi/testirovanie/:pathMatch(.*)",
    meta: component_45stubYjueJ23IJZMeta || {},
    alias: component_45stubYjueJ23IJZMeta?.alias || [],
    redirect: component_45stubYjueJ23IJZMeta?.redirect,
    component: component_45stubYjueJ23IJZ
  },
  {
    name: component_45stubYjueJ23IJZMeta?.name ?? undefined,
    path: component_45stubYjueJ23IJZMeta?.path ?? "/product-category/the-river-direction/small-boats/:pathMatch(.*)",
    meta: component_45stubYjueJ23IJZMeta || {},
    alias: component_45stubYjueJ23IJZMeta?.alias || [],
    redirect: component_45stubYjueJ23IJZMeta?.redirect,
    component: component_45stubYjueJ23IJZ
  },
  {
    name: component_45stubYjueJ23IJZMeta?.name ?? undefined,
    path: component_45stubYjueJ23IJZMeta?.path ?? "/product-category/training-of-crew-members-for-certain-types-of-ships/tanker",
    meta: component_45stubYjueJ23IJZMeta || {},
    alias: component_45stubYjueJ23IJZMeta?.alias || [],
    redirect: component_45stubYjueJ23IJZMeta?.redirect,
    component: component_45stubYjueJ23IJZ
  },
  {
    name: component_45stubYjueJ23IJZMeta?.name ?? undefined,
    path: component_45stubYjueJ23IJZMeta?.path ?? "/product-category/training-of-inland-waterway-and-onshore-specialists/onshore-specialists",
    meta: component_45stubYjueJ23IJZMeta || {},
    alias: component_45stubYjueJ23IJZMeta?.alias || [],
    redirect: component_45stubYjueJ23IJZMeta?.redirect,
    component: component_45stubYjueJ23IJZ
  },
  {
    name: component_45stubYjueJ23IJZMeta?.name ?? undefined,
    path: component_45stubYjueJ23IJZMeta?.path ?? "/product-category/transport-safety/:pathMatch(.*)",
    meta: component_45stubYjueJ23IJZMeta || {},
    alias: component_45stubYjueJ23IJZMeta?.alias || [],
    redirect: component_45stubYjueJ23IJZMeta?.redirect,
    component: component_45stubYjueJ23IJZ
  },
  {
    name: component_45stubYjueJ23IJZMeta?.name ?? undefined,
    path: component_45stubYjueJ23IJZMeta?.path ?? "/product-category/transportnaja-bezopasnost/:pathMatch(.*)",
    meta: component_45stubYjueJ23IJZMeta || {},
    alias: component_45stubYjueJ23IJZMeta?.alias || [],
    redirect: component_45stubYjueJ23IJZMeta?.redirect,
    component: component_45stubYjueJ23IJZ
  },
  {
    name: component_45stubYjueJ23IJZMeta?.name ?? undefined,
    path: component_45stubYjueJ23IJZMeta?.path ?? "/professional-development-of-specialists-in-the-maritime-sector/:pathMatch(.*)",
    meta: component_45stubYjueJ23IJZMeta || {},
    alias: component_45stubYjueJ23IJZMeta?.alias || [],
    redirect: component_45stubYjueJ23IJZMeta?.redirect,
    component: component_45stubYjueJ23IJZ
  },
  {
    name: component_45stubYjueJ23IJZMeta?.name ?? undefined,
    path: component_45stubYjueJ23IJZMeta?.path ?? "/programmy-obuchenija-dlja-povyshenija-ili-prodlenija-rabochego-diploma/",
    meta: component_45stubYjueJ23IJZMeta || {},
    alias: component_45stubYjueJ23IJZMeta?.alias || [],
    redirect: component_45stubYjueJ23IJZMeta?.redirect,
    component: component_45stubYjueJ23IJZ
  },
  {
    name: component_45stubYjueJ23IJZMeta?.name ?? undefined,
    path: component_45stubYjueJ23IJZMeta?.path ?? "/programs-of-study-for-reading-or-extending-a-working-diploma/:pathMatch(.*)",
    meta: component_45stubYjueJ23IJZMeta || {},
    alias: component_45stubYjueJ23IJZMeta?.alias || [],
    redirect: component_45stubYjueJ23IJZMeta?.redirect,
    component: component_45stubYjueJ23IJZ
  },
  {
    name: component_45stubYjueJ23IJZMeta?.name ?? undefined,
    path: component_45stubYjueJ23IJZMeta?.path ?? "/rank-and-file/:pathMatch(.*)",
    meta: component_45stubYjueJ23IJZMeta || {},
    alias: component_45stubYjueJ23IJZMeta?.alias || [],
    redirect: component_45stubYjueJ23IJZMeta?.redirect,
    component: component_45stubYjueJ23IJZ
  },
  {
    name: component_45stubYjueJ23IJZMeta?.name ?? undefined,
    path: component_45stubYjueJ23IJZMeta?.path ?? "/raspisanie/:pathMatch(.*)",
    meta: component_45stubYjueJ23IJZMeta || {},
    alias: component_45stubYjueJ23IJZMeta?.alias || [],
    redirect: component_45stubYjueJ23IJZMeta?.redirect,
    component: component_45stubYjueJ23IJZ
  },
  {
    name: component_45stubYjueJ23IJZMeta?.name ?? undefined,
    path: component_45stubYjueJ23IJZMeta?.path ?? "/rassrochka-platezha/:pathMatch(.*)",
    meta: component_45stubYjueJ23IJZMeta || {},
    alias: component_45stubYjueJ23IJZMeta?.alias || [],
    redirect: component_45stubYjueJ23IJZMeta?.redirect,
    component: component_45stubYjueJ23IJZ
  },
  {
    name: component_45stubYjueJ23IJZMeta?.name ?? undefined,
    path: component_45stubYjueJ23IJZMeta?.path ?? "/refresher-courses-for-obtaining-extending-restoring-a-diploma/:pathMatch(.*)",
    meta: component_45stubYjueJ23IJZMeta || {},
    alias: component_45stubYjueJ23IJZMeta?.alias || [],
    redirect: component_45stubYjueJ23IJZMeta?.redirect,
    component: component_45stubYjueJ23IJZ
  },
  {
    name: component_45stubYjueJ23IJZMeta?.name ?? undefined,
    path: component_45stubYjueJ23IJZMeta?.path ?? "/refund_returns/:pathMatch(.*)",
    meta: component_45stubYjueJ23IJZMeta || {},
    alias: component_45stubYjueJ23IJZMeta?.alias || [],
    redirect: component_45stubYjueJ23IJZMeta?.redirect,
    component: component_45stubYjueJ23IJZ
  },
  {
    name: component_45stubYjueJ23IJZMeta?.name ?? undefined,
    path: component_45stubYjueJ23IJZMeta?.path ?? "/registracija-prepodavatelja/:pathMatch(.*)",
    meta: component_45stubYjueJ23IJZMeta || {},
    alias: component_45stubYjueJ23IJZMeta?.alias || [],
    redirect: component_45stubYjueJ23IJZMeta?.redirect,
    component: component_45stubYjueJ23IJZ
  },
  {
    name: component_45stubYjueJ23IJZMeta?.name ?? undefined,
    path: component_45stubYjueJ23IJZMeta?.path ?? "/registracija-studenta/:pathMatch(.*)",
    meta: component_45stubYjueJ23IJZMeta || {},
    alias: component_45stubYjueJ23IJZMeta?.alias || [],
    redirect: component_45stubYjueJ23IJZMeta?.redirect,
    component: component_45stubYjueJ23IJZ
  },
  {
    name: component_45stubYjueJ23IJZMeta?.name ?? undefined,
    path: component_45stubYjueJ23IJZMeta?.path ?? "/registration-for-courses/:pathMatch(.*)",
    meta: component_45stubYjueJ23IJZMeta || {},
    alias: component_45stubYjueJ23IJZMeta?.alias || [],
    redirect: component_45stubYjueJ23IJZMeta?.redirect,
    component: component_45stubYjueJ23IJZ
  },
  {
    name: component_45stubYjueJ23IJZMeta?.name ?? undefined,
    path: component_45stubYjueJ23IJZMeta?.path ?? "/required-utz-instructor-for-training-tanker-personnel/:pathMatch(.*)",
    meta: component_45stubYjueJ23IJZMeta || {},
    alias: component_45stubYjueJ23IJZMeta?.alias || [],
    redirect: component_45stubYjueJ23IJZMeta?.redirect,
    component: component_45stubYjueJ23IJZ
  },
  {
    name: component_45stubYjueJ23IJZMeta?.name ?? undefined,
    path: component_45stubYjueJ23IJZMeta?.path ?? "/return-policy/:pathMatch(.*)",
    meta: component_45stubYjueJ23IJZMeta || {},
    alias: component_45stubYjueJ23IJZMeta?.alias || [],
    redirect: component_45stubYjueJ23IJZMeta?.redirect,
    component: component_45stubYjueJ23IJZ
  },
  {
    name: component_45stubYjueJ23IJZMeta?.name ?? undefined,
    path: component_45stubYjueJ23IJZMeta?.path ?? "/rjadovoj-sostav/:pathMatch(.*)",
    meta: component_45stubYjueJ23IJZMeta || {},
    alias: component_45stubYjueJ23IJZMeta?.alias || [],
    redirect: component_45stubYjueJ23IJZMeta?.redirect,
    component: component_45stubYjueJ23IJZ
  },
  {
    name: component_45stubYjueJ23IJZMeta?.name ?? undefined,
    path: component_45stubYjueJ23IJZMeta?.path ?? "/rukovodstvo-pedagogicheskij-nauchno-pedagogicheskij-sostav/:pathMatch(.*)",
    meta: component_45stubYjueJ23IJZMeta || {},
    alias: component_45stubYjueJ23IJZMeta?.alias || [],
    redirect: component_45stubYjueJ23IJZMeta?.redirect,
    component: component_45stubYjueJ23IJZ
  },
  {
    name: component_45stubYjueJ23IJZMeta?.name ?? undefined,
    path: component_45stubYjueJ23IJZMeta?.path ?? "/s-nastupajushhim-2021-godom/:pathMatch(.*)",
    meta: component_45stubYjueJ23IJZMeta || {},
    alias: component_45stubYjueJ23IJZMeta?.alias || [],
    redirect: component_45stubYjueJ23IJZMeta?.redirect,
    component: component_45stubYjueJ23IJZ
  },
  {
    name: component_45stubYjueJ23IJZMeta?.name ?? undefined,
    path: component_45stubYjueJ23IJZMeta?.path ?? "/s-nastupajushhim-novym-2023-godom-i-rozhdestvom/:pathMatch(.*)",
    meta: component_45stubYjueJ23IJZMeta || {},
    alias: component_45stubYjueJ23IJZMeta?.alias || [],
    redirect: component_45stubYjueJ23IJZMeta?.redirect,
    component: component_45stubYjueJ23IJZ
  },
  {
    name: component_45stubYjueJ23IJZMeta?.name ?? undefined,
    path: component_45stubYjueJ23IJZMeta?.path ?? "/s-novym-godom/:pathMatch(.*)",
    meta: component_45stubYjueJ23IJZMeta || {},
    alias: component_45stubYjueJ23IJZMeta?.alias || [],
    redirect: component_45stubYjueJ23IJZMeta?.redirect,
    component: component_45stubYjueJ23IJZ
  },
  {
    name: component_45stubYjueJ23IJZMeta?.name ?? undefined,
    path: component_45stubYjueJ23IJZMeta?.path ?? "/sailboat-pallada-headed-for-vladivostok/:pathMatch(.*)",
    meta: component_45stubYjueJ23IJZMeta || {},
    alias: component_45stubYjueJ23IJZMeta?.alias || [],
    redirect: component_45stubYjueJ23IJZMeta?.redirect,
    component: component_45stubYjueJ23IJZ
  },
  {
    name: component_45stubYjueJ23IJZMeta?.name ?? undefined,
    path: component_45stubYjueJ23IJZMeta?.path ?? "/samaya-dlinnaya-v-mire-yaxta-trimaran-ostavila-rizhskij-port/:pathMatch(.*)",
    meta: component_45stubYjueJ23IJZMeta || {},
    alias: component_45stubYjueJ23IJZMeta?.alias || [],
    redirect: component_45stubYjueJ23IJZMeta?.redirect,
    component: component_45stubYjueJ23IJZ
  },
  {
    name: component_45stubYjueJ23IJZMeta?.name ?? undefined,
    path: component_45stubYjueJ23IJZMeta?.path ?? "/saudi-arabia-will-invest-1-5-billion-in-the-creation-of-the-world-s-largest-tanker-fleet",
    meta: component_45stubYjueJ23IJZMeta || {},
    alias: component_45stubYjueJ23IJZMeta?.alias || [],
    redirect: component_45stubYjueJ23IJZMeta?.redirect,
    component: component_45stubYjueJ23IJZ
  },
  {
    name: component_45stubYjueJ23IJZMeta?.name ?? undefined,
    path: component_45stubYjueJ23IJZMeta?.path ?? "/saudovskaya-araviya-vlozhit-15-mlrd-v-sozdanie-krupnejshego-v-mire-tankernogo-flota",
    meta: component_45stubYjueJ23IJZMeta || {},
    alias: component_45stubYjueJ23IJZMeta?.alias || [],
    redirect: component_45stubYjueJ23IJZMeta?.redirect,
    component: component_45stubYjueJ23IJZ
  },
  {
    name: component_45stubYjueJ23IJZMeta?.name ?? undefined,
    path: component_45stubYjueJ23IJZMeta?.path ?? "/schedule/:pathMatch(.*)",
    meta: component_45stubYjueJ23IJZMeta || {},
    alias: component_45stubYjueJ23IJZMeta?.alias || [],
    redirect: component_45stubYjueJ23IJZMeta?.redirect,
    component: component_45stubYjueJ23IJZ
  },
  {
    name: component_45stubYjueJ23IJZMeta?.name ?? undefined,
    path: component_45stubYjueJ23IJZMeta?.path ?? "/seafarer-courses/:pathMatch(.*)",
    meta: component_45stubYjueJ23IJZMeta || {},
    alias: component_45stubYjueJ23IJZMeta?.alias || [],
    redirect: component_45stubYjueJ23IJZMeta?.redirect,
    component: component_45stubYjueJ23IJZ
  },
  {
    name: component_45stubYjueJ23IJZMeta?.name ?? undefined,
    path: component_45stubYjueJ23IJZMeta?.path ?? "/service-calculator/:pathMatch(.*)",
    meta: component_45stubYjueJ23IJZMeta || {},
    alias: component_45stubYjueJ23IJZMeta?.alias || [],
    redirect: component_45stubYjueJ23IJZMeta?.redirect,
    component: component_45stubYjueJ23IJZ
  },
  {
    name: component_45stubYjueJ23IJZMeta?.name ?? undefined,
    path: component_45stubYjueJ23IJZMeta?.path ?? "/severnoe-morskoe-paroxodstvo-vypolnyaet-pervyj-rejs-v-ramkax-novogo-partnerstva",
    meta: component_45stubYjueJ23IJZMeta || {},
    alias: component_45stubYjueJ23IJZMeta?.alias || [],
    redirect: component_45stubYjueJ23IJZMeta?.redirect,
    component: component_45stubYjueJ23IJZ
  },
  {
    name: component_45stubYjueJ23IJZMeta?.name ?? undefined,
    path: component_45stubYjueJ23IJZMeta?.path ?? "/skilled-sailor-on-a-tanker/:pathMatch(.*)",
    meta: component_45stubYjueJ23IJZMeta || {},
    alias: component_45stubYjueJ23IJZMeta?.alias || [],
    redirect: component_45stubYjueJ23IJZMeta?.redirect,
    component: component_45stubYjueJ23IJZ
  },
  {
    name: component_45stubYjueJ23IJZMeta?.name ?? undefined,
    path: component_45stubYjueJ23IJZMeta?.path ?? "/slide-anything-popup-preview/:pathMatch(.*)",
    meta: component_45stubYjueJ23IJZMeta || {},
    alias: component_45stubYjueJ23IJZMeta?.alias || [],
    redirect: component_45stubYjueJ23IJZMeta?.redirect,
    component: component_45stubYjueJ23IJZ
  },
  {
    name: component_45stubYjueJ23IJZMeta?.name ?? undefined,
    path: component_45stubYjueJ23IJZMeta?.path ?? "/small-boats/:pathMatch(.*)",
    meta: component_45stubYjueJ23IJZMeta || {},
    alias: component_45stubYjueJ23IJZMeta?.alias || [],
    redirect: component_45stubYjueJ23IJZMeta?.redirect,
    component: component_45stubYjueJ23IJZ
  },
  {
    name: component_45stubYjueJ23IJZMeta?.name ?? undefined,
    path: component_45stubYjueJ23IJZMeta?.path ?? "/small-fleet-management-training/:pathMatch(.*)",
    meta: component_45stubYjueJ23IJZMeta || {},
    alias: component_45stubYjueJ23IJZMeta?.alias || [],
    redirect: component_45stubYjueJ23IJZMeta?.redirect,
    component: component_45stubYjueJ23IJZ
  },
  {
    name: component_45stubYjueJ23IJZMeta?.name ?? undefined,
    path: component_45stubYjueJ23IJZMeta?.path ?? "/smi-o-nas-vecherniy-rostov-obuchenie/:pathMatch(.*)",
    meta: component_45stubYjueJ23IJZMeta || {},
    alias: component_45stubYjueJ23IJZMeta?.alias || [],
    redirect: component_45stubYjueJ23IJZMeta?.redirect,
    component: component_45stubYjueJ23IJZ
  },
  {
    name: component_45stubYjueJ23IJZMeta?.name ?? undefined,
    path: component_45stubYjueJ23IJZMeta?.path ?? "/smi-vodniy-transport-obuchenie-otzyvy/:pathMatch(.*)",
    meta: component_45stubYjueJ23IJZMeta || {},
    alias: component_45stubYjueJ23IJZMeta?.alias || [],
    redirect: component_45stubYjueJ23IJZMeta?.redirect,
    component: component_45stubYjueJ23IJZ
  },
  {
    name: component_45stubYjueJ23IJZMeta?.name ?? undefined,
    path: component_45stubYjueJ23IJZMeta?.path ?? "/smi-vodniy-transport-obuchenie-otzyvy-2/:pathMatch(.*)",
    meta: component_45stubYjueJ23IJZMeta || {},
    alias: component_45stubYjueJ23IJZMeta?.alias || [],
    redirect: component_45stubYjueJ23IJZMeta?.redirect,
    component: component_45stubYjueJ23IJZ
  },
  {
    name: component_45stubYjueJ23IJZMeta?.name ?? undefined,
    path: component_45stubYjueJ23IJZMeta?.path ?? "/specialist-po-skorostnym-dezhurnym-shlyupkam/:pathMatch(.*)",
    meta: component_45stubYjueJ23IJZMeta || {},
    alias: component_45stubYjueJ23IJZMeta?.alias || [],
    redirect: component_45stubYjueJ23IJZMeta?.redirect,
    component: component_45stubYjueJ23IJZ
  },
  {
    name: component_45stubYjueJ23IJZMeta?.name ?? undefined,
    path: component_45stubYjueJ23IJZMeta?.path ?? "/spisok-napravlenij/:pathMatch(.*)",
    meta: component_45stubYjueJ23IJZMeta || {},
    alias: component_45stubYjueJ23IJZMeta?.alias || [],
    redirect: component_45stubYjueJ23IJZMeta?.redirect,
    component: component_45stubYjueJ23IJZ
  },
  {
    name: component_45stubYjueJ23IJZMeta?.name ?? undefined,
    path: component_45stubYjueJ23IJZMeta?.path ?? "/starshij-pomoshhnik-kapitana-na-aframax/:pathMatch(.*)",
    meta: component_45stubYjueJ23IJZMeta || {},
    alias: component_45stubYjueJ23IJZMeta?.alias || [],
    redirect: component_45stubYjueJ23IJZMeta?.redirect,
    component: component_45stubYjueJ23IJZ
  },
  {
    name: component_45stubYjueJ23IJZMeta?.name ?? undefined,
    path: component_45stubYjueJ23IJZMeta?.path ?? "/starshij-pomoshhnik-kapitana-na-oil-bunker-vessel/:pathMatch(.*)",
    meta: component_45stubYjueJ23IJZMeta || {},
    alias: component_45stubYjueJ23IJZMeta?.alias || [],
    redirect: component_45stubYjueJ23IJZMeta?.redirect,
    component: component_45stubYjueJ23IJZ
  },
  {
    name: component_45stubYjueJ23IJZMeta?.name ?? undefined,
    path: component_45stubYjueJ23IJZMeta?.path ?? "/struktura-i-organy-upravlenija-obrazovatelnoj-organizaciej/:pathMatch(.*)",
    meta: component_45stubYjueJ23IJZMeta || {},
    alias: component_45stubYjueJ23IJZMeta?.alias || [],
    redirect: component_45stubYjueJ23IJZMeta?.redirect,
    component: component_45stubYjueJ23IJZ
  },
  {
    name: component_45stubYjueJ23IJZMeta?.name ?? undefined,
    path: component_45stubYjueJ23IJZMeta?.path ?? "/study-with-us-and-receive-a-tuition-tax-deduction/:pathMatch(.*)",
    meta: component_45stubYjueJ23IJZMeta || {},
    alias: component_45stubYjueJ23IJZMeta?.alias || [],
    redirect: component_45stubYjueJ23IJZMeta?.redirect,
    component: component_45stubYjueJ23IJZ
  },
  {
    name: component_45stubYjueJ23IJZMeta?.name ?? undefined,
    path: component_45stubYjueJ23IJZMeta?.path ?? "/sudovoditeli/:pathMatch(.*)",
    meta: component_45stubYjueJ23IJZMeta || {},
    alias: component_45stubYjueJ23IJZMeta?.alias || [],
    redirect: component_45stubYjueJ23IJZMeta?.redirect,
    component: component_45stubYjueJ23IJZ
  },
  {
    name: component_45stubYjueJ23IJZMeta?.name ?? undefined,
    path: component_45stubYjueJ23IJZMeta?.path ?? "/sudovye-mehaniki-i-jelektromehaniki/:pathMatch(.*)",
    meta: component_45stubYjueJ23IJZMeta || {},
    alias: component_45stubYjueJ23IJZMeta?.alias || [],
    redirect: component_45stubYjueJ23IJZMeta?.redirect,
    component: component_45stubYjueJ23IJZ
  },
  {
    name: component_45stubYjueJ23IJZMeta?.name ?? undefined,
    path: component_45stubYjueJ23IJZMeta?.path ?? "/tag/aframax/:pathMatch(.*)",
    meta: component_45stubYjueJ23IJZMeta || {},
    alias: component_45stubYjueJ23IJZMeta?.alias || [],
    redirect: component_45stubYjueJ23IJZMeta?.redirect,
    component: component_45stubYjueJ23IJZ
  },
  {
    name: component_45stubYjueJ23IJZMeta?.name ?? undefined,
    path: component_45stubYjueJ23IJZMeta?.path ?? "/tag/flagman-rostov/:pathMatch(.*)",
    meta: component_45stubYjueJ23IJZMeta || {},
    alias: component_45stubYjueJ23IJZMeta?.alias || [],
    redirect: component_45stubYjueJ23IJZMeta?.redirect,
    component: component_45stubYjueJ23IJZ
  },
  {
    name: component_45stubYjueJ23IJZMeta?.name ?? undefined,
    path: component_45stubYjueJ23IJZMeta?.path ?? "/tag/kursy-moryakov/:pathMatch(.*)",
    meta: component_45stubYjueJ23IJZMeta || {},
    alias: component_45stubYjueJ23IJZMeta?.alias || [],
    redirect: component_45stubYjueJ23IJZMeta?.redirect,
    component: component_45stubYjueJ23IJZ
  },
  {
    name: component_45stubYjueJ23IJZMeta?.name ?? undefined,
    path: component_45stubYjueJ23IJZMeta?.path ?? "/tag/kursy-nbzhs/:pathMatch(.*)",
    meta: component_45stubYjueJ23IJZMeta || {},
    alias: component_45stubYjueJ23IJZMeta?.alias || [],
    redirect: component_45stubYjueJ23IJZMeta?.redirect,
    component: component_45stubYjueJ23IJZ
  },
  {
    name: component_45stubYjueJ23IJZMeta?.name ?? undefined,
    path: component_45stubYjueJ23IJZMeta?.path ?? "/tag/kursy-tankernaya/:pathMatch(.*)",
    meta: component_45stubYjueJ23IJZMeta || {},
    alias: component_45stubYjueJ23IJZMeta?.alias || [],
    redirect: component_45stubYjueJ23IJZMeta?.redirect,
    component: component_45stubYjueJ23IJZ
  },
  {
    name: component_45stubYjueJ23IJZMeta?.name ?? undefined,
    path: component_45stubYjueJ23IJZMeta?.path ?? "/tag/obuchenie-moryakov-rostov/:pathMatch(.*)",
    meta: component_45stubYjueJ23IJZMeta || {},
    alias: component_45stubYjueJ23IJZMeta?.alias || [],
    redirect: component_45stubYjueJ23IJZMeta?.redirect,
    component: component_45stubYjueJ23IJZ
  },
  {
    name: component_45stubYjueJ23IJZMeta?.name ?? undefined,
    path: component_45stubYjueJ23IJZMeta?.path ?? "/tag/oplata-obucheniya/:pathMatch(.*)",
    meta: component_45stubYjueJ23IJZMeta || {},
    alias: component_45stubYjueJ23IJZMeta?.alias || [],
    redirect: component_45stubYjueJ23IJZMeta?.redirect,
    component: component_45stubYjueJ23IJZ
  },
  {
    name: component_45stubYjueJ23IJZMeta?.name ?? undefined,
    path: component_45stubYjueJ23IJZMeta?.path ?? "/tag/rassrochka/:pathMatch(.*)",
    meta: component_45stubYjueJ23IJZMeta || {},
    alias: component_45stubYjueJ23IJZMeta?.alias || [],
    redirect: component_45stubYjueJ23IJZMeta?.redirect,
    component: component_45stubYjueJ23IJZ
  },
  {
    name: component_45stubYjueJ23IJZMeta?.name ?? undefined,
    path: component_45stubYjueJ23IJZMeta?.path ?? "/tag/sertifikaty-rostov/:pathMatch(.*)",
    meta: component_45stubYjueJ23IJZMeta || {},
    alias: component_45stubYjueJ23IJZMeta?.alias || [],
    redirect: component_45stubYjueJ23IJZMeta?.redirect,
    component: component_45stubYjueJ23IJZ
  },
  {
    name: component_45stubYjueJ23IJZMeta?.name ?? undefined,
    path: component_45stubYjueJ23IJZMeta?.path ?? "/tag/skidka/:pathMatch(.*)",
    meta: component_45stubYjueJ23IJZMeta || {},
    alias: component_45stubYjueJ23IJZMeta?.alias || [],
    redirect: component_45stubYjueJ23IJZMeta?.redirect,
    component: component_45stubYjueJ23IJZ
  },
  {
    name: component_45stubYjueJ23IJZMeta?.name ?? undefined,
    path: component_45stubYjueJ23IJZMeta?.path ?? "/tag/tankernaya-podgotovka/:pathMatch(.*)",
    meta: component_45stubYjueJ23IJZMeta || {},
    alias: component_45stubYjueJ23IJZMeta?.alias || [],
    redirect: component_45stubYjueJ23IJZMeta?.redirect,
    component: component_45stubYjueJ23IJZ
  },
  {
    name: component_45stubYjueJ23IJZMeta?.name ?? undefined,
    path: component_45stubYjueJ23IJZMeta?.path ?? "/testing-and-additional-services/:pathMatch(.*)",
    meta: component_45stubYjueJ23IJZMeta || {},
    alias: component_45stubYjueJ23IJZMeta?.alias || [],
    redirect: component_45stubYjueJ23IJZMeta?.redirect,
    component: component_45stubYjueJ23IJZ
  },
  {
    name: component_45stubYjueJ23IJZMeta?.name ?? undefined,
    path: component_45stubYjueJ23IJZMeta?.path ?? "/testirovanie-i-dopolnitelnye-uslugi/:pathMatch(.*)",
    meta: component_45stubYjueJ23IJZMeta || {},
    alias: component_45stubYjueJ23IJZMeta?.alias || [],
    redirect: component_45stubYjueJ23IJZMeta?.redirect,
    component: component_45stubYjueJ23IJZ
  },
  {
    name: component_45stubYjueJ23IJZMeta?.name ?? undefined,
    path: component_45stubYjueJ23IJZMeta?.path ?? "/the-federal-agency-for-the-maritime-and-river-fleet-issued-a-certificate-of-compliance",
    meta: component_45stubYjueJ23IJZMeta || {},
    alias: component_45stubYjueJ23IJZMeta?.alias || [],
    redirect: component_45stubYjueJ23IJZMeta?.redirect,
    component: component_45stubYjueJ23IJZ
  },
  {
    name: component_45stubYjueJ23IJZMeta?.name ?? undefined,
    path: component_45stubYjueJ23IJZMeta?.path ?? "/the-flagship-confirmed-the-compliance-of-the-preparation-processes-with-the-iso-9001-2015-standards",
    meta: component_45stubYjueJ23IJZMeta || {},
    alias: component_45stubYjueJ23IJZMeta?.alias || [],
    redirect: component_45stubYjueJ23IJZMeta?.redirect,
    component: component_45stubYjueJ23IJZ
  },
  {
    name: component_45stubYjueJ23IJZMeta?.name ?? undefined,
    path: component_45stubYjueJ23IJZMeta?.path ?? "/the-flagship-has-passed-an-external-audit-of-the-quality-management-system",
    meta: component_45stubYjueJ23IJZMeta || {},
    alias: component_45stubYjueJ23IJZMeta?.alias || [],
    redirect: component_45stubYjueJ23IJZMeta?.redirect,
    component: component_45stubYjueJ23IJZ
  },
  {
    name: component_45stubYjueJ23IJZMeta?.name ?? undefined,
    path: component_45stubYjueJ23IJZMeta?.path ?? "/the-flagship-meets-the-requirements-of-iso-9001-2015/:pathMatch(.*)",
    meta: component_45stubYjueJ23IJZMeta || {},
    alias: component_45stubYjueJ23IJZMeta?.alias || [],
    redirect: component_45stubYjueJ23IJZMeta?.redirect,
    component: component_45stubYjueJ23IJZ
  },
  {
    name: component_45stubYjueJ23IJZMeta?.name ?? undefined,
    path: component_45stubYjueJ23IJZMeta?.path ?? "/the-government-is-ready-to-change-legislation-for-the-privatization-of-sovcomflot",
    meta: component_45stubYjueJ23IJZMeta || {},
    alias: component_45stubYjueJ23IJZMeta?.alias || [],
    redirect: component_45stubYjueJ23IJZMeta?.redirect,
    component: component_45stubYjueJ23IJZ
  },
  {
    name: component_45stubYjueJ23IJZMeta?.name ?? undefined,
    path: component_45stubYjueJ23IJZMeta?.path ?? "/the-norwegians-will-build-an-autonomous-cargo-ship/:pathMatch(.*)",
    meta: component_45stubYjueJ23IJZMeta || {},
    alias: component_45stubYjueJ23IJZMeta?.alias || [],
    redirect: component_45stubYjueJ23IJZMeta?.redirect,
    component: component_45stubYjueJ23IJZ
  },
  {
    name: component_45stubYjueJ23IJZMeta?.name ?? undefined,
    path: component_45stubYjueJ23IJZMeta?.path ?? "/the-queue-at-the-kerch-ferry-during-rush-hours-increased-to-900-cars",
    meta: component_45stubYjueJ23IJZMeta || {},
    alias: component_45stubYjueJ23IJZMeta?.alias || [],
    redirect: component_45stubYjueJ23IJZMeta?.redirect,
    component: component_45stubYjueJ23IJZ
  },
  {
    name: component_45stubYjueJ23IJZMeta?.name ?? undefined,
    path: component_45stubYjueJ23IJZMeta?.path ?? "/the-state-commission-accepted-the-rescue-tug-sb-121/:pathMatch(.*)",
    meta: component_45stubYjueJ23IJZMeta || {},
    alias: component_45stubYjueJ23IJZMeta?.alias || [],
    redirect: component_45stubYjueJ23IJZMeta?.redirect,
    component: component_45stubYjueJ23IJZ
  },
  {
    name: component_45stubYjueJ23IJZMeta?.name ?? undefined,
    path: component_45stubYjueJ23IJZMeta?.path ?? "/the-world-s-longest-trimaran-yacht-left-the-port-of-riga/:pathMatch(.*)",
    meta: component_45stubYjueJ23IJZMeta || {},
    alias: component_45stubYjueJ23IJZMeta?.alias || [],
    redirect: component_45stubYjueJ23IJZMeta?.redirect,
    component: component_45stubYjueJ23IJZ
  },
  {
    name: component_45stubYjueJ23IJZMeta?.name ?? undefined,
    path: component_45stubYjueJ23IJZMeta?.path ?? "/training-courses-for-sailor-and-minder-are-open/:pathMatch(.*)",
    meta: component_45stubYjueJ23IJZMeta || {},
    alias: component_45stubYjueJ23IJZMeta?.alias || [],
    redirect: component_45stubYjueJ23IJZMeta?.redirect,
    component: component_45stubYjueJ23IJZ
  },
  {
    name: component_45stubYjueJ23IJZMeta?.name ?? undefined,
    path: component_45stubYjueJ23IJZMeta?.path ?? "/training-for-the-right-to-operate-small-boats-and-jet-skis/:pathMatch(.*)",
    meta: component_45stubYjueJ23IJZMeta || {},
    alias: component_45stubYjueJ23IJZMeta?.alias || [],
    redirect: component_45stubYjueJ23IJZMeta?.redirect,
    component: component_45stubYjueJ23IJZ
  },
  {
    name: component_45stubYjueJ23IJZMeta?.name ?? undefined,
    path: component_45stubYjueJ23IJZMeta?.path ?? "/training-in-the-intricacies-of-the-profession-of-the-staff-of-the-survey-company",
    meta: component_45stubYjueJ23IJZMeta || {},
    alias: component_45stubYjueJ23IJZMeta?.alias || [],
    redirect: component_45stubYjueJ23IJZMeta?.redirect,
    component: component_45stubYjueJ23IJZ
  },
  {
    name: component_45stubYjueJ23IJZMeta?.name ?? undefined,
    path: component_45stubYjueJ23IJZMeta?.path ?? "/training-of-crew-members-for-certain-types-of-ships/:pathMatch(.*)",
    meta: component_45stubYjueJ23IJZMeta || {},
    alias: component_45stubYjueJ23IJZMeta?.alias || [],
    redirect: component_45stubYjueJ23IJZMeta?.redirect,
    component: component_45stubYjueJ23IJZ
  },
  {
    name: component_45stubYjueJ23IJZMeta?.name ?? undefined,
    path: component_45stubYjueJ23IJZMeta?.path ?? "/training-of-inland-waterway-specialists-and-coastal-specialists/:pathMatch(.*)",
    meta: component_45stubYjueJ23IJZMeta || {},
    alias: component_45stubYjueJ23IJZMeta?.alias || [],
    redirect: component_45stubYjueJ23IJZMeta?.redirect,
    component: component_45stubYjueJ23IJZ
  },
  {
    name: component_45stubYjueJ23IJZMeta?.name ?? undefined,
    path: component_45stubYjueJ23IJZMeta?.path ?? "/trainings-for-offshore-sector/:pathMatch(.*)",
    meta: component_45stubYjueJ23IJZMeta || {},
    alias: component_45stubYjueJ23IJZMeta?.alias || [],
    redirect: component_45stubYjueJ23IJZMeta?.redirect,
    component: component_45stubYjueJ23IJZ
  },
  {
    name: component_45stubYjueJ23IJZMeta?.name ?? undefined,
    path: component_45stubYjueJ23IJZMeta?.path ?? "/transport-safety/:pathMatch(.*)",
    meta: component_45stubYjueJ23IJZMeta || {},
    alias: component_45stubYjueJ23IJZMeta?.alias || [],
    redirect: component_45stubYjueJ23IJZMeta?.redirect,
    component: component_45stubYjueJ23IJZ
  },
  {
    name: component_45stubYjueJ23IJZMeta?.name ?? undefined,
    path: component_45stubYjueJ23IJZMeta?.path ?? "/transportnaja-bezopasnost/:pathMatch(.*)",
    meta: component_45stubYjueJ23IJZMeta || {},
    alias: component_45stubYjueJ23IJZMeta?.alias || [],
    redirect: component_45stubYjueJ23IJZMeta?.redirect,
    component: component_45stubYjueJ23IJZ
  },
  {
    name: component_45stubYjueJ23IJZMeta?.name ?? undefined,
    path: component_45stubYjueJ23IJZMeta?.path ?? "/trebuetsya-instruktor/:pathMatch(.*)",
    meta: component_45stubYjueJ23IJZMeta || {},
    alias: component_45stubYjueJ23IJZMeta?.alias || [],
    redirect: component_45stubYjueJ23IJZMeta?.redirect,
    component: component_45stubYjueJ23IJZ
  },
  {
    name: component_45stubYjueJ23IJZMeta?.name ?? undefined,
    path: component_45stubYjueJ23IJZMeta?.path ?? "/tvel-izgotovilo-pervuyu-partiyu-innovacionnogo-yadernogo-topliva-dlya-atomnogo-ledokola",
    meta: component_45stubYjueJ23IJZMeta || {},
    alias: component_45stubYjueJ23IJZMeta?.alias || [],
    redirect: component_45stubYjueJ23IJZMeta?.redirect,
    component: component_45stubYjueJ23IJZ
  },
  {
    name: component_45stubYjueJ23IJZMeta?.name ?? undefined,
    path: component_45stubYjueJ23IJZMeta?.path ?? "/tvel-manufactured-the-first-batch-of-innovative-nuclear-fuel-for-a-nuclear-icebreaker",
    meta: component_45stubYjueJ23IJZMeta || {},
    alias: component_45stubYjueJ23IJZMeta?.alias || [],
    redirect: component_45stubYjueJ23IJZMeta?.redirect,
    component: component_45stubYjueJ23IJZ
  },
  {
    name: component_45stubYjueJ23IJZMeta?.name ?? undefined,
    path: component_45stubYjueJ23IJZMeta?.path ?? "/utc-flagman-poluchil-svidetelstvo-o-sootvetstvii-trenazhernogo-centra",
    meta: component_45stubYjueJ23IJZMeta || {},
    alias: component_45stubYjueJ23IJZMeta?.alias || [],
    redirect: component_45stubYjueJ23IJZMeta?.redirect,
    component: component_45stubYjueJ23IJZ
  },
  {
    name: component_45stubYjueJ23IJZMeta?.name ?? undefined,
    path: component_45stubYjueJ23IJZMeta?.path ?? "/utc-flagman-proshel-dobrovolnuju-proceduru-sertifikacii-sistemy-menedzhmenta-kachestva-fau-rossii-skii-morskoi-registr-sudohodstva",
    meta: component_45stubYjueJ23IJZMeta || {},
    alias: component_45stubYjueJ23IJZMeta?.alias || [],
    redirect: component_45stubYjueJ23IJZMeta?.redirect,
    component: component_45stubYjueJ23IJZ
  },
  {
    name: component_45stubYjueJ23IJZMeta?.name ?? undefined,
    path: component_45stubYjueJ23IJZMeta?.path ?? "/utc-flagman-received-a-certificate-of-conformity-for-the-training-center",
    meta: component_45stubYjueJ23IJZMeta || {},
    alias: component_45stubYjueJ23IJZMeta?.alias || [],
    redirect: component_45stubYjueJ23IJZMeta?.redirect,
    component: component_45stubYjueJ23IJZ
  },
  {
    name: component_45stubYjueJ23IJZMeta?.name ?? undefined,
    path: component_45stubYjueJ23IJZMeta?.path ?? "/vacancies/:pathMatch(.*)",
    meta: component_45stubYjueJ23IJZMeta || {},
    alias: component_45stubYjueJ23IJZMeta?.alias || [],
    redirect: component_45stubYjueJ23IJZMeta?.redirect,
    component: component_45stubYjueJ23IJZ
  },
  {
    name: component_45stubYjueJ23IJZMeta?.name ?? undefined,
    path: component_45stubYjueJ23IJZMeta?.path ?? "/vakansii/:pathMatch(.*)",
    meta: component_45stubYjueJ23IJZMeta || {},
    alias: component_45stubYjueJ23IJZMeta?.alias || [],
    redirect: component_45stubYjueJ23IJZMeta?.redirect,
    component: component_45stubYjueJ23IJZ
  },
  {
    name: component_45stubYjueJ23IJZMeta?.name ?? undefined,
    path: component_45stubYjueJ23IJZMeta?.path ?? "/vakansii-2/:pathMatch(.*)",
    meta: component_45stubYjueJ23IJZMeta || {},
    alias: component_45stubYjueJ23IJZMeta?.alias || [],
    redirect: component_45stubYjueJ23IJZMeta?.redirect,
    component: component_45stubYjueJ23IJZ
  },
  {
    name: component_45stubYjueJ23IJZMeta?.name ?? undefined,
    path: component_45stubYjueJ23IJZMeta?.path ?? "/vakantnye-mesta-dlja-priema-perevoda/:pathMatch(.*)",
    meta: component_45stubYjueJ23IJZMeta || {},
    alias: component_45stubYjueJ23IJZMeta?.alias || [],
    redirect: component_45stubYjueJ23IJZMeta?.redirect,
    component: component_45stubYjueJ23IJZ
  },
  {
    name: component_45stubYjueJ23IJZMeta?.name ?? undefined,
    path: component_45stubYjueJ23IJZMeta?.path ?? "/vybor-kursa/:pathMatch(.*)",
    meta: component_45stubYjueJ23IJZMeta || {},
    alias: component_45stubYjueJ23IJZMeta?.alias || [],
    redirect: component_45stubYjueJ23IJZMeta?.redirect,
    component: component_45stubYjueJ23IJZ
  },
  {
    name: component_45stubYjueJ23IJZMeta?.name ?? undefined,
    path: component_45stubYjueJ23IJZMeta?.path ?? "/we-announce-the-recruitment-for-the-courses-qualified-sailor-watch-minder",
    meta: component_45stubYjueJ23IJZMeta || {},
    alias: component_45stubYjueJ23IJZMeta?.alias || [],
    redirect: component_45stubYjueJ23IJZMeta?.redirect,
    component: component_45stubYjueJ23IJZ
  },
  {
    name: component_45stubYjueJ23IJZMeta?.name ?? undefined,
    path: component_45stubYjueJ23IJZMeta?.path ?? "/what-you-need-to-take-to-get-a-diploma-for-work-on-vvp/:pathMatch(.*)",
    meta: component_45stubYjueJ23IJZMeta || {},
    alias: component_45stubYjueJ23IJZMeta?.alias || [],
    redirect: component_45stubYjueJ23IJZMeta?.redirect,
    component: component_45stubYjueJ23IJZ
  },
  {
    name: component_45stubYjueJ23IJZMeta?.name ?? undefined,
    path: component_45stubYjueJ23IJZMeta?.path ?? "/wp-content/uploads/2023/08/Karta-partnera-Flagman.pdf/:pathMatch(.*)",
    meta: component_45stubYjueJ23IJZMeta || {},
    alias: component_45stubYjueJ23IJZMeta?.alias || [],
    redirect: component_45stubYjueJ23IJZMeta?.redirect,
    component: component_45stubYjueJ23IJZ
  },
  {
    name: component_45stubYjueJ23IJZMeta?.name ?? undefined,
    path: component_45stubYjueJ23IJZMeta?.path ?? "/zapis-na-kursy/:pathMatch(.*)",
    meta: component_45stubYjueJ23IJZMeta || {},
    alias: component_45stubYjueJ23IJZMeta?.alias || [],
    redirect: component_45stubYjueJ23IJZMeta?.redirect,
    component: component_45stubYjueJ23IJZ
  },
  {
    name: component_45stubYjueJ23IJZMeta?.name ?? undefined,
    path: component_45stubYjueJ23IJZMeta?.path ?? "/zarabotnaja-plata-morjaka/:pathMatch(.*)",
    meta: component_45stubYjueJ23IJZMeta || {},
    alias: component_45stubYjueJ23IJZMeta?.alias || [],
    redirect: component_45stubYjueJ23IJZMeta?.redirect,
    component: component_45stubYjueJ23IJZ
  },
  {
    name: component_45stubYjueJ23IJZMeta?.name ?? undefined,
    path: component_45stubYjueJ23IJZMeta?.path ?? "/событие/:pathMatch(.*)",
    meta: component_45stubYjueJ23IJZMeta || {},
    alias: component_45stubYjueJ23IJZMeta?.alias || [],
    redirect: component_45stubYjueJ23IJZMeta?.redirect,
    component: component_45stubYjueJ23IJZ
  },
  {
    name: component_45stubYjueJ23IJZMeta?.name ?? undefined,
    path: component_45stubYjueJ23IJZMeta?.path ?? "/product/:pathMatch(.*)",
    meta: component_45stubYjueJ23IJZMeta || {},
    alias: component_45stubYjueJ23IJZMeta?.alias || [],
    redirect: component_45stubYjueJ23IJZMeta?.redirect,
    component: component_45stubYjueJ23IJZ
  },
  {
    name: component_45stubYjueJ23IJZMeta?.name ?? undefined,
    path: component_45stubYjueJ23IJZMeta?.path ?? "/product/advanced-h2s-refresh/:pathMatch(.*)",
    meta: component_45stubYjueJ23IJZMeta || {},
    alias: component_45stubYjueJ23IJZMeta?.alias || [],
    redirect: component_45stubYjueJ23IJZMeta?.redirect,
    component: component_45stubYjueJ23IJZ
  },
  {
    name: component_45stubYjueJ23IJZMeta?.name ?? undefined,
    path: component_45stubYjueJ23IJZMeta?.path ?? "/product/advanced-training-for-chemical-tanker-cargo-operations-section-a-v-1-1-table-av-1-1-3-mk-stcw-78-as-amended",
    meta: component_45stubYjueJ23IJZMeta || {},
    alias: component_45stubYjueJ23IJZMeta?.alias || [],
    redirect: component_45stubYjueJ23IJZMeta?.redirect,
    component: component_45stubYjueJ23IJZ
  },
  {
    name: component_45stubYjueJ23IJZMeta?.name ?? undefined,
    path: component_45stubYjueJ23IJZMeta?.path ?? "/product/advanced-training-for-lng-carrier-cargo-operations-five-year-refresher-training-section-av-1-2-table-a-vl1-2-2-mk-stcw-78-as-amended",
    meta: component_45stubYjueJ23IJZMeta || {},
    alias: component_45stubYjueJ23IJZMeta?.alias || [],
    redirect: component_45stubYjueJ23IJZMeta?.redirect,
    component: component_45stubYjueJ23IJZ
  },
  {
    name: component_45stubYjueJ23IJZMeta?.name ?? undefined,
    path: component_45stubYjueJ23IJZMeta?.path ?? "/product/advanced-training-for-oil-tanker-cargo-operations-five-year-refresher-training-section-av-1-1-table-av-1-1-2-mk-stcw-78-as-amended",
    meta: component_45stubYjueJ23IJZMeta || {},
    alias: component_45stubYjueJ23IJZMeta?.alias || [],
    redirect: component_45stubYjueJ23IJZMeta?.redirect,
    component: component_45stubYjueJ23IJZ
  },
  {
    name: component_45stubYjueJ23IJZMeta?.name ?? undefined,
    path: component_45stubYjueJ23IJZMeta?.path ?? "/product/advanced-training-for-oil-tanker-cargo-operations-section-av-1-1-table-av-1-1-2-mk-stcw-78-as-amended",
    meta: component_45stubYjueJ23IJZMeta || {},
    alias: component_45stubYjueJ23IJZMeta?.alias || [],
    redirect: component_45stubYjueJ23IJZMeta?.redirect,
    component: component_45stubYjueJ23IJZ
  },
  {
    name: component_45stubYjueJ23IJZMeta?.name ?? undefined,
    path: component_45stubYjueJ23IJZMeta?.path ?? "/product/advanced-training-for-ships-subject-to-the-igf-code/:pathMatch(.*)",
    meta: component_45stubYjueJ23IJZMeta || {},
    alias: component_45stubYjueJ23IJZMeta?.alias || [],
    redirect: component_45stubYjueJ23IJZMeta?.redirect,
    component: component_45stubYjueJ23IJZ
  },
  {
    name: component_45stubYjueJ23IJZMeta?.name ?? undefined,
    path: component_45stubYjueJ23IJZMeta?.path ?? "/product/advanced-training-of-employees-appointed-as-persons-responsible-for-ensuring-transport-security-at-a-transport-infrastructure-facility-and-or-a-vehicle-and-personnel-of-specialized-organizations-fz-21",
    meta: component_45stubYjueJ23IJZMeta || {},
    alias: component_45stubYjueJ23IJZMeta?.alias || [],
    redirect: component_45stubYjueJ23IJZMeta?.redirect,
    component: component_45stubYjueJ23IJZ
  },
  {
    name: component_45stubYjueJ23IJZMeta?.name ?? undefined,
    path: component_45stubYjueJ23IJZMeta?.path ?? "/product/advanced-training-of-persons-responsible-for-the-implementation-of-loading-and-unloading-activities-in-relation-to-dangerous-goods",
    meta: component_45stubYjueJ23IJZMeta || {},
    alias: component_45stubYjueJ23IJZMeta?.alias || [],
    redirect: component_45stubYjueJ23IJZMeta?.redirect,
    component: component_45stubYjueJ23IJZ
  },
  {
    name: component_45stubYjueJ23IJZMeta?.name ?? undefined,
    path: component_45stubYjueJ23IJZMeta?.path ?? "/product/advanced-training-program-for-employees-appointed-as-persons-responsible-for-ensuring-transport-security-in-a-transport-infrastructure-entity-federal-law-16-dated-09-02-2007-on-transport-security",
    meta: component_45stubYjueJ23IJZMeta || {},
    alias: component_45stubYjueJ23IJZMeta?.alias || [],
    redirect: component_45stubYjueJ23IJZMeta?.redirect,
    component: component_45stubYjueJ23IJZ
  },
  {
    name: component_45stubYjueJ23IJZMeta?.name ?? undefined,
    path: component_45stubYjueJ23IJZMeta?.path ?? "/product/basic-safety-training-section-a-vi-1-tables-a-vi-1-1-a-vi-1-2-a-vi-1-3-a-vi-1-4-of-the-stcw-code-as-amended",
    meta: component_45stubYjueJ23IJZMeta || {},
    alias: component_45stubYjueJ23IJZMeta?.alias || [],
    redirect: component_45stubYjueJ23IJZMeta?.redirect,
    component: component_45stubYjueJ23IJZ
  },
  {
    name: component_45stubYjueJ23IJZMeta?.name ?? undefined,
    path: component_45stubYjueJ23IJZMeta?.path ?? "/product/bazovaja-podgotovka-po-kursu-h2s/:pathMatch(.*)",
    meta: component_45stubYjueJ23IJZMeta || {},
    alias: component_45stubYjueJ23IJZMeta?.alias || [],
    redirect: component_45stubYjueJ23IJZMeta?.redirect,
    component: component_45stubYjueJ23IJZ
  },
  {
    name: component_45stubYjueJ23IJZMeta?.name ?? undefined,
    path: component_45stubYjueJ23IJZMeta?.path ?? "/product/bezopasnaja-jekspluatacija-sudohodnyh-i-portovyh-gts-otvetstvennye-lica-kompanij",
    meta: component_45stubYjueJ23IJZMeta || {},
    alias: component_45stubYjueJ23IJZMeta?.alias || [],
    redirect: component_45stubYjueJ23IJZMeta?.redirect,
    component: component_45stubYjueJ23IJZ
  },
  {
    name: component_45stubYjueJ23IJZMeta?.name ?? undefined,
    path: component_45stubYjueJ23IJZMeta?.path ?? "/product/bezopasnyj-vhod-v-zakrytye-pomeshhenija-na-sudah-rezoljucija-imo-a-1050-27",
    meta: component_45stubYjueJ23IJZMeta || {},
    alias: component_45stubYjueJ23IJZMeta?.alias || [],
    redirect: component_45stubYjueJ23IJZMeta?.redirect,
    component: component_45stubYjueJ23IJZ
  },
  {
    name: component_45stubYjueJ23IJZMeta?.name ?? undefined,
    path: component_45stubYjueJ23IJZMeta?.path ?? "/product/bridge-resource-management-brm-section-a-ii-1-a-ii-2-a-viii-1-b-viii-2-tables-a-ii-1-a-ii-2-mk-stcw-as-amended-imo-model-course-1-22",
    meta: component_45stubYjueJ23IJZMeta || {},
    alias: component_45stubYjueJ23IJZMeta?.alias || [],
    redirect: component_45stubYjueJ23IJZMeta?.redirect,
    component: component_45stubYjueJ23IJZ
  },
  {
    name: component_45stubYjueJ23IJZMeta?.name ?? undefined,
    path: component_45stubYjueJ23IJZMeta?.path ?? "/product/ces-competence-evaluation-system/:pathMatch(.*)",
    meta: component_45stubYjueJ23IJZMeta || {},
    alias: component_45stubYjueJ23IJZMeta?.alias || [],
    redirect: component_45stubYjueJ23IJZMeta?.redirect,
    component: component_45stubYjueJ23IJZ
  },
  {
    name: component_45stubYjueJ23IJZMeta?.name ?? undefined,
    path: component_45stubYjueJ23IJZMeta?.path ?? "/product/crude-oil-tank-washing-and-inert-gas-system-section-av-1-tables-av-1-1-2-mk-stcw-78-as-amended",
    meta: component_45stubYjueJ23IJZMeta || {},
    alias: component_45stubYjueJ23IJZMeta?.alias || [],
    redirect: component_45stubYjueJ23IJZMeta?.redirect,
    component: component_45stubYjueJ23IJZ
  },
  {
    name: component_45stubYjueJ23IJZMeta?.name ?? undefined,
    path: component_45stubYjueJ23IJZMeta?.path ?? "/product/cybersecurity-in-the-maritime-industry/:pathMatch(.*)",
    meta: component_45stubYjueJ23IJZMeta || {},
    alias: component_45stubYjueJ23IJZMeta?.alias || [],
    redirect: component_45stubYjueJ23IJZMeta?.redirect,
    component: component_45stubYjueJ23IJZ
  },
  {
    name: component_45stubYjueJ23IJZMeta?.name ?? undefined,
    path: component_45stubYjueJ23IJZMeta?.path ?? "/product/disorder-management-training-for-operational-level/:pathMatch(.*)",
    meta: component_45stubYjueJ23IJZMeta || {},
    alias: component_45stubYjueJ23IJZMeta?.alias || [],
    redirect: component_45stubYjueJ23IJZMeta?.redirect,
    component: component_45stubYjueJ23IJZ
  },
  {
    name: component_45stubYjueJ23IJZMeta?.name ?? undefined,
    path: component_45stubYjueJ23IJZMeta?.path ?? "/product/disorder-management-training-for-the-management-level/:pathMatch(.*)",
    meta: component_45stubYjueJ23IJZMeta || {},
    alias: component_45stubYjueJ23IJZMeta?.alias || [],
    redirect: component_45stubYjueJ23IJZMeta?.redirect,
    component: component_45stubYjueJ23IJZ
  },
  {
    name: component_45stubYjueJ23IJZMeta?.name ?? undefined,
    path: component_45stubYjueJ23IJZMeta?.path ?? "/product/disorder-management-training-for-the-sub-level/:pathMatch(.*)",
    meta: component_45stubYjueJ23IJZMeta || {},
    alias: component_45stubYjueJ23IJZMeta?.alias || [],
    redirect: component_45stubYjueJ23IJZMeta?.redirect,
    component: component_45stubYjueJ23IJZ
  },
  {
    name: component_45stubYjueJ23IJZMeta?.name ?? undefined,
    path: component_45stubYjueJ23IJZMeta?.path ?? "/product/engine-room-resource-management-and-teamwork-rules-iii-1-iii-2-iii-3-sections-a-iii-1-a-iii-2-a-iii-3-mk-stcw-78-as-amended",
    meta: component_45stubYjueJ23IJZMeta || {},
    alias: component_45stubYjueJ23IJZMeta?.alias || [],
    redirect: component_45stubYjueJ23IJZMeta?.redirect,
    component: component_45stubYjueJ23IJZ
  },
  {
    name: component_45stubYjueJ23IJZMeta?.name ?? undefined,
    path: component_45stubYjueJ23IJZMeta?.path ?? "/product/engine-room-simulator-including-engine-room-resource-management-leadership-and-personnel-management-skills-engine-room-simulator-sections-a-iii-1-a-iii-2-and-a-iii-6-b-i-12-mk-stdnv-78-s-amendments-mo",
    meta: component_45stubYjueJ23IJZMeta || {},
    alias: component_45stubYjueJ23IJZMeta?.alias || [],
    redirect: component_45stubYjueJ23IJZMeta?.redirect,
    component: component_45stubYjueJ23IJZ
  },
  {
    name: component_45stubYjueJ23IJZMeta?.name ?? undefined,
    path: component_45stubYjueJ23IJZMeta?.path ?? "/product/expanded-training-for-gas-tanker-cargo-operations-section-av-1-2-table-a-vl1-2-2-mk-stcw-78-as-amended",
    meta: component_45stubYjueJ23IJZMeta || {},
    alias: component_45stubYjueJ23IJZMeta?.alias || [],
    redirect: component_45stubYjueJ23IJZMeta?.redirect,
    component: component_45stubYjueJ23IJZ
  },
  {
    name: component_45stubYjueJ23IJZMeta?.name ?? undefined,
    path: component_45stubYjueJ23IJZMeta?.path ?? "/product/fire-fighting-training-of-crew-members-of-inland-navigation-vessels",
    meta: component_45stubYjueJ23IJZMeta || {},
    alias: component_45stubYjueJ23IJZMeta?.alias || [],
    redirect: component_45stubYjueJ23IJZMeta?.redirect,
    component: component_45stubYjueJ23IJZ
  },
  {
    name: component_45stubYjueJ23IJZMeta?.name ?? undefined,
    path: component_45stubYjueJ23IJZMeta?.path ?? "/product/fire-fighting-training-of-crew-members-of-inland-navigation-vessels-carrying-out-the-transportation-of-explosive-and-fire-hazardous-cargo-rank-and-file",
    meta: component_45stubYjueJ23IJZMeta || {},
    alias: component_45stubYjueJ23IJZMeta?.alias || [],
    redirect: component_45stubYjueJ23IJZMeta?.redirect,
    component: component_45stubYjueJ23IJZ
  },
  {
    name: component_45stubYjueJ23IJZMeta?.name ?? undefined,
    path: component_45stubYjueJ23IJZMeta?.path ?? "/product/fire-fighting-training-of-crew-members-of-inland-navigation-vessels-transporting-explosive-and-fire-hazardous-cargo-command-personnel",
    meta: component_45stubYjueJ23IJZMeta || {},
    alias: component_45stubYjueJ23IJZMeta?.alias || [],
    redirect: component_45stubYjueJ23IJZMeta?.redirect,
    component: component_45stubYjueJ23IJZ
  },
  {
    name: component_45stubYjueJ23IJZMeta?.name ?? undefined,
    path: component_45stubYjueJ23IJZMeta?.path ?? "/product/first-aid-training-section-a-vl-4-table-a-vl-4-1-mk-stcw-78-as-amended",
    meta: component_45stubYjueJ23IJZMeta || {},
    alias: component_45stubYjueJ23IJZMeta?.alias || [],
    redirect: component_45stubYjueJ23IJZMeta?.redirect,
    component: component_45stubYjueJ23IJZ
  },
  {
    name: component_45stubYjueJ23IJZMeta?.name ?? undefined,
    path: component_45stubYjueJ23IJZMeta?.path ?? "/product/food-hygiene/:pathMatch(.*)",
    meta: component_45stubYjueJ23IJZMeta || {},
    alias: component_45stubYjueJ23IJZMeta?.alias || [],
    redirect: component_45stubYjueJ23IJZMeta?.redirect,
    component: component_45stubYjueJ23IJZ
  },
  {
    name: component_45stubYjueJ23IJZMeta?.name ?? undefined,
    path: component_45stubYjueJ23IJZMeta?.path ?? "/product/fundamentals-of-safety-when-working-with-rigging-and-slinging",
    meta: component_45stubYjueJ23IJZMeta || {},
    alias: component_45stubYjueJ23IJZMeta?.alias || [],
    redirect: component_45stubYjueJ23IJZMeta?.redirect,
    component: component_45stubYjueJ23IJZ
  },
  {
    name: component_45stubYjueJ23IJZMeta?.name ?? undefined,
    path: component_45stubYjueJ23IJZMeta?.path ?? "/product/gift-certificate-for-training-jet-ski/:pathMatch(.*)",
    meta: component_45stubYjueJ23IJZMeta || {},
    alias: component_45stubYjueJ23IJZMeta?.alias || [],
    redirect: component_45stubYjueJ23IJZMeta?.redirect,
    component: component_45stubYjueJ23IJZ
  },
  {
    name: component_45stubYjueJ23IJZMeta?.name ?? undefined,
    path: component_45stubYjueJ23IJZMeta?.path ?? "/product/gift-certificate-for-training-small-boat-and-jet-ski/:pathMatch(.*)",
    meta: component_45stubYjueJ23IJZMeta || {},
    alias: component_45stubYjueJ23IJZMeta?.alias || [],
    redirect: component_45stubYjueJ23IJZMeta?.redirect,
    component: component_45stubYjueJ23IJZ
  },
  {
    name: component_45stubYjueJ23IJZMeta?.name ?? undefined,
    path: component_45stubYjueJ23IJZMeta?.path ?? "/product/gigiena-pitanija/:pathMatch(.*)",
    meta: component_45stubYjueJ23IJZMeta || {},
    alias: component_45stubYjueJ23IJZMeta?.alias || [],
    redirect: component_45stubYjueJ23IJZMeta?.redirect,
    component: component_45stubYjueJ23IJZ
  },
  {
    name: component_45stubYjueJ23IJZMeta?.name ?? undefined,
    path: component_45stubYjueJ23IJZMeta?.path ?? "/product/high-voltage-training-sec-a-iii-1-a-iii-6-a-viii-1-b-iii-2-stcw-78-with-amendements",
    meta: component_45stubYjueJ23IJZMeta || {},
    alias: component_45stubYjueJ23IJZMeta?.alias || [],
    redirect: component_45stubYjueJ23IJZMeta?.redirect,
    component: component_45stubYjueJ23IJZ
  },
  {
    name: component_45stubYjueJ23IJZMeta?.name ?? undefined,
    path: component_45stubYjueJ23IJZMeta?.path ?? "/product/initial-safety-training-five-year-refresher-training-section-a-vi-1-tables-a-vi-1-1-a-vi-1-2-a-vi-1-3-a-vi-1-4-of-the-code-stcw-as-amended",
    meta: component_45stubYjueJ23IJZMeta || {},
    alias: component_45stubYjueJ23IJZMeta?.alias || [],
    redirect: component_45stubYjueJ23IJZMeta?.redirect,
    component: component_45stubYjueJ23IJZ
  },
  {
    name: component_45stubYjueJ23IJZMeta?.name ?? undefined,
    path: component_45stubYjueJ23IJZMeta?.path ?? "/product/initial-training-for-work-on-gas-tankers-for-ratings-and-officers-section-a-v-1-2-table-a-v11-2-1mk-stcw-78-as-amended",
    meta: component_45stubYjueJ23IJZMeta || {},
    alias: component_45stubYjueJ23IJZMeta?.alias || [],
    redirect: component_45stubYjueJ23IJZMeta?.redirect,
    component: component_45stubYjueJ23IJZ
  },
  {
    name: component_45stubYjueJ23IJZMeta?.name ?? undefined,
    path: component_45stubYjueJ23IJZMeta?.path ?? "/product/initial-training-for-work-on-oil-tankers-and-chemical-tankers-five-year-retraining-for-officers-and-ratings-section-a-v-1-1-table-av-1-1-1-mk-stcw-78-as-amended",
    meta: component_45stubYjueJ23IJZMeta || {},
    alias: component_45stubYjueJ23IJZMeta?.alias || [],
    redirect: component_45stubYjueJ23IJZMeta?.redirect,
    component: component_45stubYjueJ23IJZ
  },
  {
    name: component_45stubYjueJ23IJZMeta?.name ?? undefined,
    path: component_45stubYjueJ23IJZMeta?.path ?? "/product/initial-training-for-work-on-oil-tankers-and-chemical-tankers-for-ratings-and-command-personnel-section-a-v-1-1-table-av-1-1-1-mk-stcw-78-as-amended",
    meta: component_45stubYjueJ23IJZMeta || {},
    alias: component_45stubYjueJ23IJZMeta?.alias || [],
    redirect: component_45stubYjueJ23IJZMeta?.redirect,
    component: component_45stubYjueJ23IJZ
  },
  {
    name: component_45stubYjueJ23IJZMeta?.name ?? undefined,
    path: component_45stubYjueJ23IJZMeta?.path ?? "/product/inspection-of-lifting-equipment-loler-1998/:pathMatch(.*)",
    meta: component_45stubYjueJ23IJZMeta || {},
    alias: component_45stubYjueJ23IJZMeta?.alias || [],
    redirect: component_45stubYjueJ23IJZMeta?.redirect,
    component: component_45stubYjueJ23IJZ
  },
  {
    name: component_45stubYjueJ23IJZMeta?.name ?? undefined,
    path: component_45stubYjueJ23IJZMeta?.path ?? "/product/inspekcija-podemnogo-oborudovanija/:pathMatch(.*)",
    meta: component_45stubYjueJ23IJZMeta || {},
    alias: component_45stubYjueJ23IJZMeta?.alias || [],
    redirect: component_45stubYjueJ23IJZMeta?.redirect,
    component: component_45stubYjueJ23IJZ
  },
  {
    name: component_45stubYjueJ23IJZMeta?.name ?? undefined,
    path: component_45stubYjueJ23IJZMeta?.path ?? "/product/inspektor-po-bezopasnosti-stroitelnyh-lesov-scaffolding-inspection-course",
    meta: component_45stubYjueJ23IJZMeta || {},
    alias: component_45stubYjueJ23IJZMeta?.alias || [],
    redirect: component_45stubYjueJ23IJZMeta?.redirect,
    component: component_45stubYjueJ23IJZ
  },
  {
    name: component_45stubYjueJ23IJZMeta?.name ?? undefined,
    path: component_45stubYjueJ23IJZMeta?.path ?? "/product/ispolzovanie-oborudovanija-ais-use-of-ais-mk-pdnv-78-s-popravkami",
    meta: component_45stubYjueJ23IJZMeta || {},
    alias: component_45stubYjueJ23IJZMeta?.alias || [],
    redirect: component_45stubYjueJ23IJZMeta?.redirect,
    component: component_45stubYjueJ23IJZ
  },
  {
    name: component_45stubYjueJ23IJZMeta?.name ?? undefined,
    path: component_45stubYjueJ23IJZMeta?.path ?? "/product/ispolzovanie-resursov-hodovogo-navigacionnogo-mostika-vkljuchaja-rukovodstvo-i-umenie-raboty-v-komande",
    meta: component_45stubYjueJ23IJZMeta || {},
    alias: component_45stubYjueJ23IJZMeta?.alias || [],
    redirect: component_45stubYjueJ23IJZMeta?.redirect,
    component: component_45stubYjueJ23IJZ
  },
  {
    name: component_45stubYjueJ23IJZMeta?.name ?? undefined,
    path: component_45stubYjueJ23IJZMeta?.path ?? "/product/jekspluatacija-gruzovyh-sistem-oborudovannyh-nasosami-s-gidroprivodom-framo-razdel-a-v-1-1-tablicy-a-v-1-1-2-a-v-1-1-3-kodeksa-pdnv-s-popravkami",
    meta: component_45stubYjueJ23IJZMeta || {},
    alias: component_45stubYjueJ23IJZMeta?.alias || [],
    redirect: component_45stubYjueJ23IJZMeta?.redirect,
    component: component_45stubYjueJ23IJZ
  },
  {
    name: component_45stubYjueJ23IJZMeta?.name ?? undefined,
    path: component_45stubYjueJ23IJZMeta?.path ?? "/product/jekspluatacija-gruzovyh-sistem-oborudovannyh-nasosami-s-gidroprivodom-framo-razdel-av-1-1-tablicy-av-1-1-2-av-1-1-3-kodeksa-pdnv-s-popravkami",
    meta: component_45stubYjueJ23IJZMeta || {},
    alias: component_45stubYjueJ23IJZMeta?.alias || [],
    redirect: component_45stubYjueJ23IJZMeta?.redirect,
    component: component_45stubYjueJ23IJZ
  },
  {
    name: component_45stubYjueJ23IJZMeta?.name ?? undefined,
    path: component_45stubYjueJ23IJZMeta?.path ?? "/product/jekspluatacija-jelektroustanovok-i-jelektricheskih-sistem-upravlenija-na-sudne",
    meta: component_45stubYjueJ23IJZMeta || {},
    alias: component_45stubYjueJ23IJZMeta?.alias || [],
    redirect: component_45stubYjueJ23IJZMeta?.redirect,
    component: component_45stubYjueJ23IJZ
  },
  {
    name: component_45stubYjueJ23IJZMeta?.name ?? undefined,
    path: component_45stubYjueJ23IJZMeta?.path ?? "/product/jekspluatacija-kontejnerov-osnovnye-trebovanija/:pathMatch(.*)",
    meta: component_45stubYjueJ23IJZMeta || {},
    alias: component_45stubYjueJ23IJZMeta?.alias || [],
    redirect: component_45stubYjueJ23IJZMeta?.redirect,
    component: component_45stubYjueJ23IJZ
  },
  {
    name: component_45stubYjueJ23IJZMeta?.name ?? undefined,
    path: component_45stubYjueJ23IJZMeta?.path ?? "/product/kiberbezopasnost-na-sudah/:pathMatch(.*)",
    meta: component_45stubYjueJ23IJZMeta || {},
    alias: component_45stubYjueJ23IJZMeta?.alias || [],
    redirect: component_45stubYjueJ23IJZMeta?.redirect,
    component: component_45stubYjueJ23IJZ
  },
  {
    name: component_45stubYjueJ23IJZMeta?.name ?? undefined,
    path: component_45stubYjueJ23IJZMeta?.path ?? "/product/kiberbezopasnost-v-morskoj-otrasli/:pathMatch(.*)",
    meta: component_45stubYjueJ23IJZMeta || {},
    alias: component_45stubYjueJ23IJZMeta?.alias || [],
    redirect: component_45stubYjueJ23IJZMeta?.redirect,
    component: component_45stubYjueJ23IJZ
  },
  {
    name: component_45stubYjueJ23IJZMeta?.name ?? undefined,
    path: component_45stubYjueJ23IJZMeta?.path ?? "/product/knowledge-assessment-software-package-pkoz-mechanic/:pathMatch(.*)",
    meta: component_45stubYjueJ23IJZMeta || {},
    alias: component_45stubYjueJ23IJZMeta?.alias || [],
    redirect: component_45stubYjueJ23IJZMeta?.redirect,
    component: component_45stubYjueJ23IJZ
  },
  {
    name: component_45stubYjueJ23IJZMeta?.name ?? undefined,
    path: component_45stubYjueJ23IJZMeta?.path ?? "/product/kompleks-ocenki-znanij-koz-mppss/:pathMatch(.*)",
    meta: component_45stubYjueJ23IJZMeta || {},
    alias: component_45stubYjueJ23IJZMeta?.alias || [],
    redirect: component_45stubYjueJ23IJZMeta?.redirect,
    component: component_45stubYjueJ23IJZ
  },
  {
    name: component_45stubYjueJ23IJZMeta?.name ?? undefined,
    path: component_45stubYjueJ23IJZMeta?.path ?? "/product/kursy-povyshenija-kvalifikacii-ispolnitelnyh-rukovoditelej-i-specialistov-svjazannyh-s-obespecheniem-bezopasnosti-sudohodstva-i-predotvrashhenija-zagrjaznenija-okruzhajushhej-sredy-podgotovka-lic-otve",
    meta: component_45stubYjueJ23IJZMeta || {},
    alias: component_45stubYjueJ23IJZMeta?.alias || [],
    redirect: component_45stubYjueJ23IJZMeta?.redirect,
    component: component_45stubYjueJ23IJZ
  },
  {
    name: component_45stubYjueJ23IJZMeta?.name ?? undefined,
    path: component_45stubYjueJ23IJZMeta?.path ?? "/product/kvalificirovannyj-motorist/:pathMatch(.*)",
    meta: component_45stubYjueJ23IJZMeta || {},
    alias: component_45stubYjueJ23IJZMeta?.alias || [],
    redirect: component_45stubYjueJ23IJZMeta?.redirect,
    component: component_45stubYjueJ23IJZ
  },
  {
    name: component_45stubYjueJ23IJZMeta?.name ?? undefined,
    path: component_45stubYjueJ23IJZMeta?.path ?? "/product/lesson-with-an-english-tutor/:pathMatch(.*)",
    meta: component_45stubYjueJ23IJZMeta || {},
    alias: component_45stubYjueJ23IJZMeta?.alias || [],
    redirect: component_45stubYjueJ23IJZMeta?.redirect,
    component: component_45stubYjueJ23IJZ
  },
  {
    name: component_45stubYjueJ23IJZMeta?.name ?? undefined,
    path: component_45stubYjueJ23IJZMeta?.path ?? "/product/liderstvo-i-rabota-v-komande-leadership-and-teamwork/:pathMatch(.*)",
    meta: component_45stubYjueJ23IJZMeta || {},
    alias: component_45stubYjueJ23IJZMeta?.alias || [],
    redirect: component_45stubYjueJ23IJZMeta?.redirect,
    component: component_45stubYjueJ23IJZ
  },
  {
    name: component_45stubYjueJ23IJZMeta?.name ?? undefined,
    path: component_45stubYjueJ23IJZMeta?.path ?? "/product/liderstvo-i-rabota-v-komande-leadership-and-teamwork-2/:pathMatch(.*)",
    meta: component_45stubYjueJ23IJZMeta || {},
    alias: component_45stubYjueJ23IJZMeta?.alias || [],
    redirect: component_45stubYjueJ23IJZMeta?.redirect,
    component: component_45stubYjueJ23IJZ
  },
  {
    name: component_45stubYjueJ23IJZMeta?.name ?? undefined,
    path: component_45stubYjueJ23IJZMeta?.path ?? "/product/liderstvo-v-upravlenii-chelovecheskimi-resursami-helm-human-element-leadership-management",
    meta: component_45stubYjueJ23IJZMeta || {},
    alias: component_45stubYjueJ23IJZMeta?.alias || [],
    redirect: component_45stubYjueJ23IJZMeta?.redirect,
    component: component_45stubYjueJ23IJZ
  },
  {
    name: component_45stubYjueJ23IJZMeta?.name ?? undefined,
    path: component_45stubYjueJ23IJZMeta?.path ?? "/product/maneuvering-and-ship-control-for-persons-who-have-completed-previous-training-in-full-tables-a-ii-1-a-ii-2-mk-stcw-78-as-amended",
    meta: component_45stubYjueJ23IJZMeta || {},
    alias: component_45stubYjueJ23IJZMeta?.alias || [],
    redirect: component_45stubYjueJ23IJZMeta?.redirect,
    component: component_45stubYjueJ23IJZ
  },
  {
    name: component_45stubYjueJ23IJZMeta?.name ?? undefined,
    path: component_45stubYjueJ23IJZMeta?.path ?? "/product/maneuvering-and-ship-control-tables-a-ii-1-a-ii-2-mk-stcw-78-as-amended",
    meta: component_45stubYjueJ23IJZMeta || {},
    alias: component_45stubYjueJ23IJZMeta?.alias || [],
    redirect: component_45stubYjueJ23IJZMeta?.redirect,
    component: component_45stubYjueJ23IJZ
  },
  {
    name: component_45stubYjueJ23IJZMeta?.name ?? undefined,
    path: component_45stubYjueJ23IJZMeta?.path ?? "/product/maneuvering-when-mooring-a-vessel-to-a-vessel/:pathMatch(.*)",
    meta: component_45stubYjueJ23IJZMeta || {},
    alias: component_45stubYjueJ23IJZMeta?.alias || [],
    redirect: component_45stubYjueJ23IJZMeta?.redirect,
    component: component_45stubYjueJ23IJZ
  },
  {
    name: component_45stubYjueJ23IJZMeta?.name ?? undefined,
    path: component_45stubYjueJ23IJZMeta?.path ?? "/product/manevrirovanie-i-upravlenie-krupnotonnazhnymi-sudami/:pathMatch(.*)",
    meta: component_45stubYjueJ23IJZMeta || {},
    alias: component_45stubYjueJ23IJZMeta?.alias || [],
    redirect: component_45stubYjueJ23IJZMeta?.redirect,
    component: component_45stubYjueJ23IJZ
  },
  {
    name: component_45stubYjueJ23IJZMeta?.name ?? undefined,
    path: component_45stubYjueJ23IJZMeta?.path ?? "/product/manevrirovanie-i-upravlenie-sudnom-dlja-lic-prohodivshih-ranee-obuchenie-v-polnom-obeme-tablicy-a-ii-1-a-ii-2-mk-pdnv-78-s-popravkami",
    meta: component_45stubYjueJ23IJZMeta || {},
    alias: component_45stubYjueJ23IJZMeta?.alias || [],
    redirect: component_45stubYjueJ23IJZMeta?.redirect,
    component: component_45stubYjueJ23IJZ
  },
  {
    name: component_45stubYjueJ23IJZMeta?.name ?? undefined,
    path: component_45stubYjueJ23IJZMeta?.path ?? "/product/manevrirovanie-i-upravlenie-sudnom-tablicy-a-ii-1-a-ii-2-mk-pdnv-78-s-popravkami",
    meta: component_45stubYjueJ23IJZMeta || {},
    alias: component_45stubYjueJ23IJZMeta?.alias || [],
    redirect: component_45stubYjueJ23IJZMeta?.redirect,
    component: component_45stubYjueJ23IJZ
  },
  {
    name: component_45stubYjueJ23IJZMeta?.name ?? undefined,
    path: component_45stubYjueJ23IJZMeta?.path ?? "/product/manevrirovanie-pri-shvartovke-sudna-k-sudnu/:pathMatch(.*)",
    meta: component_45stubYjueJ23IJZMeta || {},
    alias: component_45stubYjueJ23IJZMeta?.alias || [],
    redirect: component_45stubYjueJ23IJZMeta?.redirect,
    component: component_45stubYjueJ23IJZ
  },
  {
    name: component_45stubYjueJ23IJZMeta?.name ?? undefined,
    path: component_45stubYjueJ23IJZMeta?.path ?? "/product/marine-surveyor-basic-course/:pathMatch(.*)",
    meta: component_45stubYjueJ23IJZMeta || {},
    alias: component_45stubYjueJ23IJZMeta?.alias || [],
    redirect: component_45stubYjueJ23IJZMeta?.redirect,
    component: component_45stubYjueJ23IJZ
  },
  {
    name: component_45stubYjueJ23IJZMeta?.name ?? undefined,
    path: component_45stubYjueJ23IJZMeta?.path ?? "/product/marine-surveyor-calculation-of-the-amount-of-bulk-cargo-marine-fuel-bunker-survey",
    meta: component_45stubYjueJ23IJZMeta || {},
    alias: component_45stubYjueJ23IJZMeta?.alias || [],
    redirect: component_45stubYjueJ23IJZMeta?.redirect,
    component: component_45stubYjueJ23IJZ
  },
  {
    name: component_45stubYjueJ23IJZMeta?.name ?? undefined,
    path: component_45stubYjueJ23IJZMeta?.path ?? "/product/marine-surveyor-calculation-of-the-weight-of-the-cargo-by-the-draft-of-the-vessel-draft-survey",
    meta: component_45stubYjueJ23IJZMeta || {},
    alias: component_45stubYjueJ23IJZMeta?.alias || [],
    redirect: component_45stubYjueJ23IJZMeta?.redirect,
    component: component_45stubYjueJ23IJZ
  },
  {
    name: component_45stubYjueJ23IJZMeta?.name ?? undefined,
    path: component_45stubYjueJ23IJZMeta?.path ?? "/product/marlins/:pathMatch(.*)",
    meta: component_45stubYjueJ23IJZMeta || {},
    alias: component_45stubYjueJ23IJZMeta?.alias || [],
    redirect: component_45stubYjueJ23IJZMeta?.redirect,
    component: component_45stubYjueJ23IJZ
  },
  {
    name: component_45stubYjueJ23IJZMeta?.name ?? undefined,
    path: component_45stubYjueJ23IJZMeta?.path ?? "/product/marlins-simulator/:pathMatch(.*)",
    meta: component_45stubYjueJ23IJZMeta || {},
    alias: component_45stubYjueJ23IJZMeta?.alias || [],
    redirect: component_45stubYjueJ23IJZMeta?.redirect,
    component: component_45stubYjueJ23IJZ
  },
  {
    name: component_45stubYjueJ23IJZMeta?.name ?? undefined,
    path: component_45stubYjueJ23IJZMeta?.path ?? "/product/medical-care-five-year-retraining-section-a-vi-4-tables-a-vi-4-2-mk-stcw-78-as-amended",
    meta: component_45stubYjueJ23IJZMeta || {},
    alias: component_45stubYjueJ23IJZMeta?.alias || [],
    redirect: component_45stubYjueJ23IJZMeta?.redirect,
    component: component_45stubYjueJ23IJZ
  },
  {
    name: component_45stubYjueJ23IJZMeta?.name ?? undefined,
    path: component_45stubYjueJ23IJZMeta?.path ?? "/product/medical-care-section-a-vi-4-tables-a-vi-4-2-mk-stcw-78-as-amended",
    meta: component_45stubYjueJ23IJZMeta || {},
    alias: component_45stubYjueJ23IJZMeta?.alias || [],
    redirect: component_45stubYjueJ23IJZMeta?.redirect,
    component: component_45stubYjueJ23IJZ
  },
  {
    name: component_45stubYjueJ23IJZMeta?.name ?? undefined,
    path: component_45stubYjueJ23IJZMeta?.path ?? "/product/medicinskij-uhod-pjatiletnjaja-perepodgotovka-razdela-a-vi-4-tablicy-a-vi-4-2-mk-pdnv-78-s-popravkami",
    meta: component_45stubYjueJ23IJZMeta || {},
    alias: component_45stubYjueJ23IJZMeta?.alias || [],
    redirect: component_45stubYjueJ23IJZMeta?.redirect,
    component: component_45stubYjueJ23IJZ
  },
  {
    name: component_45stubYjueJ23IJZMeta?.name ?? undefined,
    path: component_45stubYjueJ23IJZMeta?.path ?? "/product/medicinskij-uhod-razdela-a-vi-4-tablicy-a-vi-4-2-mk-pdnv-78-s-popravkami",
    meta: component_45stubYjueJ23IJZMeta || {},
    alias: component_45stubYjueJ23IJZMeta?.alias || [],
    redirect: component_45stubYjueJ23IJZMeta?.redirect,
    component: component_45stubYjueJ23IJZ
  },
  {
    name: component_45stubYjueJ23IJZMeta?.name ?? undefined,
    path: component_45stubYjueJ23IJZMeta?.path ?? "/product/mojka-tankov-syroj-neftju-i-sistema-inertnogo-gaza-razdel-a-v-1-tablicy-a-v-1-1-2-mk-pdnv-78-s-popravkami",
    meta: component_45stubYjueJ23IJZMeta || {},
    alias: component_45stubYjueJ23IJZMeta?.alias || [],
    redirect: component_45stubYjueJ23IJZMeta?.redirect,
    component: component_45stubYjueJ23IJZ
  },
  {
    name: component_45stubYjueJ23IJZMeta?.name ?? undefined,
    path: component_45stubYjueJ23IJZMeta?.path ?? "/product/morskoj-sjurvejer-bazovyj-kurs/:pathMatch(.*)",
    meta: component_45stubYjueJ23IJZMeta || {},
    alias: component_45stubYjueJ23IJZMeta?.alias || [],
    redirect: component_45stubYjueJ23IJZMeta?.redirect,
    component: component_45stubYjueJ23IJZ
  },
  {
    name: component_45stubYjueJ23IJZMeta?.name ?? undefined,
    path: component_45stubYjueJ23IJZMeta?.path ?? "/product/morskoj-sjurvejer-raschjot-kolichestva-nalivnogo-gruza-sudovogo-topliva-bunker-sjurvej",
    meta: component_45stubYjueJ23IJZMeta || {},
    alias: component_45stubYjueJ23IJZMeta?.alias || [],
    redirect: component_45stubYjueJ23IJZMeta?.redirect,
    component: component_45stubYjueJ23IJZ
  },
  {
    name: component_45stubYjueJ23IJZMeta?.name ?? undefined,
    path: component_45stubYjueJ23IJZMeta?.path ?? "/product/morskoj-sjurvejer-raschjot-vesa-gruza-po-osadke-sudna-draft-sjurvej",
    meta: component_45stubYjueJ23IJZMeta || {},
    alias: component_45stubYjueJ23IJZMeta?.alias || [],
    redirect: component_45stubYjueJ23IJZMeta?.redirect,
    component: component_45stubYjueJ23IJZ
  },
  {
    name: component_45stubYjueJ23IJZMeta?.name ?? undefined,
    path: component_45stubYjueJ23IJZMeta?.path ?? "/product/motorist-dlja-raboty-na-vvp/:pathMatch(.*)",
    meta: component_45stubYjueJ23IJZMeta || {},
    alias: component_45stubYjueJ23IJZMeta?.alias || [],
    redirect: component_45stubYjueJ23IJZMeta?.redirect,
    component: component_45stubYjueJ23IJZ
  },
  {
    name: component_45stubYjueJ23IJZMeta?.name ?? undefined,
    path: component_45stubYjueJ23IJZMeta?.path ?? "/product/motorist-rulevoj-dlja-raboty-na-vvp/:pathMatch(.*)",
    meta: component_45stubYjueJ23IJZMeta || {},
    alias: component_45stubYjueJ23IJZMeta?.alias || [],
    redirect: component_45stubYjueJ23IJZMeta?.redirect,
    component: component_45stubYjueJ23IJZ
  },
  {
    name: component_45stubYjueJ23IJZMeta?.name ?? undefined,
    path: component_45stubYjueJ23IJZMeta?.path ?? "/product/nachalnaja-podgotovka-dlja-raboty-na-neftjanyh-tankerah-i-tankerah-himovozah-dlja-rjadovogo-i-komandnogo-sostava-razdel-a-v-1-1-tablica-a-v-1-1-1-mk-pdnv-78-s-popravkami",
    meta: component_45stubYjueJ23IJZMeta || {},
    alias: component_45stubYjueJ23IJZMeta?.alias || [],
    redirect: component_45stubYjueJ23IJZMeta?.redirect,
    component: component_45stubYjueJ23IJZ
  },
  {
    name: component_45stubYjueJ23IJZMeta?.name ?? undefined,
    path: component_45stubYjueJ23IJZMeta?.path ?? "/product/nachalnaja-podgotovka-dlja-raboty-na-neftjanyh-tankerah-i-tankerah-himovozah-pjatiletnjaja-perepodgotovka-dlja-komandnogo-i-rjadovogo-sostava-razdel-a-v-1-1-tablica-a-v-1-1-1-mk-pdnv-78-s-popravkami",
    meta: component_45stubYjueJ23IJZMeta || {},
    alias: component_45stubYjueJ23IJZMeta?.alias || [],
    redirect: component_45stubYjueJ23IJZMeta?.redirect,
    component: component_45stubYjueJ23IJZ
  },
  {
    name: component_45stubYjueJ23IJZMeta?.name ?? undefined,
    path: component_45stubYjueJ23IJZMeta?.path ?? "/product/nachalnaja-podgotovka-dlja-raboty-na-tankerah-gazovozah-dlja-rjadovogo-i-komandnogo-sostava-razdel-a-v-1-2-tablica-a-v11-2-1mk-pdnv-78-s-popravkami",
    meta: component_45stubYjueJ23IJZMeta || {},
    alias: component_45stubYjueJ23IJZMeta?.alias || [],
    redirect: component_45stubYjueJ23IJZMeta?.redirect,
    component: component_45stubYjueJ23IJZ
  },
  {
    name: component_45stubYjueJ23IJZMeta?.name ?? undefined,
    path: component_45stubYjueJ23IJZMeta?.path ?? "/product/nachalnaja-podgotovka-dlja-raboty-na-tankerah-gazovozah-pjatiletnjaja-perepodgotovka-razdel-a-v-1-2-tablica-a-v11-2-1mk-pdnv-78-s-popravkami",
    meta: component_45stubYjueJ23IJZMeta || {},
    alias: component_45stubYjueJ23IJZMeta?.alias || [],
    redirect: component_45stubYjueJ23IJZMeta?.redirect,
    component: component_45stubYjueJ23IJZ
  },
  {
    name: component_45stubYjueJ23IJZMeta?.name ?? undefined,
    path: component_45stubYjueJ23IJZMeta?.path ?? "/product/nachalnaja-podgotovka-dlja-raboty-na-tankerah-gazovozah-pjatiletnjaja-perepodgotovka-razdel-av-1-2-tablica-a-v11-2-1mk-pdnv-78-s-popravkami",
    meta: component_45stubYjueJ23IJZMeta || {},
    alias: component_45stubYjueJ23IJZMeta?.alias || [],
    redirect: component_45stubYjueJ23IJZMeta?.redirect,
    component: component_45stubYjueJ23IJZ
  },
  {
    name: component_45stubYjueJ23IJZMeta?.name ?? undefined,
    path: component_45stubYjueJ23IJZMeta?.path ?? "/product/nachalnaja-podgotovka-po-bezopasnosti-pjatiletnjaja-perepodgotovka-razdel-a-vi-1-tablic-a-vi-1-1-a-vi-1-2-a-vi-1-3-a-vi-1-4-kodeksa-pdnv-s-popravkami",
    meta: component_45stubYjueJ23IJZMeta || {},
    alias: component_45stubYjueJ23IJZMeta?.alias || [],
    redirect: component_45stubYjueJ23IJZMeta?.redirect,
    component: component_45stubYjueJ23IJZ
  },
  {
    name: component_45stubYjueJ23IJZMeta?.name ?? undefined,
    path: component_45stubYjueJ23IJZMeta?.path ?? "/product/nachalnaja-podgotovka-po-bezopasnosti-razdel-a-vi-1-tablic-a-vi-1-1-a-vi-1-2-a-vi-1-3-a-vi-1-4-kodeksa-pdnv-s-popravkami",
    meta: component_45stubYjueJ23IJZMeta || {},
    alias: component_45stubYjueJ23IJZMeta?.alias || [],
    redirect: component_45stubYjueJ23IJZMeta?.redirect,
    component: component_45stubYjueJ23IJZ
  },
  {
    name: component_45stubYjueJ23IJZMeta?.name ?? undefined,
    path: component_45stubYjueJ23IJZMeta?.path ?? "/product/navigation-on-small-boats-navigation-area-inland-waterways-jet-ski-driver-navigation-areas-inland-waterways-and-sea-routes-with-a-license-to-a-boat-motorboat-navigation-area-mp",
    meta: component_45stubYjueJ23IJZMeta || {},
    alias: component_45stubYjueJ23IJZMeta?.alias || [],
    redirect: component_45stubYjueJ23IJZMeta?.redirect,
    component: component_45stubYjueJ23IJZ
  },
  {
    name: component_45stubYjueJ23IJZMeta?.name ?? undefined,
    path: component_45stubYjueJ23IJZMeta?.path ?? "/product/navigation-on-small-vessels-navigation-area-inland-waterways",
    meta: component_45stubYjueJ23IJZMeta || {},
    alias: component_45stubYjueJ23IJZMeta?.alias || [],
    redirect: component_45stubYjueJ23IJZMeta?.redirect,
    component: component_45stubYjueJ23IJZ
  },
  {
    name: component_45stubYjueJ23IJZMeta?.name ?? undefined,
    path: component_45stubYjueJ23IJZMeta?.path ?? "/product/obespechenie-bezopasnosti-sudohodstva-dlja-lic-zanimajushhih-dolzhnosti-ispolnitelnyh-rukovoditelej-i-specialistov-organizacij-i-ih-podrazdelenij-osushhestvljajushhih-perevozku-passazhirov-i-gruzov-po",
    meta: component_45stubYjueJ23IJZMeta || {},
    alias: component_45stubYjueJ23IJZMeta?.alias || [],
    redirect: component_45stubYjueJ23IJZMeta?.redirect,
    component: component_45stubYjueJ23IJZ
  },
  {
    name: component_45stubYjueJ23IJZMeta?.name ?? undefined,
    path: component_45stubYjueJ23IJZMeta?.path ?? "/product/oborudovanie-i-zashhitnye-sistemy-prednaznachennye-dlja-raboty-v-potencialno-vzryvoopasnoj-srede-uroven-1",
    meta: component_45stubYjueJ23IJZMeta || {},
    alias: component_45stubYjueJ23IJZMeta?.alias || [],
    redirect: component_45stubYjueJ23IJZMeta?.redirect,
    component: component_45stubYjueJ23IJZ
  },
  {
    name: component_45stubYjueJ23IJZMeta?.name ?? undefined,
    path: component_45stubYjueJ23IJZMeta?.path ?? "/product/obuchenie-i-povyshenie-kvalifikacii-lic-uchastvujushhih-v-osushhestvlenii-sjurvejerskoj-dejatelnosti-pri-osmotre-tehnicheskogo-sostojanija-plav-sredstv-on-hire-off-hire-survey-full-condition-survey",
    meta: component_45stubYjueJ23IJZMeta || {},
    alias: component_45stubYjueJ23IJZMeta?.alias || [],
    redirect: component_45stubYjueJ23IJZMeta?.redirect,
    component: component_45stubYjueJ23IJZ
  },
  {
    name: component_45stubYjueJ23IJZMeta?.name ?? undefined,
    path: component_45stubYjueJ23IJZMeta?.path ?? "/product/ocenka-urovnja-kompetentnosti-na-sudne/:pathMatch(.*)",
    meta: component_45stubYjueJ23IJZMeta || {},
    alias: component_45stubYjueJ23IJZMeta?.alias || [],
    redirect: component_45stubYjueJ23IJZMeta?.redirect,
    component: component_45stubYjueJ23IJZ
  },
  {
    name: component_45stubYjueJ23IJZMeta?.name ?? undefined,
    path: component_45stubYjueJ23IJZMeta?.path ?? "/product/oformlenie-dublikata-svidetelstva/:pathMatch(.*)",
    meta: component_45stubYjueJ23IJZMeta || {},
    alias: component_45stubYjueJ23IJZMeta?.alias || [],
    redirect: component_45stubYjueJ23IJZMeta?.redirect,
    component: component_45stubYjueJ23IJZ
  },
  {
    name: component_45stubYjueJ23IJZMeta?.name ?? undefined,
    path: component_45stubYjueJ23IJZMeta?.path ?? "/product/oil-and-oil-product-spill-response-specialist-basic-course-1-level",
    meta: component_45stubYjueJ23IJZMeta || {},
    alias: component_45stubYjueJ23IJZMeta?.alias || [],
    redirect: component_45stubYjueJ23IJZMeta?.redirect,
    component: component_45stubYjueJ23IJZ
  },
  {
    name: component_45stubYjueJ23IJZMeta?.name ?? undefined,
    path: component_45stubYjueJ23IJZMeta?.path ?? "/product/operation-of-electrical-installations-and-electrical-control-systems-on-board",
    meta: component_45stubYjueJ23IJZMeta || {},
    alias: component_45stubYjueJ23IJZMeta?.alias || [],
    redirect: component_45stubYjueJ23IJZMeta?.redirect,
    component: component_45stubYjueJ23IJZ
  },
  {
    name: component_45stubYjueJ23IJZMeta?.name ?? undefined,
    path: component_45stubYjueJ23IJZMeta?.path ?? "/product/organizacija-hodovoj-navigacionnoj-vahty-i-upravlenie-resursami-mostika",
    meta: component_45stubYjueJ23IJZMeta || {},
    alias: component_45stubYjueJ23IJZMeta?.alias || [],
    redirect: component_45stubYjueJ23IJZMeta?.redirect,
    component: component_45stubYjueJ23IJZ
  },
  {
    name: component_45stubYjueJ23IJZMeta?.name ?? undefined,
    path: component_45stubYjueJ23IJZMeta?.path ?? "/product/organizacija-hodovoj-navigacionnoj-vahty-i-upravlenie-resursami-mostika-2",
    meta: component_45stubYjueJ23IJZMeta || {},
    alias: component_45stubYjueJ23IJZMeta?.alias || [],
    redirect: component_45stubYjueJ23IJZMeta?.redirect,
    component: component_45stubYjueJ23IJZ
  },
  {
    name: component_45stubYjueJ23IJZMeta?.name ?? undefined,
    path: component_45stubYjueJ23IJZMeta?.path ?? "/product/osnovy-bezopasnosti-pri-rabote-s-takelazhem-i-pri-stroplenii",
    meta: component_45stubYjueJ23IJZMeta || {},
    alias: component_45stubYjueJ23IJZMeta?.alias || [],
    redirect: component_45stubYjueJ23IJZMeta?.redirect,
    component: component_45stubYjueJ23IJZ
  },
  {
    name: component_45stubYjueJ23IJZMeta?.name ?? undefined,
    path: component_45stubYjueJ23IJZMeta?.path ?? "/product/oznakomitelnaja-podgotovka-po-provedeniju-shvartovyh-operacij-vspomogatelnyj-uroven",
    meta: component_45stubYjueJ23IJZMeta || {},
    alias: component_45stubYjueJ23IJZMeta?.alias || [],
    redirect: component_45stubYjueJ23IJZMeta?.redirect,
    component: component_45stubYjueJ23IJZ
  },
  {
    name: component_45stubYjueJ23IJZMeta?.name ?? undefined,
    path: component_45stubYjueJ23IJZMeta?.path ?? "/product/perevalka-opasnyh-gruzov-oznakomitelnyj-kurs-dlja-lic-kompanii-osushhestvljajushhih-perevalku-opasnyh-gruzov",
    meta: component_45stubYjueJ23IJZMeta || {},
    alias: component_45stubYjueJ23IJZMeta?.alias || [],
    redirect: component_45stubYjueJ23IJZMeta?.redirect,
    component: component_45stubYjueJ23IJZ
  },
  {
    name: component_45stubYjueJ23IJZMeta?.name ?? undefined,
    path: component_45stubYjueJ23IJZMeta?.path ?? "/product/perevalka-opasnyh-gruzov-specializirovannyj-kurs-dlja-lic-kompanii-osushhestvljajushhih-organizaciju-i-kontrol-perevalki-opasnyh-gruzov",
    meta: component_45stubYjueJ23IJZMeta || {},
    alias: component_45stubYjueJ23IJZMeta?.alias || [],
    redirect: component_45stubYjueJ23IJZMeta?.redirect,
    component: component_45stubYjueJ23IJZ
  },
  {
    name: component_45stubYjueJ23IJZMeta?.name ?? undefined,
    path: component_45stubYjueJ23IJZMeta?.path ?? "/product/perevozka-opasnyh-veshhestv-na-sudah-navalom-i-v-upakovke-pjatiletnjaja-perepodgotovka",
    meta: component_45stubYjueJ23IJZMeta || {},
    alias: component_45stubYjueJ23IJZMeta?.alias || [],
    redirect: component_45stubYjueJ23IJZMeta?.redirect,
    component: component_45stubYjueJ23IJZ
  },
  {
    name: component_45stubYjueJ23IJZMeta?.name ?? undefined,
    path: component_45stubYjueJ23IJZMeta?.path ?? "/product/perevozka-opasnyh-veshhestv-navalom-i-v-upakovke-razdel-b-v-b-tablica-b-v-c-mk-pdnv-78-s-popravkami",
    meta: component_45stubYjueJ23IJZMeta || {},
    alias: component_45stubYjueJ23IJZMeta?.alias || [],
    redirect: component_45stubYjueJ23IJZMeta?.redirect,
    component: component_45stubYjueJ23IJZ
  },
  {
    name: component_45stubYjueJ23IJZMeta?.name ?? undefined,
    path: component_45stubYjueJ23IJZMeta?.path ?? "/product/perevozka-opasnyh-veshhestv-navalom-iv-upakovke-razdel-bvb-tablica-bvc-mk-pdnv-78-s-popravkami",
    meta: component_45stubYjueJ23IJZMeta || {},
    alias: component_45stubYjueJ23IJZMeta?.alias || [],
    redirect: component_45stubYjueJ23IJZMeta?.redirect,
    component: component_45stubYjueJ23IJZ
  },
  {
    name: component_45stubYjueJ23IJZMeta?.name ?? undefined,
    path: component_45stubYjueJ23IJZMeta?.path ?? "/product/personal-respiratory-protection-rules-for-the-use-and-maintenance-of-self-contained-breathing-apparatus",
    meta: component_45stubYjueJ23IJZMeta || {},
    alias: component_45stubYjueJ23IJZMeta?.alias || [],
    redirect: component_45stubYjueJ23IJZMeta?.redirect,
    component: component_45stubYjueJ23IJZ
  },
  {
    name: component_45stubYjueJ23IJZMeta?.name ?? undefined,
    path: component_45stubYjueJ23IJZMeta?.path ?? "/product/podarochnyj-sertifikat-na-obuchenie-gidrocikl/:pathMatch(.*)",
    meta: component_45stubYjueJ23IJZMeta || {},
    alias: component_45stubYjueJ23IJZMeta?.alias || [],
    redirect: component_45stubYjueJ23IJZMeta?.redirect,
    component: component_45stubYjueJ23IJZ
  },
  {
    name: component_45stubYjueJ23IJZMeta?.name ?? undefined,
    path: component_45stubYjueJ23IJZMeta?.path ?? "/product/podgotovka-dlja-prohozhdenija-testirovanija-ces-sistema-ocenki-kompetencij",
    meta: component_45stubYjueJ23IJZMeta || {},
    alias: component_45stubYjueJ23IJZMeta?.alias || [],
    redirect: component_45stubYjueJ23IJZMeta?.redirect,
    component: component_45stubYjueJ23IJZ
  },
  {
    name: component_45stubYjueJ23IJZMeta?.name ?? undefined,
    path: component_45stubYjueJ23IJZMeta?.path ?? "/product/podgotovka-dlja-prohozhdenija-testirovanija-po-anglijskomu-jazyku",
    meta: component_45stubYjueJ23IJZMeta || {},
    alias: component_45stubYjueJ23IJZMeta?.alias || [],
    redirect: component_45stubYjueJ23IJZMeta?.redirect,
    component: component_45stubYjueJ23IJZ
  },
  {
    name: component_45stubYjueJ23IJZMeta?.name ?? undefined,
    path: component_45stubYjueJ23IJZMeta?.path ?? "/product/podgotovka-dlja-raboty-na-nalivnyh-sudah-osushhestvljajushhih-perevozki-opasnyh-gruzov",
    meta: component_45stubYjueJ23IJZMeta || {},
    alias: component_45stubYjueJ23IJZMeta?.alias || [],
    redirect: component_45stubYjueJ23IJZMeta?.redirect,
    component: component_45stubYjueJ23IJZ
  },
  {
    name: component_45stubYjueJ23IJZMeta?.name ?? undefined,
    path: component_45stubYjueJ23IJZMeta?.path ?? "/product/podgotovka-dlja-raboty-na-neftjanyh-tankerah-i-upravleniju-neftjanymi-operacijami-komandnyj-sostav-prikaz-ministerstva-transporta-rf-ot-12-03-2018-g-87-polozhenie-o-diplomirovanii-chlenov-jekipazhej",
    meta: component_45stubYjueJ23IJZMeta || {},
    alias: component_45stubYjueJ23IJZMeta?.alias || [],
    redirect: component_45stubYjueJ23IJZMeta?.redirect,
    component: component_45stubYjueJ23IJZ
  },
  {
    name: component_45stubYjueJ23IJZMeta?.name ?? undefined,
    path: component_45stubYjueJ23IJZMeta?.path ?? "/product/podgotovka-dlja-raboty-na-neftjanyh-tankerah-rjadovoj-sostav-prikaz-ministerstva-transporta-rf-ot-12-03-2018-g-87-polozhenie-o-diplomirovanii-chlenov-jekipazhej-sudov-vnutrennego-vodnogo-transporta",
    meta: component_45stubYjueJ23IJZMeta || {},
    alias: component_45stubYjueJ23IJZMeta?.alias || [],
    redirect: component_45stubYjueJ23IJZMeta?.redirect,
    component: component_45stubYjueJ23IJZ
  },
  {
    name: component_45stubYjueJ23IJZMeta?.name ?? undefined,
    path: component_45stubYjueJ23IJZMeta?.path ?? "/product/podgotovka-dlja-raboty-na-passazhirskom-sudne-rjadovoj-sostav",
    meta: component_45stubYjueJ23IJZMeta || {},
    alias: component_45stubYjueJ23IJZMeta?.alias || [],
    redirect: component_45stubYjueJ23IJZMeta?.redirect,
    component: component_45stubYjueJ23IJZ
  },
  {
    name: component_45stubYjueJ23IJZMeta?.name ?? undefined,
    path: component_45stubYjueJ23IJZMeta?.path ?? "/product/podgotovka-dolzhnostnogo-lica-kompanii-otvetstvennogo-za-ohranu-glava-xi-2-mk-solas-kodeks-osps-cirkuljar-imo-1154",
    meta: component_45stubYjueJ23IJZMeta || {},
    alias: component_45stubYjueJ23IJZMeta?.alias || [],
    redirect: component_45stubYjueJ23IJZMeta?.redirect,
    component: component_45stubYjueJ23IJZ
  },
  {
    name: component_45stubYjueJ23IJZMeta?.name ?? undefined,
    path: component_45stubYjueJ23IJZMeta?.path ?? "/product/podgotovka-dolzhnostnogo-lica-portovogo-sredstva-otvetstvennogo-za-ohranu-glava-xi-2-mk-solas-kodeks-osps-cirkuljar-imo-1-1188",
    meta: component_45stubYjueJ23IJZMeta || {},
    alias: component_45stubYjueJ23IJZMeta?.alias || [],
    redirect: component_45stubYjueJ23IJZMeta?.redirect,
    component: component_45stubYjueJ23IJZ
  },
  {
    name: component_45stubYjueJ23IJZMeta?.name ?? undefined,
    path: component_45stubYjueJ23IJZMeta?.path ?? "/product/podgotovka-jekipazhej-sudov-k-prohozhdeniju-inspekcii-po-tipu-sire-2-0",
    meta: component_45stubYjueJ23IJZMeta || {},
    alias: component_45stubYjueJ23IJZMeta?.alias || [],
    redirect: component_45stubYjueJ23IJZMeta?.redirect,
    component: component_45stubYjueJ23IJZ
  },
  {
    name: component_45stubYjueJ23IJZMeta?.name ?? undefined,
    path: component_45stubYjueJ23IJZMeta?.path ?? "/product/podgotovka-jelektromehanika-morskogo-suda-s-glavnoj-dvigatelnoj-ustanovkoj-bolee-750-kvt-pri-dlitelnom-pereryve-v-rabote",
    meta: component_45stubYjueJ23IJZMeta || {},
    alias: component_45stubYjueJ23IJZMeta?.alias || [],
    redirect: component_45stubYjueJ23IJZMeta?.redirect,
    component: component_45stubYjueJ23IJZ
  },
  {
    name: component_45stubYjueJ23IJZMeta?.name ?? undefined,
    path: component_45stubYjueJ23IJZMeta?.path ?? "/product/podgotovka-k-borbe-s-pozharom-po-rasshirennoj-programme-razdel-a-vi-3-tablica-a-vi-3-mk-pdnv",
    meta: component_45stubYjueJ23IJZMeta || {},
    alias: component_45stubYjueJ23IJZMeta?.alias || [],
    redirect: component_45stubYjueJ23IJZMeta?.redirect,
    component: component_45stubYjueJ23IJZ
  },
  {
    name: component_45stubYjueJ23IJZMeta?.name ?? undefined,
    path: component_45stubYjueJ23IJZMeta?.path ?? "/product/podgotovka-k-borbe-s-pozharom-po-rasshirennoj-programme-razdel-a-vi-3-tablica-a-vi-3-mk-pdnv-pjatiletnjaja-perepodgotovka",
    meta: component_45stubYjueJ23IJZMeta || {},
    alias: component_45stubYjueJ23IJZMeta?.alias || [],
    redirect: component_45stubYjueJ23IJZMeta?.redirect,
    component: component_45stubYjueJ23IJZ
  },
  {
    name: component_45stubYjueJ23IJZMeta?.name ?? undefined,
    path: component_45stubYjueJ23IJZMeta?.path ?? "/product/podgotovka-k-prohozhdeniju-testirovanija-v-map-1-den/:pathMatch(.*)",
    meta: component_45stubYjueJ23IJZMeta || {},
    alias: component_45stubYjueJ23IJZMeta?.alias || [],
    redirect: component_45stubYjueJ23IJZMeta?.redirect,
    component: component_45stubYjueJ23IJZ
  },
  {
    name: component_45stubYjueJ23IJZMeta?.name ?? undefined,
    path: component_45stubYjueJ23IJZMeta?.path ?? "/product/podgotovka-k-prohozhdeniju-testirovanija-v-map-3-dnja/:pathMatch(.*)",
    meta: component_45stubYjueJ23IJZMeta || {},
    alias: component_45stubYjueJ23IJZMeta?.alias || [],
    redirect: component_45stubYjueJ23IJZMeta?.redirect,
    component: component_45stubYjueJ23IJZ
  },
  {
    name: component_45stubYjueJ23IJZMeta?.name ?? undefined,
    path: component_45stubYjueJ23IJZMeta?.path ?? "/product/podgotovka-k-rabote-s-vysokim-naprjazheniem-na-urovne-jekspluatacii-high-voltage-training-razdely-a-iii-1-a-iii-6-a-viii-1-kodeksa-pdnv-s-uchetom-rukovodstv-privedennyh-v-chasti-b-kodeksa-pdnv",
    meta: component_45stubYjueJ23IJZMeta || {},
    alias: component_45stubYjueJ23IJZMeta?.alias || [],
    redirect: component_45stubYjueJ23IJZMeta?.redirect,
    component: component_45stubYjueJ23IJZ
  },
  {
    name: component_45stubYjueJ23IJZMeta?.name ?? undefined,
    path: component_45stubYjueJ23IJZMeta?.path ?? "/product/podgotovka-k-vetting-inspekcii-tankerov/:pathMatch(.*)",
    meta: component_45stubYjueJ23IJZMeta || {},
    alias: component_45stubYjueJ23IJZMeta?.alias || [],
    redirect: component_45stubYjueJ23IJZMeta?.redirect,
    component: component_45stubYjueJ23IJZ
  },
  {
    name: component_45stubYjueJ23IJZMeta?.name ?? undefined,
    path: component_45stubYjueJ23IJZMeta?.path ?? "/product/podgotovka-lic-otvetstvennyh-za-obespechenie-bezopasnosti-jekspluatacii-sudov-po-sub-na-vvp",
    meta: component_45stubYjueJ23IJZMeta || {},
    alias: component_45stubYjueJ23IJZMeta?.alias || [],
    redirect: component_45stubYjueJ23IJZMeta?.redirect,
    component: component_45stubYjueJ23IJZ
  },
  {
    name: component_45stubYjueJ23IJZMeta?.name ?? undefined,
    path: component_45stubYjueJ23IJZMeta?.path ?? "/product/podgotovka-lic-otvetstvennyh-za-obespechenie-bezopasnosti-jekspluatacii-sudov-po-sub-na-vvp-perepodgotovka",
    meta: component_45stubYjueJ23IJZMeta || {},
    alias: component_45stubYjueJ23IJZMeta?.alias || [],
    redirect: component_45stubYjueJ23IJZMeta?.redirect,
    component: component_45stubYjueJ23IJZ
  },
  {
    name: component_45stubYjueJ23IJZMeta?.name ?? undefined,
    path: component_45stubYjueJ23IJZMeta?.path ?? "/product/podgotovka-lica-komandnogo-sostava-sudna-otvetstvennogo-za-ohranu",
    meta: component_45stubYjueJ23IJZMeta || {},
    alias: component_45stubYjueJ23IJZMeta?.alias || [],
    redirect: component_45stubYjueJ23IJZMeta?.redirect,
    component: component_45stubYjueJ23IJZ
  },
  {
    name: component_45stubYjueJ23IJZMeta?.name ?? undefined,
    path: component_45stubYjueJ23IJZMeta?.path ?? "/product/podgotovka-lica-komandnogo-sostava-sudna-otvetstvennogo-za-ohranu-dlja-ranee-proshedshih-kurs-podgotovki",
    meta: component_45stubYjueJ23IJZMeta || {},
    alias: component_45stubYjueJ23IJZMeta?.alias || [],
    redirect: component_45stubYjueJ23IJZMeta?.redirect,
    component: component_45stubYjueJ23IJZ
  },
  {
    name: component_45stubYjueJ23IJZMeta?.name ?? undefined,
    path: component_45stubYjueJ23IJZMeta?.path ?? "/product/podgotovka-na-trenazhere-gruzo-ballastnyh-operacij-na-neftjanyh-tankerah-razdel-a-v-1-1-mk-pdnv-78-s-popravkami-modelnogo-kursa-imo-2-06-oil-tanker-cargo-and-ballast-handling-simulator",
    meta: component_45stubYjueJ23IJZMeta || {},
    alias: component_45stubYjueJ23IJZMeta?.alias || [],
    redirect: component_45stubYjueJ23IJZMeta?.redirect,
    component: component_45stubYjueJ23IJZ
  },
  {
    name: component_45stubYjueJ23IJZMeta?.name ?? undefined,
    path: component_45stubYjueJ23IJZMeta?.path ?? "/product/podgotovka-na-trenazhere-gruzo-ballastnyh-operacij-na-neftjanyh-tankerah-razdel-av-1-1-mk-pdnv-78-s-popravkami-modelnogo-kursa-imo-2-06-oil-tanker-cargo-and-ballast-handling-simulator",
    meta: component_45stubYjueJ23IJZMeta || {},
    alias: component_45stubYjueJ23IJZMeta?.alias || [],
    redirect: component_45stubYjueJ23IJZMeta?.redirect,
    component: component_45stubYjueJ23IJZ
  },
  {
    name: component_45stubYjueJ23IJZMeta?.name ?? undefined,
    path: component_45stubYjueJ23IJZMeta?.path ?? "/product/podgotovka-na-trenazhere-gruzo-ballastnyh-operacij-na-neftjanyh-tankerah-razdela-a-v-1-1-mk-pdnv-78-s-popravkami-modelnogo-kursa-imo-2-06-oil-tanker-cargo-and-ballast-handling-simulator-perepodgotovka",
    meta: component_45stubYjueJ23IJZMeta || {},
    alias: component_45stubYjueJ23IJZMeta?.alias || [],
    redirect: component_45stubYjueJ23IJZMeta?.redirect,
    component: component_45stubYjueJ23IJZ
  },
  {
    name: component_45stubYjueJ23IJZMeta?.name ?? undefined,
    path: component_45stubYjueJ23IJZMeta?.path ?? "/product/podgotovka-na-trenazhere-gruzo-ballastnyh-operacij-na-tankerah-himovozah-razdel-a-v-1-1-tablica-a-v-1-1-3-mk-pdnv",
    meta: component_45stubYjueJ23IJZMeta || {},
    alias: component_45stubYjueJ23IJZMeta?.alias || [],
    redirect: component_45stubYjueJ23IJZMeta?.redirect,
    component: component_45stubYjueJ23IJZ
  },
  {
    name: component_45stubYjueJ23IJZMeta?.name ?? undefined,
    path: component_45stubYjueJ23IJZMeta?.path ?? "/product/podgotovka-na-trenazhere-gruzo-ballastnyh-operacij-na-tankerah-himovozah-razdel-a-v-1-2-tablica-a-v-1-2-2-mk-pdnv-perepodgotovka",
    meta: component_45stubYjueJ23IJZMeta || {},
    alias: component_45stubYjueJ23IJZMeta?.alias || [],
    redirect: component_45stubYjueJ23IJZMeta?.redirect,
    component: component_45stubYjueJ23IJZ
  },
  {
    name: component_45stubYjueJ23IJZMeta?.name ?? undefined,
    path: component_45stubYjueJ23IJZMeta?.path ?? "/product/podgotovka-na-trenazhere-gruzo-ballastnyh-operacij-na-tankerah-himovozah-razdel-av-1-1-tablica-av-1-1-3-mk-pdnv",
    meta: component_45stubYjueJ23IJZMeta || {},
    alias: component_45stubYjueJ23IJZMeta?.alias || [],
    redirect: component_45stubYjueJ23IJZMeta?.redirect,
    component: component_45stubYjueJ23IJZ
  },
  {
    name: component_45stubYjueJ23IJZMeta?.name ?? undefined,
    path: component_45stubYjueJ23IJZMeta?.path ?? "/product/podgotovka-na-trenazhere-gruzo-ballastnyh-operacij-na-tankerah-himovozah-razdel-av-1-2-tablica-av-1-2-2-mk-pdnv",
    meta: component_45stubYjueJ23IJZMeta || {},
    alias: component_45stubYjueJ23IJZMeta?.alias || [],
    redirect: component_45stubYjueJ23IJZMeta?.redirect,
    component: component_45stubYjueJ23IJZ
  },
  {
    name: component_45stubYjueJ23IJZMeta?.name ?? undefined,
    path: component_45stubYjueJ23IJZMeta?.path ?? "/product/podgotovka-na-trenazhere-gruzo-ballastnyh-operacij-na-tankerah-himovozah-razdela-a-v-1-1-mk-pdnv-78-s-popravkami-modelnogo-kursa-imo-1-37-chemical-tanker-cargo-and-ballast-handling-simulator-perepodgo",
    meta: component_45stubYjueJ23IJZMeta || {},
    alias: component_45stubYjueJ23IJZMeta?.alias || [],
    redirect: component_45stubYjueJ23IJZMeta?.redirect,
    component: component_45stubYjueJ23IJZ
  },
  {
    name: component_45stubYjueJ23IJZMeta?.name ?? undefined,
    path: component_45stubYjueJ23IJZMeta?.path ?? "/product/podgotovka-na-trenazhere-gruzo-ballastnyh-operacij-na-tankerah-himovozah-razdela-av-1-1-mk-pdnv-78-s-popravkami-modelnogo-kursa-imo-1-37-chemical-tanker-cargo-and-ballast-handling-simulator-perepodgo",
    meta: component_45stubYjueJ23IJZMeta || {},
    alias: component_45stubYjueJ23IJZMeta?.alias || [],
    redirect: component_45stubYjueJ23IJZMeta?.redirect,
    component: component_45stubYjueJ23IJZ
  },
  {
    name: component_45stubYjueJ23IJZMeta?.name ?? undefined,
    path: component_45stubYjueJ23IJZMeta?.path ?? "/product/podgotovka-na-trenazhere-gruzoballastnyh-operacij-na-neftjanyh-tankerah-razdel-a-v-1-1-tablica-a-v-1-1-2-mk-pdnv-s-popravkami-imo-2-06-sire-2-0",
    meta: component_45stubYjueJ23IJZMeta || {},
    alias: component_45stubYjueJ23IJZMeta?.alias || [],
    redirect: component_45stubYjueJ23IJZMeta?.redirect,
    component: component_45stubYjueJ23IJZ
  },
  {
    name: component_45stubYjueJ23IJZMeta?.name ?? undefined,
    path: component_45stubYjueJ23IJZMeta?.path ?? "/product/podgotovka-na-trenazhere-gruzoballastnyh-operacij-na-tankerah-gazovozah-razdel-a-v-1-2-b-i-12-tablica-a-v-1-2-2-mk-pdnv-modelnyj-kurs-imo-1-36",
    meta: component_45stubYjueJ23IJZMeta || {},
    alias: component_45stubYjueJ23IJZMeta?.alias || [],
    redirect: component_45stubYjueJ23IJZMeta?.redirect,
    component: component_45stubYjueJ23IJZ
  },
  {
    name: component_45stubYjueJ23IJZMeta?.name ?? undefined,
    path: component_45stubYjueJ23IJZMeta?.path ?? "/product/podgotovka-na-trenazhere-gruzoballastnyh-operacij-na-tankerah-gazovozah-razdel-a-v-1-2-b-i-12-tablica-a-v-1-2-2-mk-pdnv-modelnyj-kurs-imo-1-36-pri-nalichii-rasshirennoj-podgotovki-po-tankeram-gazovoza",
    meta: component_45stubYjueJ23IJZMeta || {},
    alias: component_45stubYjueJ23IJZMeta?.alias || [],
    redirect: component_45stubYjueJ23IJZMeta?.redirect,
    component: component_45stubYjueJ23IJZ
  },
  {
    name: component_45stubYjueJ23IJZMeta?.name ?? undefined,
    path: component_45stubYjueJ23IJZMeta?.path ?? "/product/podgotovka-na-trenazhere-gruzoballastnyh-operacij-na-tankerah-himovozah-razdel-a-v-1-1-tablica-a-v-1-1-3-mk-pdnv-s-popravkami-imo-1-37-sire-2-0",
    meta: component_45stubYjueJ23IJZMeta || {},
    alias: component_45stubYjueJ23IJZMeta?.alias || [],
    redirect: component_45stubYjueJ23IJZMeta?.redirect,
    component: component_45stubYjueJ23IJZ
  },
  {
    name: component_45stubYjueJ23IJZMeta?.name ?? undefined,
    path: component_45stubYjueJ23IJZMeta?.path ?? "/product/podgotovka-operatora-gmssb-po-programme-dopolnitelnogo-professionalnogo-obrazovanija-v-sootvetstvii-s-trebovanijami-razdela-a-iv-2-kodeksa-pdnv-punkt-2-2-pravila-iv-2-konvencii-pdnv",
    meta: component_45stubYjueJ23IJZMeta || {},
    alias: component_45stubYjueJ23IJZMeta?.alias || [],
    redirect: component_45stubYjueJ23IJZMeta?.redirect,
    component: component_45stubYjueJ23IJZ
  },
  {
    name: component_45stubYjueJ23IJZMeta?.name ?? undefined,
    path: component_45stubYjueJ23IJZMeta?.path ?? "/product/podgotovka-operatora-gmssb-po-programme-dopolnitelnogo-professionalnogo-obrazovanija-v-sootvetstvii-s-trebovanijami-razdela-a-iv-2-kodeksa-pdnv-punkt-2-2-pravila-iv-2-konvencii-pdnv-pri-nalichii-roc",
    meta: component_45stubYjueJ23IJZMeta || {},
    alias: component_45stubYjueJ23IJZMeta?.alias || [],
    redirect: component_45stubYjueJ23IJZMeta?.redirect,
    component: component_45stubYjueJ23IJZ
  },
  {
    name: component_45stubYjueJ23IJZMeta?.name ?? undefined,
    path: component_45stubYjueJ23IJZMeta?.path ?? "/product/podgotovka-operatora-gmssb-pri-dlitelnom-pereryve-v-rabote/:pathMatch(.*)",
    meta: component_45stubYjueJ23IJZMeta || {},
    alias: component_45stubYjueJ23IJZMeta?.alias || [],
    redirect: component_45stubYjueJ23IJZMeta?.redirect,
    component: component_45stubYjueJ23IJZ
  },
  {
    name: component_45stubYjueJ23IJZMeta?.name ?? undefined,
    path: component_45stubYjueJ23IJZMeta?.path ?? "/product/podgotovka-operatora-ogranichennogo-rajona-gmssb-po-programme-dopolnitelnogo-professionalnogo-obrazovanija-v-sootvetstvii-s-trebovanijami-razdela-a-iv-2-kodeksa-pdnv-punkt-2-2-pravila-iv-2-konvencii-p",
    meta: component_45stubYjueJ23IJZMeta || {},
    alias: component_45stubYjueJ23IJZMeta?.alias || [],
    redirect: component_45stubYjueJ23IJZMeta?.redirect,
    component: component_45stubYjueJ23IJZ
  },
  {
    name: component_45stubYjueJ23IJZMeta?.name ?? undefined,
    path: component_45stubYjueJ23IJZMeta?.path ?? "/product/podgotovka-operatora-ogranichennogo-rajona-gmssb-pri-dlitelnom-pereryve-v-rabote",
    meta: component_45stubYjueJ23IJZMeta || {},
    alias: component_45stubYjueJ23IJZMeta?.alias || [],
    redirect: component_45stubYjueJ23IJZMeta?.redirect,
    component: component_45stubYjueJ23IJZ
  },
  {
    name: component_45stubYjueJ23IJZMeta?.name ?? undefined,
    path: component_45stubYjueJ23IJZMeta?.path ?? "/product/podgotovka-personala-po-kontrolju-i-upravleniju-ballastnymi-operacijami-na-morskih-peredvizhnyh-ustanovkah",
    meta: component_45stubYjueJ23IJZMeta || {},
    alias: component_45stubYjueJ23IJZMeta?.alias || [],
    redirect: component_45stubYjueJ23IJZMeta?.redirect,
    component: component_45stubYjueJ23IJZ
  },
  {
    name: component_45stubYjueJ23IJZMeta?.name ?? undefined,
    path: component_45stubYjueJ23IJZMeta?.path ?? "/product/podgotovka-po-ispolzovaniju-dyhatelnyh-apparatov-asv-2-drager-i-analogov",
    meta: component_45stubYjueJ23IJZMeta || {},
    alias: component_45stubYjueJ23IJZMeta?.alias || [],
    redirect: component_45stubYjueJ23IJZMeta?.redirect,
    component: component_45stubYjueJ23IJZ
  },
  {
    name: component_45stubYjueJ23IJZMeta?.name ?? undefined,
    path: component_45stubYjueJ23IJZMeta?.path ?? "/product/podgotovka-po-ispolzovaniju-jelektronnoj-kartograficheskoj-navigacionnoj-informacionnoj-sistemy-jeknis",
    meta: component_45stubYjueJ23IJZMeta || {},
    alias: component_45stubYjueJ23IJZMeta?.alias || [],
    redirect: component_45stubYjueJ23IJZMeta?.redirect,
    component: component_45stubYjueJ23IJZ
  },
  {
    name: component_45stubYjueJ23IJZMeta?.name ?? undefined,
    path: component_45stubYjueJ23IJZMeta?.path ?? "/product/podgotovka-po-ispolzovaniju-jelektronnoj-kartograficheskoj-navigacionnoj-informacionnoj-sistemy-jeknis-pjatiletnjaja-perepodgotovka",
    meta: component_45stubYjueJ23IJZMeta || {},
    alias: component_45stubYjueJ23IJZMeta?.alias || [],
    redirect: component_45stubYjueJ23IJZMeta?.redirect,
    component: component_45stubYjueJ23IJZ
  },
  {
    name: component_45stubYjueJ23IJZMeta?.name ?? undefined,
    path: component_45stubYjueJ23IJZMeta?.path ?? "/product/podgotovka-po-ispolzovaniju-radiolokacionnoj-stancii-rls-dlja-ranee-proshedshih-dannyj-kurs",
    meta: component_45stubYjueJ23IJZMeta || {},
    alias: component_45stubYjueJ23IJZMeta?.alias || [],
    redirect: component_45stubYjueJ23IJZMeta?.redirect,
    component: component_45stubYjueJ23IJZ
  },
  {
    name: component_45stubYjueJ23IJZMeta?.name ?? undefined,
    path: component_45stubYjueJ23IJZMeta?.path ?? "/product/podgotovka-po-ispolzovaniju-radiolokacionnoj-stancii-rls-razdel-a-1-12-razdel-b-l-12-mk-pdnv-78-s-popravkami",
    meta: component_45stubYjueJ23IJZMeta || {},
    alias: component_45stubYjueJ23IJZMeta?.alias || [],
    redirect: component_45stubYjueJ23IJZMeta?.redirect,
    component: component_45stubYjueJ23IJZ
  },
  {
    name: component_45stubYjueJ23IJZMeta?.name ?? undefined,
    path: component_45stubYjueJ23IJZMeta?.path ?? "/product/podgotovka-po-ispolzovaniju-sistemy-avtomaticheskoj-radiolokacionnoj-prokladki-sarp-razdel-a-1-12-razdel-v-i-12",
    meta: component_45stubYjueJ23IJZMeta || {},
    alias: component_45stubYjueJ23IJZMeta?.alias || [],
    redirect: component_45stubYjueJ23IJZMeta?.redirect,
    component: component_45stubYjueJ23IJZ
  },
  {
    name: component_45stubYjueJ23IJZMeta?.name ?? undefined,
    path: component_45stubYjueJ23IJZMeta?.path ?? "/product/podgotovka-po-ispolzovaniju-sistemy-avtomaticheskoj-radiolokacionnoj-prokladki-sarp-razdel-a-1-12-razdel-v-i-12-pjatiletnjaja-perepodgotovka",
    meta: component_45stubYjueJ23IJZMeta || {},
    alias: component_45stubYjueJ23IJZMeta?.alias || [],
    redirect: component_45stubYjueJ23IJZMeta?.redirect,
    component: component_45stubYjueJ23IJZ
  },
  {
    name: component_45stubYjueJ23IJZMeta?.name ?? undefined,
    path: component_45stubYjueJ23IJZMeta?.path ?? "/product/podgotovka-po-ispolzovaniju-sudovyh-radiolokacionnyh-stancij-na-vnutrennih-vodnyh-putjah",
    meta: component_45stubYjueJ23IJZMeta || {},
    alias: component_45stubYjueJ23IJZMeta?.alias || [],
    redirect: component_45stubYjueJ23IJZMeta?.redirect,
    component: component_45stubYjueJ23IJZ
  },
  {
    name: component_45stubYjueJ23IJZMeta?.name ?? undefined,
    path: component_45stubYjueJ23IJZMeta?.path ?? "/product/podgotovka-po-ispolzovaniju-sudovyh-radiolokacionnyh-stancij-na-vnutrennih-vodnyh-putjah-dlja-prohodivshih-ranee-obuchenie",
    meta: component_45stubYjueJ23IJZMeta || {},
    alias: component_45stubYjueJ23IJZMeta?.alias || [],
    redirect: component_45stubYjueJ23IJZMeta?.redirect,
    component: component_45stubYjueJ23IJZ
  },
  {
    name: component_45stubYjueJ23IJZMeta?.name ?? undefined,
    path: component_45stubYjueJ23IJZMeta?.path ?? "/product/podgotovka-po-ispolzovaniju-sudovyh-radiolokacionnyh-stancij-na-vnutrennih-vodnyh-putjah-dlja-prohodivshih-ranee-obuchenie-2",
    meta: component_45stubYjueJ23IJZMeta || {},
    alias: component_45stubYjueJ23IJZMeta?.alias || [],
    redirect: component_45stubYjueJ23IJZMeta?.redirect,
    component: component_45stubYjueJ23IJZ
  },
  {
    name: component_45stubYjueJ23IJZMeta?.name ?? undefined,
    path: component_45stubYjueJ23IJZMeta?.path ?? "/product/podgotovka-po-jekspluatacii-sistem-otobrazhenija-jelektronnyh-navigacionnyh-kart-i-informacii-sojenki-na-vnutrennih-vodnyh-putjah",
    meta: component_45stubYjueJ23IJZMeta || {},
    alias: component_45stubYjueJ23IJZMeta?.alias || [],
    redirect: component_45stubYjueJ23IJZMeta?.redirect,
    component: component_45stubYjueJ23IJZ
  },
  {
    name: component_45stubYjueJ23IJZMeta?.name ?? undefined,
    path: component_45stubYjueJ23IJZMeta?.path ?? "/product/podgotovka-po-jekspluatacii-sistem-otobrazhenija-jelektronnyh-navigacionnyh-kart-i-informacii-sojenki-na-vnutrennih-vodnyh-putjah-dlja-prohodivshih-ranee-obuchenie",
    meta: component_45stubYjueJ23IJZMeta || {},
    alias: component_45stubYjueJ23IJZMeta?.alias || [],
    redirect: component_45stubYjueJ23IJZMeta?.redirect,
    component: component_45stubYjueJ23IJZ
  },
  {
    name: component_45stubYjueJ23IJZMeta?.name ?? undefined,
    path: component_45stubYjueJ23IJZMeta?.path ?? "/product/podgotovka-po-ohrane-dlja-lic-imejushhih-naznachennye-objazannosti",
    meta: component_45stubYjueJ23IJZMeta || {},
    alias: component_45stubYjueJ23IJZMeta?.alias || [],
    redirect: component_45stubYjueJ23IJZMeta?.redirect,
    component: component_45stubYjueJ23IJZ
  },
  {
    name: component_45stubYjueJ23IJZMeta?.name ?? undefined,
    path: component_45stubYjueJ23IJZMeta?.path ?? "/product/podgotovka-po-ohrane-dlja-lic-ne-imejushhih-naznachennye-objazannosti",
    meta: component_45stubYjueJ23IJZMeta || {},
    alias: component_45stubYjueJ23IJZMeta?.alias || [],
    redirect: component_45stubYjueJ23IJZMeta?.redirect,
    component: component_45stubYjueJ23IJZ
  },
  {
    name: component_45stubYjueJ23IJZMeta?.name ?? undefined,
    path: component_45stubYjueJ23IJZMeta?.path ?? "/product/podgotovka-po-ohrane-morskoj-okruzhajushhej-sredy/:pathMatch(.*)",
    meta: component_45stubYjueJ23IJZMeta || {},
    alias: component_45stubYjueJ23IJZMeta?.alias || [],
    redirect: component_45stubYjueJ23IJZMeta?.redirect,
    component: component_45stubYjueJ23IJZ
  },
  {
    name: component_45stubYjueJ23IJZMeta?.name ?? undefined,
    path: component_45stubYjueJ23IJZMeta?.path ?? "/product/podgotovka-po-okazaniju-pervoj-medicinskoj-pomoshhi-pjatiletnjaja-perepodgotovka-razdel-a-vl-4-tablica-a-vl-4-1-mk-pdnv-78-s-popravkami",
    meta: component_45stubYjueJ23IJZMeta || {},
    alias: component_45stubYjueJ23IJZMeta?.alias || [],
    redirect: component_45stubYjueJ23IJZMeta?.redirect,
    component: component_45stubYjueJ23IJZ
  },
  {
    name: component_45stubYjueJ23IJZMeta?.name ?? undefined,
    path: component_45stubYjueJ23IJZMeta?.path ?? "/product/podgotovka-po-okazaniju-pervoj-medicinskoj-pomoshhi-razdel-a-vl-4-tablica-a-vl-4-1-mk-pdnv-78-s-popravkami",
    meta: component_45stubYjueJ23IJZMeta || {},
    alias: component_45stubYjueJ23IJZMeta?.alias || [],
    redirect: component_45stubYjueJ23IJZMeta?.redirect,
    component: component_45stubYjueJ23IJZ
  },
  {
    name: component_45stubYjueJ23IJZMeta?.name ?? undefined,
    path: component_45stubYjueJ23IJZMeta?.path ?? "/product/podgotovka-po-provedeniju-shvartovnyh-rabot/:pathMatch(.*)",
    meta: component_45stubYjueJ23IJZMeta || {},
    alias: component_45stubYjueJ23IJZMeta?.alias || [],
    redirect: component_45stubYjueJ23IJZMeta?.redirect,
    component: component_45stubYjueJ23IJZ
  },
  {
    name: component_45stubYjueJ23IJZMeta?.name ?? undefined,
    path: component_45stubYjueJ23IJZMeta?.path ?? "/product/podgotovka-po-provedeniju-shvartovnyh-rabot-2/:pathMatch(.*)",
    meta: component_45stubYjueJ23IJZMeta || {},
    alias: component_45stubYjueJ23IJZMeta?.alias || [],
    redirect: component_45stubYjueJ23IJZMeta?.redirect,
    component: component_45stubYjueJ23IJZ
  },
  {
    name: component_45stubYjueJ23IJZMeta?.name ?? undefined,
    path: component_45stubYjueJ23IJZMeta?.path ?? "/product/podgotovka-po-rasshirennoj-program-v-otnoshenii-gruzovyh-operacij-na-tankerah-himovozah-pjatiletnjaja-perepodgotovka-razdel-av-1-1-tablica-av-1-1-3-mk-pdnv-78-s-popravkami",
    meta: component_45stubYjueJ23IJZMeta || {},
    alias: component_45stubYjueJ23IJZMeta?.alias || [],
    redirect: component_45stubYjueJ23IJZMeta?.redirect,
    component: component_45stubYjueJ23IJZ
  },
  {
    name: component_45stubYjueJ23IJZMeta?.name ?? undefined,
    path: component_45stubYjueJ23IJZMeta?.path ?? "/product/podgotovka-po-rasshirennoj-programme-v-otnoshenii-gruzovyh-operacij-na-neftjanyh-tankerah-pjatiletnjaja-perepodgotovka-razdel-a-v-1-1-tablica-a-v-1-1-2-mk-pdnv-78-s-popravkami",
    meta: component_45stubYjueJ23IJZMeta || {},
    alias: component_45stubYjueJ23IJZMeta?.alias || [],
    redirect: component_45stubYjueJ23IJZMeta?.redirect,
    component: component_45stubYjueJ23IJZ
  },
  {
    name: component_45stubYjueJ23IJZMeta?.name ?? undefined,
    path: component_45stubYjueJ23IJZMeta?.path ?? "/product/podgotovka-po-rasshirennoj-programme-v-otnoshenii-gruzovyh-operacij-na-neftjanyh-tankerah-razdel-a-v-1-1-tablica-a-v-1-1-2-mk-pdnv-78-s-popravkami",
    meta: component_45stubYjueJ23IJZMeta || {},
    alias: component_45stubYjueJ23IJZMeta?.alias || [],
    redirect: component_45stubYjueJ23IJZMeta?.redirect,
    component: component_45stubYjueJ23IJZ
  },
  {
    name: component_45stubYjueJ23IJZMeta?.name ?? undefined,
    path: component_45stubYjueJ23IJZMeta?.path ?? "/product/podgotovka-po-rasshirennoj-programme-v-otnoshenii-gruzovyh-operacij-na-tankerah-gazovozah-pjatiletnjaja-perepodgotovka-razdel-a-v-1-2-tablica-a-vl1-2-2-mk-pdnv-78-s-popravkami",
    meta: component_45stubYjueJ23IJZMeta || {},
    alias: component_45stubYjueJ23IJZMeta?.alias || [],
    redirect: component_45stubYjueJ23IJZMeta?.redirect,
    component: component_45stubYjueJ23IJZ
  },
  {
    name: component_45stubYjueJ23IJZMeta?.name ?? undefined,
    path: component_45stubYjueJ23IJZMeta?.path ?? "/product/podgotovka-po-rasshirennoj-programme-v-otnoshenii-gruzovyh-operacij-na-tankerah-gazovozah-razdel-a-v-1-2-tablica-a-vl1-2-2-mk-pdnv-78-s-popravkami",
    meta: component_45stubYjueJ23IJZMeta || {},
    alias: component_45stubYjueJ23IJZMeta?.alias || [],
    redirect: component_45stubYjueJ23IJZMeta?.redirect,
    component: component_45stubYjueJ23IJZ
  },
  {
    name: component_45stubYjueJ23IJZMeta?.name ?? undefined,
    path: component_45stubYjueJ23IJZMeta?.path ?? "/product/podgotovka-po-rasshirennoj-programme-v-otnoshenii-gruzovyh-operacij-na-tankerah-himovozah-pjatiletnjaja-perepodgotovka-razdel-a-v-1-1-tablica-a-v-1-1-3-mk-pdnv-78-s-popravkami",
    meta: component_45stubYjueJ23IJZMeta || {},
    alias: component_45stubYjueJ23IJZMeta?.alias || [],
    redirect: component_45stubYjueJ23IJZMeta?.redirect,
    component: component_45stubYjueJ23IJZ
  },
  {
    name: component_45stubYjueJ23IJZMeta?.name ?? undefined,
    path: component_45stubYjueJ23IJZMeta?.path ?? "/product/podgotovka-po-rasshirennoj-programme-v-otnoshenii-gruzovyh-operacij-na-tankerah-himovozah-razdel-a-v-1-1-tablica-a-v-1-1-3-mk-pdnv-78-s-popravkami",
    meta: component_45stubYjueJ23IJZMeta || {},
    alias: component_45stubYjueJ23IJZMeta?.alias || [],
    redirect: component_45stubYjueJ23IJZMeta?.redirect,
    component: component_45stubYjueJ23IJZ
  },
  {
    name: component_45stubYjueJ23IJZMeta?.name ?? undefined,
    path: component_45stubYjueJ23IJZMeta?.path ?? "/product/podgotovka-po-upravleniju-neorganizovannoj-massoj-ljudej-komandnyj-sostav-prikaz-ministerstva-transporta-rf-ot-12-03-2018-g-87-polozhenie-o-diplomirovanii-chlenov-jekipazhej-sudov-vnutrennego-vodnogo",
    meta: component_45stubYjueJ23IJZMeta || {},
    alias: component_45stubYjueJ23IJZMeta?.alias || [],
    redirect: component_45stubYjueJ23IJZMeta?.redirect,
    component: component_45stubYjueJ23IJZ
  },
  {
    name: component_45stubYjueJ23IJZMeta?.name ?? undefined,
    path: component_45stubYjueJ23IJZMeta?.path ?? "/product/podgotovka-po-upravleniju-neorganizovannoj-massoj-ljudej-komandnyj-sostav-prikaz-ministerstva-transporta-rf-ot-12-03-2018-g-87-polozhenie-o-diplomirovanii-chlenov-jekipazheren-sudgoov-vnut-vodnogo",
    meta: component_45stubYjueJ23IJZMeta || {},
    alias: component_45stubYjueJ23IJZMeta?.alias || [],
    redirect: component_45stubYjueJ23IJZMeta?.redirect,
    component: component_45stubYjueJ23IJZ
  },
  {
    name: component_45stubYjueJ23IJZMeta?.name ?? undefined,
    path: component_45stubYjueJ23IJZMeta?.path ?? "/product/podgotovka-po-upravleniju-neorganizovannoj-massoj-ljudej-podgotovka-po-bezopasnosti-dlja-personala-obespechivajushhego-neposredstvennoe-obsluzhivanie-passazhirov-v-passazhirskih-pomeshhenijah-podgot-2",
    meta: component_45stubYjueJ23IJZMeta || {},
    alias: component_45stubYjueJ23IJZMeta?.alias || [],
    redirect: component_45stubYjueJ23IJZMeta?.redirect,
    component: component_45stubYjueJ23IJZ
  },
  {
    name: component_45stubYjueJ23IJZMeta?.name ?? undefined,
    path: component_45stubYjueJ23IJZMeta?.path ?? "/product/podgotovka-po-upravleniju-neorganizovannoj-massoj-ljudej-podgotovka-po-bezopasnosti-dlja-personala-obespechivajushhego-neposredstvennoe-obsluzhivanie-passazhirov-v-passazhirskih-pomeshhenijah-podgotov",
    meta: component_45stubYjueJ23IJZMeta || {},
    alias: component_45stubYjueJ23IJZMeta?.alias || [],
    redirect: component_45stubYjueJ23IJZMeta?.redirect,
    component: component_45stubYjueJ23IJZ
  },
  {
    name: component_45stubYjueJ23IJZMeta?.name ?? undefined,
    path: component_45stubYjueJ23IJZMeta?.path ?? "/product/podgotovka-po-upravleniju-neorganizovannoj-massoj-ljudej-podgotovka-po-bezopasnosti-dlja-personala-obespechivajushhego-neposredstvennoe-obsluzhivanie-passazhirov-v-passazhirskih-pomeshhenijah-rjadovoj",
    meta: component_45stubYjueJ23IJZMeta || {},
    alias: component_45stubYjueJ23IJZMeta?.alias || [],
    redirect: component_45stubYjueJ23IJZMeta?.redirect,
    component: component_45stubYjueJ23IJZ
  },
  {
    name: component_45stubYjueJ23IJZMeta?.name ?? undefined,
    path: component_45stubYjueJ23IJZMeta?.path ?? "/product/podgotovka-sudovogo-mehanika-urovnja-jekspluatacii-pri-dlitelnom-pereryve-v-rabote-po-dolzhnosti",
    meta: component_45stubYjueJ23IJZMeta || {},
    alias: component_45stubYjueJ23IJZMeta?.alias || [],
    redirect: component_45stubYjueJ23IJZMeta?.redirect,
    component: component_45stubYjueJ23IJZ
  },
  {
    name: component_45stubYjueJ23IJZMeta?.name ?? undefined,
    path: component_45stubYjueJ23IJZMeta?.path ?? "/product/podgotovka-sudovogo-mehanika-urovnja-upravlenija-pri-dlitelnom-pereryve-v-rabote-po-dolzhnosti",
    meta: component_45stubYjueJ23IJZMeta || {},
    alias: component_45stubYjueJ23IJZMeta?.alias || [],
    redirect: component_45stubYjueJ23IJZMeta?.redirect,
    component: component_45stubYjueJ23IJZ
  },
  {
    name: component_45stubYjueJ23IJZMeta?.name ?? undefined,
    path: component_45stubYjueJ23IJZMeta?.path ?? "/product/podgotovka-sudovogo-oficera-po-bezopasnosti-glavy-ii-i-iii-kodeksa-pdnv-mkub-osps-modelnyj-kurs-imo-3-11",
    meta: component_45stubYjueJ23IJZMeta || {},
    alias: component_45stubYjueJ23IJZMeta?.alias || [],
    redirect: component_45stubYjueJ23IJZMeta?.redirect,
    component: component_45stubYjueJ23IJZ
  },
  {
    name: component_45stubYjueJ23IJZMeta?.name ?? undefined,
    path: component_45stubYjueJ23IJZMeta?.path ?? "/product/podgotovka-sudovyh-specialistov-po-voprosam-upravlenija-bezopasnostju-i-predotvrashheniem-zagrjaznenija-v-sootvetstvii-s-trebovanijami-mezhdunarodnogo-kodeksa-po-upravleniju-bezopasnostju-mkub",
    meta: component_45stubYjueJ23IJZMeta || {},
    alias: component_45stubYjueJ23IJZMeta?.alias || [],
    redirect: component_45stubYjueJ23IJZMeta?.redirect,
    component: component_45stubYjueJ23IJZ
  },
  {
    name: component_45stubYjueJ23IJZMeta?.name ?? undefined,
    path: component_45stubYjueJ23IJZMeta?.path ?? "/product/podgotovka-sudovyh-specialistov-po-voprosam-upravlenija-bezopasnostju-i-predotvrashheniem-zagrjaznenija-v-sootvetstvii-s-trebovanijami-mezhdunarodnogo-kodeksa-po-upravleniju-bezopasnostju-mkub-pjatile",
    meta: component_45stubYjueJ23IJZMeta || {},
    alias: component_45stubYjueJ23IJZMeta?.alias || [],
    redirect: component_45stubYjueJ23IJZMeta?.redirect,
    component: component_45stubYjueJ23IJZ
  },
  {
    name: component_45stubYjueJ23IJZMeta?.name ?? undefined,
    path: component_45stubYjueJ23IJZMeta?.path ?? "/product/port-facility-security-officer-training-chapter-xi-2-mk-solas-isps-code-imo-circular-1-1188",
    meta: component_45stubYjueJ23IJZMeta || {},
    alias: component_45stubYjueJ23IJZMeta?.alias || [],
    redirect: component_45stubYjueJ23IJZMeta?.redirect,
    component: component_45stubYjueJ23IJZ
  },
  {
    name: component_45stubYjueJ23IJZMeta?.name ?? undefined,
    path: component_45stubYjueJ23IJZMeta?.path ?? "/product/povyshenie-kvalifikacii-rabotnikov-naznachennyh-v-kachestve-lic-otvetstvennyh-za-osushhestvlenie-sjurvejerskoj-dejatelnosti-pri-gruzovyh-operacijah-s-nalivnymi-gruzami",
    meta: component_45stubYjueJ23IJZMeta || {},
    alias: component_45stubYjueJ23IJZMeta?.alias || [],
    redirect: component_45stubYjueJ23IJZMeta?.redirect,
    component: component_45stubYjueJ23IJZ
  },
  {
    name: component_45stubYjueJ23IJZMeta?.name ?? undefined,
    path: component_45stubYjueJ23IJZMeta?.path ?? "/product/povyshenie-kvalifikacii-rabotnikov-naznachennyh-v-kachestve-lic-otvetstvennyh-za-osushhestvlenie-sjurvejerskoj-dejatelnosti-pri-gruzovyh-operacijah-s-navalochnymi-gruzami",
    meta: component_45stubYjueJ23IJZMeta || {},
    alias: component_45stubYjueJ23IJZMeta?.alias || [],
    redirect: component_45stubYjueJ23IJZMeta?.redirect,
    component: component_45stubYjueJ23IJZ
  },
  {
    name: component_45stubYjueJ23IJZMeta?.name ?? undefined,
    path: component_45stubYjueJ23IJZMeta?.path ?? "/product/povyshenija-kvalifikacii-lic-otvetstvennyh-za-osushhestvlenie-pogruzochno-razgruzochnoj-dejatelnosti-primenitelno-k-opasnym-gruzam",
    meta: component_45stubYjueJ23IJZMeta || {},
    alias: component_45stubYjueJ23IJZMeta?.alias || [],
    redirect: component_45stubYjueJ23IJZMeta?.redirect,
    component: component_45stubYjueJ23IJZ
  },
  {
    name: component_45stubYjueJ23IJZMeta?.name ?? undefined,
    path: component_45stubYjueJ23IJZMeta?.path ?? "/product/praktika-borby-s-pozharom-na-tankerah-vspomogatelnyj-uroven",
    meta: component_45stubYjueJ23IJZMeta || {},
    alias: component_45stubYjueJ23IJZMeta?.alias || [],
    redirect: component_45stubYjueJ23IJZMeta?.redirect,
    component: component_45stubYjueJ23IJZ
  },
  {
    name: component_45stubYjueJ23IJZMeta?.name ?? undefined,
    path: component_45stubYjueJ23IJZMeta?.path ?? "/product/pravila-osushhestvlenija-kontrolja-bezopasnosti-vozdushnoj-sredy-authorised-gas-tester",
    meta: component_45stubYjueJ23IJZMeta || {},
    alias: component_45stubYjueJ23IJZMeta?.alias || [],
    redirect: component_45stubYjueJ23IJZMeta?.redirect,
    component: component_45stubYjueJ23IJZ
  },
  {
    name: component_45stubYjueJ23IJZMeta?.name ?? undefined,
    path: component_45stubYjueJ23IJZMeta?.path ?? "/product/pravila-polzovanija-izolirujushhimi-dyhatelnymi-apparatami/:pathMatch(.*)",
    meta: component_45stubYjueJ23IJZMeta || {},
    alias: component_45stubYjueJ23IJZMeta?.alias || [],
    redirect: component_45stubYjueJ23IJZMeta?.redirect,
    component: component_45stubYjueJ23IJZ
  },
  {
    name: component_45stubYjueJ23IJZMeta?.name ?? undefined,
    path: component_45stubYjueJ23IJZMeta?.path ?? "/product/pravila-polzovanija-priborami-gazovogo-kontrolja/:pathMatch(.*)",
    meta: component_45stubYjueJ23IJZMeta || {},
    alias: component_45stubYjueJ23IJZMeta?.alias || [],
    redirect: component_45stubYjueJ23IJZMeta?.redirect,
    component: component_45stubYjueJ23IJZ
  },
  {
    name: component_45stubYjueJ23IJZMeta?.name ?? undefined,
    path: component_45stubYjueJ23IJZMeta?.path ?? "/product/predotvrashhenie-zagrjaznenija-s-sudov-marpol-ora-90/:pathMatch(.*)",
    meta: component_45stubYjueJ23IJZMeta || {},
    alias: component_45stubYjueJ23IJZMeta?.alias || [],
    redirect: component_45stubYjueJ23IJZMeta?.redirect,
    component: component_45stubYjueJ23IJZ
  },
  {
    name: component_45stubYjueJ23IJZMeta?.name ?? undefined,
    path: component_45stubYjueJ23IJZMeta?.path ?? "/product/preparation-for-mooring-and-anchoring-operations/:pathMatch(.*)",
    meta: component_45stubYjueJ23IJZMeta || {},
    alias: component_45stubYjueJ23IJZMeta?.alias || [],
    redirect: component_45stubYjueJ23IJZMeta?.redirect,
    component: component_45stubYjueJ23IJZ
  },
  {
    name: component_45stubYjueJ23IJZMeta?.name ?? undefined,
    path: component_45stubYjueJ23IJZMeta?.path ?? "/product/preparation-for-work-on-a-passenger-ship-rank-and-file/:pathMatch(.*)",
    meta: component_45stubYjueJ23IJZMeta || {},
    alias: component_45stubYjueJ23IJZMeta?.alias || [],
    redirect: component_45stubYjueJ23IJZMeta?.redirect,
    component: component_45stubYjueJ23IJZ
  },
  {
    name: component_45stubYjueJ23IJZMeta?.name ?? undefined,
    path: component_45stubYjueJ23IJZMeta?.path ?? "/product/preparation-for-work-on-oil-tankers-and-management-of-oil-operations-command-personnel-order-of-the-ministry-of-transport-of-the-russian-federation-of-march-12-2018-no-87-regulations-on-the-certifica",
    meta: component_45stubYjueJ23IJZMeta || {},
    alias: component_45stubYjueJ23IJZMeta?.alias || [],
    redirect: component_45stubYjueJ23IJZMeta?.redirect,
    component: component_45stubYjueJ23IJZ
  },
  {
    name: component_45stubYjueJ23IJZMeta?.name ?? undefined,
    path: component_45stubYjueJ23IJZMeta?.path ?? "/product/preparation-for-work-on-oil-tankers-enlisted-personnel-order-of-the-ministry-of-transport-of-the-russian-federation-of-12-03-2018-no-87-regulations-on-the-certification-of-crew-members-of-inland-wate",
    meta: component_45stubYjueJ23IJZMeta || {},
    alias: component_45stubYjueJ23IJZMeta?.alias || [],
    redirect: component_45stubYjueJ23IJZMeta?.redirect,
    component: component_45stubYjueJ23IJZ
  },
  {
    name: component_45stubYjueJ23IJZMeta?.name ?? undefined,
    path: component_45stubYjueJ23IJZMeta?.path ?? "/product/preparing-for-firefighting-according-to-the-expanded-program-section-a-vi-3-table-a-vi-3-mk-stcw-five-year-retraining",
    meta: component_45stubYjueJ23IJZMeta || {},
    alias: component_45stubYjueJ23IJZMeta?.alias || [],
    redirect: component_45stubYjueJ23IJZMeta?.redirect,
    component: component_45stubYjueJ23IJZ
  },
  {
    name: component_45stubYjueJ23IJZMeta?.name ?? undefined,
    path: component_45stubYjueJ23IJZMeta?.path ?? "/product/preparing-to-fight-a-fire-according-to-an-expanded-program-section-a-vi-3-table-a-vi-3-mk-stcw",
    meta: component_45stubYjueJ23IJZMeta || {},
    alias: component_45stubYjueJ23IJZMeta?.alias || [],
    redirect: component_45stubYjueJ23IJZMeta?.redirect,
    component: component_45stubYjueJ23IJZ
  },
  {
    name: component_45stubYjueJ23IJZMeta?.name ?? undefined,
    path: component_45stubYjueJ23IJZMeta?.path ?? "/product/principy-i-pravila-okazanija-pervoj-pomoshhi-postradavshim/:pathMatch(.*)",
    meta: component_45stubYjueJ23IJZMeta || {},
    alias: component_45stubYjueJ23IJZMeta?.alias || [],
    redirect: component_45stubYjueJ23IJZMeta?.redirect,
    component: component_45stubYjueJ23IJZ
  },
  {
    name: component_45stubYjueJ23IJZMeta?.name ?? undefined,
    path: component_45stubYjueJ23IJZMeta?.path ?? "/product/professional-development-of-employees-appointed-as-persons-responsible-for-carrying-out-survey-activities-in-cargo-operations-with-bulk-cargo",
    meta: component_45stubYjueJ23IJZMeta || {},
    alias: component_45stubYjueJ23IJZMeta?.alias || [],
    redirect: component_45stubYjueJ23IJZMeta?.redirect,
    component: component_45stubYjueJ23IJZ
  },
  {
    name: component_45stubYjueJ23IJZMeta?.name ?? undefined,
    path: component_45stubYjueJ23IJZMeta?.path ?? "/product/professional-development-of-employees-appointed-as-persons-responsible-for-carrying-out-survey-activities-in-cargo-operations-with-liquid-bulk-cargo",
    meta: component_45stubYjueJ23IJZMeta || {},
    alias: component_45stubYjueJ23IJZMeta?.alias || [],
    redirect: component_45stubYjueJ23IJZMeta?.redirect,
    component: component_45stubYjueJ23IJZ
  },
  {
    name: component_45stubYjueJ23IJZMeta?.name ?? undefined,
    path: component_45stubYjueJ23IJZMeta?.path ?? "/product/programma-dpo-v-sootvetstvii-s-trebovanijami-razdela-a-iii-2-kodeksa-pdnv-dlja-starshego-mehanika-morskogo-sudna-s-glavnoj-dvigatelnoj-ustanovkoj-3000-kvt-i-bolee-punkt-2-2-pravila-iii-2-konvencii-p-2",
    meta: component_45stubYjueJ23IJZMeta || {},
    alias: component_45stubYjueJ23IJZMeta?.alias || [],
    redirect: component_45stubYjueJ23IJZMeta?.redirect,
    component: component_45stubYjueJ23IJZ
  },
  {
    name: component_45stubYjueJ23IJZMeta?.name ?? undefined,
    path: component_45stubYjueJ23IJZMeta?.path ?? "/product/programma-dpo-v-sootvetstvii-s-trebovanijami-razdela-a-iii-2-kodeksa-pdnv-dlja-starshego-mehanika-morskogo-sudna-s-glavnoj-dvigatelnoj-ustanovkoj-3000-kvt-i-bolee-punkt-2-2-pravila-iii-2-konvencii-p-3",
    meta: component_45stubYjueJ23IJZMeta || {},
    alias: component_45stubYjueJ23IJZMeta?.alias || [],
    redirect: component_45stubYjueJ23IJZMeta?.redirect,
    component: component_45stubYjueJ23IJZ
  },
  {
    name: component_45stubYjueJ23IJZMeta?.name ?? undefined,
    path: component_45stubYjueJ23IJZMeta?.path ?? "/product/programma-dpo-v-sootvetstvii-s-trebovanijami-razdela-a-iii-2-kodeksa-pdnv-dlja-starshego-mehanika-morskogo-sudna-s-glavnoj-dvigatelnoj-ustanovkoj-3000-kvt-i-bolee-punkt-2-2-pravila-iii-2-konvencii-pdn",
    meta: component_45stubYjueJ23IJZMeta || {},
    alias: component_45stubYjueJ23IJZMeta?.alias || [],
    redirect: component_45stubYjueJ23IJZMeta?.redirect,
    component: component_45stubYjueJ23IJZ
  },
  {
    name: component_45stubYjueJ23IJZMeta?.name ?? undefined,
    path: component_45stubYjueJ23IJZMeta?.path ?? "/product/programma-dpo-v-sootvetstvii-s-trebovanijami-razdela-a-iii-2-kodeksa-pdnv-dlja-vtorogo-mehanika-morskogo-sudna-s-glavnoj-dvigatelnoj-ustanovkoj-3000-kvt-i-bolee-punkt-2-2-pravila-iii-2-konvencii-pdn-2",
    meta: component_45stubYjueJ23IJZMeta || {},
    alias: component_45stubYjueJ23IJZMeta?.alias || [],
    redirect: component_45stubYjueJ23IJZMeta?.redirect,
    component: component_45stubYjueJ23IJZ
  },
  {
    name: component_45stubYjueJ23IJZMeta?.name ?? undefined,
    path: component_45stubYjueJ23IJZMeta?.path ?? "/product/programma-dpo-v-sootvetstvii-s-trebovanijami-razdela-a-iii-2-kodeksa-pdnv-dlja-vtorogo-mehanika-morskogo-sudna-s-glavnoj-dvigatelnoj-ustanovkoj-3000-kvt-i-bolee-punkt-2-2-pravila-iii-2-konvencii-pdn-3",
    meta: component_45stubYjueJ23IJZMeta || {},
    alias: component_45stubYjueJ23IJZMeta?.alias || [],
    redirect: component_45stubYjueJ23IJZMeta?.redirect,
    component: component_45stubYjueJ23IJZ
  },
  {
    name: component_45stubYjueJ23IJZMeta?.name ?? undefined,
    path: component_45stubYjueJ23IJZMeta?.path ?? "/product/programma-dpo-v-sootvetstvii-s-trebovanijami-razdela-a-iii-2-kodeksa-pdnv-dlja-vtorogo-mehanika-morskogo-sudna-s-glavnoj-dvigatelnoj-ustanovkoj-3000-kvt-i-bolee-punkt-2-2-pravila-iii-2-konvencii-pdnv",
    meta: component_45stubYjueJ23IJZMeta || {},
    alias: component_45stubYjueJ23IJZMeta?.alias || [],
    redirect: component_45stubYjueJ23IJZMeta?.redirect,
    component: component_45stubYjueJ23IJZ
  },
  {
    name: component_45stubYjueJ23IJZMeta?.name ?? undefined,
    path: component_45stubYjueJ23IJZMeta?.path ?? "/product/programma-dpo-v-sootvetstvii-s-trebovanijami-razdela-a-iii-3-kodeksa-pdnv-dlja-starshego-mehanika-morskogo-sudna-s-glavnoj-dvigatelnoj-ustanovkoj-ot-750-do-3000-kvt-punkt-2-2-pravila-iii-3-konvencii-2",
    meta: component_45stubYjueJ23IJZMeta || {},
    alias: component_45stubYjueJ23IJZMeta?.alias || [],
    redirect: component_45stubYjueJ23IJZMeta?.redirect,
    component: component_45stubYjueJ23IJZ
  },
  {
    name: component_45stubYjueJ23IJZMeta?.name ?? undefined,
    path: component_45stubYjueJ23IJZMeta?.path ?? "/product/programma-dpo-v-sootvetstvii-s-trebovanijami-razdela-a-iii-3-kodeksa-pdnv-dlja-starshego-mehanika-morskogo-sudna-s-glavnoj-dvigatelnoj-ustanovkoj-ot-750-do-3000-kvt-punkt-2-2-pravila-iii-3-konvencii-3",
    meta: component_45stubYjueJ23IJZMeta || {},
    alias: component_45stubYjueJ23IJZMeta?.alias || [],
    redirect: component_45stubYjueJ23IJZMeta?.redirect,
    component: component_45stubYjueJ23IJZ
  },
  {
    name: component_45stubYjueJ23IJZMeta?.name ?? undefined,
    path: component_45stubYjueJ23IJZMeta?.path ?? "/product/programma-dpo-v-sootvetstvii-s-trebovanijami-razdela-a-iii-3-kodeksa-pdnv-dlja-starshego-mehanika-morskogo-sudna-s-glavnoj-dvigatelnoj-ustanovkoj-ot-750-do-3000-kvt-punkt-2-2-pravila-iii-3-konvencii-p",
    meta: component_45stubYjueJ23IJZMeta || {},
    alias: component_45stubYjueJ23IJZMeta?.alias || [],
    redirect: component_45stubYjueJ23IJZMeta?.redirect,
    component: component_45stubYjueJ23IJZ
  },
  {
    name: component_45stubYjueJ23IJZMeta?.name ?? undefined,
    path: component_45stubYjueJ23IJZMeta?.path ?? "/product/programma-dpo-v-sootvetstvii-s-trebovanijami-razdela-a-iii-3-kodeksa-pdnv-dlja-vtorogo-mehanika-morskogo-sudna-s-glavnoj-dvigatelnoj-ustanovkoj-ot-750-do-3000-kvt-punkt-2-2-pravila-iii-3-konvencii-p-2",
    meta: component_45stubYjueJ23IJZMeta || {},
    alias: component_45stubYjueJ23IJZMeta?.alias || [],
    redirect: component_45stubYjueJ23IJZMeta?.redirect,
    component: component_45stubYjueJ23IJZ
  },
  {
    name: component_45stubYjueJ23IJZMeta?.name ?? undefined,
    path: component_45stubYjueJ23IJZMeta?.path ?? "/product/programma-dpo-v-sootvetstvii-s-trebovanijami-razdela-a-iii-3-kodeksa-pdnv-dlja-vtorogo-mehanika-morskogo-sudna-s-glavnoj-dvigatelnoj-ustanovkoj-ot-750-do-3000-kvt-punkt-2-2-pravila-iii-3-konvencii-pdn",
    meta: component_45stubYjueJ23IJZMeta || {},
    alias: component_45stubYjueJ23IJZMeta?.alias || [],
    redirect: component_45stubYjueJ23IJZMeta?.redirect,
    component: component_45stubYjueJ23IJZ
  },
  {
    name: component_45stubYjueJ23IJZMeta?.name ?? undefined,
    path: component_45stubYjueJ23IJZMeta?.path ?? "/product/programma-povyshenie-kvalifikacii-rabotnikov-subekta-transportnoj-infrastruktury-podrazdelenija-transportnoj-bezopasnosti-rukovodjashhih-vypolneniem-rabot-neposredstvenno-svjazannyh-s-obespecheniem-tr",
    meta: component_45stubYjueJ23IJZMeta || {},
    alias: component_45stubYjueJ23IJZMeta?.alias || [],
    redirect: component_45stubYjueJ23IJZMeta?.redirect,
    component: component_45stubYjueJ23IJZ
  },
  {
    name: component_45stubYjueJ23IJZMeta?.name ?? undefined,
    path: component_45stubYjueJ23IJZMeta?.path ?? "/product/programma-povyshenie-kvalifikacii-rabotnikov-upravljajushhih-tehnicheskimi-sredstvami-obespechenija-transportnoj-bezopasnosti",
    meta: component_45stubYjueJ23IJZMeta || {},
    alias: component_45stubYjueJ23IJZMeta?.alias || [],
    redirect: component_45stubYjueJ23IJZMeta?.redirect,
    component: component_45stubYjueJ23IJZ
  },
  {
    name: component_45stubYjueJ23IJZMeta?.name ?? undefined,
    path: component_45stubYjueJ23IJZMeta?.path ?? "/product/programma-povyshenija-kvalifikacii-inyh-rabotnikov-subekta-transportnoj-infrastruktury-podrazdelenija-transportnoj-bezopasnosti-vypolnjajushhih-raboty-neposredstvenno-svjazannye-s-obespecheniem-transp",
    meta: component_45stubYjueJ23IJZMeta || {},
    alias: component_45stubYjueJ23IJZMeta?.alias || [],
    redirect: component_45stubYjueJ23IJZMeta?.redirect,
    component: component_45stubYjueJ23IJZ
  },
  {
    name: component_45stubYjueJ23IJZMeta?.name ?? undefined,
    path: component_45stubYjueJ23IJZMeta?.path ?? "/product/programma-povyshenija-kvalifikacii-po-dolzhnosti-jelektromehanika-morskogo-sudna-s-glavnoj-dvigatelnoj-ustanovkoj-bolee-750-kvt",
    meta: component_45stubYjueJ23IJZMeta || {},
    alias: component_45stubYjueJ23IJZMeta?.alias || [],
    redirect: component_45stubYjueJ23IJZMeta?.redirect,
    component: component_45stubYjueJ23IJZ
  },
  {
    name: component_45stubYjueJ23IJZMeta?.name ?? undefined,
    path: component_45stubYjueJ23IJZMeta?.path ?? "/product/programma-povyshenija-kvalifikacii-po-dolzhnosti-jelektromehanika-morskogo-sudna-s-glavnoj-dvigatelnoj-ustanovkoj-bolee-750-kvt-2",
    meta: component_45stubYjueJ23IJZMeta || {},
    alias: component_45stubYjueJ23IJZMeta?.alias || [],
    redirect: component_45stubYjueJ23IJZMeta?.redirect,
    component: component_45stubYjueJ23IJZ
  },
  {
    name: component_45stubYjueJ23IJZMeta?.name ?? undefined,
    path: component_45stubYjueJ23IJZMeta?.path ?? "/product/programma-povyshenija-kvalifikacii-po-dolzhnosti-kapitana-morskogo-sudna-valovoj-vmestimostju-3000-i-bolee",
    meta: component_45stubYjueJ23IJZMeta || {},
    alias: component_45stubYjueJ23IJZMeta?.alias || [],
    redirect: component_45stubYjueJ23IJZMeta?.redirect,
    component: component_45stubYjueJ23IJZ
  },
  {
    name: component_45stubYjueJ23IJZMeta?.name ?? undefined,
    path: component_45stubYjueJ23IJZMeta?.path ?? "/product/programma-povyshenija-kvalifikacii-po-dolzhnosti-kapitana-morskogo-sudna-valovoj-vmestimostju-3000-i-bolee-2",
    meta: component_45stubYjueJ23IJZMeta || {},
    alias: component_45stubYjueJ23IJZMeta?.alias || [],
    redirect: component_45stubYjueJ23IJZMeta?.redirect,
    component: component_45stubYjueJ23IJZ
  },
  {
    name: component_45stubYjueJ23IJZMeta?.name ?? undefined,
    path: component_45stubYjueJ23IJZMeta?.path ?? "/product/programma-povyshenija-kvalifikacii-po-dolzhnosti-kapitana-morskogo-sudna-valovoj-vmestimostju-ot-500-do-3000",
    meta: component_45stubYjueJ23IJZMeta || {},
    alias: component_45stubYjueJ23IJZMeta?.alias || [],
    redirect: component_45stubYjueJ23IJZMeta?.redirect,
    component: component_45stubYjueJ23IJZ
  },
  {
    name: component_45stubYjueJ23IJZMeta?.name ?? undefined,
    path: component_45stubYjueJ23IJZMeta?.path ?? "/product/programma-povyshenija-kvalifikacii-po-dolzhnosti-kapitana-morskogo-sudna-valovoj-vmestimostju-ot-500-do-3000-2",
    meta: component_45stubYjueJ23IJZMeta || {},
    alias: component_45stubYjueJ23IJZMeta?.alias || [],
    redirect: component_45stubYjueJ23IJZMeta?.redirect,
    component: component_45stubYjueJ23IJZ
  },
  {
    name: component_45stubYjueJ23IJZMeta?.name ?? undefined,
    path: component_45stubYjueJ23IJZMeta?.path ?? "/product/programma-povyshenija-kvalifikacii-po-dolzhnosti-operatora-gmssb",
    meta: component_45stubYjueJ23IJZMeta || {},
    alias: component_45stubYjueJ23IJZMeta?.alias || [],
    redirect: component_45stubYjueJ23IJZMeta?.redirect,
    component: component_45stubYjueJ23IJZ
  },
  {
    name: component_45stubYjueJ23IJZMeta?.name ?? undefined,
    path: component_45stubYjueJ23IJZMeta?.path ?? "/product/programma-povyshenija-kvalifikacii-po-dolzhnosti-operatora-ogranichennogo-rajona-gmssb",
    meta: component_45stubYjueJ23IJZMeta || {},
    alias: component_45stubYjueJ23IJZMeta?.alias || [],
    redirect: component_45stubYjueJ23IJZMeta?.redirect,
    component: component_45stubYjueJ23IJZ
  },
  {
    name: component_45stubYjueJ23IJZMeta?.name ?? undefined,
    path: component_45stubYjueJ23IJZMeta?.path ?? "/product/programma-povyshenija-kvalifikacii-po-dolzhnosti-starshego-mehanika-morskogo-sudna-s-glavnoj-dvigatelnoj-ustanovkoj-3000-kvt-i-bolee",
    meta: component_45stubYjueJ23IJZMeta || {},
    alias: component_45stubYjueJ23IJZMeta?.alias || [],
    redirect: component_45stubYjueJ23IJZMeta?.redirect,
    component: component_45stubYjueJ23IJZ
  },
  {
    name: component_45stubYjueJ23IJZMeta?.name ?? undefined,
    path: component_45stubYjueJ23IJZMeta?.path ?? "/product/programma-povyshenija-kvalifikacii-po-dolzhnosti-starshego-mehanika-morskogo-sudna-s-glavnoj-dvigatelnoj-ustanovkoj-3000-kvt-i-bolee-2",
    meta: component_45stubYjueJ23IJZMeta || {},
    alias: component_45stubYjueJ23IJZMeta?.alias || [],
    redirect: component_45stubYjueJ23IJZMeta?.redirect,
    component: component_45stubYjueJ23IJZ
  },
  {
    name: component_45stubYjueJ23IJZMeta?.name ?? undefined,
    path: component_45stubYjueJ23IJZMeta?.path ?? "/product/programma-povyshenija-kvalifikacii-po-dolzhnosti-starshego-mehanika-morskogo-sudna-s-glavnoj-dvigatelnoj-ustanovkoj-ot-750-do-3000-kvt",
    meta: component_45stubYjueJ23IJZMeta || {},
    alias: component_45stubYjueJ23IJZMeta?.alias || [],
    redirect: component_45stubYjueJ23IJZMeta?.redirect,
    component: component_45stubYjueJ23IJZ
  },
  {
    name: component_45stubYjueJ23IJZMeta?.name ?? undefined,
    path: component_45stubYjueJ23IJZMeta?.path ?? "/product/programma-povyshenija-kvalifikacii-po-dolzhnosti-starshego-mehanika-morskogo-sudna-s-glavnoj-dvigatelnoj-ustanovkoj-ot-750-do-3000-kvt-2",
    meta: component_45stubYjueJ23IJZMeta || {},
    alias: component_45stubYjueJ23IJZMeta?.alias || [],
    redirect: component_45stubYjueJ23IJZMeta?.redirect,
    component: component_45stubYjueJ23IJZ
  },
  {
    name: component_45stubYjueJ23IJZMeta?.name ?? undefined,
    path: component_45stubYjueJ23IJZMeta?.path ?? "/product/programma-povyshenija-kvalifikacii-po-dolzhnosti-starshego-pomoshhnika-kapitana-morskogo-sudna-valovoj-vmestimostju-3000-i-bolee",
    meta: component_45stubYjueJ23IJZMeta || {},
    alias: component_45stubYjueJ23IJZMeta?.alias || [],
    redirect: component_45stubYjueJ23IJZMeta?.redirect,
    component: component_45stubYjueJ23IJZ
  },
  {
    name: component_45stubYjueJ23IJZMeta?.name ?? undefined,
    path: component_45stubYjueJ23IJZMeta?.path ?? "/product/programma-povyshenija-kvalifikacii-po-dolzhnosti-starshego-pomoshhnika-kapitana-morskogo-sudna-valovoj-vmestimostju-3000-i-bolee-2",
    meta: component_45stubYjueJ23IJZMeta || {},
    alias: component_45stubYjueJ23IJZMeta?.alias || [],
    redirect: component_45stubYjueJ23IJZMeta?.redirect,
    component: component_45stubYjueJ23IJZ
  },
  {
    name: component_45stubYjueJ23IJZMeta?.name ?? undefined,
    path: component_45stubYjueJ23IJZMeta?.path ?? "/product/programma-povyshenija-kvalifikacii-po-dolzhnosti-starshego-pomoshhnika-kapitana-morskogo-sudna-valovoj-vmestimostju-ot-500-do-3000",
    meta: component_45stubYjueJ23IJZMeta || {},
    alias: component_45stubYjueJ23IJZMeta?.alias || [],
    redirect: component_45stubYjueJ23IJZMeta?.redirect,
    component: component_45stubYjueJ23IJZ
  },
  {
    name: component_45stubYjueJ23IJZMeta?.name ?? undefined,
    path: component_45stubYjueJ23IJZMeta?.path ?? "/product/programma-povyshenija-kvalifikacii-po-dolzhnosti-starshego-pomoshhnika-kapitana-morskogo-sudna-valovoj-vmestimostju-ot-500-do-3000-2",
    meta: component_45stubYjueJ23IJZMeta || {},
    alias: component_45stubYjueJ23IJZMeta?.alias || [],
    redirect: component_45stubYjueJ23IJZMeta?.redirect,
    component: component_45stubYjueJ23IJZ
  },
  {
    name: component_45stubYjueJ23IJZMeta?.name ?? undefined,
    path: component_45stubYjueJ23IJZMeta?.path ?? "/product/programma-povyshenija-kvalifikacii-po-dolzhnosti-vahtennogo-mehanika-morskogo-sudna-s-glavnoj-dvigatelnoj-ustanovkoj-moshhnostju-750-kvt-i-bolee",
    meta: component_45stubYjueJ23IJZMeta || {},
    alias: component_45stubYjueJ23IJZMeta?.alias || [],
    redirect: component_45stubYjueJ23IJZMeta?.redirect,
    component: component_45stubYjueJ23IJZ
  },
  {
    name: component_45stubYjueJ23IJZMeta?.name ?? undefined,
    path: component_45stubYjueJ23IJZMeta?.path ?? "/product/programma-povyshenija-kvalifikacii-po-dolzhnosti-vahtennogo-mehanika-morskogo-sudna-s-glavnoj-dvigatelnoj-ustanovkoj-moshhnostju-750-kvt-i-bolee-2",
    meta: component_45stubYjueJ23IJZMeta || {},
    alias: component_45stubYjueJ23IJZMeta?.alias || [],
    redirect: component_45stubYjueJ23IJZMeta?.redirect,
    component: component_45stubYjueJ23IJZ
  },
  {
    name: component_45stubYjueJ23IJZMeta?.name ?? undefined,
    path: component_45stubYjueJ23IJZMeta?.path ?? "/product/programma-povyshenija-kvalifikacii-po-dolzhnosti-vtorogo-mehanika-morskogo-sudna-s-glavnoj-dvigatelnoj-ustanovkoj-3000-kvt-i-bolee",
    meta: component_45stubYjueJ23IJZMeta || {},
    alias: component_45stubYjueJ23IJZMeta?.alias || [],
    redirect: component_45stubYjueJ23IJZMeta?.redirect,
    component: component_45stubYjueJ23IJZ
  },
  {
    name: component_45stubYjueJ23IJZMeta?.name ?? undefined,
    path: component_45stubYjueJ23IJZMeta?.path ?? "/product/programma-povyshenija-kvalifikacii-po-dolzhnosti-vtorogo-mehanika-morskogo-sudna-s-glavnoj-dvigatelnoj-ustanovkoj-3000-kvt-i-bolee-2",
    meta: component_45stubYjueJ23IJZMeta || {},
    alias: component_45stubYjueJ23IJZMeta?.alias || [],
    redirect: component_45stubYjueJ23IJZMeta?.redirect,
    component: component_45stubYjueJ23IJZ
  },
  {
    name: component_45stubYjueJ23IJZMeta?.name ?? undefined,
    path: component_45stubYjueJ23IJZMeta?.path ?? "/product/programma-povyshenija-kvalifikacii-po-dolzhnosti-vtorogo-mehanika-morskogo-sudna-s-glavnoj-dvigatelnoj-ustanovkoj-ot-750-do-3000-kvt",
    meta: component_45stubYjueJ23IJZMeta || {},
    alias: component_45stubYjueJ23IJZMeta?.alias || [],
    redirect: component_45stubYjueJ23IJZMeta?.redirect,
    component: component_45stubYjueJ23IJZ
  },
  {
    name: component_45stubYjueJ23IJZMeta?.name ?? undefined,
    path: component_45stubYjueJ23IJZMeta?.path ?? "/product/programma-povyshenija-kvalifikacii-po-dolzhnosti-vtorogo-mehanika-morskogo-sudna-s-glavnoj-dvigatelnoj-ustanovkoj-ot-750-do-3000-kvt-2",
    meta: component_45stubYjueJ23IJZMeta || {},
    alias: component_45stubYjueJ23IJZMeta?.alias || [],
    redirect: component_45stubYjueJ23IJZMeta?.redirect,
    component: component_45stubYjueJ23IJZ
  },
  {
    name: component_45stubYjueJ23IJZMeta?.name ?? undefined,
    path: component_45stubYjueJ23IJZMeta?.path ?? "/product/programma-povyshenija-kvalifikacii-po-voprosam-bezopasnosti-passazhirov-i-gruza-a-takzhe-vodonepronicaemosti-korpusa",
    meta: component_45stubYjueJ23IJZMeta || {},
    alias: component_45stubYjueJ23IJZMeta?.alias || [],
    redirect: component_45stubYjueJ23IJZMeta?.redirect,
    component: component_45stubYjueJ23IJZ
  },
  {
    name: component_45stubYjueJ23IJZMeta?.name ?? undefined,
    path: component_45stubYjueJ23IJZMeta?.path ?? "/product/programma-povyshenija-kvalifikacii-rabotnikov-naznachennyh-v-kachestve-lic-otvetstvennyh-za-obespechenie-transportnoj-bezopasnosti-na-obekte-transportnoj-infrastruktury-i-ili-transportnom-sredstve-fz",
    meta: component_45stubYjueJ23IJZMeta || {},
    alias: component_45stubYjueJ23IJZMeta?.alias || [],
    redirect: component_45stubYjueJ23IJZMeta?.redirect,
    component: component_45stubYjueJ23IJZ
  },
  {
    name: component_45stubYjueJ23IJZMeta?.name ?? undefined,
    path: component_45stubYjueJ23IJZMeta?.path ?? "/product/programma-povyshenija-kvalifikacii-rabotnikov-naznachennyh-v-kachestve-lic-otvetstvennyh-za-obespechenie-transportnoj-bezopasnosti-v-subekte-transportnoj-infrastruktury-fz-16-ot-09-02-2007-g-o-transp",
    meta: component_45stubYjueJ23IJZMeta || {},
    alias: component_45stubYjueJ23IJZMeta?.alias || [],
    redirect: component_45stubYjueJ23IJZMeta?.redirect,
    component: component_45stubYjueJ23IJZ
  },
  {
    name: component_45stubYjueJ23IJZMeta?.name ?? undefined,
    path: component_45stubYjueJ23IJZMeta?.path ?? "/product/programma-povyshenija-kvalifikacii-rabotnikov-osushhestvljajushhih-dosmotr-dopolnitelnyj-dosmotr-povtornyj-dosmotr-v-celjah-obespechenija-transportnoj-bezopasnosti",
    meta: component_45stubYjueJ23IJZMeta || {},
    alias: component_45stubYjueJ23IJZMeta?.alias || [],
    redirect: component_45stubYjueJ23IJZMeta?.redirect,
    component: component_45stubYjueJ23IJZ
  },
  {
    name: component_45stubYjueJ23IJZMeta?.name ?? undefined,
    path: component_45stubYjueJ23IJZMeta?.path ?? "/product/programma-povyshenija-kvalifikacii-rabotnikov-osushhestvljajushhih-nabljudenie-i-ili-sobesedovanie-v-celjah-obespechenija-transportnoj-bezopasnosti",
    meta: component_45stubYjueJ23IJZMeta || {},
    alias: component_45stubYjueJ23IJZMeta?.alias || [],
    redirect: component_45stubYjueJ23IJZMeta?.redirect,
    component: component_45stubYjueJ23IJZ
  },
  {
    name: component_45stubYjueJ23IJZMeta?.name ?? undefined,
    path: component_45stubYjueJ23IJZMeta?.path ?? "/product/programma-povyshenija-kvalifikacii-rabotnikov-vkljuchennyh-v-sostav-gruppy-bystrogo-reagirovanija",
    meta: component_45stubYjueJ23IJZMeta || {},
    alias: component_45stubYjueJ23IJZMeta?.alias || [],
    redirect: component_45stubYjueJ23IJZMeta?.redirect,
    component: component_45stubYjueJ23IJZ
  },
  {
    name: component_45stubYjueJ23IJZMeta?.name ?? undefined,
    path: component_45stubYjueJ23IJZMeta?.path ?? "/product/programma-povyshenija-kvalifikacii-v-otnoshenii-upravlenija-i-povedenija-cheloveka-v-kriticheskih-situacijah",
    meta: component_45stubYjueJ23IJZMeta || {},
    alias: component_45stubYjueJ23IJZMeta?.alias || [],
    redirect: component_45stubYjueJ23IJZMeta?.redirect,
    component: component_45stubYjueJ23IJZ
  },
  {
    name: component_45stubYjueJ23IJZMeta?.name ?? undefined,
    path: component_45stubYjueJ23IJZMeta?.path ?? "/product/programma-povyshenija-kvalifikacii-v-otnoshenii-upravlenija-i-povedenija-cheloveka-v-kriticheskih-situacijah-2",
    meta: component_45stubYjueJ23IJZMeta || {},
    alias: component_45stubYjueJ23IJZMeta?.alias || [],
    redirect: component_45stubYjueJ23IJZMeta?.redirect,
    component: component_45stubYjueJ23IJZ
  },
  {
    name: component_45stubYjueJ23IJZMeta?.name ?? undefined,
    path: component_45stubYjueJ23IJZMeta?.path ?? "/product/programma-professionalnogo-obuchenija-dlja-sudovoditelja-malomernogo-sudna-ispolzuemogo-v-kommercheskih-celjah",
    meta: component_45stubYjueJ23IJZMeta || {},
    alias: component_45stubYjueJ23IJZMeta?.alias || [],
    redirect: component_45stubYjueJ23IJZMeta?.redirect,
    component: component_45stubYjueJ23IJZ
  },
  {
    name: component_45stubYjueJ23IJZMeta?.name ?? undefined,
    path: component_45stubYjueJ23IJZMeta?.path ?? "/product/programma-professionalnogo-obuchenija-dlja-sudovoditelja-progulochnogo-sudna",
    meta: component_45stubYjueJ23IJZMeta || {},
    alias: component_45stubYjueJ23IJZMeta?.alias || [],
    redirect: component_45stubYjueJ23IJZMeta?.redirect,
    component: component_45stubYjueJ23IJZ
  },
  {
    name: component_45stubYjueJ23IJZMeta?.name ?? undefined,
    path: component_45stubYjueJ23IJZMeta?.path ?? "/product/programma-professionalnoj-podgotovki-po-professii-26527-socialnyj-rabotnik",
    meta: component_45stubYjueJ23IJZMeta || {},
    alias: component_45stubYjueJ23IJZMeta?.alias || [],
    redirect: component_45stubYjueJ23IJZMeta?.redirect,
    component: component_45stubYjueJ23IJZ
  },
  {
    name: component_45stubYjueJ23IJZMeta?.name ?? undefined,
    path: component_45stubYjueJ23IJZMeta?.path ?? "/product/programmnyj-kompleks-ocenki-znanij-pkoz-mehanik/:pathMatch(.*)",
    meta: component_45stubYjueJ23IJZMeta || {},
    alias: component_45stubYjueJ23IJZMeta?.alias || [],
    redirect: component_45stubYjueJ23IJZMeta?.redirect,
    component: component_45stubYjueJ23IJZ
  },
  {
    name: component_45stubYjueJ23IJZMeta?.name ?? undefined,
    path: component_45stubYjueJ23IJZMeta?.path ?? "/product/programmnyj-kompleks-ocenki-znanij-pkoz-rjadovoj-sostav/:pathMatch(.*)",
    meta: component_45stubYjueJ23IJZMeta || {},
    alias: component_45stubYjueJ23IJZMeta?.alias || [],
    redirect: component_45stubYjueJ23IJZMeta?.redirect,
    component: component_45stubYjueJ23IJZ
  },
  {
    name: component_45stubYjueJ23IJZMeta?.name ?? undefined,
    path: component_45stubYjueJ23IJZMeta?.path ?? "/product/programmnyj-kompleks-ocenki-znanij-pkoz-sudovoditel/:pathMatch(.*)",
    meta: component_45stubYjueJ23IJZMeta || {},
    alias: component_45stubYjueJ23IJZMeta?.alias || [],
    redirect: component_45stubYjueJ23IJZMeta?.redirect,
    component: component_45stubYjueJ23IJZ
  },
  {
    name: component_45stubYjueJ23IJZMeta?.name ?? undefined,
    path: component_45stubYjueJ23IJZMeta?.path ?? "/product/protivopozharnaja-podgotovka-chlenov-jekipazhej-sudov-vnutrennego-plavanija",
    meta: component_45stubYjueJ23IJZMeta || {},
    alias: component_45stubYjueJ23IJZMeta?.alias || [],
    redirect: component_45stubYjueJ23IJZMeta?.redirect,
    component: component_45stubYjueJ23IJZ
  },
  {
    name: component_45stubYjueJ23IJZMeta?.name ?? undefined,
    path: component_45stubYjueJ23IJZMeta?.path ?? "/product/protivopozharnaja-podgotovka-chlenov-jekipazhej-sudov-vnutrennego-plavanija-osushhestvljajushhih-perevozki-vzryvo-pozharoopasnyh-gruzov-komandnyj-sostav",
    meta: component_45stubYjueJ23IJZMeta || {},
    alias: component_45stubYjueJ23IJZMeta?.alias || [],
    redirect: component_45stubYjueJ23IJZMeta?.redirect,
    component: component_45stubYjueJ23IJZ
  },
  {
    name: component_45stubYjueJ23IJZMeta?.name ?? undefined,
    path: component_45stubYjueJ23IJZMeta?.path ?? "/product/protivopozharnaja-podgotovka-chlenov-jekipazhej-sudov-vnutrennego-plavanija-osushhestvljajushhih-perevozki-vzryvo-pozharoopasnyh-gruzov-rjadovoj-sostav",
    meta: component_45stubYjueJ23IJZMeta || {},
    alias: component_45stubYjueJ23IJZMeta?.alias || [],
    redirect: component_45stubYjueJ23IJZMeta?.redirect,
    component: component_45stubYjueJ23IJZ
  },
  {
    name: component_45stubYjueJ23IJZMeta?.name ?? undefined,
    path: component_45stubYjueJ23IJZMeta?.path ?? "/product/provedenie-shvartovyh-operacij/:pathMatch(.*)",
    meta: component_45stubYjueJ23IJZMeta || {},
    alias: component_45stubYjueJ23IJZMeta?.alias || [],
    redirect: component_45stubYjueJ23IJZMeta?.redirect,
    component: component_45stubYjueJ23IJZ
  },
  {
    name: component_45stubYjueJ23IJZMeta?.name ?? undefined,
    path: component_45stubYjueJ23IJZMeta?.path ?? "/product/psihometricheskoe-testirovanie/:pathMatch(.*)",
    meta: component_45stubYjueJ23IJZMeta || {},
    alias: component_45stubYjueJ23IJZMeta?.alias || [],
    redirect: component_45stubYjueJ23IJZMeta?.redirect,
    component: component_45stubYjueJ23IJZ
  },
  {
    name: component_45stubYjueJ23IJZMeta?.name ?? undefined,
    path: component_45stubYjueJ23IJZMeta?.path ?? "/product/qualified-minder/:pathMatch(.*)",
    meta: component_45stubYjueJ23IJZMeta || {},
    alias: component_45stubYjueJ23IJZMeta?.alias || [],
    redirect: component_45stubYjueJ23IJZMeta?.redirect,
    component: component_45stubYjueJ23IJZ
  },
  {
    name: component_45stubYjueJ23IJZMeta?.name ?? undefined,
    path: component_45stubYjueJ23IJZMeta?.path ?? "/product/rabota-na-vysote-uroven-1/:pathMatch(.*)",
    meta: component_45stubYjueJ23IJZMeta || {},
    alias: component_45stubYjueJ23IJZMeta?.alias || [],
    redirect: component_45stubYjueJ23IJZMeta?.redirect,
    component: component_45stubYjueJ23IJZ
  },
  {
    name: component_45stubYjueJ23IJZMeta?.name ?? undefined,
    path: component_45stubYjueJ23IJZMeta?.path ?? "/product/rabota-na-vysote-uroven-2/:pathMatch(.*)",
    meta: component_45stubYjueJ23IJZMeta || {},
    alias: component_45stubYjueJ23IJZMeta?.alias || [],
    redirect: component_45stubYjueJ23IJZMeta?.redirect,
    component: component_45stubYjueJ23IJZ
  },
  {
    name: component_45stubYjueJ23IJZMeta?.name ?? undefined,
    path: component_45stubYjueJ23IJZMeta?.path ?? "/product/rabota-operatora-radiotelefonista-na-vnutrennih-vodnyh-putjah",
    meta: component_45stubYjueJ23IJZMeta || {},
    alias: component_45stubYjueJ23IJZMeta?.alias || [],
    redirect: component_45stubYjueJ23IJZMeta?.redirect,
    component: component_45stubYjueJ23IJZ
  },
  {
    name: component_45stubYjueJ23IJZMeta?.name ?? undefined,
    path: component_45stubYjueJ23IJZMeta?.path ?? "/product/rabotniki-organizacij-primenjajushhie-v-svoej-professionalnoj-dejatelnosti-izolirujushhie-dyhatelnye-apparaty-v-kachestve-sredstv-individualnoj-zashhity-organov-dyhanija",
    meta: component_45stubYjueJ23IJZMeta || {},
    alias: component_45stubYjueJ23IJZMeta?.alias || [],
    redirect: component_45stubYjueJ23IJZMeta?.redirect,
    component: component_45stubYjueJ23IJZ
  },
  {
    name: component_45stubYjueJ23IJZMeta?.name ?? undefined,
    path: component_45stubYjueJ23IJZMeta?.path ?? "/product/radar-training/:pathMatch(.*)",
    meta: component_45stubYjueJ23IJZMeta || {},
    alias: component_45stubYjueJ23IJZMeta?.alias || [],
    redirect: component_45stubYjueJ23IJZMeta?.redirect,
    component: component_45stubYjueJ23IJZ
  },
  {
    name: component_45stubYjueJ23IJZMeta?.name ?? undefined,
    path: component_45stubYjueJ23IJZMeta?.path ?? "/product/rasshirennaja-podgotovka-po-kursu-h2s/:pathMatch(.*)",
    meta: component_45stubYjueJ23IJZMeta || {},
    alias: component_45stubYjueJ23IJZMeta?.alias || [],
    redirect: component_45stubYjueJ23IJZMeta?.redirect,
    component: component_45stubYjueJ23IJZ
  },
  {
    name: component_45stubYjueJ23IJZMeta?.name ?? undefined,
    path: component_45stubYjueJ23IJZMeta?.path ?? "/product/rasshirennaja-podgotovka-po-kursu-h2s-2/:pathMatch(.*)",
    meta: component_45stubYjueJ23IJZMeta || {},
    alias: component_45stubYjueJ23IJZMeta?.alias || [],
    redirect: component_45stubYjueJ23IJZMeta?.redirect,
    component: component_45stubYjueJ23IJZ
  },
  {
    name: component_45stubYjueJ23IJZMeta?.name ?? undefined,
    path: component_45stubYjueJ23IJZMeta?.path ?? "/product/rasshirennaja-podgotovka-po-kursu-h2s-pjatiletnjaja-perepodgotovka",
    meta: component_45stubYjueJ23IJZMeta || {},
    alias: component_45stubYjueJ23IJZMeta?.alias || [],
    redirect: component_45stubYjueJ23IJZMeta?.redirect,
    component: component_45stubYjueJ23IJZ
  },
  {
    name: component_45stubYjueJ23IJZMeta?.name ?? undefined,
    path: component_45stubYjueJ23IJZMeta?.path ?? "/product/regulations-for-the-use-of-self-contained-breathing-apparatus",
    meta: component_45stubYjueJ23IJZMeta || {},
    alias: component_45stubYjueJ23IJZMeta?.alias || [],
    redirect: component_45stubYjueJ23IJZMeta?.redirect,
    component: component_45stubYjueJ23IJZ
  },
  {
    name: component_45stubYjueJ23IJZMeta?.name ?? undefined,
    path: component_45stubYjueJ23IJZMeta?.path ?? "/product/requirements-for-tank-containers-transportation-of-dangerous-goods-in-them",
    meta: component_45stubYjueJ23IJZMeta || {},
    alias: component_45stubYjueJ23IJZMeta?.alias || [],
    redirect: component_45stubYjueJ23IJZMeta?.redirect,
    component: component_45stubYjueJ23IJZ
  },
  {
    name: component_45stubYjueJ23IJZMeta?.name ?? undefined,
    path: component_45stubYjueJ23IJZMeta?.path ?? "/product/risk-management-and-incident-investigation-tables-a-ii-1-a-ii-2-a-iii-1-a-iii-2-a-iii-6-of-the-stcw-code-imo-resolution-a-849-20-model-course-imo-3-11-2014-edition-iii-08-01",
    meta: component_45stubYjueJ23IJZMeta || {},
    alias: component_45stubYjueJ23IJZMeta?.alias || [],
    redirect: component_45stubYjueJ23IJZMeta?.redirect,
    component: component_45stubYjueJ23IJZ
  },
  {
    name: component_45stubYjueJ23IJZMeta?.name ?? undefined,
    path: component_45stubYjueJ23IJZMeta?.path ?? "/product/rukovoditel-morskoj-peredvizhnoj-ustanovki-offshore-instalation-manager-mou",
    meta: component_45stubYjueJ23IJZMeta || {},
    alias: component_45stubYjueJ23IJZMeta?.alias || [],
    redirect: component_45stubYjueJ23IJZMeta?.redirect,
    component: component_45stubYjueJ23IJZ
  },
  {
    name: component_45stubYjueJ23IJZMeta?.name ?? undefined,
    path: component_45stubYjueJ23IJZMeta?.path ?? "/product/rukovoditel-otvetstvennyj-za-organizaciju-i-vypolnenie-rabot-v-zamknutom-prostranstve-rezoljucija-imo-a-1050-27",
    meta: component_45stubYjueJ23IJZMeta || {},
    alias: component_45stubYjueJ23IJZMeta?.alias || [],
    redirect: component_45stubYjueJ23IJZMeta?.redirect,
    component: component_45stubYjueJ23IJZ
  },
  {
    name: component_45stubYjueJ23IJZMeta?.name ?? undefined,
    path: component_45stubYjueJ23IJZMeta?.path ?? "/product/rules-for-the-use-of-gas-control-devices/:pathMatch(.*)",
    meta: component_45stubYjueJ23IJZMeta || {},
    alias: component_45stubYjueJ23IJZMeta?.alias || [],
    redirect: component_45stubYjueJ23IJZMeta?.redirect,
    component: component_45stubYjueJ23IJZ
  },
  {
    name: component_45stubYjueJ23IJZMeta?.name ?? undefined,
    path: component_45stubYjueJ23IJZMeta?.path ?? "/product/rulevoj-dlja-raboty-na-vvp/:pathMatch(.*)",
    meta: component_45stubYjueJ23IJZMeta || {},
    alias: component_45stubYjueJ23IJZMeta?.alias || [],
    redirect: component_45stubYjueJ23IJZMeta?.redirect,
    component: component_45stubYjueJ23IJZ
  },
  {
    name: component_45stubYjueJ23IJZMeta?.name ?? undefined,
    path: component_45stubYjueJ23IJZMeta?.path ?? "/product/security-training-for-non-assigned-duties/:pathMatch(.*)",
    meta: component_45stubYjueJ23IJZMeta || {},
    alias: component_45stubYjueJ23IJZMeta?.alias || [],
    redirect: component_45stubYjueJ23IJZMeta?.redirect,
    component: component_45stubYjueJ23IJZ
  },
  {
    name: component_45stubYjueJ23IJZMeta?.name ?? undefined,
    path: component_45stubYjueJ23IJZMeta?.path ?? "/product/security-training-for-those-with-assigned-responsibilities/:pathMatch(.*)",
    meta: component_45stubYjueJ23IJZMeta || {},
    alias: component_45stubYjueJ23IJZMeta?.alias || [],
    redirect: component_45stubYjueJ23IJZMeta?.redirect,
    component: component_45stubYjueJ23IJZ
  },
  {
    name: component_45stubYjueJ23IJZMeta?.name ?? undefined,
    path: component_45stubYjueJ23IJZMeta?.path ?? "/product/sistema-kontrolja-parov-na-bortu-sudna-pravila-a-v-1-mk-pdmnv-78-s-popravkami",
    meta: component_45stubYjueJ23IJZMeta || {},
    alias: component_45stubYjueJ23IJZMeta?.alias || [],
    redirect: component_45stubYjueJ23IJZMeta?.redirect,
    component: component_45stubYjueJ23IJZ
  },
  {
    name: component_45stubYjueJ23IJZMeta?.name ?? undefined,
    path: component_45stubYjueJ23IJZMeta?.path ?? "/product/special-training-in-the-use-of-ecdis-equipment-ecdis-type-navcom-voyager-sb-23-1",
    meta: component_45stubYjueJ23IJZMeta || {},
    alias: component_45stubYjueJ23IJZMeta?.alias || [],
    redirect: component_45stubYjueJ23IJZMeta?.redirect,
    component: component_45stubYjueJ23IJZ
  },
  {
    name: component_45stubYjueJ23IJZMeta?.name ?? undefined,
    path: component_45stubYjueJ23IJZMeta?.path ?? "/product/special-training-in-the-use-of-ecdis-equipment-transas-navi-sailor-270",
    meta: component_45stubYjueJ23IJZMeta || {},
    alias: component_45stubYjueJ23IJZMeta?.alias || [],
    redirect: component_45stubYjueJ23IJZMeta?.redirect,
    component: component_45stubYjueJ23IJZ
  },
  {
    name: component_45stubYjueJ23IJZMeta?.name ?? undefined,
    path: component_45stubYjueJ23IJZMeta?.path ?? "/product/special-training-in-the-use-of-ecdis-equipment-transas-navi-sailor-270-2",
    meta: component_45stubYjueJ23IJZMeta || {},
    alias: component_45stubYjueJ23IJZMeta?.alias || [],
    redirect: component_45stubYjueJ23IJZMeta?.redirect,
    component: component_45stubYjueJ23IJZ
  },
  {
    name: component_45stubYjueJ23IJZMeta?.name ?? undefined,
    path: component_45stubYjueJ23IJZMeta?.path ?? "/product/specialist-in-high-speed-rescue-boats-section-a-vi-2-table-a-vi-2-2-mk-stcw-78-as-amended",
    meta: component_45stubYjueJ23IJZMeta || {},
    alias: component_45stubYjueJ23IJZMeta?.alias || [],
    redirect: component_45stubYjueJ23IJZMeta?.redirect,
    component: component_45stubYjueJ23IJZ
  },
  {
    name: component_45stubYjueJ23IJZMeta?.name ?? undefined,
    path: component_45stubYjueJ23IJZMeta?.path ?? "/product/specialist-in-lifeboats-and-rafts-and-rescue-boats-other-than-fast-rescue-boats-five-year-refresher-training-section-a-vi-2-table-a-vi-2-1-of-the-stcw-code-as-amended",
    meta: component_45stubYjueJ23IJZMeta || {},
    alias: component_45stubYjueJ23IJZMeta?.alias || [],
    redirect: component_45stubYjueJ23IJZMeta?.redirect,
    component: component_45stubYjueJ23IJZ
  },
  {
    name: component_45stubYjueJ23IJZMeta?.name ?? undefined,
    path: component_45stubYjueJ23IJZMeta?.path ?? "/product/specialist-in-lifeboats-and-rafts-and-rescue-boats-other-than-fast-rescue-boats-section-a-vi-2-table-a-vi-2-1-of-the-stcw-code-as-amended",
    meta: component_45stubYjueJ23IJZMeta || {},
    alias: component_45stubYjueJ23IJZMeta?.alias || [],
    redirect: component_45stubYjueJ23IJZMeta?.redirect,
    component: component_45stubYjueJ23IJZ
  },
  {
    name: component_45stubYjueJ23IJZMeta?.name ?? undefined,
    path: component_45stubYjueJ23IJZMeta?.path ?? "/product/specialist-po-likvidacii-avarijnyh-razlivov-nefti-i-nefteproduktov-bazovyj-kurs-1-uroven",
    meta: component_45stubYjueJ23IJZMeta || {},
    alias: component_45stubYjueJ23IJZMeta?.alias || [],
    redirect: component_45stubYjueJ23IJZMeta?.redirect,
    component: component_45stubYjueJ23IJZ
  },
  {
    name: component_45stubYjueJ23IJZMeta?.name ?? undefined,
    path: component_45stubYjueJ23IJZMeta?.path ?? "/product/specialist-po-likvidacii-avarijnyh-razlivov-nefti-i-nefteproduktov-bazovyj-kurs-uroven-2",
    meta: component_45stubYjueJ23IJZMeta || {},
    alias: component_45stubYjueJ23IJZMeta?.alias || [],
    redirect: component_45stubYjueJ23IJZMeta?.redirect,
    component: component_45stubYjueJ23IJZ
  },
  {
    name: component_45stubYjueJ23IJZMeta?.name ?? undefined,
    path: component_45stubYjueJ23IJZMeta?.path ?? "/product/specialist-po-skorostnym-dezhurnym-shljupkam-razdel-a-vi-2-tablica-a-vi-2-2-mk-pdnv-78-s-popravkami",
    meta: component_45stubYjueJ23IJZMeta || {},
    alias: component_45stubYjueJ23IJZMeta?.alias || [],
    redirect: component_45stubYjueJ23IJZMeta?.redirect,
    component: component_45stubYjueJ23IJZ
  },
  {
    name: component_45stubYjueJ23IJZMeta?.name ?? undefined,
    path: component_45stubYjueJ23IJZMeta?.path ?? "/product/specialist-po-spasatelnym-shljupkam-i-plotam-i-dezhurnym-shljupkam-ne-javljajushhimisja-skorostnymi-dezhurnymi-shljupkami-pjatiletnjaja-perepodgotovka-razdel-a-vi-2-tablica-a-vi-2-1-kodeksa-pdnv-s-pop",
    meta: component_45stubYjueJ23IJZMeta || {},
    alias: component_45stubYjueJ23IJZMeta?.alias || [],
    redirect: component_45stubYjueJ23IJZMeta?.redirect,
    component: component_45stubYjueJ23IJZ
  },
  {
    name: component_45stubYjueJ23IJZMeta?.name ?? undefined,
    path: component_45stubYjueJ23IJZMeta?.path ?? "/product/specialist-po-spasatelnym-shljupkam-i-plotam-i-dezhurnym-shljupkam-ne-javljajushhimisja-skorostnymi-dezhurnymi-shljupkami-razdel-a-vi-2-tablica-a-vi-2-1-kodeksa-pdnv-s-popravkami",
    meta: component_45stubYjueJ23IJZMeta || {},
    alias: component_45stubYjueJ23IJZMeta?.alias || [],
    redirect: component_45stubYjueJ23IJZMeta?.redirect,
    component: component_45stubYjueJ23IJZ
  },
  {
    name: component_45stubYjueJ23IJZMeta?.name ?? undefined,
    path: component_45stubYjueJ23IJZMeta?.path ?? "/product/specialist-po-spasatelnym-shljupkam-i-plotam-i-dezhurnym-shljupkam-ne-javljajushhimisja-skorostnymi-dezhurnymi-shljupkami-razdel-a-vi-2-tablica-a-vi-2-1-kodeksa-pdnv-s-popravkami-2",
    meta: component_45stubYjueJ23IJZMeta || {},
    alias: component_45stubYjueJ23IJZMeta?.alias || [],
    redirect: component_45stubYjueJ23IJZMeta?.redirect,
    component: component_45stubYjueJ23IJZ
  },
  {
    name: component_45stubYjueJ23IJZMeta?.name ?? undefined,
    path: component_45stubYjueJ23IJZMeta?.path ?? "/product/specialnaja-podgotovka-po-ispolzovaniju-oborudovanija-jeknis-jeknis-tipa-navcom-voyager-sb-23-1",
    meta: component_45stubYjueJ23IJZMeta || {},
    alias: component_45stubYjueJ23IJZMeta?.alias || [],
    redirect: component_45stubYjueJ23IJZMeta?.redirect,
    component: component_45stubYjueJ23IJZ
  },
  {
    name: component_45stubYjueJ23IJZMeta?.name ?? undefined,
    path: component_45stubYjueJ23IJZMeta?.path ?? "/product/specialnaja-podgotovka-po-ispolzovaniju-oborudovanija-jeknis-jeknis-tipa-navcom-voyager-sb-23-1-type-specific-ecdis-training",
    meta: component_45stubYjueJ23IJZMeta || {},
    alias: component_45stubYjueJ23IJZMeta?.alias || [],
    redirect: component_45stubYjueJ23IJZMeta?.redirect,
    component: component_45stubYjueJ23IJZ
  },
  {
    name: component_45stubYjueJ23IJZMeta?.name ?? undefined,
    path: component_45stubYjueJ23IJZMeta?.path ?? "/product/specialnaja-podgotovka-po-ispolzovaniju-oborudovanija-jeknis-jeknis-tipa-navcom-voyager-sb-23-1-type-specific-ecdis-training-2",
    meta: component_45stubYjueJ23IJZMeta || {},
    alias: component_45stubYjueJ23IJZMeta?.alias || [],
    redirect: component_45stubYjueJ23IJZMeta?.redirect,
    component: component_45stubYjueJ23IJZ
  },
  {
    name: component_45stubYjueJ23IJZMeta?.name ?? undefined,
    path: component_45stubYjueJ23IJZMeta?.path ?? "/product/specialnaja-podgotovka-po-ispolzovaniju-oborudovanija-jeknis-transas-navi-sailor-270",
    meta: component_45stubYjueJ23IJZMeta || {},
    alias: component_45stubYjueJ23IJZMeta?.alias || [],
    redirect: component_45stubYjueJ23IJZMeta?.redirect,
    component: component_45stubYjueJ23IJZ
  },
  {
    name: component_45stubYjueJ23IJZMeta?.name ?? undefined,
    path: component_45stubYjueJ23IJZMeta?.path ?? "/product/specialnaja-podgotovka-po-ispolzovaniju-oborudovanija-jeknis-transas-navi-sailor-270-2",
    meta: component_45stubYjueJ23IJZMeta || {},
    alias: component_45stubYjueJ23IJZMeta?.alias || [],
    redirect: component_45stubYjueJ23IJZMeta?.redirect,
    component: component_45stubYjueJ23IJZ
  },
  {
    name: component_45stubYjueJ23IJZMeta?.name ?? undefined,
    path: component_45stubYjueJ23IJZMeta?.path ?? "/product/sudovozhdenie-na-malomernyh-sudah-rajon-plavanija-morskie-puti",
    meta: component_45stubYjueJ23IJZMeta || {},
    alias: component_45stubYjueJ23IJZMeta?.alias || [],
    redirect: component_45stubYjueJ23IJZMeta?.redirect,
    component: component_45stubYjueJ23IJZ
  },
  {
    name: component_45stubYjueJ23IJZMeta?.name ?? undefined,
    path: component_45stubYjueJ23IJZMeta?.path ?? "/product/sudovozhdenie-na-malomernyh-sudah-rajon-plavanija-vnutrennie-vodnye-puti",
    meta: component_45stubYjueJ23IJZMeta || {},
    alias: component_45stubYjueJ23IJZMeta?.alias || [],
    redirect: component_45stubYjueJ23IJZMeta?.redirect,
    component: component_45stubYjueJ23IJZ
  },
  {
    name: component_45stubYjueJ23IJZMeta?.name ?? undefined,
    path: component_45stubYjueJ23IJZMeta?.path ?? "/product/sudovozhdenie-na-malomernyh-sudah-rajon-plavanija-vnutrennie-vodnye-puti-voditel-gidrocikla-rajony-plavanija-vnutrennie-vodnye-puti-i-morskie-puti-pri-nalichii-prav-na-kater-motolodku-rajon-plavanija",
    meta: component_45stubYjueJ23IJZMeta || {},
    alias: component_45stubYjueJ23IJZMeta?.alias || [],
    redirect: component_45stubYjueJ23IJZMeta?.redirect,
    component: component_45stubYjueJ23IJZ
  },
  {
    name: component_45stubYjueJ23IJZMeta?.name ?? undefined,
    path: component_45stubYjueJ23IJZMeta?.path ?? "/product/sudovozhdenie-na-malomernyh-sudah-rajony-plavanija-vnutrennie-vodnye-puti-i-morskie-puti",
    meta: component_45stubYjueJ23IJZMeta || {},
    alias: component_45stubYjueJ23IJZMeta?.alias || [],
    redirect: component_45stubYjueJ23IJZMeta?.redirect,
    component: component_45stubYjueJ23IJZ
  },
  {
    name: component_45stubYjueJ23IJZMeta?.name ?? undefined,
    path: component_45stubYjueJ23IJZMeta?.path ?? "/product/sudovozhdenie-na-malomernyh-sudah-rajony-plavanija-vnutrennie-vodnye-puti-i-morskie-puti-pri-nalichii-prav-po-rajonu-plavanija-vvp",
    meta: component_45stubYjueJ23IJZMeta || {},
    alias: component_45stubYjueJ23IJZMeta?.alias || [],
    redirect: component_45stubYjueJ23IJZMeta?.redirect,
    component: component_45stubYjueJ23IJZ
  },
  {
    name: component_45stubYjueJ23IJZMeta?.name ?? undefined,
    path: component_45stubYjueJ23IJZMeta?.path ?? "/product/sudovozhdenie-na-malomernyh-sudah-rajony-plavanija-vnutrennie-vodnye-puti-i-morskie-puti-pri-nalichii-prav-po-rajonu-plavanija-vvp-2",
    meta: component_45stubYjueJ23IJZMeta || {},
    alias: component_45stubYjueJ23IJZMeta?.alias || [],
    redirect: component_45stubYjueJ23IJZMeta?.redirect,
    component: component_45stubYjueJ23IJZ
  },
  {
    name: component_45stubYjueJ23IJZMeta?.name ?? undefined,
    path: component_45stubYjueJ23IJZMeta?.path ?? "/product/sudovozhdenie-na-malomernyh-sudah-rajony-plavanija-vnutrennie-vodnye-puti-i-morskie-puti-voditel-gidrocikla-rajony-plavanija-vnutrennie-vodnye-puti-i-morskie-puti",
    meta: component_45stubYjueJ23IJZMeta || {},
    alias: component_45stubYjueJ23IJZMeta?.alias || [],
    redirect: component_45stubYjueJ23IJZMeta?.redirect,
    component: component_45stubYjueJ23IJZ
  },
  {
    name: component_45stubYjueJ23IJZMeta?.name ?? undefined,
    path: component_45stubYjueJ23IJZMeta?.path ?? "/product/sudovozhdenie-na-malomernyh-sudah-rajony-plavanija-vnutrennie-vodnye-puti-i-morskie-puti-voditel-gidrocikla-rajony-plavanija-vnutrennie-vodnye-puti-i-morskie-puti-pri-nalichii-prav-na-kater-motolodku",
    meta: component_45stubYjueJ23IJZMeta || {},
    alias: component_45stubYjueJ23IJZMeta?.alias || [],
    redirect: component_45stubYjueJ23IJZMeta?.redirect,
    component: component_45stubYjueJ23IJZ
  },
  {
    name: component_45stubYjueJ23IJZMeta?.name ?? undefined,
    path: component_45stubYjueJ23IJZMeta?.path ?? "/product/supervajzer-morskoj-peredvizhnoj-ustanovki-mpu/:pathMatch(.*)",
    meta: component_45stubYjueJ23IJZMeta || {},
    alias: component_45stubYjueJ23IJZMeta?.alias || [],
    redirect: component_45stubYjueJ23IJZMeta?.redirect,
    component: component_45stubYjueJ23IJZ
  },
  {
    name: component_45stubYjueJ23IJZMeta?.name ?? undefined,
    path: component_45stubYjueJ23IJZMeta?.path ?? "/product/tanker-fire-fighting-practice-sub-level/:pathMatch(.*)",
    meta: component_45stubYjueJ23IJZMeta || {},
    alias: component_45stubYjueJ23IJZMeta?.alias || [],
    redirect: component_45stubYjueJ23IJZMeta?.redirect,
    component: component_45stubYjueJ23IJZ
  },
  {
    name: component_45stubYjueJ23IJZMeta?.name ?? undefined,
    path: component_45stubYjueJ23IJZMeta?.path ?? "/product/testirovanie-marlins/:pathMatch(.*)",
    meta: component_45stubYjueJ23IJZMeta || {},
    alias: component_45stubYjueJ23IJZMeta?.alias || [],
    redirect: component_45stubYjueJ23IJZMeta?.redirect,
    component: component_45stubYjueJ23IJZ
  },
  {
    name: component_45stubYjueJ23IJZMeta?.name ?? undefined,
    path: component_45stubYjueJ23IJZMeta?.path ?? "/product/testovaja-kniga/:pathMatch(.*)",
    meta: component_45stubYjueJ23IJZMeta || {},
    alias: component_45stubYjueJ23IJZMeta?.alias || [],
    redirect: component_45stubYjueJ23IJZMeta?.redirect,
    component: component_45stubYjueJ23IJZ
  },
  {
    name: component_45stubYjueJ23IJZMeta?.name ?? undefined,
    path: component_45stubYjueJ23IJZMeta?.path ?? "/product/the-head-responsible-for-the-organization-and-performance-of-work-in-a-confined-space-imo-resolution-a-1050-27",
    meta: component_45stubYjueJ23IJZMeta || {},
    alias: component_45stubYjueJ23IJZMeta?.alias || [],
    redirect: component_45stubYjueJ23IJZMeta?.redirect,
    component: component_45stubYjueJ23IJZ
  },
  {
    name: component_45stubYjueJ23IJZMeta?.name ?? undefined,
    path: component_45stubYjueJ23IJZMeta?.path ?? "/product/training-for-mooring-and-anchoring-operations/:pathMatch(.*)",
    meta: component_45stubYjueJ23IJZMeta || {},
    alias: component_45stubYjueJ23IJZMeta?.alias || [],
    redirect: component_45stubYjueJ23IJZMeta?.redirect,
    component: component_45stubYjueJ23IJZ
  },
  {
    name: component_45stubYjueJ23IJZMeta?.name ?? undefined,
    path: component_45stubYjueJ23IJZMeta?.path ?? "/product/training-in-the-use-of-a-radar-station-radar-for-those-who-have-previously-completed-this-course",
    meta: component_45stubYjueJ23IJZMeta || {},
    alias: component_45stubYjueJ23IJZMeta?.alias || [],
    redirect: component_45stubYjueJ23IJZMeta?.redirect,
    component: component_45stubYjueJ23IJZ
  },
  {
    name: component_45stubYjueJ23IJZMeta?.name ?? undefined,
    path: component_45stubYjueJ23IJZMeta?.path ?? "/product/training-in-the-use-of-a-radar-station-radar-section-a-1-12-section-b-l-12-mk-stcw-78-as-amended",
    meta: component_45stubYjueJ23IJZMeta || {},
    alias: component_45stubYjueJ23IJZMeta?.alias || [],
    redirect: component_45stubYjueJ23IJZMeta?.redirect,
    component: component_45stubYjueJ23IJZ
  },
  {
    name: component_45stubYjueJ23IJZMeta?.name ?? undefined,
    path: component_45stubYjueJ23IJZMeta?.path ?? "/product/training-in-the-use-of-an-automatic-radar-plotting-system-arpa-section-a-1-12-section-b-i-12",
    meta: component_45stubYjueJ23IJZMeta || {},
    alias: component_45stubYjueJ23IJZMeta?.alias || [],
    redirect: component_45stubYjueJ23IJZMeta?.redirect,
    component: component_45stubYjueJ23IJZ
  },
  {
    name: component_45stubYjueJ23IJZMeta?.name ?? undefined,
    path: component_45stubYjueJ23IJZMeta?.path ?? "/product/training-in-the-use-of-an-automatic-radar-plotting-system-arpa-section-a-1-12-section-b-i-12-five-year-retraining",
    meta: component_45stubYjueJ23IJZMeta || {},
    alias: component_45stubYjueJ23IJZMeta?.alias || [],
    redirect: component_45stubYjueJ23IJZMeta?.redirect,
    component: component_45stubYjueJ23IJZ
  },
  {
    name: component_45stubYjueJ23IJZMeta?.name ?? undefined,
    path: component_45stubYjueJ23IJZMeta?.path ?? "/product/training-in-the-use-of-electronic-cards-for-those-who-have-completed-previous-training",
    meta: component_45stubYjueJ23IJZMeta || {},
    alias: component_45stubYjueJ23IJZMeta?.alias || [],
    redirect: component_45stubYjueJ23IJZMeta?.redirect,
    component: component_45stubYjueJ23IJZ
  },
  {
    name: component_45stubYjueJ23IJZMeta?.name ?? undefined,
    path: component_45stubYjueJ23IJZMeta?.path ?? "/product/training-in-the-use-of-electronic-cartographic-navigation-information-system-ecdis",
    meta: component_45stubYjueJ23IJZMeta || {},
    alias: component_45stubYjueJ23IJZMeta?.alias || [],
    redirect: component_45stubYjueJ23IJZMeta?.redirect,
    component: component_45stubYjueJ23IJZ
  },
  {
    name: component_45stubYjueJ23IJZMeta?.name ?? undefined,
    path: component_45stubYjueJ23IJZMeta?.path ?? "/product/training-in-the-use-of-electronic-cartographic-navigation-information-system-ecdis-five-year-retraining",
    meta: component_45stubYjueJ23IJZMeta || {},
    alias: component_45stubYjueJ23IJZMeta?.alias || [],
    redirect: component_45stubYjueJ23IJZMeta?.redirect,
    component: component_45stubYjueJ23IJZ
  },
  {
    name: component_45stubYjueJ23IJZMeta?.name ?? undefined,
    path: component_45stubYjueJ23IJZMeta?.path ?? "/product/training-in-the-use-of-electronic-maps/:pathMatch(.*)",
    meta: component_45stubYjueJ23IJZMeta || {},
    alias: component_45stubYjueJ23IJZMeta?.alias || [],
    redirect: component_45stubYjueJ23IJZMeta?.redirect,
    component: component_45stubYjueJ23IJZ
  },
  {
    name: component_45stubYjueJ23IJZMeta?.name ?? undefined,
    path: component_45stubYjueJ23IJZMeta?.path ?? "/product/training-of-a-company-officer-responsible-for-security-chapter-xi-2-mk-solas-isps-code-imo-circular-1154",
    meta: component_45stubYjueJ23IJZMeta || {},
    alias: component_45stubYjueJ23IJZMeta?.alias || [],
    redirect: component_45stubYjueJ23IJZMeta?.redirect,
    component: component_45stubYjueJ23IJZ
  },
  {
    name: component_45stubYjueJ23IJZMeta?.name ?? undefined,
    path: component_45stubYjueJ23IJZMeta?.path ?? "/product/training-of-a-ship-safety-officer-chapters-ii-and-iii-of-the-stcw-code-ism-code-isps-imo-model-course-3-11",
    meta: component_45stubYjueJ23IJZMeta || {},
    alias: component_45stubYjueJ23IJZMeta?.alias || [],
    redirect: component_45stubYjueJ23IJZMeta?.redirect,
    component: component_45stubYjueJ23IJZ
  },
  {
    name: component_45stubYjueJ23IJZMeta?.name ?? undefined,
    path: component_45stubYjueJ23IJZMeta?.path ?? "/product/training-of-the-ship-s-officer-in-charge-of-security/:pathMatch(.*)",
    meta: component_45stubYjueJ23IJZMeta || {},
    alias: component_45stubYjueJ23IJZMeta?.alias || [],
    redirect: component_45stubYjueJ23IJZMeta?.redirect,
    component: component_45stubYjueJ23IJZ
  },
  {
    name: component_45stubYjueJ23IJZMeta?.name ?? undefined,
    path: component_45stubYjueJ23IJZMeta?.path ?? "/product/training-of-the-ship-s-officer-in-charge-of-security-for-those-who-have-previously-completed-the-training-course",
    meta: component_45stubYjueJ23IJZMeta || {},
    alias: component_45stubYjueJ23IJZMeta?.alias || [],
    redirect: component_45stubYjueJ23IJZMeta?.redirect,
    component: component_45stubYjueJ23IJZ
  },
  {
    name: component_45stubYjueJ23IJZMeta?.name ?? undefined,
    path: component_45stubYjueJ23IJZMeta?.path ?? "/product/training-on-a-simulator-of-cargo-and-ballast-operations-on-chemical-tankers-section-av-1-2-table-av-1-2-2-mk-stcw-retraining",
    meta: component_45stubYjueJ23IJZMeta || {},
    alias: component_45stubYjueJ23IJZMeta?.alias || [],
    redirect: component_45stubYjueJ23IJZMeta?.redirect,
    component: component_45stubYjueJ23IJZ
  },
  {
    name: component_45stubYjueJ23IJZMeta?.name ?? undefined,
    path: component_45stubYjueJ23IJZMeta?.path ?? "/product/training-on-the-simulator-of-cargo-and-ballast-operations-on-oil-tankers-section-a-v-1-1-mk-stcw-78-as-amended-model-course-imo-2-06-oil-tanker-cargo-and-ballast-handling-simulator-retraining",
    meta: component_45stubYjueJ23IJZMeta || {},
    alias: component_45stubYjueJ23IJZMeta?.alias || [],
    redirect: component_45stubYjueJ23IJZMeta?.redirect,
    component: component_45stubYjueJ23IJZ
  },
  {
    name: component_45stubYjueJ23IJZMeta?.name ?? undefined,
    path: component_45stubYjueJ23IJZMeta?.path ?? "/product/transport-of-dangerous-goods-including-familiarization-with-section-c-49-of-the-us-code-of-federal-regulations-five-year-refresher-training-section-bv-b-bv-c-mk-stcw-78-as-amended",
    meta: component_45stubYjueJ23IJZMeta || {},
    alias: component_45stubYjueJ23IJZMeta?.alias || [],
    redirect: component_45stubYjueJ23IJZMeta?.redirect,
    component: component_45stubYjueJ23IJZ
  },
  {
    name: component_45stubYjueJ23IJZMeta?.name ?? undefined,
    path: component_45stubYjueJ23IJZMeta?.path ?? "/product/transport-of-dangerous-goods-including-familiarization-with-section-c-49-of-the-us-code-of-federal-regulations-section-bv-b-bv-c-mk-stcw-78-as-amended",
    meta: component_45stubYjueJ23IJZMeta || {},
    alias: component_45stubYjueJ23IJZMeta?.alias || [],
    redirect: component_45stubYjueJ23IJZMeta?.redirect,
    component: component_45stubYjueJ23IJZ
  },
  {
    name: component_45stubYjueJ23IJZMeta?.name ?? undefined,
    path: component_45stubYjueJ23IJZMeta?.path ?? "/product/transportation-of-hazardous-substances-on-ships-in-bulk-and-in-packaging-five-year-retraining",
    meta: component_45stubYjueJ23IJZMeta || {},
    alias: component_45stubYjueJ23IJZMeta?.alias || [],
    redirect: component_45stubYjueJ23IJZMeta?.redirect,
    component: component_45stubYjueJ23IJZ
  },
  {
    name: component_45stubYjueJ23IJZMeta?.name ?? undefined,
    path: component_45stubYjueJ23IJZMeta?.path ?? "/product/transportirovka-opasnyh-gruzov-vkljuchaja-oznakomlenie-s-razdelom-s-49-kodeksa-federalnyh-pravil-ssha-pjatiletnjaja-perepodgotovka-razdel-b-v-b-b-v-c-mk-pdnv-78-s-popravkami",
    meta: component_45stubYjueJ23IJZMeta || {},
    alias: component_45stubYjueJ23IJZMeta?.alias || [],
    redirect: component_45stubYjueJ23IJZMeta?.redirect,
    component: component_45stubYjueJ23IJZ
  },
  {
    name: component_45stubYjueJ23IJZMeta?.name ?? undefined,
    path: component_45stubYjueJ23IJZMeta?.path ?? "/product/transportirovka-opasnyh-gruzov-vkljuchaja-oznakomlenie-s-razdelom-s-49-kodeksa-federalnyh-pravil-ssha-razdel-b-v-b-b-v-c-mk-pdnv-78-s-popravkami",
    meta: component_45stubYjueJ23IJZMeta || {},
    alias: component_45stubYjueJ23IJZMeta?.alias || [],
    redirect: component_45stubYjueJ23IJZMeta?.redirect,
    component: component_45stubYjueJ23IJZ
  },
  {
    name: component_45stubYjueJ23IJZMeta?.name ?? undefined,
    path: component_45stubYjueJ23IJZMeta?.path ?? "/product/transshipment-of-dangerous-goods-familiarization-course-for-the-persons-of-the-company-engaged-in-the-transshipment-of-dangerous-goods",
    meta: component_45stubYjueJ23IJZMeta || {},
    alias: component_45stubYjueJ23IJZMeta?.alias || [],
    redirect: component_45stubYjueJ23IJZMeta?.redirect,
    component: component_45stubYjueJ23IJZ
  },
  {
    name: component_45stubYjueJ23IJZMeta?.name ?? undefined,
    path: component_45stubYjueJ23IJZMeta?.path ?? "/product/transshipment-of-dangerous-goods-specialized-course-for-company-persons-who-organize-and-control-the-transshipment-of-dangerous-goods",
    meta: component_45stubYjueJ23IJZMeta || {},
    alias: component_45stubYjueJ23IJZMeta?.alias || [],
    redirect: component_45stubYjueJ23IJZMeta?.redirect,
    component: component_45stubYjueJ23IJZ
  },
  {
    name: component_45stubYjueJ23IJZMeta?.name ?? undefined,
    path: component_45stubYjueJ23IJZMeta?.path ?? "/product/transshipment-of-dangerous-goods-specialized-course-for-company-persons-who-organize-and-control-the-transshipment-of-dangerous-goods-kopirovat",
    meta: component_45stubYjueJ23IJZMeta || {},
    alias: component_45stubYjueJ23IJZMeta?.alias || [],
    redirect: component_45stubYjueJ23IJZMeta?.redirect,
    component: component_45stubYjueJ23IJZ
  },
  {
    name: component_45stubYjueJ23IJZMeta?.name ?? undefined,
    path: component_45stubYjueJ23IJZMeta?.path ?? "/product/transshipment-of-dangerous-goods-specialized-course-for-company-persons-who-organize-and-control-the-transshipment-of-dangerous-goods-kopirovat-kopirovat",
    meta: component_45stubYjueJ23IJZMeta || {},
    alias: component_45stubYjueJ23IJZMeta?.alias || [],
    redirect: component_45stubYjueJ23IJZMeta?.redirect,
    component: component_45stubYjueJ23IJZ
  },
  {
    name: component_45stubYjueJ23IJZMeta?.name ?? undefined,
    path: component_45stubYjueJ23IJZMeta?.path ?? "/product/transshipment-of-dangerous-goods-specialized-course-for-company-persons-who-organize-and-control-the-transshipment-of-dangerous-goods-kopirovat-kopirovat-kopirovat",
    meta: component_45stubYjueJ23IJZMeta || {},
    alias: component_45stubYjueJ23IJZMeta?.alias || [],
    redirect: component_45stubYjueJ23IJZMeta?.redirect,
    component: component_45stubYjueJ23IJZ
  },
  {
    name: component_45stubYjueJ23IJZMeta?.name ?? undefined,
    path: component_45stubYjueJ23IJZMeta?.path ?? "/product/transshipment-of-dangerous-goods-specialized-course-for-company-persons-who-organize-and-control-the-transshipment-of-dangerous-goods-kopirovat-kopirovat-kopirovat-kopirovat",
    meta: component_45stubYjueJ23IJZMeta || {},
    alias: component_45stubYjueJ23IJZMeta?.alias || [],
    redirect: component_45stubYjueJ23IJZMeta?.redirect,
    component: component_45stubYjueJ23IJZ
  },
  {
    name: component_45stubYjueJ23IJZMeta?.name ?? undefined,
    path: component_45stubYjueJ23IJZMeta?.path ?? "/product/transshipment-of-dangerous-goods-specialized-course-for-company-persons-who-organize-and-control-the-transshipment-of-dangerous-goods-kopirovat-kopirovat-kopirovat-kopirovat-2",
    meta: component_45stubYjueJ23IJZMeta || {},
    alias: component_45stubYjueJ23IJZMeta?.alias || [],
    redirect: component_45stubYjueJ23IJZMeta?.redirect,
    component: component_45stubYjueJ23IJZ
  },
  {
    name: component_45stubYjueJ23IJZMeta?.name ?? undefined,
    path: component_45stubYjueJ23IJZMeta?.path ?? "/product/trebovanija-k-kontejneram-cisternam-perevozka-opasnyh-gruzov-v-nih",
    meta: component_45stubYjueJ23IJZMeta || {},
    alias: component_45stubYjueJ23IJZMeta?.alias || [],
    redirect: component_45stubYjueJ23IJZMeta?.redirect,
    component: component_45stubYjueJ23IJZ
  },
  {
    name: component_45stubYjueJ23IJZMeta?.name ?? undefined,
    path: component_45stubYjueJ23IJZMeta?.path ?? "/product/trebovanija-mezhdunarodnoj-konvencii-po-predotvrashheniju-zagrjaznenija-s-sudov-1973-g-izmenennoj-protokolom-1978-g-mk-marpol-73-78",
    meta: component_45stubYjueJ23IJZMeta || {},
    alias: component_45stubYjueJ23IJZMeta?.alias || [],
    redirect: component_45stubYjueJ23IJZMeta?.redirect,
    component: component_45stubYjueJ23IJZ
  },
  {
    name: component_45stubYjueJ23IJZMeta?.name ?? undefined,
    path: component_45stubYjueJ23IJZMeta?.path ?? "/product/trenazher-mashinnogo-otdelenija-vkljuchaja-upravlenie-resursami-mashinnogo-otdelenija-navyki-rukovodstva-i-upravlenija-lichnym-sostavom-engine-room-simulator-razdely-a-iii-1-a-iii-2-i-a-iii-6-v-i-12-m",
    meta: component_45stubYjueJ23IJZMeta || {},
    alias: component_45stubYjueJ23IJZMeta?.alias || [],
    redirect: component_45stubYjueJ23IJZMeta?.redirect,
    component: component_45stubYjueJ23IJZ
  },
  {
    name: component_45stubYjueJ23IJZMeta?.name ?? undefined,
    path: component_45stubYjueJ23IJZMeta?.path ?? "/product/upravlenie-jenergojeffektivnostju-sudna/:pathMatch(.*)",
    meta: component_45stubYjueJ23IJZMeta || {},
    alias: component_45stubYjueJ23IJZMeta?.alias || [],
    redirect: component_45stubYjueJ23IJZMeta?.redirect,
    component: component_45stubYjueJ23IJZ
  },
  {
    name: component_45stubYjueJ23IJZMeta?.name ?? undefined,
    path: component_45stubYjueJ23IJZMeta?.path ?? "/product/upravlenie-resursami-jekipazha/:pathMatch(.*)",
    meta: component_45stubYjueJ23IJZMeta || {},
    alias: component_45stubYjueJ23IJZMeta?.alias || [],
    redirect: component_45stubYjueJ23IJZMeta?.redirect,
    component: component_45stubYjueJ23IJZ
  },
  {
    name: component_45stubYjueJ23IJZMeta?.name ?? undefined,
    path: component_45stubYjueJ23IJZMeta?.path ?? "/product/upravlenie-resursami-mashinnogo-otdelenija-i-nesenie-vahty-engine-room-resource-management-and-teamwork-pravila-iii-1-iii-2-iii-3-razdelah-a-iii-1-a-iii-2-a-iii-3-mk-pdnv-78-s-popravkami",
    meta: component_45stubYjueJ23IJZMeta || {},
    alias: component_45stubYjueJ23IJZMeta?.alias || [],
    redirect: component_45stubYjueJ23IJZMeta?.redirect,
    component: component_45stubYjueJ23IJZ
  },
  {
    name: component_45stubYjueJ23IJZMeta?.name ?? undefined,
    path: component_45stubYjueJ23IJZMeta?.path ?? "/product/upravlenie-riskom-i-rassledovanie-incidentov-tablicy-a-ii-1-a-ii-2-a-iii-1-a-iii-2-a-iii-6-kodeksa-pdnv-rezoljucii-imo-a-849-20-tipovogo-kursa-imo-3-11-2014-goda-izdanija-iii-08-01",
    meta: component_45stubYjueJ23IJZMeta || {},
    alias: component_45stubYjueJ23IJZMeta?.alias || [],
    redirect: component_45stubYjueJ23IJZMeta?.redirect,
    component: component_45stubYjueJ23IJZ
  },
  {
    name: component_45stubYjueJ23IJZMeta?.name ?? undefined,
    path: component_45stubYjueJ23IJZMeta?.path ?? "/product/use-of-ais-equipment-use-of-ais-mk-stcw-78-as-amended/:pathMatch(.*)",
    meta: component_45stubYjueJ23IJZMeta || {},
    alias: component_45stubYjueJ23IJZMeta?.alias || [],
    redirect: component_45stubYjueJ23IJZMeta?.redirect,
    component: component_45stubYjueJ23IJZ
  },
  {
    name: component_45stubYjueJ23IJZMeta?.name ?? undefined,
    path: component_45stubYjueJ23IJZMeta?.path ?? "/product/use-of-lifeboats-rafts-and-rescue-boats-other-than-fast-rescue-boats",
    meta: component_45stubYjueJ23IJZMeta || {},
    alias: component_45stubYjueJ23IJZMeta?.alias || [],
    redirect: component_45stubYjueJ23IJZMeta?.redirect,
    component: component_45stubYjueJ23IJZ
  },
  {
    name: component_45stubYjueJ23IJZMeta?.name ?? undefined,
    path: component_45stubYjueJ23IJZMeta?.path ?? "/product/vahtennyj-motorist/:pathMatch(.*)",
    meta: component_45stubYjueJ23IJZMeta || {},
    alias: component_45stubYjueJ23IJZMeta?.alias || [],
    redirect: component_45stubYjueJ23IJZMeta?.redirect,
    component: component_45stubYjueJ23IJZ
  },
  {
    name: component_45stubYjueJ23IJZMeta?.name ?? undefined,
    path: component_45stubYjueJ23IJZMeta?.path ?? "/product/vapor-control-system-on-board-the-vessel-regulation-a-v-1-mk-stmnv-78-as-amended",
    meta: component_45stubYjueJ23IJZMeta || {},
    alias: component_45stubYjueJ23IJZMeta?.alias || [],
    redirect: component_45stubYjueJ23IJZMeta?.redirect,
    component: component_45stubYjueJ23IJZ
  },
  {
    name: component_45stubYjueJ23IJZMeta?.name ?? undefined,
    path: component_45stubYjueJ23IJZMeta?.path ?? "/product/vn/:pathMatch(.*)",
    meta: component_45stubYjueJ23IJZMeta || {},
    alias: component_45stubYjueJ23IJZMeta?.alias || [],
    redirect: component_45stubYjueJ23IJZMeta?.redirect,
    component: component_45stubYjueJ23IJZ
  },
  {
    name: component_45stubYjueJ23IJZMeta?.name ?? undefined,
    path: component_45stubYjueJ23IJZMeta?.path ?? "/product/voditel-gidrocikla-rajon-plavanija-morskie-puti/:pathMatch(.*)",
    meta: component_45stubYjueJ23IJZMeta || {},
    alias: component_45stubYjueJ23IJZMeta?.alias || [],
    redirect: component_45stubYjueJ23IJZMeta?.redirect,
    component: component_45stubYjueJ23IJZ
  },
  {
    name: component_45stubYjueJ23IJZMeta?.name ?? undefined,
    path: component_45stubYjueJ23IJZMeta?.path ?? "/product/voditel-gidrocikla-rajony-plavanija-vnutrennie-vodnye-puti-i-morskie-puti",
    meta: component_45stubYjueJ23IJZMeta || {},
    alias: component_45stubYjueJ23IJZMeta?.alias || [],
    redirect: component_45stubYjueJ23IJZMeta?.redirect,
    component: component_45stubYjueJ23IJZ
  },
  {
    name: component_45stubYjueJ23IJZMeta?.name ?? undefined,
    path: component_45stubYjueJ23IJZMeta?.path ?? "/product/vyzhivanie-v-jekstremalnyh-pogodnyh-uslovijah-razdel-a-vi-1-1-kodeksa-pdnv-78-s-popravkami",
    meta: component_45stubYjueJ23IJZMeta || {},
    alias: component_45stubYjueJ23IJZMeta?.alias || [],
    redirect: component_45stubYjueJ23IJZMeta?.redirect,
    component: component_45stubYjueJ23IJZ
  },
  {
    name: component_45stubYjueJ23IJZMeta?.name ?? undefined,
    path: component_45stubYjueJ23IJZMeta?.path ?? "/product/watch-minder/:pathMatch(.*)",
    meta: component_45stubYjueJ23IJZMeta || {},
    alias: component_45stubYjueJ23IJZMeta?.alias || [],
    redirect: component_45stubYjueJ23IJZMeta?.redirect,
    component: component_45stubYjueJ23IJZ
  },
  {
    name: component_45stubYjueJ23IJZMeta?.name ?? undefined,
    path: component_45stubYjueJ23IJZMeta?.path ?? "/product/work-of-a-radiotelephone-operator-on-inland-waterways/:pathMatch(.*)",
    meta: component_45stubYjueJ23IJZMeta || {},
    alias: component_45stubYjueJ23IJZMeta?.alias || [],
    redirect: component_45stubYjueJ23IJZMeta?.redirect,
    component: component_45stubYjueJ23IJZ
  },
  {
    name: component_45stubYjueJ23IJZMeta?.name ?? undefined,
    path: component_45stubYjueJ23IJZMeta?.path ?? "/product/working-at-height-level-1/:pathMatch(.*)",
    meta: component_45stubYjueJ23IJZMeta || {},
    alias: component_45stubYjueJ23IJZMeta?.alias || [],
    redirect: component_45stubYjueJ23IJZMeta?.redirect,
    component: component_45stubYjueJ23IJZ
  },
  {
    name: component_45stubYjueJ23IJZMeta?.name ?? undefined,
    path: component_45stubYjueJ23IJZMeta?.path ?? "/product/working-at-height-level-1-2/:pathMatch(.*)",
    meta: component_45stubYjueJ23IJZMeta || {},
    alias: component_45stubYjueJ23IJZMeta?.alias || [],
    redirect: component_45stubYjueJ23IJZMeta?.redirect,
    component: component_45stubYjueJ23IJZ
  },
  {
    name: component_45stubYjueJ23IJZMeta?.name ?? undefined,
    path: component_45stubYjueJ23IJZMeta?.path ?? "/product/working-at-height-level-2/:pathMatch(.*)",
    meta: component_45stubYjueJ23IJZMeta || {},
    alias: component_45stubYjueJ23IJZMeta?.alias || [],
    redirect: component_45stubYjueJ23IJZMeta?.redirect,
    component: component_45stubYjueJ23IJZ
  },
  {
    name: component_45stubYjueJ23IJZMeta?.name ?? undefined,
    path: component_45stubYjueJ23IJZMeta?.path ?? "/product/zanjatie-s-repetitorom-po-anglijskomu-jazyku/:pathMatch(.*)",
    meta: component_45stubYjueJ23IJZMeta || {},
    alias: component_45stubYjueJ23IJZMeta?.alias || [],
    redirect: component_45stubYjueJ23IJZMeta?.redirect,
    component: component_45stubYjueJ23IJZ
  }
]